import React from 'react';

import { LayoutStackContentModel, LayoutStackDesignModel } from "../../models/blocks/layoutStackModel";
import BlockRender from '../renders/blockRender';
import genUniqueId from "../../models/utils/genUniqueId";
import DoodleWrapper from "../common/doodleWrapper";
import BlockComponent from './blockComponent';
import { generateContext } from '../renders/prerenderModel';
import SeparatorContainer from '../separators/separatorContainer';

export default class LayoutStack extends BlockComponent {

    private id: string = genUniqueId();

    render() {

        // content
        const contentModel = new LayoutStackContentModel();
        contentModel.initialize(this.props.context.targetBlock.blocksProps);

        const navbar = contentModel.getNavbar();
        const blocks = contentModel.getInnerBlocks();

        // design
        const designProperties = this.props.context.commonProperties.concat(this.props.context.targetBlock.blocksProps);
        const designModel = new LayoutStackDesignModel();
        designModel.setDefaultValues(this.props.context);
        designModel.setCustomValues(designProperties);

        const backgroundStyle = designModel.generateBackgroundStyle(this.props.context);
        const contentStyle = designModel.generateContentStyle(this.props.context);
        const doodle = designModel.generateDoodle();
        const hashNavigation = designModel.generateHashNavigationName();
        const navbarStyle = designModel.generateNavbarStyle();
        const separatorType = designModel.getSeparator();

        if (navbar != null) {
            backgroundStyle.gridTemplateRows = "auto 1fr";
        }

        contentStyle.gridTemplateRows = blocks.length > 0 ? `repeat(${blocks.length - 1}, auto) 1fr` : null;

        const childCommonProps = designModel.formatCommonProps(designProperties);

        // effect appear
        const effectAppearName = designModel.generateEffectAppear();

        // if it has navbar than we must increase z-index
        if (navbar != null) {
            backgroundStyle.zIndex = "var(--z-index-navbar-container)";
        }

        const resolvedContentWidth = designModel.getResolvedContentWidth();
        const resolvedBackgroundColor = designModel.getActiveBackgroundColor();

        const navbarContext = navbar == null ? null :
        {
            environment: this.props.context.environment,

            prevBlock: this.props.context.prevBlock,
            nextBlock: this.props.context.nextBlock,
            parentBlock: this.props.context,

            parentBlockWidth: resolvedContentWidth,
            parentBlockColor: resolvedBackgroundColor,
            parentBlockAnimation: effectAppearName,

            rootSectionType: this.props.context.rootSectionType,

            targetBlock: navbar,
            targetBlockType: navbar.blocksType,
            targetColorSchema: this.props.context.targetColorSchema,
            targetHasBackgroundImage: false,

            commonProperties: childCommonProps,
        };

        const blocksContexts = generateContext(blocks, this.props.context.environment, this.props.context, navbarContext);
        for (const contextItem of blocksContexts) {
            contextItem.parentBlockWidth = resolvedContentWidth;
            contextItem.parentBlockColor = resolvedBackgroundColor;
            contextItem.parentBlockAnimation = effectAppearName;
            contextItem.commonProperties = childCommonProps;
        }

        if (navbarContext != null && blocksContexts.length > 0) {
            navbarContext.nextBlock = blocksContexts[0];
        }

        return (
            <div id={hashNavigation} style={backgroundStyle}>
                {
                    doodle != null &&
                    <DoodleWrapper rule={doodle} />
                }
                {
                    navbar != null &&
                    <div style={navbarStyle}>
                        <BlockRender context={navbarContext} />
                    </div>
                }
                <div style={contentStyle}>
                    {
                        blocksContexts.map((context, index) => <BlockRender key={`content-${this.id}-${index}`} context={context} />)
                    }
                </div>
                {
                    separatorType != null &&
                    <SeparatorContainer context={this.props.context} separatorType={separatorType} />
                }
            </div>
        );
    }
}