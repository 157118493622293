import React from 'react';

interface ICustomRenderLayoutProps {
    children?: React.ReactNode
}

export default class CustomRenderLayout extends React.Component<ICustomRenderLayoutProps> {

    render() {
        return (
            <div className="min-vh-100 w-100 d-flex flex-fill">
                {this.props.children}
            </div>
        );
    }
}