import React from 'react';

import Style from "../../models/style";
import PointPageEnvironment from "../renders/pointPageEnvironment";

interface IProps {
    environment: PointPageEnvironment;
    textStyle: Style;
}

export default class PolicyLinks extends React.Component<IProps> {

    render() {

        let termsOfServiceTitle = "Regulamin";

        const language = this.props.environment.getLanguage();
        switch (language.toUpperCase()) {
            case "EN":
                termsOfServiceTitle = "Terms Of Service";
                break;
            case "CZ":
                termsOfServiceTitle = "Podmínky služby";
                break;
            case "RU":
                termsOfServiceTitle = "Условия использования";
                break;
        }

        return (
            <React.Fragment>
                <a style={this.props.textStyle} className="text-nowrap" href="#" onClick={() => this.props.environment.currentLocation.openTermsOfService()}>{termsOfServiceTitle}</a>
                <span style={this.props.textStyle}>|</span>
                <a style={this.props.textStyle} className="text-nowrap" href="#" onClick={() => this.props.environment.currentLocation.openCookiePolicy()}>Cookies</a>
                <span style={this.props.textStyle}>|</span>
                <a style={this.props.textStyle} className="text-nowrap bi bi-rocket-takeoff-fill" href="https://lifedots.pl" target="_blank"/>
            </React.Fragment>
        );
    }
}