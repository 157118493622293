import React from 'react';

import { MapSimpleContentModel, MapSimpleDesignModel } from "../../models/blocks/mapSimpleModel";
import DoodleWrapper from "../common/doodleWrapper";
import BlockComponent from './blockComponent';
import TransactionContainer from '../effects/transactionContainer';
import SeparatorContainer from '../separators/separatorContainer';

export default class EditorMapSimple extends BlockComponent {

    render() {

        // content
        const block = new MapSimpleContentModel();
        block.initialize(this.props.context.targetBlock.blocksProps);

        const address = block.getAddress();

        // design
        const designProperties = this.props.context.commonProperties.concat(this.props.context.targetBlock.blocksProps);
        const designModel = new MapSimpleDesignModel();
        designModel.setDefaultValues(this.props.context);
        designModel.setCustomValues(designProperties);

        const backgroundStyle = designModel.generateBackgroundStyle(this.props.context);
        const contentStyle = designModel.generateContentStyle(this.props.context);
        const doodle = designModel.generateDoodle();
        const hashNavigation = designModel.generateHashNavigationName();
        const separatorType = designModel.getSeparator();

        const mapUrl = "https://maps.google.com/maps?width=100%&height=100%&hl=en&q=" + encodeURI(address) + "&z=15&ie=UTF8&iwloc=B&output=embed";

        // effect appear
        const effectAppearName = designModel.generateEffectAppear();

        return (
            <div id={hashNavigation} style={backgroundStyle}>
                {
                    doodle != null &&
                    <DoodleWrapper rule={doodle} />
                }
                <TransactionContainer style={contentStyle} effect={effectAppearName}>
                    <div className="w-100 d-grid" style={{ minHeight: "400px", maxHeight: "400px" }}>
                        <iframe className="w-100 h-100" scrolling="no" src={mapUrl} />
                    </div>
                </TransactionContainer>
                {
                    separatorType != null &&
                    <SeparatorContainer context={this.props.context} separatorType={separatorType} />
                }
            </div>
        );
    }
}