import { IPageBlockProperty, IPageRenderedBlockProperty } from "../../models/pageLayoutModel";
import CommonDesignModel from "./commonDesignModel";
import { IBlockModel } from "../modelsHelper";
import RenderBlockContext from "../../controls/renders/renderBlockContext";

export class SeparatorEmptyContentModel implements IBlockModel {
    
    initialize(properties: IPageRenderedBlockProperty[]) { }

    formatBlock(properties: IPageBlockProperty[]): IPageBlockProperty[] {
        return [];
    }
}

export class SeparatorEmptyDesignModel extends CommonDesignModel {

    constructor() {
        super();

        this.allowBackground = true;
        this.allowContentWidth = false;
        this.allowContentMargin = false;
        this.allowBlockHieghtAndMargin = true;
        this.allowHashNavigation = false;
        this.allowHorizontalAlignment = false;
        this.allowVerticalAlignment = false;
        this.allowTextStyle = false;
        this.allowAppearEffect = false;
        this.allowDoodle = true;
    }

    public setDefaultValues(renderContext: RenderBlockContext) {
        super.setDefaultValues(renderContext);

        this.defaultHorizontalAlignment = "fill";
        this.defaultWidth = "max";
        this.defaultContentMarginTop = "none";
        this.defaultContentMarginBottom = "none";
    }
}