import React from 'react';
import "reflect-metadata";

import { CloseButton } from "react-bootstrap";
import CustomRenderLayout from "../../layouts/customRenderLayout";
import BlockRender from "./blockRender";
import PointPageEnvironment from "./pointPageEnvironment";
import genUniqueId from "../../models/utils/genUniqueId";
import { generateContext } from './prerenderModel';

interface IPointPageProps {
    environment: PointPageEnvironment,
}

interface IPointPageState {
    isLoginIn: boolean,
    isAcceptedCookies: boolean,
}

export default class PointPageRender extends React.Component<IPointPageProps, Partial<IPointPageState>> {

    private id: string = genUniqueId();

    constructor(props: any) {
        super(props);

        this.state = {
            isLoginIn: this.props.environment.isLoggedIn(),
            isAcceptedCookies: this.props.environment.getAcceptCookiesMark(),
        };

        this.onAcceptCookies = this.onAcceptCookies.bind(this);
    }

    onAcceptCookies() {
        this.props.environment.setAcceptCookiesMark();
        this.setState({ isAcceptedCookies: true });
    }

    render() {
        
        const blocks = this.props.environment.getBlocks();
        const language = this.props.environment.getLanguage().toUpperCase();

        const politykaLink = "https://lifedots.pl/cookie-policy";
        let politykaContent: React.ReactElement = null;

        switch (language) {
            case "EN":
                politykaContent = (<React.Fragment>By using the website, you agree to the use of cookies, which are installed on your device. You can learn more about this in the <a href={politykaLink}>Privacy Policy</a>.</React.Fragment>);
                break;
            case "CZ":
                politykaContent = (<React.Fragment>Používáním této stránky souhlasíte s používáním souborů cookie, které jsou nainstalovány na vašem zařízení. Více informací o tomto naleznete v naší <a href={politykaLink}>Polityce Ochrany Osobních Údajů</a>.</React.Fragment>);
                break;
            case "RU":
                politykaContent = (<React.Fragment>Используя этот веб-сайт, вы соглашаетесь на использование файлов cookie, которые устанавливаются на вашем устройстве. Вы можете узнать больше об этом в <a href={politykaLink}>Политике Конфиденциальности</a>.</React.Fragment>);
                break;
            case "PL":
            default:
                politykaContent = (<React.Fragment>Korzystając ze strony zgadzasz się na używanie plików cookie, które są instalowane na Twoim urządzeniu. Więcej o tym dowiesz się w <a href={politykaLink}>Polityce Prywatności</a>.</React.Fragment>);
                break;
        }

        const blocksContexts = generateContext(blocks, this.props.environment, null, null);

        return (
            <CustomRenderLayout>

                <div className="d-flex flex-column w-100">

                    {
                        blocksContexts.map((context, index) => <BlockRender key={`block-${this.id}-${index}`} context={context}/>)
                    }

                </div>

                {
                    !this.state.isAcceptedCookies &&
                    <div className="fixed-bottom bg-white card card-shadow shadow-lg p-3 m-2 d-flex flex-row gap-2">
                        <div className="lp-text-small flex-fill">{politykaContent}</div>
                        <CloseButton onClick={this.onAcceptCookies} className="mt-1" />
                    </div>
                }

            </CustomRenderLayout>
        );
    }
}