import React from 'react';

import { Button, Row, Col, Container } from "react-bootstrap";
import * as Logger from "../utils/logger";

import CustomRenderLayout from "../layouts/customRenderLayout";

export default class NotFoundPage extends React.Component {

    constructor(prop: any) {
        super(prop);

        document.title = "Not Found";
    }

    componentDidMount() {
        Logger.Core.warn("404 error: " + window.location.pathname);
    }

    render() {
        return (
            <CustomRenderLayout>
                <Container fluid className="d-flex flex-column">
                    <Row className="mt-5">
                        <Col />
                        <Col xs="auto">
                            <h1 className="lp-text-pagetitle">Strona nie znaleziona</h1>
                        </Col>
                        <Col />
                    </Row>
                    <Row>
                        <Col />
                        <Col xs="auto">
                            <div className="lp-text-subtitle">Strona jest nieaktualna, usunięta lub w ogóle nie istniała.</div>
                        </Col>
                        <Col />
                    </Row>
                    <Row className="mt-5">
                        <Col />
                        <Col xs="auto">
                            <Button href="/">Powrót do strony głównej</Button>
                        </Col>
                        <Col />
                    </Row>
                </Container>
            </CustomRenderLayout>
        );
    }
}