import React from 'react';

import { CoverTitleSubtitleContentModel, CoverTitleSubtitleDesignModel } from "../../models/blocks/coverTitleSubtitleModel";
import DoodleWrapper from "../common/doodleWrapper";
import BlockComponent from './blockComponent';
import TransactionContainer from '../effects/transactionContainer';
import SeparatorContainer from '../separators/separatorContainer';
import { BlockType } from '../../models/blockType';
import { WebpageStyleType } from '../renders/pointPageEnvironment';

export default class CoverTitleSubtitle extends BlockComponent {

    render() {

        // content
        const contentModule = new CoverTitleSubtitleContentModel();
        contentModule.initialize(this.props.context.targetBlock.blocksProps);

        const uppertitle = contentModule.getUppertitle();
        const title = contentModule.getTitle();
        const subtitle = contentModule.getSubtitle();

        // design
        const designModel = new CoverTitleSubtitleDesignModel();
        designModel.setDefaultValues(this.props.context);

        if (this.props.context.parentBlock != null &&
            this.props.context.parentBlock.targetBlockType === BlockType.LayoutStack &&
            this.props.context.nextBlock != null) {

            switch (this.props.context.nextBlock.targetBlockType) {
            case BlockType.Map:
            case BlockType.ContactsInIcons:
            case BlockType.ContactsSimple:
            case BlockType.GalleryPolaroid:
            case BlockType.GalleryPreview:
            case BlockType.GallerySimple:
            case BlockType.ImageSimple:
            case BlockType.YoutubeFrame:
            case BlockType.ButtonsVertical:
            case BlockType.ButtonsVerticalWithContacts:
            case BlockType.CardsSmall:
            case BlockType.SimpleCards:
            case BlockType.Faq:
            case BlockType.PostFeed:
            case BlockType.PostFeedInColumns:
            case BlockType.Numbers:
                designModel.defaultTextAndButtonsAlignment = "middle";
                break;
            case BlockType.TimelinePoints:
                designModel.defaultTextAndButtonsAlignment = this.props.context.environment.getWebpageStyle() === WebpageStyleType.Modern ? "middle" : "left";
                break;
            }
        }
        else if (this.props.context.parentBlock != null &&
            this.props.context.parentBlock.targetBlockType === BlockType.LayoutTwoColumnsAndHeader &&
            (this.props.context.prevBlock == null || this.props.context.prevBlock.parentBlock !== this.props.context.parentBlock)) {

            designModel.defaultTextAndButtonsAlignment = "middle";
        }

        const designProperties = this.props.context.commonProperties.concat(this.props.context.targetBlock.blocksProps);
        designModel.setCustomValues(designProperties);

        const backgroundStyle = designModel.generateBackgroundStyle(this.props.context);
        const contentStyle = designModel.generateContentStyle(this.props.context);
        const textStyle = designModel.generateTextStyle();
        const fontClassModificator = designModel.getFontClassModificator();
        const doodle = designModel.generateDoodle();
        const hashNavigation = designModel.generateHashNavigationName();
        const effectAppearName = designModel.generateEffectAppear();
        const separatorType = designModel.getSeparator();

        return (
            <div id={hashNavigation} style={backgroundStyle}>
                {
                    doodle != null &&
                    <DoodleWrapper rule={doodle} />
                }
                <TransactionContainer style={contentStyle} effect={effectAppearName}>
                    <div className="d-flex flex-column gap-2 w-100">
                        {
                            uppertitle != null && uppertitle.length > 0 &&
                            <div className={`lp-text-uppertitle last-without-margin ${fontClassModificator}`} style={textStyle} dangerouslySetInnerHTML={{ __html: uppertitle }} />
                        }

                        {
                            title != null && title.length > 0 &&
                            <h1 className={`lp-text-pagetitle last-without-margin mb-0 ${fontClassModificator}`} style={textStyle} dangerouslySetInnerHTML={{ __html: title }} />
                        }

                        {
                            subtitle != null && subtitle.length > 0 &&
                            <div className={`lp-text-subtitle last-without-margin ${fontClassModificator}`} style={textStyle} dangerouslySetInnerHTML={{ __html: subtitle }} />
                        }
                    </div>
                </TransactionContainer>

                {
                    separatorType != null &&
                    <SeparatorContainer context={this.props.context} separatorType={separatorType} />
                }

            </div>
        );
    }
}