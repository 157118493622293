import "reflect-metadata";

import "reflect-metadata";
import { resolve } from 'inversify-react';
import { injectable } from "inversify";

import WebRequest from "../utils/webRequest";
import UserInfo from "./userInfo";
import { IId } from "./entities/iidname";

export interface IRender {

    pointId: string;

    pointName: string;
    pointLanguages: string[];
    pointFavicon: string;

    navigationPages: INavigationPage[];
    navigationHelpPages: INavigationHelpPage[];

    isPost: boolean;
    language: string;
    
    content: string;
    innerContent: string;
    visualStyle: number;
    businessType: number;
    colors: string;
    pageType: number;

    publishDate: string;
    path: string;
    id: string;

    address: string;
    mail: string;
    telephone: string;
    facebook: string;
    youtube: string;
    instagramId: string;
    telegramId: string;
    whatsAppId: string;
    twitterId: string;
    pinterestId: string;
    linkedinId: string;

    seoTitle: string;
    seoDescription: string;
    seoImageId: string;
}

export interface INavigationPage {
    title: string;
    path: string;
}

export interface INavigationHelpPage {
    id: string;
    category: string;
    title: string;
    path: string;
}

export interface IPageContent {
    content: string;
}

export interface ICount {
    count: number;
}

export interface IPageNameSettings {
    title: string;
    path: string;
    type: number;
}

export interface IPageTitle {
    title: string;
}

export interface IColors {
    colors: string;
}

export interface IHelpPageTitle {
    category: string;
    title: string;
}

export interface IPageDescription {
    description: string;
}

export interface IPageImage {
    imageId: string;
}

export interface IPostHeader {
    header: string;
}

export interface IPageShortDetails {
    id: string;
    path: string;
    title: string;
    isVisible: boolean;
    showOnNavbar: boolean;
}

export interface IPostShortDetails {
    id: string;
    title: string;
    description: string;
    imageId: string;
    publishDate: string;
}

export interface IHelpShortDetails {
    id: string;
    category: string;
    title: string;
    isVisible: boolean;
}

export interface IPageVisibility {
    visibile: boolean;
    visibileOnNavbar: boolean;
}

export interface IPostVisibility {
    visibile: boolean;
}

export interface IPageSeo {
    settings: string;
}

export interface IContent {
    content: string;
}

@injectable()
export class PagesInfoProvider {

    public user: UserInfo;

    public async render(domain: string, path: string, paginationPosition: number): Promise<IRender> {

        let url = "/api/points/v1/render/" + domain + "/json?path=" + path;
        if (paginationPosition != null) {
            url += "&page=" + paginationPosition;
        }

        const json = await WebRequest.requestItem<IRender>(url, "GET", null, null);
        return json;
    }

    public async registerPageRendering(pointId: string, pageId: string, userAgent: string, referrer: string): Promise<string> {

        return WebRequest.requestText("/api/statistics/v1/entries/increaseForPage", "POST", null,
            {
                pointId: pointId,
                pageId: pageId,
                userKey: this.user.getUserKey(),
                userAgent: userAgent,
                referrer: referrer
            });
    }

    public async registerPostRendering(pointId: string, postId: string, userAgent: string, referrer: string): Promise<string> {

        return WebRequest.requestText("/api/statistics/v1/entries/increaseForPost", "POST", this.user.getUserToken(),
            {
                pointId: pointId,
                postId: postId,
                userKey: this.user.getUserKey(),
                userAgent: userAgent,
                referrer: referrer
            });
    }
    
    public updatePageNameSettings(pageId: string, title: string, path: string, type: number): Promise<string> {
        return WebRequest.requestText("/api/points/v1/pages/" + pageId + "/nameSettings", "POST", this.user.getUserToken(),
            {
                "title": title,
                "path": path,
                "type": type,
            });
    }
    
    public updatePageContent(pageId: string, content: string): Promise<string> {
        return WebRequest.requestText("/api/points/v1/pages/" + pageId + "/content", "POST", this.user.getUserToken(), content);
    }

    public getPageContent(pageId: string): Promise<IPageContent> {
        return WebRequest.requestItem<IPageContent>("/api/points/v1/pages/" + pageId + "/content", "GET", this.user.getUserToken(), null);
    }

    public getPageNameSettings(pageId: string): Promise<IPageNameSettings> {
        return WebRequest.requestItem<IPageNameSettings>("/api/points/v1/pages/" + pageId + "/nameSettings", "GET", this.user.getUserToken(), null);
    }

    public getVisibilitySettings(pageId: string): Promise<IPageVisibility> {
        return WebRequest.requestItem<IPageVisibility>("/api/points/v1/pages/" + pageId + "/visibility", "GET", this.user.getUserToken(), null);
    }

    public getSeoSettings(pageId: string): Promise<IPageSeo> {
        return WebRequest.requestItem<IPageSeo>("/api/points/v1/pages/" + pageId + "/seo", "GET", this.user.getUserToken(), null);
    }
    
    public getPageTitle(pageId: string): Promise<IPageTitle> {
        return WebRequest.requestItem<IPageTitle>("/api/points/v1/pages/" + pageId + "/title", "GET", this.user.getUserToken(), null);
    }

    public getPageColors(pageId: string): Promise<IColors> {
        return WebRequest.requestItem<IColors>("/api/points/v1/pages/" + pageId + "/colors", "GET", this.user.getUserToken(), null);
    }

    public setPageColors(pageId: string, colors: string): Promise<string> {
        return WebRequest.requestText("/api/points/v1/pages/" + pageId + "/colors", "POST", this.user.getUserToken(),
            {
                "colors": colors
            });
    }

    public getPostColors(postId: string): Promise<IColors> {
        return WebRequest.requestItem<IColors>("/api/points/v1/posts/" + postId + "/colors", "GET", this.user.getUserToken(), null);
    }

    public setPostColors(postId: string, colors: string): Promise<string> {
        return WebRequest.requestText("/api/points/v1/posts/" + postId + "/colors", "POST", this.user.getUserToken(),
            {
                "colors": colors
            });
    }

    public addPage(pointId: string, path: string, title: string, pageType: number): Promise<IId> {
        return WebRequest.requestItem<IId>("/api/points/v1/pages/create", "POST", this.user.getUserToken(),
            {
                "pointId": pointId,
                "path": path,
                "title": title,
                "pageType": pageType,
            });
    }

    public deletePage(pageId: string): Promise<string> {
        return WebRequest.requestText("/api/points/v1/pages/" + pageId + "/delete", "POST", this.user.getUserToken(), null);
    }
    
    public increaseOrder(pageId: string): Promise<string> {
        return WebRequest.requestText("/api/points/v1/pages/" + pageId + "/increaseOrder", "POST", this.user.getUserToken(), null);
    }

    public decreaseOrder(pageId: string): Promise<string> {
        return WebRequest.requestText("/api/points/v1/pages/" + pageId + "/decreaseOrder", "POST", this.user.getUserToken(), null);
    }

    public searchPage(pointId: string, path: string, onNavbarOnly: boolean): Promise<IPageShortDetails[]> {

        let query = "&pointId=" + pointId;
        
        if (path != null && path.length > 0) {
            query += "&path=" + path;
        }

        if (onNavbarOnly) {
            query += "&visibleOnNavbarOnly=" + (onNavbarOnly ? "true" : "false");
        }
        
        let url = "/api/points/v1/pages/public?" + query.substr(1);
        return WebRequest.requestItem<IPageShortDetails[]>(url, "GET", null, null);
    }
    
    public getAllPages(pointId: string): Promise<IPageShortDetails[]> {
        return WebRequest.requestItem<IPageShortDetails[]>("/api/points/v1/pages/all?pointId=" + pointId, "GET", this.user.getUserToken(), null);
    }
    
    public getAllPosts(pointId: string): Promise<IPostShortDetails[]> {

        return WebRequest.requestItem<IPostShortDetails[]>("/api/points/v1/posts/all?pointId=" + pointId, "GET", this.user.getUserToken(), null);
    }
    
    public getAllHelp(pointId: string): Promise<IHelpShortDetails[]> {

        return WebRequest.requestItem<IHelpShortDetails[]>("/api/points/v1/help/all?pointId=" + pointId, "GET", this.user.getUserToken(), null);
    }
    
    public addHelpPage(pointId: string, category: string, title: string, content: string): Promise<IId> {
        return WebRequest.requestItem<IId>("/api/points/v1/help/create", "POST", this.user.getUserToken(),
            {
                "pointId": pointId,
                "category": category,
                "title": title,
                "content": content
            });
    }

    public deleteHelpPage(helpId: string): Promise<string> {
        return WebRequest.requestText("/api/points/v1/help/" + helpId + "/delete", "POST", this.user.getUserToken(), null);
    }

    public getHelpPageTitle(helpId: string): Promise<IHelpPageTitle> {
        return WebRequest.requestItem<IHelpPageTitle>("/api/points/v1/help/" + helpId + "/title", "GET", this.user.getUserToken(), null);
    }

    public getHelpPageVisibility(helpId: string): Promise<IPostVisibility> {
        return WebRequest.requestItem<IPostVisibility>("/api/points/v1/help/" + helpId + "/visibility", "GET", this.user.getUserToken(), null);
    }

    public getHelpPageSeo(helpId: string): Promise<IPageSeo> {
        return WebRequest.requestItem<IPageSeo>("/api/points/v1/help/" + helpId + "/seo", "GET", this.user.getUserToken(), null);
    }

    public updateHelpPageTitle(helpId: string, category: string, title: string): Promise<string> {
        return WebRequest.requestText("/api/points/v1/help/" + helpId + "/title", "POST", this.user.getUserToken(),
            {
                "category": category,
                "title": title,
            });
    }

    public updateHelpPageVisibility(helpId: string, visibile: boolean): Promise<string> {
        return WebRequest.requestText("/api/points/v1/help/" + helpId + "/visibility", "POST", this.user.getUserToken(),
            {
                "visibile": visibile,
            });
    }

    public updateHelpPageSeo(helpId: string, settings: string): Promise<string> {
        return WebRequest.requestText("/api/points/v1/help/" + helpId + "/seo", "POST", this.user.getUserToken(),
            {
                "settings": settings,
            });
    }

    public getHelpPageContent(helpId: string): Promise<IPageContent> {
        return WebRequest.requestItem<IPageContent>("/api/points/v1/help/" + helpId + "/content", "GET", this.user.getUserToken(), null);
    }

    public updateHelpPageContent(helpId: string, content: string): Promise<string> {
        return WebRequest.requestText("/api/points/v1/help/" + helpId + "/content", "POST", this.user.getUserToken(), content);
    }

    public increaseHelpPageOrder(helpId: string): Promise<string> {
        return WebRequest.requestText("/api/points/v1/help/" + helpId + "/increaseOrder", "POST", this.user.getUserToken(), null);
    }

    public decreaseHelpPageOrder(helpId: string): Promise<string> {
        return WebRequest.requestText("/api/points/v1/help/" + helpId + "/decreaseOrder", "POST", this.user.getUserToken(), null);
    }

    public getPublicPosts(pointId: string, language: string, skip?: number, take?: number): Promise<IPostShortDetails[]> {

        let query = "pointId=" + pointId;

        if (language != null) {
            query += "&language=" + language;
        }

        if (skip != null) {
            query += "&skip=" + skip;
        }

        if (take != null) {
            query += "&take=" + take;
        }

        return WebRequest.requestItem<IPostShortDetails[]>("/api/points/v1/posts/public?" + query, "GET", this.user.getUserToken(), null);
    }
    
    public getPublicPostsCount(pointId: string): Promise<ICount> {

        return WebRequest.requestItem<ICount>("/api/points/v1/posts/publicCount?pointId=" + pointId, "GET", this.user.getUserToken(), null);
    }
    
    public addPost(pointId: string, title: string, content: string): Promise<IId> {
        return WebRequest.requestItem<IId>("/api/points/v1/posts/create", "POST", this.user.getUserToken(),
            {
                "pointId": pointId,
                "title": title,
                "content": content
            });
    }

    public updatePostTitle(postId: string, title: string): Promise<string> {
        return WebRequest.requestText("/api/points/v1/posts/" + postId + "/title", "POST", this.user.getUserToken(),
            {
                "value": title,
            });
    }
    
    public updateVisibilitySettings(pageId: string, visibile: boolean, visibileOnNavbar: boolean): Promise<string> {
        return WebRequest.requestText("/api/points/v1/pages/" + pageId + "/visibility", "POST", this.user.getUserToken(),
            {
                "visibile": visibile,
                "visibileOnNavbar": visibileOnNavbar,
            });
    }

    public updateSeoSettings(pageId: string, settings: string): Promise<string> {
        return WebRequest.requestText("/api/points/v1/pages/" + pageId + "/seo", "POST", this.user.getUserToken(),
            {
                "settings": settings,
            });
    }
    
    public getPostTitle(postId: string): Promise<IPageTitle> {
        return WebRequest.requestItem<IPageTitle>("/api/points/v1/posts/" + postId + "/title", "GET", this.user.getUserToken(), null);
    }

    public updatePostContent(postId: string, content: string): Promise<string> {
        return WebRequest.requestText("/api/points/v1/posts/" + postId + "/content", "POST", this.user.getUserToken(), content);
    }

    public getPostContent(postId: string): Promise<IPageContent> {
        return WebRequest.requestItem<IPageContent>("/api/points/v1/posts/" + postId + "/content", "GET", this.user.getUserToken(), null);
    }

    public deletePost(postId: string): Promise<string> {
        return WebRequest.requestText("/api/points/v1/posts/" + postId + "/delete", "POST", this.user.getUserToken(), null);
    }

    public getPostVisibility(postId: string): Promise<IPostVisibility> {
        return WebRequest.requestItem<IPostVisibility>("/api/points/v1/posts/" + postId + "/visibility", "GET", this.user.getUserToken(), null);
    }

    public updatePostVisibility(postId: string, visibile: boolean): Promise<string> {
        return WebRequest.requestText("/api/points/v1/posts/" + postId + "/visibility", "POST", this.user.getUserToken(),
            {
                "visibile": visibile,
            });
    }

    public getPostSeo(postId: string): Promise<IPageSeo> {
        return WebRequest.requestItem<IPageSeo>("/api/points/v1/posts/" + postId + "/seo", "GET", this.user.getUserToken(), null);
    }

    public updatePostSeo(postId: string, settings: string): Promise<string> {
        return WebRequest.requestText("/api/points/v1/posts/" + postId + "/seo", "POST", this.user.getUserToken(),
            {
                "settings": settings,
            });
    }

    public generatePage(pageType: string, pointName: string): Promise<IContent> {
        return WebRequest.requestItem<IContent>("/api/generator/v1/page", "POST", this.user.getUserToken(),
            {
                "pointName": pointName,
                "pointType": "other",
                "pageType": pageType,
            });
    }
}