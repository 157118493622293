import React from 'react';

import "reflect-metadata";
import { resolve } from 'inversify-react';

import { Container, Row, Col, FormControl, FormLabel, FormSelect, Figure, Button, Form } from "react-bootstrap";

import WaitPanel from "../../controls/waitPanel";

import { ErrorDetails, ErrorPanel } from "../../controls/errorPanel";
import ComponentBase from "../common/componentBase";
import UserInfo from "../../models/userInfo";
import { IAccountDetails, AccountInfoProvider } from "../../models/accountInfoProvider";
import { IImageEditorContext, InnerImageEditor } from "../../controls/common/innerImageEditor";
import { SubmitForm } from '../../controls/common/submitForm';

interface IState {
    isLoaded: boolean,
    image: IImageEditorContext,
    account: IAccountDetails,
    error: ErrorDetails,
}

export default class AccountDetailsPage extends ComponentBase<any, IState> {

    @resolve(UserInfo)
    private readonly user: UserInfo;

    @resolve(AccountInfoProvider)
    private readonly accountProvider: AccountInfoProvider;

    constructor(prop: any) {
        super(prop);

        document.title = "Konto";

        this.state = {
            isLoaded: false,
            image: { imageId: null },
            account: null,
            error: null,
        };

        this.onSave = this.onSave.bind(this);
    }

    componentDidMount(): void {

        this.generateStartState()
            .then(x => this.setState(x))
            .catch((error: Error) => this.processError("Cannot receive some details", error));
    }

    async generateStartState(): Promise<IState> {

        const account = await this.accountProvider.getAccountDetails();

        return {
            isLoaded: true,
            image: { imageId: account.image },
            account: account,
            error: null,
        };
    }

    async onSave(form: HTMLFormElement): Promise<boolean> {

        try {

            const firstName = form["txtFirstName"].value;
            const lastName = form["txtLastName"].value;
            const birthday: number = form["txtBirthday"].value;
            const gender: number = form["txtGender"].value;
            const location = form["txtLocation"].value;

            await this.accountProvider.updateAccountName(firstName, lastName);
            await this.accountProvider.updateAccountBirthday(birthday);
            await this.accountProvider.updateAccountGender(gender < 0 ? null : gender);
            await this.accountProvider.updateAccountLocation(location);
            await this.accountProvider.updateAccountImage(this.state.image.imageId);

            this.user.refreshUserDetails();

            const account = await this.accountProvider.getAccountDetails();
            this.setState({ account: account });
            return true;
        }
        catch (error) {
            this.processError("New user creation failed", error as Error);
            return false;
        }
    }

    genderToString(gender: number) {
        switch (gender) {
            case 0:
                return "Mężczyzna";
            case 1:
                return "Kobieta";
            default:
                return "Inna";
        }
    }

    private convert(date: Date): string {
        return date.toISOString().split('T')[0];
    }

    render() {
        
        if (!this.state.isLoaded) {
            return (
                <React.Fragment>
                    <WaitPanel />
                    <ErrorPanel error={this.state.error} />
                </React.Fragment>
            );
        }

        return (
            <React.Fragment>

                <Container fluid className="bg-white card card-shadow py-3">
                    <Row>
                        <h2 className="pt-2">Szczegóły</h2>
                    </Row>

                    <Row>
                        <Col>
                            <SubmitForm onSave={this.onSave} submitText="Zapisać">
                                <Form.Group controlId="txtFirstName" className="mb-3">
                                    <FormLabel>Imię</FormLabel>
                                    <FormControl type="text" defaultValue={this.state.account.firstName} />
                                    <FormControl.Feedback type="invalid" tooltip>Wymagana jest wartość dla imienia i nazwiska.</FormControl.Feedback>
                                </Form.Group>
                                <Form.Group controlId="txtLastName" className="mb-3">
                                    <FormLabel>Nazwisko</FormLabel>
                                    <FormControl type="text" defaultValue={this.state.account.lastName} />
                                    <FormControl.Feedback type="invalid" tooltip>Wymagana jest wartość dla imienia i nazwiska.</FormControl.Feedback>
                                </Form.Group>
                                <Form.Group controlId="txtBirthday" className="mb-3">
                                    <FormLabel>Urodziny</FormLabel>
                                    <FormControl type="date" defaultValue={this.convert(new Date(this.state.account.birthday ?? new Date().getDate()))} />
                                    <FormControl.Feedback type="invalid" tooltip>Wymagana jest data.</FormControl.Feedback>
                                </Form.Group>
                                <Form.Group controlId="txtGender" className="mb-3">
                                    <FormLabel>Płeć</FormLabel>
                                    <FormSelect defaultValue={this.state.account.gender ?? -1}>
                                        <option value={-1}>{this.genderToString(-1)}</option>
                                        <option value={0}>{this.genderToString(0)}</option>
                                        <option value={1}>{this.genderToString(1)}</option>
                                    </FormSelect>
                                </Form.Group>
                                <Form.Group controlId="txtLocation" className="mb-3">
                                    <FormLabel>Miasto</FormLabel>
                                    <FormControl type="text" defaultValue={this.state.account.location} />
                                </Form.Group>
                            </SubmitForm>
                        </Col>
                        <Col xs="auto">
                            <FormLabel>Obraz</FormLabel>
                            <InnerImageEditor context={this.state.image} />
                        </Col>
                    </Row>
                </Container>

                <ErrorPanel error={this.state.error} />

            </React.Fragment>
        );
    }
}