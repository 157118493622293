import React from 'react';

import RenderBlockContext from "../renders/renderBlockContext";
import { SeparatorType } from '../../models/blocks/commonDesignModel';
import SeparatorSpikes from './separatorSpikes';
import SeparatorPyramid from './separatorPyramid';
import SeparatorPyramidInverted from './separatorPyramidInverted';
import SeparatorTriangle from './separatorTriangle';
import SeparatorTriangleInverted from './separatorTriangleInverted';

interface IProps {
    context: RenderBlockContext,
    separatorType: SeparatorType,
}

export default class SeparatorContainer extends React.Component<IProps> {

    render() {

        if (this.props.separatorType == null)
            return null;

        switch (this.props.separatorType) {
            case SeparatorType.Spikes:
                return <SeparatorSpikes context={this.props.context}/>;
            case SeparatorType.Pyramid:
                return <SeparatorPyramid context={this.props.context}/>;
            case SeparatorType.PyramidInverted:
                return <SeparatorPyramidInverted context={this.props.context}/>;
            case SeparatorType.Triangle:
                return <SeparatorTriangle context={this.props.context}/>;
            case SeparatorType.TriangleInverted:
                return <SeparatorTriangleInverted context={this.props.context}/>;
            default:
                return null;
        }
    }
}