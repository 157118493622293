import React from 'react';

import { Button } from "react-bootstrap";
import { ChildButtonsContentModel as ButtonsItemContentModel, ChildButtonsDesignModel as ButtonsItemDesignModel } from "../../models/blocks/childButtonsModel";
import BlockComponent from './blockComponent';


export default class ChildButtons extends BlockComponent {
    
    render() {

        // content
        const contentModel = new ButtonsItemContentModel();
        contentModel.initialize(this.props.context.targetBlock.blocksProps);

        const content = contentModel.getContent();
        const link = this.props.context.environment.getIsPreview() ? "#" :
            contentModel.getLink(this.props.context.environment.currentLocation);
        
        // design
        const designProperties = this.props.context.commonProperties.concat(this.props.context.targetBlock.blocksProps);
        const designModel = new ButtonsItemDesignModel();
        designModel.setDefaultValues(this.props.context);
        designModel.setCustomValues(designProperties);

        const backgroundStyle = designModel.generateBackgroundStyle(this.props.context);
        const contentStyle = designModel.generateContentStyle(this.props.context);
        const buttonStyle = designModel.generateButtonStyle();

        const textStyle = designModel.generateTextStyle();
        textStyle.minWidth = "7rem";

        const fontClassModificator = designModel.getFontClassModificator();

        const doodle = designModel.generateDoodle();
        const hashNavigation = designModel.generateHashNavigationName();
        const effectAppearName = designModel.generateEffectAppear();
        
        return (
            <Button href={link} style={buttonStyle} className="btn darker-on-hover">
                <div style={contentStyle}>
                    <span className={`mx-auto lp-text ${fontClassModificator}`} style={textStyle}>{content}</span>
                </div>
            </Button>);
    }
}