import React from 'react';

import genUniqueId from "../../models/utils/genUniqueId";
import DoodleWrapper from "../common/doodleWrapper";
import BlockComponent from './blockComponent';
import { HelpPageContentModel, HelpPageDesignModel } from '../../models/blocks/helpPageModel';
import BlockRender from '../renders/blockRender';
import { generateContext } from "../renders/prerenderModel";
import NavigationPanelGroup from '../../pages/editPoint/common/navigationPanelGroup';
import NavigationPanelPoint from '../../pages/editPoint/common/navigationPanelPoint';
import { INavigationHelpPage } from "../../models/pagesInfoProvider";

interface IEditorState {
}

export default class HelpPage extends BlockComponent<Partial<IEditorState>> {

    private id: string = genUniqueId();

    constructor(props: any) {
        super(props);

        this.state = {
        }
    }

    render() {

        // content
        const block = new HelpPageContentModel();
        block.initialize(this.props.context.targetBlock.blocksProps);

        // design
        const designProperties = this.props.context.commonProperties.concat(this.props.context.targetBlock.blocksProps);
        const designModel = new HelpPageDesignModel();
        designModel.setDefaultValues(this.props.context);
        designModel.setCustomValues(designProperties);

        const backgroundStyle = designModel.generateBackgroundStyle(this.props.context);
        const contentStyle = designModel.generateContentStyle(this.props.context);
        const doodle = designModel.generateDoodle();
        const hashNavigation = designModel.generateHashNavigationName();
        const effectAppearName = designModel.generateEffectAppear();

        const childCommonProps = designModel.formatCommonProps(designProperties);

        const currentId = this.props.context.environment.getId();
        const helpBlocks = this.props.context.environment.getInnerContent();
        const helpPages = this.props.context.environment.getNavigationHelpPages();

        const groups = new Array<INavigationHelpPage[]>();
        let lastCategory = "";
        for (const page of helpPages) {
            if (page.category !== lastCategory) {
                lastCategory = page.category;
                groups.push(new Array<INavigationHelpPage>());
            }

            groups[groups.length - 1].push(page);
        }
        
        const helpContexts = generateContext(helpBlocks, this.props.context.environment, null, null);

        return (
            <React.Fragment>
                <div id={hashNavigation} style={backgroundStyle}>
                    {
                        doodle != null &&
                        <DoodleWrapper rule={doodle} />
                    }
                    <div style={contentStyle}>
                        {
                            this.props.context.environment.getIsPreview() && helpPages.length === 0 &&
                            <div className="text-secondary text-center my-3">Projekt nie ma żadnych stron pomocy</div>
                        }
                        <div className="d-flex flex-row flex-fill">
                            <div className="flex-shrink-0">
                                <div className="d-flex flex-column px-3">

                                    {
                                        groups.map(g => {

                                            const category = g[0].category;

                                            return (<NavigationPanelGroup title={category}>

                                                {
                                                    g.map(page => <NavigationPanelPoint title={page.title} icon="" innerKey={page.path} onSelect={null} isSelected={page.id === currentId} />)
                                                }

                                            </NavigationPanelGroup>);
                                        })
                                    }

                                </div>
                            </div>
                            <div className="d-flex flex-column w-100">
                                {
                                    helpContexts.map((context, index) => <BlockRender key={`helpblock-${this.id}-${index}`} context={context}/>)
                                }
                            </div>
                        </div>


                    </div>
                </div>
            </React.Fragment>
        );
    }
}