import React from 'react';

import { Container, Row, Col } from "react-bootstrap";

interface IEmptyLayoutProps {
    children?: React.ReactNode
}

export default class EmptyLayout extends React.Component<IEmptyLayoutProps> {

    render() {
        return (
            <Container fluid className="min-vh-100 d-flex flex-column">
                <Row className="flex-fill">
                    {this.props.children}
                </Row>
            </Container>
        );
    }
}