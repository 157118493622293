import { IPageBlockProperty, IPageRenderedBlockProperty } from "../../models/pageLayoutModel";
import { BlockPropType } from "../../models/blockType";
import CommonDesignModel from "./commonDesignModel";
import { BlockRenderType } from "./commonDesignModel";
import { IBlockModel } from "../modelsHelper";
import RenderBlockContext from "../../controls/renders/renderBlockContext";

export class ImageSimpleContentModel implements IBlockModel {

    private image: string;
    private imageAltText: string;
    private imageDescription: string;

    getImage(): string { return this.image; }
    getImageAltText(): string { return this.imageAltText; }
    getImageDescription(): string { return this.imageDescription; }

    initialize(properties: IPageRenderedBlockProperty[]) {

        let image: IPageRenderedBlockProperty = null;
        let imageAltText: IPageRenderedBlockProperty = null;
        let imageDescription: IPageRenderedBlockProperty = null;

        for (let index = 0; index < properties.length; index++) {

            const property = properties[index];

            switch (property.propType) {
                case BlockPropType.Image:
                    image = property;
                    break;
                case BlockPropType.ImageAltText:
                    imageAltText = property;
                    break;
                case BlockPropType.ImageDescription:
                    imageDescription = property;
                    break;
                default:
                    break;
            }
        }

        this.image = image?.propString;
        this.imageAltText = imageAltText?.propString;
        this.imageDescription = imageDescription?.propString;
    }

    formatBlock(properties: IPageBlockProperty[]): IPageBlockProperty[] {

        let image: IPageBlockProperty = null;
        let imageAltText: IPageBlockProperty = null;
        let imageDescription: IPageBlockProperty = null;

        for (let index = 0; index < properties.length; index++) {

            const property = properties[index];

            switch (property.propType) {
                case BlockPropType.Image:
                    image = property;
                    break;
                case BlockPropType.ImageAltText:
                    imageAltText = property;
                    break;
                case BlockPropType.ImageDescription:
                    imageDescription = property;
                    break;
                default:
                    break;
            }
        }

        if (image == null) {
            image = { propType: BlockPropType.Image };
        }

        if (imageAltText == null) {
            imageAltText = { propType: BlockPropType.ImageAltText };
        }

        if (imageDescription == null) {
            imageDescription = { propType: BlockPropType.ImageDescription };
        }

        return [image, imageAltText, imageDescription];
    }
}

export class ImageSimpleDesignModel extends CommonDesignModel {

    private effectImageAdditionalBackgroundColor: string = null;
    private hasEffectImageBorderRadius: boolean = null;

    constructor() {
        super();

        this.allowBackground = true;
        this.allowContentWidth = true;
        this.allowContentMargin = true;
        this.allowBlockHieghtAndMargin = true;
        this.allowHashNavigation = true;
        this.allowHorizontalAlignment = true;
        this.allowVerticalAlignment = true;
        this.allowTextAndButtonsAlignment = true;
        this.allowTextStyle = true;
        this.allowAppearEffect = true;
        this.allowSeparator = true;
    }

    public setDefaultValues(renderContext: RenderBlockContext) {
        super.setDefaultValues(renderContext);

        this.defaultHorizontalAlignment = "middle";
        this.defaultTextAndButtonsAlignment = "middle";
    }

    public setCustomValues(properties: IPageRenderedBlockProperty[]) {
        super.setCustomValues(properties);

        this.effectImageAdditionalBackgroundColor = null;
        for (let index = 0; index < properties.length; index++) {

            const property = properties[index];
            if (property == null) {
                continue;
            }

            switch (property.propType) {
            case BlockPropType.EffectImageAdditionalBackground:
                if (property.propString != null) {
                    this.effectImageAdditionalBackgroundColor = property.propString;
                }
                break;
            case BlockPropType.EffectImageBorderRadius:
                if (property.propBool != null) {
                    this.hasEffectImageBorderRadius = property.propBool;
                }
                break;
            }
        }
    }

    public getEffectImageAdditionalBackgroundColor(): string {
        return this.effectImageAdditionalBackgroundColor;
    }

    public getEffectImageBorderRadius(): boolean {
        return this.hasEffectImageBorderRadius ?? false;
    }
    
    public formatBlock(properties: IPageRenderedBlockProperty[]): IPageBlockProperty[] {

        const result = super.formatBlock(properties);

        let imageBackProp: IPageBlockProperty = null;
        let imageBorderProp: IPageBlockProperty = null;
        for (const item of properties) {

            if (item == null)
                continue;

            if (item.propType === BlockPropType.EffectImageAdditionalBackground) {
                imageBackProp = { propType: item.propType, propString: item.propString, propBool: item.propBool };
            }
            else if (item.propType === BlockPropType.EffectImageBorderRadius) {
                imageBorderProp = { propType: item.propType, propString: item.propString, propBool: item.propBool };
            }
        }

        result.push(imageBackProp ?? { propType: BlockPropType.EffectImageAdditionalBackground });
        result.push(imageBorderProp ?? { propType: BlockPropType.EffectImageBorderRadius });
        return result;
    }
}