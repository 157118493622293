import React from 'react';
import { SeparatorEmptyContentModel, SeparatorEmptyDesignModel } from '../../models/blocks/separatorEmptyModel';
import DoodleWrapper from '../common/doodleWrapper';

import BlockComponent from './blockComponent';

export default class SeparatorEmpty extends BlockComponent {

    render() {

        // content
        const block = new SeparatorEmptyContentModel();
        block.initialize(this.props.context.targetBlock.blocksProps);

        // design
        const designProperties = this.props.context.commonProperties.concat(this.props.context.targetBlock.blocksProps);
        const designModel = new SeparatorEmptyDesignModel();
        designModel.setDefaultValues(this.props.context);
        designModel.setCustomValues(designProperties);

        const backgroundStyle = designModel.generateBackgroundStyle(this.props.context);
        const contentStyle = designModel.generateContentStyle(this.props.context);
        const doodle = designModel.generateDoodle();
        const hashNavigation = designModel.generateHashNavigationName();
        const effectAppearName = designModel.generateEffectAppear();
        const childCommonProps = designModel.formatCommonProps(designProperties);
        
        return (
            <React.Fragment>
                <div id={hashNavigation} style={backgroundStyle}>
                    {
                        doodle != null &&
                        <DoodleWrapper rule={doodle} />
                    }
                    <div style={{ minHeight: "10vh" }} />
                </div>
            </React.Fragment>
        );
    }
}