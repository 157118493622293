import { IPageBlockProperty, IPageRenderedBlockProperty, IPageRenderedBlock, IPageBlock } from "../../models/pageLayoutModel";
import { BlockPropType, BlockType } from "../../models/blockType";
import CommonDesignModel from "./commonDesignModel";
import { IBlockModel } from "../modelsHelper";
import Style from "../style";
import PointPageEnvironment from "../../controls/renders/pointPageEnvironment";
import RenderBlockContext from "../../controls/renders/renderBlockContext";
import { WebpageStyleType } from "../../controls/renders/pointPageEnvironment";

export class DefaultNavbarContentModel implements IBlockModel {

    private showTitle: boolean;
    private autoGenerateForPages: boolean;
    private autoGenerateForAnchors: boolean;
    private showContacts: boolean;
    private blocks: IPageRenderedBlock[];

    getShowTitle(): boolean { return this.showTitle; }
    getShowContacts(): boolean { return this.showContacts; }
    
    initialize(properties: IPageRenderedBlockProperty[]) {

        let showTitle: IPageRenderedBlockProperty = null;
        let showContacts: IPageRenderedBlockProperty = null;
        let autoGenerateForAnchors: IPageRenderedBlockProperty = null;
        let autoGenerateForPages: IPageRenderedBlockProperty = null;
        let blockCollection: IPageRenderedBlockProperty = null;

        for (let index = 0; index < properties.length; index++) {

            const property = properties[index];

            switch (property.propType) {
                case BlockPropType.ShowTitle:
                    showTitle = property;
                    break;
                case BlockPropType.AutoGenerateContent:
                    autoGenerateForPages = property;
                    break;
                case BlockPropType.AutoGenerateForAnchors:
                    autoGenerateForAnchors = property;
                    break;
                case BlockPropType.ShowContacts:
                    showContacts = property;
                    break;
                case BlockPropType.Collection:
                    blockCollection = property;
                    break;
                default:
                    break;
            }
        }

        this.showTitle = showTitle?.propBool === true;
        this.showContacts = showContacts?.propBool === true;
        this.autoGenerateForPages = autoGenerateForPages?.propBool === true;
        this.autoGenerateForAnchors = autoGenerateForAnchors?.propBool === true;
        this.blocks = blockCollection?.propChildren ?? new Array<IPageRenderedBlock>();
    }

    getBlocks(environment: PointPageEnvironment): IPageRenderedBlock[] {

        if (!this.autoGenerateForPages && !this.autoGenerateForAnchors)
            return this.blocks;

        const result = new Array<IPageRenderedBlock>();

        if (this.autoGenerateForAnchors) {

            let pagePath = environment.getPagePath();
            if (pagePath.endsWith("/")) {
                pagePath = pagePath.substr(0, pagePath.length - 1);
            }

            environment.getBlocks().forEach(x => {

                if (x.blocksProps == null || x.blocksProps.length === 0)
                    return;

                let text: string;
                let code: string;
                for (const prop of x.blocksProps) {
                    switch (prop.propType) {
                        case BlockPropType.HashNavigation:
                            code = prop.propString;
                            break;
                        case BlockPropType.HashNavigationText:
                            text = prop.propString;
                            break;
                    }
                }

                if (text == null || text.length === 0 || code == null || code.length === 0) {
                    return;
                }

                const hashLink: IPageRenderedBlock = {
                    blocksType: BlockType.CollectionChild,
                    blocksProps: [
                        {
                            propType: BlockPropType.LinkText,
                            propString: text,
                        },
                        {
                            propType: BlockPropType.Link,
                            propString: pagePath + "#" + code,
                        }
                    ],
                };

                result.push(hashLink);
            });
        }

        if (this.autoGenerateForPages) {

            const nativeNavigationPages = environment.getNavigationPages();
            nativeNavigationPages.forEach(page => {

                const block: IPageRenderedBlock = {
                    blocksType: BlockType.CollectionChild,
                    blocksProps: [
                        {
                            propType: BlockPropType.LinkText,
                            propString: page.title,
                        },
                        {
                            propType: BlockPropType.Link,
                            propString: page.path,
                        }
                    ],
                };

                result.push(block);
            });
        }

        this.blocks.forEach(x => result.push(x));

        return result;
    }

    formatBlock(properties: IPageBlockProperty[]): IPageBlockProperty[] {
        let showTitle: IPageBlockProperty = null;
        let showContacts: IPageBlockProperty = null;
        let autoGenerateForPages: IPageBlockProperty = null;
        let autoGenerateForAnchors: IPageBlockProperty = null;
        let blocks: IPageBlockProperty = null;

        for (let index = 0; index < properties.length; index++) {

            const property = properties[index];

            switch (property.propType) {
                case BlockPropType.ShowTitle:
                    showTitle = property;
                    break;
                case BlockPropType.ShowContacts:
                    showContacts = property;
                    break;
                case BlockPropType.AutoGenerateContent:
                    autoGenerateForPages = property;
                    break;
                case BlockPropType.AutoGenerateForAnchors:
                    autoGenerateForAnchors = property;
                    break;
                case BlockPropType.Collection:
                    blocks = property;
                    break;
                default:
                    break;
            }
        }

        if (showTitle == null) {
            showTitle = { propType: BlockPropType.ShowTitle };
        }

        if (showContacts == null) {
            showContacts = { propType: BlockPropType.ShowContacts };
        }

        if (autoGenerateForPages == null) {
            autoGenerateForPages = { propType: BlockPropType.AutoGenerateContent };
        }

        if (autoGenerateForAnchors == null) {
            autoGenerateForAnchors = { propType: BlockPropType.AutoGenerateForAnchors };
        }

        if (blocks == null) {
            blocks = { propType: BlockPropType.Collection };
        }

        if (blocks.propChildren == null) {
            blocks.propChildren = new Array<IPageBlock>();
        }

        return [showTitle, showContacts, autoGenerateForPages, autoGenerateForAnchors, blocks];
    }
}

export class DefaultNavbarDesignModel extends CommonDesignModel {

    constructor() {
        super();

        this.allowBackground = true;
        this.allowContentWidth = true;
        this.allowContentMargin = false;
        this.allowBlockHieghtAndMargin = false;
        this.allowHashNavigation = false;
        this.allowHorizontalAlignment = false;
        this.allowVerticalAlignment = false;
        this.allowDoodle = false;
        this.allowTextAndButtonsAlignment = true;
        this.allowTextStyle = true;
        this.allowAppearEffect = false;
    }

    public setDefaultValues(renderContext: RenderBlockContext) {
        super.setDefaultValues(renderContext);

        if (renderContext != null && renderContext.environment.getWebpageStyle() === WebpageStyleType.Classic)
            this.defaultWidth = "small";
        else
            this.defaultWidth = "large";

        this.defaultTextAndButtonsAlignment = "left";
    }

    generateBackgroundStyle(context: RenderBlockContext): Style {

        const result = super.generateBackgroundStyle(context);
        result.zIndex = null;

        return result;
    }

    generateContentStyle(context: RenderBlockContext): Style {

        const result = super.generateContentStyle(context);
        result.flexDirection = "initial";
        result.zIndex = null;

        return result;
    }
}