import React from 'react';

import { GalleryPolaroidContentModel, GalleryPolaroidDesignModel } from "../../models/blocks/galleryPolaroidModel";
import { GalleryPolaroidItemContentModel } from "../../models/blocks/galleryPolaroidItemModel";
import genUniqueId from "../../models/utils/genUniqueId";
import DoodleWrapper from "../common/doodleWrapper";
import BlockComponent from './blockComponent';
import TransactionContainer from '../effects/transactionContainer';
import SeparatorContainer from '../separators/separatorContainer';

export default class GalleryPolaroid extends BlockComponent {

    private id: string = genUniqueId();

    render() {

        // content
        const contentModel = new GalleryPolaroidContentModel();
        contentModel.initialize(this.props.context.targetBlock.blocksProps);

        const images = contentModel.getImages();

        // design
        const designProperties = this.props.context.commonProperties.concat(this.props.context.targetBlock.blocksProps);
        const designModel = new GalleryPolaroidDesignModel();
        designModel.setDefaultValues(this.props.context);
        designModel.setCustomValues(designProperties);

        const backgroundStyle = designModel.generateBackgroundStyle(this.props.context);
        const contentStyle = designModel.generateContentStyle(this.props.context);
        const doodle = designModel.generateDoodle();
        const hashNavigation = designModel.generateHashNavigationName();
        const effectAppearName = designModel.generateEffectAppear();
        const separatorType = designModel.getSeparator();

        const imagesModels = images.map(image => {

            const childModel = new GalleryPolaroidItemContentModel();
            childModel.initialize(image.blocksProps);

            return childModel;
        });

        return (
            <div id={hashNavigation} style={backgroundStyle}>
                {
                    doodle != null &&
                    <DoodleWrapper rule={doodle} />
                }
                <TransactionContainer style={contentStyle} effect={effectAppearName}>
                    <div className="polaroid-container mx-auto">
                        {
                            imagesModels.length > 0 &&
                            <img className="hidden maxwidth-100" src={this.props.context.environment.currentLocation.getImageUrl(imagesModels[0].getImageId())} />
                        }
                        {
                            imagesModels.map((childModel, index) => {

                                const imageId = childModel.getImageId();
                                const imageAltText = childModel.getImageAltText();
                                const description = childModel.getDescription();

                                const imageUrl = this.props.context.environment.currentLocation.getImageUrl(imageId);

                                let className = "polaroid";
                                if (index === images.length - 1) {
                                    className += " polaroid-5";
                                } else {
                                    className += " polaroid-" + ((index % 5) + 1).toString();
                                }

                                return (
                                    <div key={`image-${this.id}-${index}`} className={className}>
                                        <img src={imageUrl} alt={imageAltText} />
                                        {
                                            description != null && description.length > 0 &&
                                            <div className="polaroid-caption lp-text-subtitle mt-4 mb-2 last-without-margin" dangerouslySetInnerHTML={{ __html: description }} />
                                        }
                                    </div>);
                            })
                        }
                    </div>
                </TransactionContainer>

                {
                    separatorType != null &&
                    <SeparatorContainer context={this.props.context} separatorType={separatorType} />
                }

            </div>
        );
    }
}