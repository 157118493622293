import React from 'react';
import Doodle from './doodle';

interface IProps {
    rule: string,
}

interface IState {
}

export default class DoodleWrapper extends React.Component<IProps, Partial<IState>> {

    constructor(prop: any) {
        super(prop);
    }

    render() {

        return (
            <div className="doodle-container">
                <Doodle key={this.getHash().toString()} rule={this.props.rule} />
            </div>
        );
    }

    getHash() : number {

        let hash = 0;

        if (this.props.rule.length === 0) {
            return hash;
        }

        for (let i = 0; i < this.props.rule.length; i++) {

            const char = this.props.rule.charCodeAt(i);
            hash = ((hash << 5) - hash) + char;
            hash = hash & hash;
        }

        return hash;
    }
}