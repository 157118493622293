import { IPageRenderedBlock, IPageBlockProperty, IPageBlock, IPageRenderedBlockProperty } from "../../models/pageLayoutModel";
import CommonDesignModel from "./commonDesignModel";
import { IBlockModel } from "../modelsHelper";
import { BlockPropType } from "../blockType";
import Style from "../style";
import RenderBlockContext from "../../controls/renders/renderBlockContext";
import { isNavbar } from "../../models/blocksHelper";
import { WebpageStyleType } from "../../controls/renders/pointPageEnvironment";
import { SectionType } from "../../controls/renders/renderBlockContext";

export class LayoutStackContentModel implements IBlockModel {

    private navbar: IPageRenderedBlock;
    private blocks: IPageRenderedBlock[];

    getNavbar(): IPageRenderedBlock { return this.navbar; }
    getInnerBlocks(): IPageRenderedBlock[] { return this.blocks; }

    initialize(properties: IPageRenderedBlockProperty[]) {
        
        let blocks: IPageRenderedBlockProperty = null;

        for (let index = 0; index < properties.length; index++) {

            const property = properties[index];

            switch (property.propType) {
                case BlockPropType.Collection:
                    blocks = property;
                    break;
                default:
                    break;
            }
        }

        if (blocks != null && blocks.propChildren != null) {
            this.blocks = blocks.propChildren;
        } else {
            this.blocks = new Array<IPageRenderedBlock>();
        }

        if (this.blocks.length > 0 && this.blocks[0] != null && isNavbar(this.blocks[0].blocksType)) {
            this.navbar = this.blocks[0];
            this.blocks = this.blocks.slice(1);
        } else {
            this.navbar = null;
        }
    }

    formatBlock(properties: IPageBlockProperty[]): IPageBlockProperty[] {

        let blocks: IPageBlockProperty = null;

        for (let index = 0; index < properties.length; index++) {

            const property = properties[index];

            switch (property.propType) {
            case BlockPropType.Collection:
                blocks = property;
                break;
            default:
                break;
            }
        }

        if (blocks == null) {
            blocks = { propType: BlockPropType.Collection };
        }

        if (blocks.propChildren == null) {
            blocks.propChildren = new Array<IPageBlock>();
        }

        return [blocks];
    }
}

export class LayoutStackDesignModel extends CommonDesignModel {

    constructor() {
        super();

        this.allowBackground = true;
        this.allowContentWidth = true;
        this.allowContentMargin = true;
        this.allowBlockHieghtAndMargin = true;
        this.allowHashNavigation = true;
        this.allowHorizontalAlignment = true;
        this.allowVerticalAlignment = true;
        this.allowTextAndButtonsAlignment = true;
        this.allowButtonsStyle = true;
        this.allowTextStyle = true;
        this.allowAppearEffect = true;
        this.allowSeparator = true;
    }

    public setDefaultValues(renderContext: RenderBlockContext) {
        super.setDefaultValues(renderContext);

        if (renderContext != null && renderContext.environment.getWebpageStyle() === WebpageStyleType.Classic)
            this.defaultWidth = "small";
        else
            this.defaultWidth = "max";
    }

    generateContentStyle(context: RenderBlockContext): Style {

        const result: Style = super.generateContentStyle(context);
        result.display = "grid";
        result.gridTemplateRows = "1fr";
        result.gridTemplateColumns = "1fr";
        result.gridGap = "var(--layout-inner-gap)";

        return result;
    }
}