import { IPageBlockProperty, IPageRenderedBlockProperty, IPageRenderedBlock, IPageBlock } from "../../models/pageLayoutModel";
import { BlockPropType, BlockType } from "../../models/blockType";
import CommonDesignModel from "./commonDesignModel";
import { IBlockModel } from "../modelsHelper";
import PointPageEnvironment from "../../controls/renders/pointPageEnvironment";
import RenderBlockContext from "../../controls/renders/renderBlockContext";

export class MenuAnchorsListContentModel implements IBlockModel {

    private autoGenerateForAnchors: boolean;
    private blocks: IPageRenderedBlock[];

    initialize(properties: IPageRenderedBlockProperty[]) {

        let autoGenerateForAnchors: IPageRenderedBlockProperty = null;
        let blockCollection: IPageRenderedBlockProperty = null;

        for (let index = 0; index < properties.length; index++) {

            const property = properties[index];

            switch (property.propType) {
                case BlockPropType.AutoGenerateForAnchors:
                    autoGenerateForAnchors = property;
                    break;
                case BlockPropType.Collection:
                    blockCollection = property;
                    break;
                default:
                    break;
            }
        }

        this.autoGenerateForAnchors = autoGenerateForAnchors?.propBool === true;
        this.blocks = blockCollection?.propChildren ?? new Array<IPageRenderedBlock>();
    }

    getBlocks(environment: PointPageEnvironment): IPageRenderedBlock[] {

        if (!this.autoGenerateForAnchors)
            return this.blocks;

        const result = new Array<IPageRenderedBlock>();

        if (this.autoGenerateForAnchors) {

            let pagePath = environment.getPagePath();
            if (pagePath.endsWith("/")) {
                pagePath = pagePath.substr(0, pagePath.length - 1);
            }

            environment.getBlocks().forEach(x => {

                if (x.blocksProps == null || x.blocksProps.length === 0)
                    return;

                let text: string;
                let code: string;
                for (const prop of x.blocksProps) {
                    switch (prop.propType) {
                        case BlockPropType.HashNavigation:
                            code = prop.propString;
                            break;
                        case BlockPropType.HashNavigationText:
                            text = prop.propString;
                            break;
                    }
                }

                if (text == null || text.length === 0 || code == null || code.length === 0) {
                    return;
                }

                const hashLink: IPageRenderedBlock = {
                    blocksType: BlockType.LinksItem,
                    blocksProps: [
                        {
                            propType: BlockPropType.LinkText,
                            propString: text,
                        },
                        {
                            propType: BlockPropType.Link,
                            propString: pagePath + "#" + code,
                        }
                    ],
                };

                result.push(hashLink);
            });
        }

        this.blocks.forEach(x => result.push(x));

        return result;
    }

    formatBlock(properties: IPageBlockProperty[]): IPageBlockProperty[] {
        let autoGenerateForAnchors: IPageBlockProperty = null;
        let blocks: IPageBlockProperty = null;

        for (let index = 0; index < properties.length; index++) {

            const property = properties[index];

            switch (property.propType) {
                case BlockPropType.AutoGenerateForAnchors:
                    autoGenerateForAnchors = property;
                    break;
                case BlockPropType.Collection:
                    blocks = property;
                    break;
                default:
                    break;
            }
        }

        if (autoGenerateForAnchors == null) {
            autoGenerateForAnchors = { propType: BlockPropType.AutoGenerateForAnchors };
        }

        if (blocks == null) {
            blocks = { propType: BlockPropType.Collection };
        }

        if (blocks.propChildren == null) {
            blocks.propChildren = new Array<IPageBlock>();
        }

        return [autoGenerateForAnchors, blocks];
    }
}

export class MenuAnchorsListDesignModel extends CommonDesignModel {

    constructor() {
        super();

        this.allowBackground = true;
        this.allowContentWidth = true;
        this.allowContentMargin = true;
        this.allowBlockHieghtAndMargin = true;
        this.allowHashNavigation = true;
        this.allowHorizontalAlignment = true;
        this.allowVerticalAlignment = true;
        this.allowDoodle = true;
        this.allowTextAndButtonsAlignment = true;
        this.allowTextStyle = true;
        this.allowAppearEffect = true;
        this.allowSeparator = true;
    }
    
    formatCommonProps(properties: IPageRenderedBlockProperty[]): IPageRenderedBlockProperty[] {

        const result: Array<IPageRenderedBlockProperty> = super.formatCommonProps(properties);

        return result.filter(x =>
            x.propType !== BlockPropType.FontColor &&
            x.propType !== BlockPropType.FontShadow &&
            x.propType !== BlockPropType.FontBorderColor);
    }
}