import { IPageRenderedBlockProperty, IPageBlockProperty } from "../../models/pageLayoutModel";
import { BlockPropType } from "../../models/blockType";
import CommonDesignModel from "./commonDesignModel";
import { IBlockModel } from "../modelsHelper";
import Style from "../style";
import RenderBlockContext from "../../controls/renders/renderBlockContext";

export class NumbersItemContentModel implements IBlockModel {

    private title: string;
    private content: string;

    getTitle(): string { return this.title; }
    getContent(): string { return this.content; }

    initialize(properties: IPageRenderedBlockProperty[]) {

        let title: IPageRenderedBlockProperty = null;
        let content: IPageRenderedBlockProperty = null;
        
        for (let index = 0; index < properties.length; index++) {

            const property = properties[index];

            switch (property.propType) {
            case BlockPropType.Title:
                title = property;
                break;
            case BlockPropType.Content:
                content = property;
                break;
            }
        }

        this.title = title?.propString;
        this.content = content?.propString;
    }

    formatBlock(properties: IPageBlockProperty[]): IPageBlockProperty[] {

        let title: IPageBlockProperty = null;
        let content: IPageBlockProperty = null;

        for (let index = 0; index < properties.length; index++) {

            const property = properties[index];

            switch (property.propType) {
            case BlockPropType.Title:
                title = property;
                break;
            case BlockPropType.Content:
                content = property;
                break;
            }
        }

        if (title == null) {
            title = { propType: BlockPropType.Title };
        }

        if (content == null) {
            content = { propType: BlockPropType.Content };
        }

        return [title, content];
    }
}

export class NumbersItemDesignModel extends CommonDesignModel {

    constructor() {
        super();

        this.allowBackground = true;
        this.allowContentWidth = false;
        this.allowContentMargin = false;
        this.allowBlockHieghtAndMargin = false;
        this.allowHashNavigation = false;
        this.allowHorizontalAlignment = false;
        this.allowVerticalAlignment = false;
        this.allowTextStyle = true;
        this.allowDoodle = false;
        this.allowAppearEffect = true;
        this.allowTextAndButtonsAlignment = true;
    }

    public setDefaultValues(renderContext: RenderBlockContext) {

        super.setDefaultValues(renderContext);

        if (renderContext != null && renderContext.parentBlock.parentBlock == null) {
            this.defaultTextAndButtonsAlignment = "middle";
        }
    }

    generateBackgroundStyle(context: RenderBlockContext): Style {

        const result = super.generateBackgroundStyle(context);
        result.padding = "0";
        result.display = "flex";
        result.flexDirection = "column";
        
        return result;
    }

    generateContentStyle(context: RenderBlockContext): Style {

        const result = super.generateContentStyle(context);
        result.display = "flex";
        result.flexDirection = "column";
        result.gridGap = "0.5rem";
        result.width = "100%";

        if (this.allowBackground && (this.borderColor != null || this.backgroundShadow || this.backgroundColor != null || this.backgroundImage != null)) {
            result.padding = "var(--small-marge)";
        }

        return result;
    }
}