import React from 'react';
import { createRef } from 'react';
import "reflect-metadata";
import { EffectAppearType } from '../../models/blocks/commonDesignModel';
import Style from "../../models/style";

interface IProps {
    effect: EffectAppearType,

    id?: string,
    className?: string,
    style?: Style,

    children?: React.ReactNode;
}

export default class TransactionContainer extends React.Component<IProps> {

    private rootRef: any;

    private isSubscribed: boolean = false;
    private animationName: string = null;
    private isAnimationPlayed: boolean = false;

    constructor(props: any) {
        super(props);

        this.setAnimationName(this.props.effect);

        this.rootRef = createRef();
        this.onScroll = this.onScroll.bind(this);

    }

    componentDidMount() {

        this.setAnimationName(this.props.effect);
        this.initializeLogic();
    }

    componentDidUpdate() {

        this.setAnimationName(this.props.effect);
        this.initializeLogic();
    }

    componentWillUnmount() {

        this.uninitializeLogic();
    }

    setAnimationName(effectName: EffectAppearType) {

        let resolvedEffectName: string = null;
        if (effectName != null) {
            switch (effectName) {
                case "unhide":
                    resolvedEffectName = "effect-appear-unhide";
                    break;
                case "bounceIn":
                    resolvedEffectName = "effect-appear-bounceIn";
                    break;
                case "grayscale":
                    resolvedEffectName = "effect-appear-grayscale";
                    break;
            }
        }

        if (resolvedEffectName === this.animationName) {
            return;
        }

        this.animationName = resolvedEffectName;
        this.isAnimationPlayed = false;
    }

    initializeLogic() {

        if (this.isAnimationPlayed || this.animationName == null) {
            return;
        }

        const shouldActivateAnimation = this.shouldActivateAnimation();
        if (shouldActivateAnimation) {
            this.activateAnimation();
            return;
        }

        if (!this.isSubscribed) {

            const win: Window = window;
            win.addEventListener("scroll", this.onScroll);

            this.isSubscribed = true;
        }
    }

    uninitializeLogic() {

        if (this.isSubscribed) {

            const win: Window = window;
            win.removeEventListener("scroll", this.onScroll);

            this.isSubscribed = false;
        }
    }

    onScroll(event: Event) {

        if (this.isAnimationPlayed || this.animationName == null)
            return;

        const shouldActivateAnimation = this.shouldActivateAnimation();
        if (shouldActivateAnimation) {
            this.activateAnimation();
        }
    };

    shouldActivateAnimation(): boolean {

        const win: Window = window;
        const windowHeight = win.innerHeight;
        
        const elementTop = this.rootRef.current.getBoundingClientRect().top;
        const elementVisible = 150;

        const result = elementTop < windowHeight - elementVisible;
        return result;
    }

    activateAnimation() {
        
        this.rootRef.current.classList.add("active");
        this.isAnimationPlayed = true;
    }

    render() {

        let resolvedClasses = this.props.className;

        if (this.animationName != null) {

            const effectCalsses = `effect-appear ${this.animationName}`;

            if (resolvedClasses == null) {
                resolvedClasses = effectCalsses;
            } else {
                resolvedClasses += " " + effectCalsses;
            }

            if (this.isAnimationPlayed) {
                resolvedClasses += " active";
            }
        }

        return (
            <div id={this.props.id} className={resolvedClasses} style={this.props.style} ref={this.rootRef}>
                {this.props.children}
            </div>
        );
    }
}