import React from 'react';

import DoodleWrapper from "../common/doodleWrapper";
import { ButtonsContentModel, ButtonsDesignModel } from "../../models/blocks/buttonsModel";
import BlockRender from '../renders/blockRender';
import { BlockPropType, BlockType } from "../../models/blockType";
import genUniqueId from "../../models/utils/genUniqueId";
import Style from "../../models/style";
import BlockComponent from './blockComponent';
import TransactionContainer from '../effects/transactionContainer';
import RenderBlockContext from "../renders/renderBlockContext";
import SeparatorContainer from '../separators/separatorContainer';

export default class Buttons extends BlockComponent {

    private id: string = genUniqueId();

    render() {

        // content
        const contentModel = new ButtonsContentModel();
        contentModel.initialize(this.props.context.targetBlock.blocksProps);

        const blocks = contentModel.getBlocks();

        // design
        const designProperties = this.props.context.commonProperties
            .filter(x => x.propType !== BlockPropType.FontShadow && x.propType !== BlockPropType.FontBorderColor)
            .concat(this.props.context.targetBlock.blocksProps);

        const designModel = new ButtonsDesignModel();
        designModel.setDefaultValues(this.props.context);
        designModel.setCustomValues(designProperties);

        const backgroundStyle = designModel.generateBackgroundStyle(this.props.context);
        const contentStyle = designModel.generateContentStyle(this.props.context);
        const doodle = designModel.generateDoodle();
        const hashNavigation = designModel.generateHashNavigationName();
        const childCommonProps = designModel.formatCommonProps(designProperties);
        const effectAppearName = designModel.generateEffectAppear();
        const separatorType = designModel.getSeparator();

        let buttonsContainerStyle: Style = null;
        if (designModel.allowTextAndButtonsAlignment) {

            buttonsContainerStyle = new Style();
            switch (designModel.getButtonsHorizontalAlignment()) {
                case "left":
                    buttonsContainerStyle.margin = "auto auto auto 0";
                    buttonsContainerStyle.justifyContent = "start";
                    break;
                case "right":
                    buttonsContainerStyle.margin = "auto 0 auto auto";
                    buttonsContainerStyle.justifyContent = "end";
                    break;
                case "middle":
                    buttonsContainerStyle.margin = "auto";
                    buttonsContainerStyle.justifyContent = "center";
                    break;
            }
        }

        const resolvedContentWidth = designModel.getResolvedContentWidth();
        const resolvedBackgroundColor = designModel.getActiveBackgroundColor();

        return (
            <div id={hashNavigation} style={backgroundStyle}>
                {
                    doodle != null &&
                    <DoodleWrapper rule={doodle} />
                }
                <TransactionContainer style={contentStyle} effect={effectAppearName}>
                    <div className="d-flex flex-row gap-3 flex-wrap" style={buttonsContainerStyle}>
                        {
                            blocks.map((x, index) => {

                                const context: RenderBlockContext =
                                {
                                    environment: this.props.context.environment,

                                    prevBlock: null,
                                    nextBlock: null,
                                    parentBlock: this.props.context,

                                    parentBlockWidth: resolvedContentWidth,
                                    parentBlockColor: resolvedBackgroundColor,
                                    parentBlockAnimation: effectAppearName,

                                    rootSectionType: this.props.context.rootSectionType,

                                    targetBlock: x,
                                    targetBlockType: BlockType.ButtonsItem,
                                    targetColorSchema: this.props.context.targetColorSchema,
                                    targetHasBackgroundImage: false,

                                    commonProperties: childCommonProps,
                                };

                                return <BlockRender key={`button-${this.id}-${index}`} context={context} />;
                            })
                        }
                    </div>
                </TransactionContainer>

                {
                    separatorType != null &&
                    <SeparatorContainer context={this.props.context} separatorType={separatorType} />
                }
            </div>
        );
    }
}