import "reflect-metadata";

import { resolve } from 'inversify-react';
import { injectable } from "inversify";

import WebRequest from "../utils/webRequest";
import UserInfo from "./userInfo";
import { IId } from "./entities/iidname";

interface IUploadedImage {
    id: string,
    uploadLink: string,
}

@injectable()
export class ImageProvider {
    
    public user: UserInfo;

    public async uploadImage(image: Blob): Promise<IId> {
        const info = await WebRequest.requestItem<IUploadedImage>("/api/images/v1/images/add", "POST", this.user.getUserToken(), null);

        var myHeaders = new Headers();

        var formdata = new FormData();
        formdata.append("file", image);

        var requestOptions: RequestInit = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        const response: Response = await fetch(info.uploadLink, requestOptions);
        if (!response.ok) {
            const responseText = await response.text();
            throw new Error(responseText);
        }

        return { id: info.id };
    }
}