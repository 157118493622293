import { IPageBlockProperty, IPageRenderedBlockProperty } from "../../models/pageLayoutModel";
import CommonDesignModel from "./commonDesignModel";
import { IBlockModel } from "../modelsHelper";
import RenderBlockContext from "../../controls/renders/renderBlockContext";

export class PostFeedPageContentModel implements IBlockModel {

    initialize(properties: IPageRenderedBlockProperty[]) { }

    formatBlock(properties: IPageBlockProperty[]): IPageBlockProperty[] {
        return [];
    }
}

export class PostFeedPageDesignModel extends CommonDesignModel {

    constructor() {
        super();

        this.allowBackground = true;
        this.allowContentWidth = true;
        this.allowContentMargin = true;
        this.allowBlockHieghtAndMargin = true;
        this.allowHashNavigation = false;
        this.allowHorizontalAlignment = false;
        this.allowVerticalAlignment = false;
        this.allowTextStyle = true;
        this.allowAppearEffect = false;
        this.allowSeparator = true;
    }
}