import { IPageRenderedBlock, IPageBlock, IPageBlockProperty, IPageRenderedBlockProperty } from "../../models/pageLayoutModel";
import { BlockPropType } from "../../models/blockType";
import CommonDesignModel from "./commonDesignModel";
import { IBlockModel } from "../modelsHelper";
import Style from "../style";
import RenderBlockContext from "../../controls/renders/renderBlockContext";

export class FeaturesWithHeaderInColumnContentModel implements IBlockModel {

    private blocks: IPageRenderedBlock[];

    getBlocks(): IPageRenderedBlock[] { return this.blocks; }

    initialize(properties: IPageRenderedBlockProperty[]) {

        let blockCollection: IPageRenderedBlockProperty = null; 

        for (let index = 0; index < properties.length; index++) {

            const property = properties[index];

            switch (property.propType) {
                case BlockPropType.Collection:
                    blockCollection = property;
                    break;
                default:
                    break;
            }
        }

        this.blocks = blockCollection?.propChildren ?? new Array<IPageRenderedBlock>();
    }

    formatBlock(properties: IPageBlockProperty[]): IPageBlockProperty[] {

        let blocks: IPageBlockProperty = null;

        for (let index = 0; index < properties.length; index++) {

            const property = properties[index];

            switch (property.propType) {
            case BlockPropType.Collection:
                blocks = property;
                break;
            default:
                break;
            }
        }

        if (blocks == null) {
            blocks = { propType: BlockPropType.Collection };
        }

        if (blocks.propChildren == null) {
            blocks.propChildren = new Array<IPageBlock>();
        }

        return [blocks];
    }
}

export class FeaturesWithHeaderInColumnDesignModel extends CommonDesignModel {

    constructor() {
        super();

        this.allowBackground = true;
        this.allowContentWidth = true;
        this.allowContentMargin = true;
        this.allowBlockHieghtAndMargin = true;
        this.allowHashNavigation = true;
        this.allowHorizontalAlignment = true;
        this.allowVerticalAlignment = true;
        this.allowTextStyle = true;
        this.allowAppearEffect = true;
        this.allowSeparator = true;
    }
    
    generateContentStyle(context: RenderBlockContext): Style {

        const result: Style = super.generateContentStyle(context);
        result.gridGap = "1rem";

        return result;
    }
}