import { IPageBlockProperty, IPageRenderedBlockProperty } from "../../models/pageLayoutModel";
import { BlockPropType, BlockType } from "../../models/blockType";
import { CurrentLocationProvider } from "../currentLocationProvider";
import SmlType from "../utils/sml";
import { IBlockModel } from "../modelsHelper";
import Style from "../style";
import { IDoodle } from "../doodles/IDoodle";
import Bubbles from "../doodles/bubbles";
import Squares from "../doodles/squares";
import Triangles from "../doodles/triangles";
import RenderBlockContext from "../../controls/renders/renderBlockContext";
import { ColorWithDescription } from "../../utils/colorUtils";
import { SectionType } from "../../controls/renders/renderBlockContext";
import { WebpageStyleType } from "../../controls/renders/pointPageEnvironment";
import { PageType } from "../pageType";

export type ContentMarginType = "auto" | "none" | "small" | "large";
export type ContentWidthType = "auto" | "max" | "large" | "medium" | "small";
export type HorizontalAlignmentType = "fill" | "left" | "middle" | "right";
export type TextAndButtonsAlignmentType = "auto" | "left" | "middle" | "right";
export type VerticalAlignmentType = "fill" | "top" | "middle" | "bottom";
export type EffectAppearType = "none" | "unhide" | "bounceIn" | "grayscale";
export type EffectAppearSet = "none" | "root" | "rootAndChildren";

export enum SeparatorType {

    None = 0,

    Spikes = 1,

    Pyramid = 10,
    PyramidInverted = 11,

    Triangle = 20,
    TriangleInverted = 21,
}

export default class CommonDesignModel implements IBlockModel {

    private renderContext: RenderBlockContext;

    public allowBackground: boolean = false;
    public allowContentWidth: boolean = false;
    public allowContentMargin: boolean = false;
    public allowBlockHieghtAndMargin: boolean = false;
    public allowHashNavigation: boolean = false;
    public allowHorizontalAlignment: boolean = false;
    public allowVerticalAlignment: boolean = false;
    public allowTextAndButtonsAlignment: boolean = false;
    public allowButtonsStyle: boolean = false;
    public allowTextStyle: boolean = false;
    public allowDoodle: boolean = true;
    public allowAppearEffect: boolean = false;
    public allowSeparator: boolean = false;

    public defaultWidth: ContentWidthType = "small";
    public defaultVerticalAlignment: VerticalAlignmentType = "middle";
    public defaultHorizontalAlignment: HorizontalAlignmentType = "fill";
    public defaultTextAndButtonsAlignment: TextAndButtonsAlignmentType = "left";
    public defaultBackgroundCorner: SmlType = "none";
    public defaultContentMarginTop: ContentMarginType = "auto";
    public defaultContentMarginBottom: ContentMarginType = "auto";
    public defaultMarginSize: string;

    protected backgroundImage: string;
    private backgroundImageDirection: string;
    protected backgroundColor: string;
    protected backgroundShadow?: boolean;
    private fontClassModificator: string;
    private fontColor: string;
    private fontShadow: boolean;
    private fontBorderColor: string;
    private verticalAlignment?: VerticalAlignmentType;
    private horizontalAlignment?: HorizontalAlignmentType;
    private textAndButtonsAlignment: TextAndButtonsAlignmentType;
    private backgroundCorner: SmlType;
    private borderMarginLeft?: boolean;
    private borderMarginRight?: boolean;
    private borderMarginTop?: boolean;
    private borderMarginBottom?: boolean;
    private contentMarginTop: ContentMarginType;
    private contentMarginBottom: ContentMarginType;
    protected contentWidth: ContentWidthType;
    private borderHeight: SmlType;
    protected borderColor: string;
    private buttonColor: string;
    private buttonCorner: SmlType;
    private buttonFilled?: boolean;
    private hashNavigation: string;
    private hashNavigationText: string;
    private doodle: IDoodle;
    private effectAppear?: EffectAppearType;
    private separatorType: SeparatorType;
    
    constructor() {
        this.setDefaultValues(null);
        this.clearCustomValues();
    }

    protected clearCustomValues() {

        this.backgroundImage = null;
        this.backgroundImageDirection = null;
        this.backgroundColor = null;
        this.backgroundShadow = null;
        this.fontClassModificator = null;
        this.fontColor = null;
        this.fontShadow = false;
        this.fontBorderColor = null;
        this.verticalAlignment = null;
        this.horizontalAlignment = null;
        this.textAndButtonsAlignment = "auto";
        this.backgroundCorner = "auto";
        this.borderMarginLeft = null;
        this.borderMarginRight = null;
        this.borderMarginTop = null;
        this.borderMarginBottom = null;
        this.contentMarginTop = "auto";
        this.contentMarginBottom = "auto";
        this.contentWidth = "auto";
        this.borderHeight = "auto";
        this.borderColor = null;
        this.buttonColor = null;
        this.buttonCorner = "auto";
        this.buttonFilled = null;
        this.hashNavigation = null;
        this.hashNavigationText = null;
        this.doodle = null;
        this.effectAppear = null;
        this.separatorType = null;
    }

    public getButtonsHorizontalAlignment(): TextAndButtonsAlignmentType {

        if (!this.allowTextAndButtonsAlignment)
            return "auto";

        return this.textAndButtonsAlignment !== "auto" ? this.textAndButtonsAlignment : this.defaultTextAndButtonsAlignment;
    }
    
    public setDefaultValues(renderContext: RenderBlockContext) {
        
        this.renderContext = renderContext;

        this.defaultWidth ="small";
        this.defaultVerticalAlignment = "middle";
        this.defaultHorizontalAlignment = "fill";
        this.defaultTextAndButtonsAlignment = "left";
        this.defaultBackgroundCorner = "none";
        this.defaultContentMarginTop = "auto";
        this.defaultContentMarginBottom = "auto";
        this.defaultMarginSize = "var(--medium-marge)";

        if (this.renderContext == null)
            return;

        if (this.renderContext.rootSectionType === SectionType.Title) {
            this.defaultTextAndButtonsAlignment = "middle";
        }

        const webpageStyleType = this.renderContext.environment.getWebpageStyle();
        if (webpageStyleType === WebpageStyleType.Modern) {
            this.defaultMarginSize = "calc(2 * var(--medium-marge))";
        }
    }

    public setCustomValues(properties: IPageRenderedBlockProperty[]) {
        this.initialize(properties);
    }

    public initialize(properties: IPageRenderedBlockProperty[]) {

        this.clearCustomValues();
        
        if (properties == null || properties.length === 0) {
            return;
        }

        for (let index = 0; index < properties.length; index++) {

            const property = properties[index];
            if (property == null) {
                continue;
            }

            switch (property.propType) {
                case BlockPropType.BackgroundImage:
                    this.backgroundImage = property.propString;
                    break;
                case BlockPropType.BackgroundImageDirection:
                    this.backgroundImageDirection = property.propString;
                    break;
                case BlockPropType.BackgroundColor:
                    this.backgroundColor = property.propString;
                    break;
                case BlockPropType.BackgroundShadow:
                    if (property.propBool != null) {
                        this.backgroundShadow = property.propBool;
                    }
                    break;
                case BlockPropType.FontColor:
                    this.fontColor = property.propString;
                    break;
                case BlockPropType.FontClassModificator:
                    if (property.propString != null && property.propString.length > 0) {
                        this.fontClassModificator = property.propString;
                    }
                    break;
                case BlockPropType.FontShadow:
                    if (property.propBool != null) {
                        this.fontShadow = property.propBool;
                    }
                    break;
                case BlockPropType.FontBorderColor:
                    this.fontBorderColor = property.propString;
                    break;
                case BlockPropType.ButtonColor:
                    this.buttonColor = property.propString;
                    break;
                case BlockPropType.ButtonFilled:
                    if (property.propBool != null) {
                        this.buttonFilled = property.propBool;
                    }
                    break;
                case BlockPropType.ButtonCorner:
                    const buttonCorner = property.propString as SmlType;
                    if (buttonCorner != null && buttonCorner !== "auto") {
                        this.buttonCorner = buttonCorner;
                    }
                    break;
                case BlockPropType.VerticalAlignment:
                    const verticalAlignment = property.propString as VerticalAlignmentType;
                    if (verticalAlignment != null) {
                        this.verticalAlignment = verticalAlignment;
                    }
                    break;
                case BlockPropType.HorizontalAlignment:
                    const horizontalAlignment = property.propString as HorizontalAlignmentType;
                    if (horizontalAlignment != null) {
                        this.horizontalAlignment = horizontalAlignment;
                    }
                    break;
                case BlockPropType.TextAndButtonsAlignment:
                    const buttonsHorizontalAlignment = property.propString as TextAndButtonsAlignmentType;
                    if (buttonsHorizontalAlignment != null && buttonsHorizontalAlignment !== "auto") {
                        this.textAndButtonsAlignment = buttonsHorizontalAlignment;
                    }
                    break;
                case BlockPropType.BackgroundCorner:
                    const backgroundCorner = property.propString as SmlType;
                    if (backgroundCorner != null) {
                        this.backgroundCorner = backgroundCorner;
                    }
                    break;
                case BlockPropType.BackgroundMarginTop:
                    if (property.propBool != null) {
                        this.borderMarginTop = property.propBool;
                    }
                    break;
                case BlockPropType.BackgroundMarginBottom:
                    if (property.propBool != null) {
                        this.borderMarginBottom = property.propBool;
                    }
                    break;
                case BlockPropType.BackgroundMarginLeft:
                    if (property.propBool != null) {
                        this.borderMarginLeft = property.propBool;
                    }
                    break;
                case BlockPropType.BackgroundMarginRight:
                    if (property.propBool != null) {
                        this.borderMarginRight = property.propBool;
                    }
                    break;
                case BlockPropType.ContentMarginTop:
                    const tempPaddingTop = property.propString as ContentMarginType;
                    if (tempPaddingTop != null) {
                        this.contentMarginTop = tempPaddingTop;
                    }
                    break;
                case BlockPropType.ContentMarginBottom:
                    const tempPaddingBottom = property.propString as ContentMarginType;
                    if (tempPaddingBottom != null) {
                        this.contentMarginBottom = tempPaddingBottom;
                    }
                    break;
                case BlockPropType.ContentWidth:
                    const tempContentWidth = property.propString as ContentWidthType;
                    if (tempContentWidth != null && tempContentWidth !== "auto") {
                        this.contentWidth = tempContentWidth;
                    }
                    break;
                case BlockPropType.BackgroundHeight:
                    const heightValue = property.propString as SmlType;
                    if (heightValue != null) {
                        this.borderHeight = heightValue;
                    }
                    break;
                case BlockPropType.BorderColor:
                    this.borderColor = property.propString;
                    break;
                case BlockPropType.HashNavigation:
                    this.hashNavigation = property.propString;
                    break;
                case BlockPropType.HashNavigationText:
                    this.hashNavigationText = property.propString;
                    break;
                case BlockPropType.SeparatorType:
                    {
                        const separatorType = property.propNumber as SeparatorType;
                        if (separatorType != null) {
                            this.separatorType = separatorType;
                        }
                        break;
                    }
                case BlockPropType.EffectAppear:
                    {
                        const effectType = property.propString as EffectAppearType;
                        if (effectType != null) {
                            this.effectAppear = effectType;
                        }
                        break;
                    }

                // DoodleBubbles
                case BlockPropType.DoodleBubblesColor1:
                    if (this.doodle == null || !(this.doodle instanceof Bubbles)) {
                        this.doodle = new Bubbles();
                    }
                    (this.doodle as Bubbles).color1 = property.propString;
                    break;
                case BlockPropType.DoodleBubblesColor2:
                    if (this.doodle == null || !(this.doodle instanceof Bubbles)) {
                        this.doodle = new Bubbles();
                    }
                    (this.doodle as Bubbles).color2 = property.propString;
                    break;
                case BlockPropType.DoodleBubblesColor3:
                    if (this.doodle == null || !(this.doodle instanceof Bubbles)) {
                        this.doodle = new Bubbles();
                    }
                    (this.doodle as Bubbles).color3 = property.propString;
                    break;
                case BlockPropType.DoodleSquaresColorFill:
                    if (this.doodle == null || !(this.doodle instanceof Squares)) {
                        this.doodle = new Squares();
                    }
                    (this.doodle as Squares).colorFill = property.propString;
                    break;
                case BlockPropType.DoodleSquaresColorBorder:
                    if (this.doodle == null || !(this.doodle instanceof Squares)) {
                        this.doodle = new Squares();
                    }
                    (this.doodle as Squares).colorBorder = property.propString;
                    break;
                case BlockPropType.DoodleTrianglesColor0:
                    if (this.doodle == null || !(this.doodle instanceof Triangles)) {
                        this.doodle = new Triangles();
                    }
                    (this.doodle as Triangles).color0 = property.propString;
                    break;
                case BlockPropType.DoodleTrianglesColor1:
                    if (this.doodle == null || !(this.doodle instanceof Triangles)) {
                        this.doodle = new Triangles();
                    }
                    (this.doodle as Triangles).color1 = property.propString;
                    break;
                case BlockPropType.DoodleTrianglesColor2:
                    if (this.doodle == null || !(this.doodle instanceof Triangles)) {
                        this.doodle = new Triangles();
                    }
                    (this.doodle as Triangles).color2 = property.propString;
                    break;

                default:
                    break;
            }
        }
    }

    generateHashNavigationName() {

        if (!this.allowHashNavigation)
            return null;

        if (this.hashNavigation == null || this.hashNavigation.length === 0)
            return null;

        let result = this.hashNavigation;
        return result;
    }

    generateBackgroundStyle(context: RenderBlockContext): Style {

        const result: Style = new Style();
        result.position = "relative";
        result.display = "grid";
        result.gridTemplateColumns = "1fr";
        result.gridTemplateRows = "1fr";
        result.zIndex = "var(--z-index-background)";

        this.applyImage(result, context.environment.currentLocation);
        this.applyColor(result);
        this.applyBorder(result, context);
        this.applyShadow(result);
        this.applyMargin(result, context);
        
        return result;
    }

    getFontClassModificator(): string {
        return this.fontClassModificator ?? "";
    }

    private getButtonFilledResolved(): boolean {
        return this.buttonFilled == null || this.buttonFilled;
    }

    generateButtonStyle(): Style {

        const resolvedButtonColor = this.getResolvedButtonColor();

        const result: Style = new Style();
        result.borderColor = resolvedButtonColor;

        if (this.getButtonFilledResolved()) {
            result.backgroundColor = resolvedButtonColor;
        } else {
            result.backgroundColor = "#FFFFFF50";
        }

        const resolvedButtonCorner = this.buttonCorner != null && this.buttonCorner !== "auto" ? this.buttonCorner : "small";
        result.borderRadius = this.convertBorderCorner(resolvedButtonCorner);
        
        return result;
    }

    getResolvedButtonColor(): string {

        if (this.buttonColor != null && this.buttonColor.length > 0) {
            return this.buttonColor;
        }

        const color = this.renderContext?.targetColorSchema?.button;
        if (color != null) {
            return color.getColor();
        }

        return "#3b505b";
    }

    generateContentStyle(context: RenderBlockContext): Style {

        const result: Style = new Style();
        result.display = "flex";
        result.flexDirection = "column";
        result.zIndex = "var(--z-index-content)";
        
        this.applyContentHeightWidth(result, context.environment.isPostOrHelp(), context.parentBlock != null, this.allowContentMargin);
        this.applyContentMargin(result);
        
        return result;
    }

    getSeparator(): SeparatorType {

        if (!this.allowSeparator)
            return null;

        if (this.renderContext != null && this.renderContext.parentBlock != null)
            return null;

        if (this.separatorType === SeparatorType.None)
            return null;

        if (this.separatorType != null)
            return this.separatorType;
            
        if (this.renderContext == null)
            return null;

        if (!this.allowBackground)
            return null;

        if (this.renderContext.environment.isPostOrHelp())
            return null;

        const webpageStyleType = this.renderContext.environment.getWebpageStyle();
        if (webpageStyleType !== WebpageStyleType.Modern)
            return null;

        if (this.renderContext.nextBlock == null) {
            return null;
        }

        if (this.renderContext.nextBlock.targetHasBackgroundImage) {
            return null;
        }

        const pageType = this.renderContext.environment.getPageType();
        if (pageType !== PageType.Landing && pageType !== PageType.Main) {
            return null;
        }

        const backColor = this.renderContext.targetColorSchema?.background.getColor();
        const nextBackColor = this.renderContext.nextBlock?.targetColorSchema?.background.getColor();
        if (backColor === nextBackColor)
            return null;

        switch (this.renderContext.rootSectionType) {
            case SectionType.Title:
            {
                if (this.renderContext.nextBlock?.rootSectionType !== SectionType.Intro)
                    return SeparatorType.Triangle;

                const nextNextBackColor = this.renderContext.nextBlock?.nextBlock?.targetColorSchema?.background.getColor();
                if (nextBackColor === nextNextBackColor)
                    return SeparatorType.Triangle;

                return SeparatorType.Spikes;
            }
            case SectionType.Intro:
                return this.renderContext.prevBlock?.rootSectionType === SectionType.Title && SeparatorType.Spikes;
            default:
                return null;
        }
    }

    generateNavbarStyle(): Style {

        const result: Style = new Style();
        
        this.applyContentHeightWidth(result, this.renderContext.environment.isPostOrHelp(), this.renderContext.parentBlock != null, false);

        result.margin = `auto`;
        result.width = "100%";

        return result;
    }

    generateTextStyle(): Style {

        const result: Style = new Style();

        if (this.allowTextStyle) {
            this.applyFontColor(result);
            this.applyTextAlignment(result);
            this.applyFontEffects(result);
        }

        return result;
    }

    generateDoodle(): string {

        if (!this.allowDoodle || this.doodle == null)
            return null;

        return this.doodle.getDoodle();
    }
    
    generateEffectAppear(): EffectAppearType {

        if (!this.allowAppearEffect)
            return "none";
            
        if (this.effectAppear != null)
            return this.effectAppear;

        if (this.renderContext.environment.isPostOrHelp())
            return "none";

        const webpageStyleType = this.renderContext.environment.getWebpageStyle();
        const isModern = webpageStyleType === WebpageStyleType.Modern;

        const hasParent = this.renderContext != null && this.renderContext.parentBlock != null;
        if (!hasParent) {
            return isModern ? "unhide" : "none";
        }

        return this.renderContext.parentBlockAnimation;
    }

    private applyImage(result: Style, currentLocation: CurrentLocationProvider) {

        if (!this.allowBackground)
            return;

        if (this.backgroundImage == null || this.backgroundImage.length === 0)
            return;
        
        let direction = this.backgroundImageDirection;
        if (direction == null || direction.length === 0)
            direction = "center";

        const imageUrl = currentLocation.getImageUrl(this.backgroundImage);

        result.backgroundImage = "url(" + imageUrl + ")";
        result.backgroundRepeat = "no-repeat";
        result.backgroundSize = "cover";

        switch (direction) {
        case "left-top":
            result.backgroundPosition = "left top";
            break;
        case "top":
            result.backgroundPosition = "top";
            break;
        case "right-top":
            result.backgroundPosition = "right top";
            break;
        case "right":
            result.backgroundPosition = "right";
            break;
        case "right-bottom":
            result.backgroundPosition = "right bottom";
            break;
        case "bottom":
            result.backgroundPosition = "bottom";
            break;
        case "left-bottom":
            result.backgroundPosition = "left bottom";
            break;
        case "left":
            result.backgroundPosition = "left";
            break;
        default:
            result.backgroundPosition = "center";
            break;
        }
    }

    private applyColor(result: Style) {

        if (!this.allowBackground)
            return;

        if (this.backgroundImage != null && this.backgroundImage.length > 0)
            return;

        if (this.backgroundColor != null && this.backgroundColor.length > 0) {
            result.backgroundColor = this.backgroundColor;
            return;
        }

        if (this.renderContext?.parentBlock == null) {
            result.backgroundColor = this.renderContext?.targetColorSchema?.background.getColor();
        }
    }

    public getActiveBackgroundColor(): ColorWithDescription {
        
        if (this.renderContext != null && this.renderContext.targetBlockType === BlockType.ButtonsItem && this.getButtonFilledResolved()) {
        
            if (this.buttonColor != null && this.buttonColor.length > 0) {
                return new ColorWithDescription(this.buttonColor);
            }
        
            const buttonColor = this.renderContext?.targetColorSchema?.button;
            if (buttonColor != null) {
                return buttonColor;
            }
        
            return new ColorWithDescription("#000000");
        }

        if (this.allowBackground) {

            const hasImage = this.backgroundImage != null && this.backgroundImage.length > 0;
            const hasColor = this.backgroundColor != null && this.backgroundColor.length > 0;
            if (!hasImage && hasColor) {
                return new ColorWithDescription(this.backgroundColor);
            }
        }

        return this.renderContext?.parentBlockColor ?? this.renderContext?.targetColorSchema?.background;
    }
    
    private applyBorder(result: Style, context: RenderBlockContext) {

        if (!this.allowBackground)
            return;

        const backgroundCornerResolved = this.getResolvedBackgroundCorner();
        
        if (this.borderColor != null) {
            
            const hasCorner = backgroundCornerResolved !== "none";

            let hasMarginFromParent = false;
            for (var activeBlock = context; activeBlock != null; activeBlock = activeBlock.parentBlock) {
                
                if (activeBlock.parentBlockWidth != null && activeBlock.parentBlockWidth !== "max") {
                    hasMarginFromParent = true;
                    break;
                }
            }
            
            const hasLeftMargin = hasMarginFromParent || this.borderMarginLeft || hasCorner;
            const hasRightMargin = hasMarginFromParent || this.borderMarginRight || hasCorner;

            const leftBorderWidth = hasLeftMargin ? "1px" : "0";
            const rightBorderWidth = hasRightMargin ? "1px" : "0";

            result.borderWidth = `1px ${rightBorderWidth} 1px ${leftBorderWidth}`;
            result.borderColor = this.borderColor;
            result.borderStyle = "solid";
        } else {
            result.borderStyle = "none";
        }
        
        result.borderRadius = `${this.convertBorderCorner(backgroundCornerResolved)}`;
    }

    private getResolvedBackgroundCorner(): SmlType {
        const backgroundCornerResolved = this.backgroundCorner !== "auto" ? this.backgroundCorner : this.defaultBackgroundCorner;
        return backgroundCornerResolved;
    }

    private convertBorderCorner(value: SmlType): string {
        
        switch (value) {
            case "auto":
            case "none":
                return "0";
            case "small":
                return "0.375rem";
            case "medium":
                return "1rem";
            case "large":
            case "max":
                return "2rem";
            default:
                return "0";
        }
    }
    
    private applyMargin(result: Style, context: RenderBlockContext) {

        if (this.allowBlockHieghtAndMargin) {

            const topMargin = this.borderMarginTop ? "var(--medium-marge)" : "0";
            const bottomMargin = this.borderMarginBottom ? "var(--medium-marge)" : "0";

            const hasCorner = this.getResolvedBackgroundCorner() !== "none";
            const forceLeftRightMargin = 
                (hasCorner && context.parentBlock == null) ||
                (hasCorner && context.parentBlock != null && context.parentBlockWidth === "max");

            const leftMargin = this.borderMarginLeft || forceLeftRightMargin ? "var(--medium-marge)" : "0";
            const rightMargin = this.borderMarginRight || forceLeftRightMargin ? "var(--medium-marge)" : "0";

            result.margin = `${topMargin} ${rightMargin} ${bottomMargin} ${leftMargin}`;
            result.padding = "0";

            const resolvedBorderHeight = this.getResolvedMinHeight();
            switch (resolvedBorderHeight) {
                case "small":
                    result.minHeight = "30vh";
                    break;
                case "medium":
                    result.minHeight = "50vh";
                    break;
                case "large":
                    result.minHeight = "75vh";
                    break;
                case "max":
                    result.minHeight = "100vh";
                    break;
            }
        }
    }

    private getResolvedMinHeight(): SmlType {

        if (this.borderHeight !== "auto")
            return this.borderHeight;

        if (this.renderContext == null || this.renderContext.parentBlock != null)
            return "auto";

        if (this.renderContext.rootSectionType !== SectionType.Title)
            return "auto";

        const pageType = this.renderContext.environment.getPageType();
        if (pageType === PageType.Help) {
            return "auto";
        }
        const webpageStyleType = this.renderContext.environment.getWebpageStyle();
        switch (webpageStyleType) {
            case WebpageStyleType.Modern:
                return pageType === PageType.Landing || pageType === PageType.Main ? "large" : "medium";
            default:
                return "medium";
        }
    }

    private applyContentMargin(result: Style) {

        const verticalAlignmentResolved = !this.allowVerticalAlignment ? this.defaultVerticalAlignment : this.verticalAlignment ?? this.defaultVerticalAlignment;
        const horizontalAlignmentResolved = !this.allowHorizontalAlignment ? this.defaultHorizontalAlignment : this.horizontalAlignment ?? this.defaultHorizontalAlignment;

        let topAlign: string = null;
        let bottomAlign: string = null;
        switch (verticalAlignmentResolved) {
            case "top":
                topAlign = "0";
                bottomAlign = "auto";
                break;
            case "middle":
                topAlign = "auto";
                bottomAlign = "auto";
                break;
            case "bottom":
                topAlign = "auto";
                bottomAlign = "0";
                break;
            case "fill":
                topAlign = "0";
                bottomAlign = "0";
                break;
        }

        let leftAlign: string = null;
        let rightAlign: string = null;
        let width: string = null;
        switch (horizontalAlignmentResolved) { 
            case "left":
                leftAlign = "0";
                rightAlign = "auto";
                break;
            case "right":
                leftAlign = "auto";
                rightAlign = "0";
                break;
            case "middle":
                leftAlign = "auto";
                rightAlign = "auto";
                break;
            case "fill":
                leftAlign = "auto";
                rightAlign = "auto";
                width = "100%";
                break;
        }

        result.margin = `${topAlign} ${rightAlign} ${bottomAlign} ${leftAlign}`;
        result.width = width;
    }

    public getResolvedContentWidth() : ContentWidthType {

        if (this.contentWidth !== "auto") {
            return this.contentWidth;
        }

        if (this.isParentNotMax()) {
            return "max";
        }

        return this.defaultWidth;
    }

    public isSmallOrParentSmall() : boolean {

        if (this.getResolvedContentWidth() === "small")
            return true;

        for (var context = this.renderContext; context != null; context = context.parentBlock) {
            if (context.parentBlockWidth === "small")
                return true;
        }

        return false;
    }

    private isParentNotMax() : boolean {

        for (var context = this.renderContext; context != null; context = context.parentBlock) {
            if (context.parentBlockWidth != null && context.parentBlockWidth !== "max")
                return true;
        }

        return false;
    }

    private applyContentHeightWidth(result: Style, isPost: boolean, isInnerBlock: boolean, allowContentMargin: boolean) {
        
        let topMargin: string = "0";
        let bottomMargin: string = "0";
        if (allowContentMargin) {

            const resolvedContentMarginTop = this.contentMarginTop !== "auto" ? this.contentMarginTop : this.defaultContentMarginTop;
            const resolvedContentMarginBottom = this.contentMarginBottom !== "auto" ? this.contentMarginBottom : this.defaultContentMarginBottom;

            topMargin = this.convertVerticalContentMargin(resolvedContentMarginTop, isPost, isInnerBlock);
            bottomMargin = this.convertVerticalContentMargin(resolvedContentMarginBottom, isPost, isInnerBlock);
        }

        let leftRightMargin: string = "0";
        if (this.allowContentWidth) {

            const resolvedWidth = this.getResolvedContentWidth();
            switch (resolvedWidth) {
                case "max":
                    {
                        if (this.allowBackground && (this.borderColor != null || this.backgroundShadow || this.backgroundColor != null || this.backgroundImage != null) && this.isParentNotMax()) {
                            leftRightMargin = "var(--small-marge)";
                        } else {
                            leftRightMargin = "0";
                        }
                        break;
                    }
                case "large":
                    leftRightMargin = "var(--small-marge)";
                    break;
                case "medium":
                case "small":
                    leftRightMargin = "var(--medium-marge)";
                    break;
            }

            if (resolvedWidth === "small") {
                result.maxWidth = isPost ?
                    "calc(920px + var(--medium-marge) + var(--medium-marge))" :
                    "calc(1100px + var(--medium-marge) + var(--medium-marge))";
            }
        }

        result.padding = `${topMargin} ${leftRightMargin} ${bottomMargin} ${leftRightMargin}`;
    }

    convertVerticalContentMargin(padding: ContentMarginType, isPost: boolean, isInnerBlock: boolean): string {

        const paddingResolved: ContentMarginType = padding == null ? "auto" : padding;
        
        switch (paddingResolved) {
            case "auto":
                {
                    if (this.allowBackground && (
                        this.backgroundColor != null && this.backgroundColor.length > 0 ||
                        this.backgroundImage != null && this.backgroundImage.length > 0 ||
                        this.borderColor != null && this.borderColor.length > 0)) {

                        return "var(--medium-marge)";
                    }

                    if (isInnerBlock) {
                        return "0";
                    } else if (isPost) {
                        return "var(--small-marge)";
                    } else {
                        return this.defaultMarginSize;
                    }
                }
            case "none":
                return "0";
            case "small":
                return "var(--small-marge)";
            case "large":
                return this.defaultMarginSize;
            default:
                return "0";
        }
    }

    private applyFontColor(result: Style) {

        if (this.fontColor != null && this.fontColor.length > 0) {
            result.color = this.fontColor;
            return;
        }

        if (this.fontClassModificator != null && this.fontClassModificator.length > 0)
            return;

        const backgroundColor = this.getActiveBackgroundColor();
        result.color = backgroundColor == null || backgroundColor.isLight() ? "#3b505b" : "white";
    }

    private applyTextAlignment(result: Style) {

        if (!this.allowTextAndButtonsAlignment)
            return;

        const textAlignResolved = this.textAndButtonsAlignment !== "auto" ? this.textAndButtonsAlignment : this.defaultTextAndButtonsAlignment;
        switch (textAlignResolved) {
            case "left":
                result.textAlign = "left";
                break;
            case "middle":
                result.textAlign = "center";
                break;
            case "right":
                result.textAlign = "right";
                break;
        }
    }

    private applyFontEffects(result: Style) {

        let value: string = null;
        if (this.fontShadow) {
            value = "0em 0em 0.3em rgba(0,0,0,0.4)";
        } else if (this.fontBorderColor != null && this.fontBorderColor.length > 0) {
            value = "0 0 2px " + this.fontBorderColor;
        }

        if (value != null) {
            result.textShadow = value;
        }
    }

    private applyShadow(result: Style) {

        if (!this.allowBackground)
            return;

        if (this.backgroundShadow) {
            result.boxShadow = "0 .625rem 1rem rgba(35,35,35,.1)";
        }
    }

    formatBlock(properties: IPageRenderedBlockProperty[]): IPageBlockProperty[] {
        
        const map = new Map();
        properties.forEach(x => {
            if (x != null) {
                map.set(x.propType, x);
            }
        });

        function tryGetProperty(propType: BlockPropType): IPageBlockProperty {
            return map.get(propType) ?? { propType: propType };
        }

        const result = new Array<IPageBlockProperty>();
        
        if (this.allowBackground) {
            result.push(tryGetProperty(BlockPropType.BackgroundImage));
            result.push(tryGetProperty(BlockPropType.BackgroundImageDirection));
            result.push(tryGetProperty(BlockPropType.BackgroundColor));
            result.push(tryGetProperty(BlockPropType.BackgroundShadow));
            result.push(tryGetProperty(BlockPropType.BackgroundCorner));
            result.push(tryGetProperty(BlockPropType.BorderColor));
        }

        if (this.allowButtonsStyle) {
            result.push(tryGetProperty(BlockPropType.ButtonColor));
            result.push(tryGetProperty(BlockPropType.ButtonCorner));
            result.push(tryGetProperty(BlockPropType.ButtonFilled));
        }

        if (this.allowTextStyle) {
            result.push(tryGetProperty(BlockPropType.FontColor));
            result.push(tryGetProperty(BlockPropType.FontShadow));
            result.push(tryGetProperty(BlockPropType.FontBorderColor));
            result.push(tryGetProperty(BlockPropType.FontClassModificator));
        }

        if (this.allowHorizontalAlignment) {
            result.push(tryGetProperty(BlockPropType.HorizontalAlignment));
        }

        if (this.allowVerticalAlignment) {
            result.push(tryGetProperty(BlockPropType.VerticalAlignment));
        }

        if (this.allowTextAndButtonsAlignment) {
            result.push(tryGetProperty(BlockPropType.TextAndButtonsAlignment));
        }
        
        if (this.allowContentWidth) {
            result.push(tryGetProperty(BlockPropType.ContentWidth));
        }

        if (this.allowContentMargin) {
            result.push(tryGetProperty(BlockPropType.ContentMarginTop));
            result.push(tryGetProperty(BlockPropType.ContentMarginBottom));
        }

        if (this.allowBlockHieghtAndMargin) {
            result.push(tryGetProperty(BlockPropType.BackgroundMarginBottom));
            result.push(tryGetProperty(BlockPropType.BackgroundMarginTop));
            result.push(tryGetProperty(BlockPropType.BackgroundMarginLeft));
            result.push(tryGetProperty(BlockPropType.BackgroundMarginRight));
            result.push(tryGetProperty(BlockPropType.BackgroundHeight));
        }

        if (this.allowHashNavigation) {
            result.push(tryGetProperty(BlockPropType.HashNavigation));
            result.push(tryGetProperty(BlockPropType.HashNavigationText));
        }

        if (this.allowDoodle) {
            result.push(tryGetProperty(BlockPropType.DoodleBubblesColor1));
            result.push(tryGetProperty(BlockPropType.DoodleBubblesColor2));
            result.push(tryGetProperty(BlockPropType.DoodleBubblesColor3));
            result.push(tryGetProperty(BlockPropType.DoodleSquaresColorFill));
            result.push(tryGetProperty(BlockPropType.DoodleSquaresColorBorder));
            result.push(tryGetProperty(BlockPropType.DoodleTrianglesColor0));
            result.push(tryGetProperty(BlockPropType.DoodleTrianglesColor1));
            result.push(tryGetProperty(BlockPropType.DoodleTrianglesColor2));
        }

        if (this.allowAppearEffect) {
            result.push(tryGetProperty(BlockPropType.EffectAppear));
        }

        if (this.allowSeparator) {
            result.push(tryGetProperty(BlockPropType.SeparatorType));
        }
        
        return result;
    }

    formatCommonProps(properties: IPageRenderedBlockProperty[]): IPageRenderedBlockProperty[] {

        const formatBlock = this.formatBlock(properties);
        const result = new Array<IPageRenderedBlockProperty>();

        for (let i = 0; i < formatBlock.length; i++) {
            const property = formatBlock[i];

            switch (property.propType) {
                case BlockPropType.FontClassModificator:
                case BlockPropType.FontColor:
                case BlockPropType.FontShadow:
                case BlockPropType.FontBorderColor:
                case BlockPropType.TextAndButtonsAlignment:
                case BlockPropType.ButtonColor:
                case BlockPropType.ButtonCorner:
                case BlockPropType.ButtonFilled:
                    result.push(property);
                    break;
            }
        }
        
        return result;
    }
}

export function lightenDarkenColor(col: string, amt: number) {
    var usePound = false;
    if (col[0] === "#") {
        col = col.slice(1);
        usePound = true;
    }

    var num = parseInt(col, 16);

    var r = (num >> 16) + amt;

    if (r > 255) r = 255;
    else if (r < 0) r = 0;

    var b = ((num >> 8) & 0x00FF) + amt;

    if (b > 255) b = 255;
    else if (b < 0) b = 0;

    var g = (num & 0x0000FF) + amt;

    if (g > 255) g = 255;
    else if (g < 0) g = 0;

    return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
}

export enum BlockRenderType {

    Page,
    PageInner,

    Post,
    PostInner,
}

export function makeInnerBlockRenderType(renderType: BlockRenderType): BlockRenderType {
    switch (renderType) {
        case BlockRenderType.Page:
            return BlockRenderType.PageInner;
        case BlockRenderType.Post:
            return BlockRenderType.PostInner;
        default:
            return renderType;
    }
}