import React from 'react';

import { Button, Form, Stack } from "react-bootstrap";
import * as Logger from "../../utils/logger";
import { ErrorDetails } from "../errorPanel";

interface IProps {
    
    submitText: string,

    onBeforeValidate?: (form: HTMLFormElement) => void,
    onValidate?: (form: HTMLFormElement) => Promise<boolean>,
    onSave: (form: HTMLFormElement) => Promise<boolean>,

    children?: React.ReactNode,
}

interface IState {
    isValidated: boolean,
    isSubmitting: boolean,
    isSubmitted: boolean,
}

export class SubmitForm extends React.Component<IProps, IState> {

    constructor(prop: any) {
        super(prop);

        this.state = {
            isValidated: false,
            isSubmitting: false,
            isSubmitted: false,
        };

        this.onSubmit = this.onSubmit.bind(this);
    }

    async onSubmit(event: any) : Promise<boolean> {

        try {

            event.preventDefault();
            event.stopPropagation();

            const form = event.currentTarget as HTMLFormElement;
            if (this.props.onBeforeValidate != null) {
                await this.props.onBeforeValidate(form);
            }

            const isNativeValid = form.checkValidity();
            if (!isNativeValid) {

                this.setState({ isValidated: true, isSubmitting: false, isSubmitted: false });
                return false;
            }

            const isCustomValid = this.props.onValidate == null || await this.props.onValidate(form);
            if (!isCustomValid) {

                this.setState({ isValidated: true, isSubmitting: false, isSubmitted: false });
                return false;
            }

            this.setState({ isValidated: false, isSubmitting: true, isSubmitted: false });

            const isSuccess = await this.props.onSave(form);

            this.setState({ isValidated: false, isSubmitting: false, isSubmitted: isSuccess });

            return false;

        } catch (error) {

            const errorDetails = new ErrorDetails("Submit error", error as Error);
            Logger.logError(errorDetails);

            this.setState({ isValidated: false, isSubmitting: false, isSubmitted: false });
            return false;
        }
    }
    
    render() {
        
        return (
            <Form noValidate validated={this.state.isValidated} onSubmit={this.onSubmit}>

                {this.props.children}

                <Stack direction="horizontal">
                    <Button type="submit">{this.props.submitText}</Button>

                    {
                        this.state.isSubmitting &&
                        <span className="ms-2 bi bi-hourglass-split"> Submitting...</span>
                    }
                    {
                        this.state.isSubmitted &&
                        <span className="ms-2 text-success bi bi-check hide-animation"> Submitted</span>
                    }

                </Stack>
                

            </Form>
        );
    }
}