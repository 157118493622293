import React from 'react';
import { createRef } from 'react';

import "reflect-metadata";
import { resolve } from 'inversify-react';

import { Button, Stack, Spinner } from "react-bootstrap";

import { ImageProvider } from "../../models/imageProvider";
import { CurrentLocationProvider } from "../../models/currentLocationProvider";
import Style from "../../models/style";
import { IPageRenderedBlockProperty } from "../../models/pageLayoutModel";
import { BlockPropType } from "../../models/blockType";
import CommonDesignModel, { BlockRenderType } from "../../models/blocks/commonDesignModel";
import { TestEnvironmentProvider } from "../../models/testEnvironmentProvider";

interface IProps {
    context: IImageEditorContext,
    direction?: string,
    onChanged?: () => void,
}

interface IState {
    isLoading: boolean,
    imageId: string,
    isHovering: boolean,
}

export class InnerImageEditor extends React.Component<IProps, Partial<IState>> {

    @resolve(CurrentLocationProvider)
    private readonly currentLocation: CurrentLocationProvider;

    @resolve(ImageProvider)
    private readonly imageProvider: ImageProvider;

    @resolve(TestEnvironmentProvider)
    private readonly testEnvironmentProvider: TestEnvironmentProvider;

    private inputFileWithImage: any;
    private inputFileWithoutImage: any;

    constructor(prop: any) {
        super(prop);

        this.state = {
            isLoading: false,
            imageId: this.props.context.imageId,
            isHovering: false,
        };

        this.onFileWithImageClick = this.onFileWithImageClick.bind(this);
        this.onFileWithoutImageClick = this.onFileWithoutImageClick.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.fileChangedHandler = this.fileChangedHandler.bind(this);
        this.inputFileWithImage = createRef();
        this.inputFileWithoutImage = createRef();
    }

    componentDidUpdate(prevProps: IProps, prevState: IState) {

        if (prevProps.context === this.props.context) {
            return;
        }

        this.setState({
            isLoading: false,
            imageId: this.props.context.imageId,
        });
    }

    async onFileWithImageClick(event: any) {
        this.inputFileWithImage.current.click();
    }

    async onFileWithoutImageClick(event: any) {
        this.inputFileWithoutImage.current.click();
    }

    onDelete() {
        this.props.context.imageId = null;
        this.setState({ imageId: null });

        if (this.props.onChanged != null) {
            this.props.onChanged();
        }
    }

    async fileChangedHandler(event: any) {

        this.setState({ isLoading: true, isHovering: false });

        const file = event.target.files[0];
        const imageId = await this.imageProvider.uploadImage(file);

        this.props.context.imageId = imageId.id;
        this.setState({ isLoading: false, imageId: imageId.id });

        if (this.props.onChanged != null) {
            this.props.onChanged();
        }
    }

    generateBackgroundStyle(): Style {

        const style = new Style();
        style.backgroundImage = "url(\"data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23F0F0F0' d='M0 0h8v8H0zm8 8h8v8H8z'/%3E%3C/svg%3E\")";
        style.backgroundRepeat = "repeat";
        style.backgroundSize = "16px 16px";
        style.minWidth = "180px";
        style.minHeight = "180px";
        style.borderColor = "#666";
        style.borderWidth = "1px";
        style.borderStyle = "solid";
        style.display = "grid";

        return style;
    }

    generateStyle(imageId: string): Style {

        const fontProperties = new Array<IPageRenderedBlockProperty>();
        fontProperties.push({
            propType: BlockPropType.BackgroundImage,
            propString: imageId
        });

        if (this.props.direction != null) {
            fontProperties.push({
                propType: BlockPropType.BackgroundImageDirection,
                propString: this.props.direction
            });
        }

        const designModel = new CommonDesignModel();
        designModel.allowBackground = true;

        designModel.setDefaultValues(this.testEnvironmentProvider.getPreviewContextWithLocation(this.currentLocation));
        designModel.defaultContentMarginBottom = "none";
        designModel.defaultContentMarginTop = "none";

        designModel.setCustomValues(fontProperties);

        return designModel.generateBackgroundStyle(this.testEnvironmentProvider.getPreviewContextWithLocation(this.currentLocation));
    }

    render() {

        const hasImage = this.state.imageId != null && this.state.imageId.length > 0;

        return (

            <div className="d-flex flex-column gap-1 w-auto bg-white"
                onMouseOver={() => this.setState({ isHovering: true })}
                onMouseOut={() => this.setState({ isHovering: false })}>

                <div style={this.generateBackgroundStyle()} className="position-relative">
                    {
                        this.state.isLoading &&
                        <div className="d-flex w-100 h-100">
                            <div className="m-auto">
                                <Spinner animation="border" />
                            </div>
                        </div>
                    }
                    {
                        !this.state.isLoading && hasImage &&
                        <div style={this.generateStyle(this.state.imageId)} />
                    }

                    <div className={!this.state.isLoading && !hasImage ? "position-absolute bottom-0 left-0 right-0 top-0 z-3 d-grid" : "d-none"}>
                        <div className="bg-white p-1 bg-transparent d-grid">
                            <input
                                type="file"
                                name="file"
                                id="inputGroupInputFileWithoutImage"
                                onChange={this.fileChangedHandler}
                                ref={this.inputFileWithoutImage}
                                style={{ display: 'none' }} />

                            <Button onClick={this.onFileWithoutImageClick} className="bi bi-upload bg-white text-secondary m-auto" variant="outline-secondary"> Wgrywać</Button>
                        </div>
                    </div>

                    <div className={!this.state.isLoading && hasImage && this.state.isHovering ? "position-absolute bottom-0 left-0 right-0 top-0 z-3 d-grid" : "d-none"}>
                        <div className="mt-auto bg-white bg-opacity-50 p-1 d-flex flex-row">
                            <input
                                type="file"
                                name="file"
                                id="inputGroupInputFileWithImage"
                                onChange={this.fileChangedHandler}
                                ref={this.inputFileWithImage}
                                style={{ display: 'none' }} />

                            <Button onClick={this.onFileWithImageClick} className="bi bi-upload bg-white text-secondary" variant="outline-secondary"> Wgrywać</Button>
                            <Button variant="danger" className="bi bi-trash ms-auto" title="Usuń" onClick={this.onDelete} />
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}

export interface IImageEditorContext {
    imageId: string;
}