import React from 'react';

import { FeaturesSimpleInColumnDesignModel, FeaturesSimpleInColumnContentModel } from "../../models/blocks/featuresSimpleInColumnModel";
import { BlockType } from "../../models/blockType";
import BlockRender from "../renders/blockRender";
import genUniqueId from "../../models/utils/genUniqueId";
import DoodleWrapper from "../common/doodleWrapper";
import BlockComponent from './blockComponent';
import RenderBlockContext from "../renders/renderBlockContext";
import SeparatorContainer from '../separators/separatorContainer';

export default class FeaturesSimpleInColumn extends BlockComponent {

    private id: string = genUniqueId();

    render() {

        // content
        const block = new FeaturesSimpleInColumnContentModel();
        block.initialize(this.props.context.targetBlock.blocksProps);

        const blockCollection = block.getBlocks();

        // design
        const designProperties = this.props.context.commonProperties.concat(this.props.context.targetBlock.blocksProps);
        const designModel = new FeaturesSimpleInColumnDesignModel();
        designModel.setDefaultValues(this.props.context);
        designModel.setCustomValues(designProperties);

        const backgroundStyle = designModel.generateBackgroundStyle(this.props.context);
        const contentStyle = designModel.generateContentStyle(this.props.context);
        const doodle = designModel.generateDoodle();
        const hashNavigation = designModel.generateHashNavigationName();
        const childCommonProps = designModel.formatCommonProps(designProperties);
        const effectAppearName = designModel.generateEffectAppear();
        const separatorType = designModel.getSeparator();

        const resolvedContentWidth = designModel.getResolvedContentWidth();
        const resolvedBackgroundColor = designModel.getActiveBackgroundColor();

        return (
            <div id={hashNavigation} style={backgroundStyle}>
                {
                    doodle != null &&
                    <DoodleWrapper rule={doodle} />
                }
                <div style={contentStyle} >
                    {
                        blockCollection.map((x, childIndex) => {

                            const context: RenderBlockContext =
                            {
                                environment: this.props.context.environment,

                                prevBlock: null,
                                nextBlock: null,
                                parentBlock: this.props.context,

                                parentBlockWidth: resolvedContentWidth,
                                parentBlockColor: resolvedBackgroundColor,
                                parentBlockAnimation: effectAppearName,

                                rootSectionType: this.props.context.rootSectionType,

                                targetBlock: x,
                                targetBlockType: BlockType.FeatureItem,
                                targetColorSchema: this.props.context.targetColorSchema,
                                targetHasBackgroundImage: false,

                                commonProperties: childCommonProps,
                            };

                            return <div key={`feature-${this.id}-${childIndex}`}>
                                <BlockRender context={context} />
                            </div>;
                        })
                    }
                </div>
                {
                    separatorType != null &&
                    <SeparatorContainer context={this.props.context} separatorType={separatorType} />
                }
            </div>
        );
    }
}