import React from 'react';

import { LayoutWithSingleChildContentModel, LayoutWithSingleChildDesignModel } from "../../models/blocks/layoutWithSingleChildModel";
import BlockRender from '../renders/blockRender';
import DoodleWrapper from "../common/doodleWrapper";
import BlockComponent from './blockComponent';
import RenderBlockContext from "../renders/renderBlockContext";
import SeparatorContainer from '../separators/separatorContainer';

export default class LayoutWithSingleChild extends BlockComponent {

    render() {

        // content
        const contentModel = new LayoutWithSingleChildContentModel();
        contentModel.initialize(this.props.context.targetBlock.blocksProps);

        const navbar = contentModel.getNavbar();
        const block = contentModel.getInnerBlock();

        // design
        const designProperties = this.props.context.commonProperties.concat(this.props.context.targetBlock.blocksProps);
        const designModel = new LayoutWithSingleChildDesignModel();
        designModel.setDefaultValues(this.props.context);
        designModel.setCustomValues(designProperties);

        const backgroundStyle = designModel.generateBackgroundStyle(this.props.context);
        const contentStyle = designModel.generateContentStyle(this.props.context);
        const doodle = designModel.generateDoodle();
        const hashNavigation = designModel.generateHashNavigationName();
        const separatorType = designModel.getSeparator();
        const navbarStyle = designModel.generateNavbarStyle();

        if (navbar != null) {
            backgroundStyle.gridTemplateRows = "auto 1fr";
        }

        const childCommonProps = designModel.formatCommonProps(designProperties);

        // effect appear
        const effectAppearName = designModel.generateEffectAppear();

        // if it has navbar than we must increase z-index
        if (navbar != null) {
            backgroundStyle.zIndex = "var(--z-index-navbar-container)";
        }

        const resolvedContentWidth = designModel.getResolvedContentWidth();
        const resolvedBackgroundColor = designModel.getActiveBackgroundColor();

        const navbarContext: RenderBlockContext = navbar == null ? null :
        {
            environment: this.props.context.environment,

            prevBlock: this.props.context.prevBlock,
            nextBlock: this.props.context.nextBlock,
            parentBlock: this.props.context,

            parentBlockWidth: resolvedContentWidth,
            parentBlockColor: resolvedBackgroundColor,
            parentBlockAnimation: effectAppearName,

            rootSectionType: this.props.context.rootSectionType,

            targetBlock: navbar,
            targetBlockType: navbar.blocksType,
            targetColorSchema: this.props.context.targetColorSchema,
            targetHasBackgroundImage: false,

            commonProperties: childCommonProps,
        };

        const blockContext: RenderBlockContext = block == null ? null :
        {
            environment: this.props.context.environment,

            prevBlock: navbarContext ?? this.props.context.prevBlock,
            nextBlock: this.props.context.nextBlock,
            parentBlock: this.props.context,

            parentBlockWidth: resolvedContentWidth,
            parentBlockColor: resolvedBackgroundColor,
            parentBlockAnimation: effectAppearName,

            rootSectionType: this.props.context.rootSectionType,

            targetBlock: block,
            targetBlockType: block.blocksType,
            targetColorSchema: this.props.context.targetColorSchema,
            targetHasBackgroundImage: false,

            commonProperties: childCommonProps,
        };

        if (navbarContext != null && blockContext != null)
            navbarContext.nextBlock = blockContext;

        return (
            <div id={hashNavigation} style={backgroundStyle}>
                {
                    doodle != null &&
                    <DoodleWrapper rule={doodle} />
                }
                {
                    navbar != null &&
                    <div style={navbarStyle}>
                        <BlockRender context={navbarContext} />
                    </div>
                }
                <div style={contentStyle}>
                    {
                        block != null &&
                        <BlockRender context={blockContext} />
                    }
                </div>
                {
                    separatorType != null &&
                    <SeparatorContainer context={this.props.context} separatorType={separatorType} />
                }
            </div>
        );
    }
}