import React from 'react';
import { BlockPropType } from '../../models/blockType';
import { isTransparent } from '../../utils/colorUtils';
import DoodleWrapper from '../common/doodleWrapper';

import BlockComponent from "../blocks/blockComponent";

export default class SeparatorTriangle extends BlockComponent {

    render() {

        // bottom color
        let bottomColor: string = null;
        if (this.props.context.nextBlock != null) {
            bottomColor = this.props.context.nextBlock.targetBlock.blocksProps.find(x => x.propType === BlockPropType.BackgroundColor)?.propString ??
                this.props.context.nextBlock.targetColorSchema?.background.getColor();
        }

        const isBottomTransparent = isTransparent(bottomColor);
        const bottomColorResolved = isBottomTransparent ? "white" : bottomColor;

        const rule = `:doodle { background: transparent; }
                    @grid: 1 / 100% 100%;
                    background: ${bottomColorResolved};
                    clip-path: polygon(0 0, 100% 100%, 0 100%);`;

        return (
            <div style={{ height: "calc(100vw/20)" }} className="position-relative mb-negative">
                <DoodleWrapper rule={rule} />
            </div>
        );
    }
}