import React from 'react';

import { ContactsSimpleContentModel, ContactsSimpleDesignModel } from "../../models/blocks/contactsSimpleModel";
import DoodleWrapper from "../common/doodleWrapper";
import BlockComponent from './blockComponent';
import TransactionContainer from '../effects/transactionContainer';
import SeparatorContainer from '../separators/separatorContainer';

export default class EditorContactsSimple extends BlockComponent {

    render() {

        // content
        const block = new ContactsSimpleContentModel();
        block.initialize(this.props.context.targetBlock.blocksProps);

        const contacts = this.props.context.environment.getContacts();

        // design
        const designProperties = this.props.context.commonProperties.concat(this.props.context.targetBlock.blocksProps);
        const designModel = new ContactsSimpleDesignModel();
        designModel.setDefaultValues(this.props.context);
        designModel.setCustomValues(designProperties);

        const backgroundStyle = designModel.generateBackgroundStyle(this.props.context);
        const contentStyle = designModel.generateContentStyle(this.props.context);
        const textStyle = designModel.generateTextStyle();
        const doodle = designModel.generateDoodle();
        const hashNavigation = designModel.generateHashNavigationName();
        const separatorType = designModel.getSeparator();

        const hasFacebook = contacts.facebook != null && contacts.facebook.length > 0;
        const hasYoutube = contacts.youtube != null && contacts.youtube.length > 0;
        const hasInstagram = contacts.instagramId != null && contacts.instagramId.length > 0;
        const hasTelegram = contacts.telegramId != null && contacts.telegramId.length > 0;
        const hasWhatsApp = contacts.whatsAppId != null && contacts.whatsAppId.length > 0;
        const hasTwitter = contacts.twitterId != null && contacts.twitterId.length > 0;
        const hasPinterest = contacts.pinterestId != null && contacts.pinterestId.length > 0;
        const hasLinkedin = contacts.linkedinId != null && contacts.linkedinId.length > 0;

        const effectAppearName = designModel.generateEffectAppear();

        return (
            <div id={hashNavigation} style={backgroundStyle}>
                {
                    doodle != null &&
                    <DoodleWrapper rule={doodle} />
                }
                <TransactionContainer style={contentStyle} effect={effectAppearName}>
                    <div className="d-flex flex-row gap-3 mx-auto">
                        {
                            contacts.telephone != null && contacts.telephone.length > 0 &&
                            <a className="bi bi-telephone lp-icon-middle lp-text-after-subtitle" style={textStyle} href={"tel:" + contacts.telephone} />
                        }
                        {
                            contacts.mail != null && contacts.mail.length > 0 &&
                            <a className="bi bi bi-envelope lp-icon-middle lp-text-after-subtitle" style={textStyle} href={"mailto:" + contacts.mail} />
                        }
                        {
                            hasFacebook &&
                            <a className="bi bi-facebook lp-icon-middle lp-text-after-subtitle" style={textStyle} target="_blank" href={"https://www.facebook.com/" + contacts.facebook} />
                        }
                        {
                            hasInstagram &&
                            <a className="bi bi-instagram lp-icon-middle lp-text-after-subtitle" style={textStyle} target="_blank" href={"https://www.instagram.com/" + contacts.instagramId} />
                        }
                        {
                            hasYoutube &&
                            <a className="bi bi-youtube lp-icon-middle lp-text-after-subtitle" style={textStyle} target="_blank" href={"https://www.youtube.com/" + contacts.youtube} />
                        }
                        {
                            hasTelegram &&
                            <a className="bi bi-telegram lp-icon-middle lp-text-after-subtitle" style={textStyle} target="_blank" href={"https://t.me/" + contacts.telegramId} />
                        }
                        {
                            hasWhatsApp &&
                            <a className="bi bi-whatsapp lp-icon-middle lp-text-after-subtitle" style={textStyle} target="_blank" href={"https://wa.me/" + contacts.whatsAppId} />
                        }
                        {
                            hasTwitter &&
                            <a className="bi bi-twitter-x lp-icon-middle lp-text-after-subtitle" style={textStyle} target="_blank" href={"https://twitter.com/" + contacts.twitterId} />
                        }
                        {
                            hasPinterest &&
                            <a className="bi bi-pinterest lp-icon-middle lp-text-after-subtitle" style={textStyle} target="_blank" href={"https://pinterest.com/" + contacts.pinterestId} />
                        }
                        {
                            hasLinkedin &&
                            <a className="bi bi-linkedin lp-icon-middle lp-text-after-subtitle" style={textStyle} target="_blank" href={"https://www.linkedin.com/company/" + contacts.linkedinId} />
                        }
                    </div>
                </TransactionContainer>

                {
                    separatorType != null &&
                    <SeparatorContainer context={this.props.context} separatorType={separatorType} />
                }

            </div>
        );
    }
}