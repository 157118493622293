import React from 'react';

import { Accordion } from "react-bootstrap";

import { FaqContentModel, FaqDesignModel } from "../../models/blocks/faqModel";
import BlockRender from "../renders/blockRender";
import { BlockType } from "../../models/blockType";
import genUniqueId from "../../models/utils/genUniqueId";
import DoodleWrapper from "../common/doodleWrapper";
import BlockComponent from './blockComponent';
import TransactionContainer from '../effects/transactionContainer';
import RenderBlockContext from "../renders/renderBlockContext";
import SeparatorContainer from '../separators/separatorContainer';
import { ColorWithDescription } from "../../utils/colorUtils";

export default class FaqPlain extends BlockComponent {

    private id: string = genUniqueId();

    render() {

        // content
        const block = new FaqContentModel();
        block.initialize(this.props.context.targetBlock.blocksProps);

        const blockCollection = block.getBlocks();

        // design
        const designProperties = this.props.context.commonProperties.concat(this.props.context.targetBlock.blocksProps);
        const designModel = new FaqDesignModel();
        designModel.setDefaultValues(this.props.context);
        designModel.setCustomValues(designProperties);

        const backgroundStyle = designModel.generateBackgroundStyle(this.props.context);
        const contentStyle = designModel.generateContentStyle(this.props.context);
        const doodle = designModel.generateDoodle();
        const hashNavigation = designModel.generateHashNavigationName();
        const childCommonProps = designModel.formatCommonProps(designProperties);
        const effectAppearName = designModel.generateEffectAppear();
        const separatorType = designModel.getSeparator();

        const resolvedContentWidth = designModel.getResolvedContentWidth();
        const resolvedBackgroundColor = designModel.getActiveBackgroundColor();
        const whiteColor = new ColorWithDescription("#FFFFFF");

        return (
            <div id={hashNavigation} style={backgroundStyle}>
                {
                    doodle != null &&
                    <DoodleWrapper rule={doodle} />
                }
                <TransactionContainer style={contentStyle} effect={effectAppearName}>
                    <Accordion alwaysOpen>
                        {
                            blockCollection.map((x, childIndex) => {

                                const context: RenderBlockContext =
                                {
                                    environment: this.props.context.environment,

                                    prevBlock: null,
                                    nextBlock: null,
                                    parentBlock: this.props.context,

                                    parentBlockWidth: resolvedContentWidth,
                                    parentBlockColor: whiteColor,
                                    parentBlockAnimation: effectAppearName,

                                    rootSectionType: this.props.context.rootSectionType,

                                    targetBlock: x,
                                    targetBlockType: BlockType.FaqItem,
                                    targetColorSchema: this.props.context.targetColorSchema,
                                    targetHasBackgroundImage: false,

                                    commonProperties: childCommonProps,
                                };

                                return <BlockRender key={`feature-${this.id}-${childIndex}`} context={context} />;
                            })
                        }
                    </Accordion>
                </TransactionContainer>

                {
                    separatorType != null &&
                    <SeparatorContainer context={this.props.context} separatorType={separatorType} />
                }

            </div>
        );
    }
}