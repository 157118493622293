import "reflect-metadata";

import React, { Suspense } from 'react';
import { Container } from 'inversify';
import { Provider } from 'inversify-react';
import * as Logger from "./utils/logger";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import UserInfo from "./models/userInfo";
import PointInfo from "./models/pointInfo";

import LoginPage from "./pages/loginPage";
import AccountPage from "./pages/editAccount/accountPage";
import NotFoundPage from "./pages/notFoundPage";

import PointPageRender from "./controls/renders/pointPageRender";
import { PointsInfoProvider } from "./models/pointsInfoProvider";
import { AccountInfoProvider } from "./models/accountInfoProvider";
import { UsersInfoProvider } from "./models/usersInfoProvider";
import { CurrentLocationProvider } from "./models/currentLocationProvider";
import { PagesInfoProvider, IRender } from "./models/pagesInfoProvider";
import { StatisticsProvider } from "./models/statisticsProvider";
import { GeneratorProvider } from "./models/generatorProvider";
import { ImageProvider } from "./models/imageProvider";
import SessionDataProvider from "./models/utils/sessionDataProvider";
import PointPageEnvironment, { WebpageColorStyle, WebpageStyleType } from "./controls/renders/pointPageEnvironment";
import WaitPanel from "./controls/waitPanel";
import EmptyLayout from "./layouts/emptyLayout";
import { ErrorDetails } from "./controls/errorPanel";
import { TestEnvironmentProvider } from "./models/testEnvironmentProvider";
import { BlockPropType } from "./models/blockType";

const NewPointPage = React.lazy(() => import("./pages/newPointPage"));
const EditPointSettingsPage = React.lazy(() => import("./pages/editPoint/settings/editPointSettingsPage"));
const PointDashboardPage = React.lazy(() => import("./pages/editPoint/dashboard/pointDashboardPage"));
const EditPointContentPage = React.lazy(() => import("./pages/editPoint/content/editPointContentPage"));
const ViewPointStatisticsPage = React.lazy(() => import("./pages/editPoint/statistics/pointStatisticsPage"));
const ViewActivitiesPage = React.lazy(() => import("./pages/editPoint/activities/viewActivitiesPage"));
const ValidationsPage = React.lazy(() => import("./pages/editPoint/validations/validationsPage"));
const RegisterUserPage = React.lazy(() => import("./pages/registerUserPage"));
const DashboardPage = React.lazy(() => import("./pages/dashboardPage"));
const StatePage = React.lazy(() => import("./pages/statePage"));

interface IState {
    isLoaded: boolean,
    isCustomPage: boolean,
    pageNotFound: boolean,
    environment: PointPageEnvironment,
}

export default class App extends React.Component<any, IState> {

    private readonly container = new Container();
    private isStatisticPosted: boolean = false;

    constructor(props: any) {
        super(props);

        this.container.bind(UserInfo)
            .toSelf()
            .inSingletonScope()
            .onActivation((context, target) => {
                target.sessionDataProvider = context.container.resolve(SessionDataProvider);
                return target;
            });
        this.container.bind(PointInfo)
            .toSelf()
            .inSingletonScope()
            .onActivation((context, target) => {
                target.sessionDataProvider = context.container.resolve(SessionDataProvider);
                target.pointsProvider = context.container.resolve(PointsInfoProvider);
                return target;
            });
        
        this.container.bind(AccountInfoProvider)
            .toSelf()
            .inSingletonScope()
            .onActivation((context, target) => {
            target.user = context.container.resolve(UserInfo);
            return target;
        });
        this.container.bind(PointsInfoProvider)
            .toSelf()
            .inSingletonScope()
            .onActivation((context, target) => {
            target.user = context.container.resolve(UserInfo);
            return target;
        });
        this.container.bind(StatisticsProvider)
            .toSelf()
            .inSingletonScope()
            .onActivation((context, target) => {
            target.user = context.container.resolve(UserInfo);
            return target;
        });
        this.container.bind(GeneratorProvider)
            .toSelf()
            .inSingletonScope()
            .onActivation((context, target) => {
            target.user = context.container.resolve(UserInfo);
            return target;
        });
        this.container.bind(UsersInfoProvider)
            .toSelf()
            .inSingletonScope()
            .onActivation((context, target) => {
            target.user = context.container.resolve(UserInfo);
            return target;
        });
        this.container.bind(PagesInfoProvider)
            .toSelf()
            .inSingletonScope()
            .onActivation((context, target) => {
            target.user = context.container.resolve(UserInfo);
            return target;
            });
        this.container.bind(CurrentLocationProvider)
            .toSelf()
            .inSingletonScope()
            .onActivation((context, target) => {
                target.pointsProvider = context.container.resolve(PointsInfoProvider);
                target.pagesProvider = context.container.resolve(PagesInfoProvider);
                target.sessionDataProvider = context.container.resolve(SessionDataProvider);
                return target;
            });
        this.container.bind(ImageProvider)
            .toSelf()
            .inSingletonScope()
            .onActivation((context, target) => {
                target.user = context.container.resolve(UserInfo);
                return target;
            });
        this.container.bind(TestEnvironmentProvider)
            .toSelf()
            .inSingletonScope()
            .onActivation((context, target) => {
                target.userInfo = context.container.resolve(UserInfo);
                target.pagesInfoProvider = context.container.resolve(PagesInfoProvider);
                target.pointsProvider = context.container.resolve(PointsInfoProvider);
                target.currentLocation = context.container.resolve(CurrentLocationProvider);
                return target;
            });

        try {
            const locationProvider = this.container.resolve(CurrentLocationProvider);
            locationProvider.initialize(window.location.hostname, window.location.pathname);

            const pageLocation = locationProvider.getPageLocation().toLowerCase();
            if (pageLocation.startsWith('/app/')) {

                this.state = {
                    isLoaded: true,
                    isCustomPage: false,
                    pageNotFound: false,
                    environment: null,
                };
                return;
            }

            const prerenderedJson = document.getElementById('json_page_content');
            if (prerenderedJson != null) {

                const pagesInfoProvider = this.container.resolve(PagesInfoProvider);
                const userInfo = this.container.resolve(UserInfo);

                const json = this.b64DecodeUnicode(prerenderedJson.textContent);
                let renderInfo: IRender = JSON.parse(json);
                const environment = new PointPageEnvironment(
                    locationProvider,
                    pagesInfoProvider,
                    userInfo,
                    renderInfo,
                    false);

                this.state = {
                    isLoaded: true,
                    isCustomPage: true,
                    pageNotFound: false,
                    environment: environment,
                };

                prerenderedJson.remove();
                return;
            }

            this.state = {
                isLoaded: false,
                isCustomPage: false,
                pageNotFound: false,
                environment: null,
            }
        } catch (error) {

            Logger.logError(new ErrorDetails("Page not found", error as Error));

            this.state = {
                isLoaded: true,
                isCustomPage: true,
                pageNotFound: true,
                environment: null,
            };
        }
    }

    b64DecodeUnicode(str: string) : string {
        return decodeURIComponent(Array.prototype.map.call(atob(str), function (c:any) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
    }

    componentDidMount() {

        if (!this.state.isLoaded) {
            this.loadStateFromServer().then(x => { this.setState(x); return x; }).then(x => this.registerRendering(x.environment));
        } else {
            this.registerRendering(this.state.environment);
        }
    }

    async loadStateFromServer(): Promise<IState> {

        const pagesInfoProvider = this.container.resolve(PagesInfoProvider);
        const currentLocation = this.container.resolve(CurrentLocationProvider);
        const pointsProvider = this.container.resolve(PointsInfoProvider);
        const userInfo = this.container.resolve(UserInfo);

        try {

            const hostname = currentLocation.getHostname();
            const pageLocation = currentLocation.getPageLocation();
            const pageIndex = currentLocation.getPageArg();

            let renderInfo = await pagesInfoProvider.render(hostname, pageLocation, pageIndex);

            const environment = new PointPageEnvironment(currentLocation,
                pagesInfoProvider,
                userInfo,
                renderInfo,
                false);

            return {
                isLoaded: true,
                isCustomPage: true,
                pageNotFound: false,
                environment: environment,
            };

        } catch (error) {

            Logger.logError(new ErrorDetails("Page not found", error as Error));

            return {
                isLoaded: true,
                isCustomPage: true,
                pageNotFound: true,
                environment: null,
            };
        }
    }

    registerRendering(environment: PointPageEnvironment): Promise<string> {

        if (environment == null || this.isStatisticPosted)
            return null;

        this.isStatisticPosted = true;

        const isPost = environment.isPost();
        const pointId = environment.getPointId();
        const entityId = environment.getId();
        let referrer = document.referrer;
        
        if (referrer != null && referrer.length > 0) {
            try
            {
                let url = new URL(referrer);
                if (url.host === window.location.hostname) {
                    referrer = null;
                }
            }
            catch (error) { }
        }
        
        const pagesInfoProvider = this.container.resolve(PagesInfoProvider);
        if (isPost) {
            return pagesInfoProvider.registerPostRendering(pointId, entityId, navigator.userAgent, referrer);
        } else {
            return pagesInfoProvider.registerPageRendering(pointId, entityId, navigator.userAgent, referrer);
        }
    }
    
    render() {

        if (!this.state.isLoaded) {
            return (
                <Provider container={this.container}>
                    <EmptyLayout>
                        <WaitPanel />
                    </EmptyLayout>
                </Provider>
            );
        }

        if (this.state.pageNotFound) {
            return (
                <Provider container={this.container}>
                    <BrowserRouter>
                        <Routes>
                            <Route path="*" element={<NotFoundPage />} />
                        </Routes>
                    </BrowserRouter>
                </Provider>
            );
        }

        if (!this.state.isCustomPage) {
            return (
                <Provider container={this.container}>
                    <BrowserRouter>
                        <Routes>
                            <Route path="/app/dashboard" element={<Suspense><DashboardPage /></Suspense>} />

                            <Route path="/app/points/create" element={<Suspense><NewPointPage/></Suspense>} />
                            <Route path="/app/points/:pointId" element={<Suspense><PointDashboardPage /></Suspense>} />
                            <Route path="/app/points/:pointId/settings" element={<Suspense><EditPointSettingsPage /></Suspense>} />
                            <Route path="/app/points/:pointId/content" element={<Suspense><EditPointContentPage /></Suspense>} />
                            <Route path="/app/points/:pointId/statistics" element={<Suspense><ViewPointStatisticsPage /></Suspense>} />
                            <Route path="/app/points/:pointId/activities" element={<Suspense><ViewActivitiesPage /></Suspense>} />
                            <Route path="/app/points/:pointId/warnings" element={<Suspense><ValidationsPage /></Suspense>} />

                            <Route path="/app/login" element={<LoginPage />} />
                            <Route path="/app/register" element={<Suspense><RegisterUserPage restoreMode={false} /></Suspense>} />
                            <Route path="/app/restore" element={<Suspense><RegisterUserPage restoreMode={true} /></Suspense>} />
                            <Route path="/app/account" element={<AccountPage />} />

                            <Route path="/app/state" element={<Suspense><StatePage /></Suspense>} />

                            <Route path="*" element={<NotFoundPage />} />
                        </Routes>
                    </BrowserRouter>
                </Provider>
            );
        }

        if (this.state.environment.getPointId() === "053706c0-21d2-430a-feab-08db3f4343b0") // tisona
        {
            for (const block of this.state.environment.getBlocks()) {
                block.blocksProps.push({
                    propType: BlockPropType.FontClassModificator,
                    propString: "tisona",
                });
            }
        }

        return (
            <Provider container={this.container}>
                <PointPageRender environment={this.state.environment} />
            </Provider>
        );
    }
}