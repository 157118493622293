import React from 'react';

import { ErrorDetails } from "../../controls/errorPanel";
import * as Logger from "../../utils/logger";

export interface IComponentBaseState {
    isLoaded: boolean,
    error: ErrorDetails | null,
}

export default class ComponentBase<IProp, IState extends IComponentBaseState> extends React.Component<IProp, Partial<IState>> {

    protected processError(title: string, details: Error): void {

        const errorDetails = new ErrorDetails(title, details);
        this.processErrorDetails(errorDetails);
    }

    protected processErrorDetails(error: ErrorDetails): void {
        Logger.logError(error);
        this.setState({ error: error });
    }
}