import React from 'react';

import "reflect-metadata";

import genUniqueId from "../../models/utils/genUniqueId";
import BlockComponent from './blockComponent';
import { BlockType } from '../../models/blockType';
import BlockRender from '../renders/blockRender';
import { MenuAnchorsListContentModel, MenuAnchorsListDesignModel } from '../../models/blocks/menuAnchorsListModel';
import DoodleWrapper from '../common/doodleWrapper';
import TransactionContainer from '../effects/transactionContainer';
import RenderBlockContext from "../renders/renderBlockContext";
import SeparatorContainer from '../separators/separatorContainer';

export default class MenuAnchorsList extends BlockComponent {

    private id: string = genUniqueId();

    render() {

        // content
        const contentModel = new MenuAnchorsListContentModel();
        contentModel.initialize(this.props.context.targetBlock.blocksProps);

        const blocks = contentModel.getBlocks(this.props.context.environment);

        // design
        const designProperties = this.props.context.commonProperties.concat(this.props.context.targetBlock.blocksProps);
        const designModel = new MenuAnchorsListDesignModel();
        designModel.setDefaultValues(this.props.context);
        designModel.setCustomValues(designProperties);

        const backgroundStyle = designModel.generateBackgroundStyle(this.props.context);
        const contentStyle = designModel.generateContentStyle(this.props.context);
        const doodle = designModel.generateDoodle();
        const hashNavigation = designModel.generateHashNavigationName();
        const effectAppearName = designModel.generateEffectAppear();
        const separatorType = designModel.getSeparator();

        const childCommonProps = designModel.formatCommonProps(designProperties);
        const resolvedContentWidth = designModel.getResolvedContentWidth();
        const resolvedBackgroundColor = designModel.getActiveBackgroundColor();

        return (
            <div id={hashNavigation} style={backgroundStyle}>
                {
                    doodle != null &&
                    <DoodleWrapper rule={doodle} />
                }
                <TransactionContainer style={contentStyle} effect={effectAppearName}>
                    <div className="d-flex flex-column gap-2 w-100">
                        {
                            this.props.context.environment.getIsPreview() && blocks.length === 0 &&
                            <div className="text-secondary">Strona nie ma żadnych linków</div>
                        }
                        {
                            blocks.map((x, index) => {

                                const context: RenderBlockContext =
                                {
                                    environment: this.props.context.environment,

                                    prevBlock: null,
                                    nextBlock: null,
                                    parentBlock: this.props.context,

                                    parentBlockWidth: resolvedContentWidth,
                                    parentBlockColor: resolvedBackgroundColor,
                                    parentBlockAnimation: effectAppearName,

                                    rootSectionType: this.props.context.rootSectionType,

                                    targetBlock: x,
                                    targetBlockType: BlockType.LinksItem,
                                    targetColorSchema: this.props.context.targetColorSchema,
                                    targetHasBackgroundImage: false,

                                    commonProperties: childCommonProps,
                                };

                                return <BlockRender key={`menu-${this.id}-${index}`} context={context} />;
                            })
                        }
                    </div>
                </TransactionContainer>
                {
                    separatorType != null &&
                    <SeparatorContainer context={this.props.context} separatorType={separatorType} />
                }
            </div>
        );
    }
}