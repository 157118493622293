import "reflect-metadata";

import { injectable } from "inversify";

import { PointsInfoProvider } from "./pointsInfoProvider";
import { PagesInfoProvider } from "./pagesInfoProvider";
import SessionDataProvider from "./utils/sessionDataProvider";
import UrlParams from "../utils/urlParams";

@injectable()
export class CurrentLocationProvider {
    
    static dotDetailsKey = "DotDetails";
    public sessionDataProvider: SessionDataProvider;

    public pointsProvider: PointsInfoProvider;
    public pagesProvider: PagesInfoProvider;

    private currentHost: string;
    private currentPathname: string;

    private absoluteLinkRegExp: RegExp;

    public initialize(hostname: string, pathname: string): any {

        this.currentHost = hostname;
        this.currentPathname = pathname;
    }

    public getHostname(): string {
        return this.currentHost;
    }

    private isAbsoluteLink(link: string) {

        if (link == null) {
            return false;
        }

        const lowerCaseLink = link.toLowerCase();
        if (lowerCaseLink.startsWith("tel:") || lowerCaseLink.startsWith("mailto:")) {
            return true;
        }

        if (this.absoluteLinkRegExp == null) {
            this.absoluteLinkRegExp = new RegExp('^(?:[a-z+]+:)?//', 'i');
        }

        return this.absoluteLinkRegExp.test(link);
    }

    public getLink(link: string, lang: string): string {

        if (this.isAbsoluteLink(link)) {
            return link;
        }

        let path = link ?? "/";
        if (path.length === 0) {
            path = "/";
        } else if (path[0] !== '/') {
            path = "/" + path;
        }

        if (lang != null && lang.length > 0) {

            path = "/" + lang + path;
        }

        return path;
    }

    public getAbsoluteLink(link: string, lang: string): string {

        const relativePart = this.getLink(link, lang);
        if (this.isAbsoluteLink(relativePart))
            return relativePart;

        return "https://" + this.getHostname() + relativePart;
    }

    public getLoginLink() {

        let loginUrl = this.getLink("/app/login", null);
        if (window.location.pathname != null && window.location.pathname !== '/') {
            loginUrl += '?backLink=' + window.location.href;
        }

        return loginUrl;
    }

    public getLanguage() : string{

        if (this.currentPathname == null || this.currentPathname.length === 0) {
            return null;
        }

        if (this.currentPathname.length > 3 && this.currentPathname[0] === '/' && this.currentPathname[3] === '/') {
            return this.currentPathname.substring(1, 3);
        }

        if (this.currentPathname.length === 3 && this.currentPathname[0] === '/') {
            return this.currentPathname.substring(1, 3);
        }

        return null;
    }

    public getQueryParam(location: Location, name: string): string {

        const urlSearchParams = new URLSearchParams(location.search);
        const result = urlSearchParams.get(name);

        return result;
    }

    public getHash(location: Location, defaultValue: string): string {

        return location.hash == null || location.hash.length === 0 ? defaultValue : location.hash;
    }

    public getPageArg(): number {

        return getPageArg();
    }

    public getImageUrl(imageId: string): string {

        if (imageId == null || imageId.length === 0)
            return null;

        return "https://imagedelivery.net/LEkYLg3AynTmDJfgU-NNdw/" + imageId + "/public";
    }
    
    public getPreviewImageUrl(imageId: string): string {

        if (imageId == null)
            return null;

        return "https://imagedelivery.net/LEkYLg3AynTmDJfgU-NNdw/" + imageId + "/preview";
    }
    
    public getPageLocation(): string {
        return this.currentPathname;
    }

    public openTermsOfService() {
        window.open("https://lifedots.pl/terms-of-service", "_blank");
    }

    public openPrivacyPolicy() {
        window.open("https://lifedots.pl/privacy-policy", "_blank");
    }

    public openRodo() {
        window.open("https://lifedots.pl/rodo", "_blank");
    }

    public openCookiePolicy() {
        window.open("https://lifedots.pl/cookie-policy", "_blank");
    }
}

export function getPageArg(): number {

    const urlParams = new UrlParams(window.location.search);
    const pageText = urlParams.get("page");

    let page = parseInt(pageText);
    if (page == null || isNaN(page)) {
        page = 1;
    }

    if (page < 1) {
        page = 1;
    }

    return page;
}