export enum BlockType {

    None = 0,

    TextPlain = 1,

    CoverTitleSubtitle = 101,

    QuoteAuthor = 201,

    ImageSimple = 301,

    FooterPlainWithContacts = 401,
    FooterWithNavigationAndContacts = 402,
    FooterWithoutContacts = 403,

    PostFeed = 601,
    PostFeedPage = 602,
    PostFeedInColumns = 603,

    FeaturesWithHeaderInColumn = 701,
    FeaturesSimpleInColumn = 702,
    FeaturesSimpleInTwoColumns = 703,

    SimpleCards = 802,
    CardsSmall = 803,

    TimelinePoints = 901,

    ContactsSimple = 1001,
    ContactsInIcons = 1002,

    GallerySimple = 1101,
    GalleryPreview = 1102,
    GalleryPolaroid = 1103,

    LayoutTwoColumns = 1201,
    LayoutCarousel = 1202,
    LayoutStack = 1203,
    LayoutTwoColumnsAndHeader = 1204,
    LayoutWithSingleChild = 1205,

    ExternalFrame = 1301,
    YoutubeFrame = 1302,

    DefaultNavbar = 1401,
    MenuAnchorsList = 1402,

    Map = 1501,

    Buttons = 1601,
    ButtonsVertical = 1602,
    ButtonsVerticalWithContacts = 1603,
    Links = 1604,

    Faq = 1701,

    SeparatorEmpty = 1802,
    SeparatorLineSimple = 1803,
    SeparatorLineWithText = 1804,

    HelpPage = 1901,

    Numbers = 2001,

    CollectionChild = 10000,

    CardItem = 10101,
    FeatureItem = 10102,
    FeatureWithTitleItem = 10103,
    TimelineWithTitleItem = 10104,
    GalleryItem = 10105,
    NavbarItem = 10106,
    GalleryPreviewItem = 10107,
    GalleryPolaroidItem = 10108,
    ButtonsItem = 10109,
    FaqItem = 10110,
    CardSmallItem = 10111,
    LinksItem = 10112,
    NumbersItem = 10113,
    NavbarLightItem = 10114,
}

export enum BlockPropType {

    Title = 0,
    Subtitle = 1,
    Uppertitle = 2,

    Content = 10,
    Html = 11,
    RegularText = 12,

    Image = 20,
    ImageDescription = 21,
    ImageAltText = 22,

    Author = 30,

    BackgroundImage = 40,
    BackgroundImageDirection = 41,

    BackgroundColor = 50,
    BackgroundShadow = 51,

    BackgroundMarginLeft = 60,
    BackgroundMarginRight = 61,
    BackgroundMarginTop = 62,
    BackgroundMarginBottom = 63,
    BackgroundHeight = 64,

    BackgroundCorner = 70,

    FontColor = 80,
    FontShadow = 81,
    FontBorderColor = 82,

    VerticalAlignment = 90,
    HorizontalAlignment = 91,
    TextAndButtonsAlignment = 92,

    ContentMarginTop = 100,
    ContentMarginBottom = 101,
    ContentWidth = 102,

    BorderColor = 110,

    Link = 120,
    HashNavigation = 121,
    LinkText = 122,
    HashNavigationText = 123,

    ShowTitle = 130,
    ShowContacts = 131,

    Address = 140,

    ItemId = 150,
    Date = 151,
    Count = 152,

    SeoTitle = 160,
    SeoDescription = 161,
    SeoImage = 162,

    TargetColor0 = 170,
    TargetColor1 = 171,
    
    AutoGenerateContent = 180,
    AutoGenerateForAnchors = 181,

    ButtonColor = 190,
    ButtonFilled = 191,
    ButtonCorner = 192,

    SeparatorType = 200,
    EffectAppear = 201,

    EffectImageAdditionalBackground = 500,
    EffectImageBorderRadius = 501,

    ThemeTimelinePoints = 600,

    Collection = 1000,
    
    DoodleBubblesColor1 = 3000,
    DoodleBubblesColor2 = 3001,
    DoodleBubblesColor3 = 3002,

    DoodleSquaresColorFill = 3010,
    DoodleSquaresColorBorder = 3011,

    DoodleTrianglesColor0 = 3020,
    DoodleTrianglesColor1 = 3021,
    DoodleTrianglesColor2 = 3022,

    FontClassModificator = 9999,
}
