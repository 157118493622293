import React from 'react';

import "reflect-metadata";

import { Button } from "react-bootstrap";

interface IRootProps {
    innerKey: string;
    icon: string;
    title: string;
    isSelected: Boolean;
    onSelect: (innerKey: string) => void;
}

export default class NavigationPanelPoint extends React.Component<IRootProps> {

    render() {

        return (
            <Button className={`border-0 ${this.props.icon} mt-2 mb-0 w-100 text-start text-nowrap`}
                variant={this.props.isSelected ? "secondary" : "outline-secondary"} href={this.props.innerKey}
                onClick={() => this.props.onSelect == null ? null : this.props.onSelect(this.props.innerKey)}> {this.props.title}</Button>
        );
    }
}