import React from 'react';

import { FooterPlainWithContactsContentModel, FooterPlainWithContactsDesignModel } from "../../models/blocks/footerPlainWithContactsModel";
import BlockComponent from './blockComponent';
import PolicyLinks from "../common/policyLinks";

export default class EditorFooterWithoutContacts extends BlockComponent {
    
    render() {

        // content
        const block = new FooterPlainWithContactsContentModel();
        block.initialize(this.props.context.targetBlock.blocksProps);

        const content = block.getContent();

        // design
        const designProperties = this.props.context.commonProperties.concat(this.props.context.targetBlock.blocksProps);
        const designModel = new FooterPlainWithContactsDesignModel();
        designModel.setDefaultValues(this.props.context);
        designModel.setCustomValues(designProperties);

        const backgroundStyle = designModel.generateBackgroundStyle(this.props.context);
        const contentStyle = designModel.generateContentStyle(this.props.context);
        const textStyle = designModel.generateTextStyle();
        const fontClassModificator = designModel.getFontClassModificator();
        const doodle = designModel.generateDoodle();
        const hashNavigation = designModel.generateHashNavigationName();
        const effectAppearName = designModel.generateEffectAppear();

        return (
            <div style={backgroundStyle} className="mt-auto">
                <div style={contentStyle}>
                    <div className="footer-without-contacts-grid my-3">
                        <div className={`lp-text-small w-auto ${fontClassModificator}`} style={textStyle}>
                            <div dangerouslySetInnerHTML={{ __html: content }} />
                        </div>
                        <div className="lp-text-small d-flex flex-row me-auto gap-2 w-auto">
                            <PolicyLinks textStyle={textStyle} environment={this.props.context.environment} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}