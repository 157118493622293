import "reflect-metadata";

import Cookies from 'universal-cookie';
import { injectable } from "inversify";

import * as Logger from "../utils/logger";

import { AccountInfoProvider } from "./accountInfoProvider";
import SessionDataProvider from "./utils/sessionDataProvider";

@injectable()
export default class UserInfo {

    static cookiesUserTokenKey = "EC918550-25C0-424F-A016-1319D72E4362";
    static cookiesAcceptKey = "AcceptCookies";

    static userDetailsKey = "User";
    static statisticsKey = "StatisticsKey";

    public sessionDataProvider: SessionDataProvider;

    private statisticsKey: string;
    private userDetails: IUserDetails;

    public getUserKey(): string {

        if (this.statisticsKey != null) {
            return this.statisticsKey;
        }

        this.statisticsKey = this.sessionDataProvider.getString(null, UserInfo.statisticsKey);
        if (this.statisticsKey != null) {
            return this.statisticsKey;
        }

        const { v4: uuidv4 } = require('uuid');
        this.statisticsKey = uuidv4();
        
        this.sessionDataProvider.setString(null, UserInfo.statisticsKey, this.statisticsKey);
        return this.statisticsKey;
    }

    public getUserToken(): string | null {

        const cookies = new Cookies();

        let tokenString = cookies.get(UserInfo.cookiesUserTokenKey);
        if (tokenString != null) {
            return tokenString;
        }

        this.clear();
        return null;
    }

    public setUserToken(value: string, keepSignedIn: boolean, expires: Date): void {

        const cookies = new Cookies();
        if (keepSignedIn) {
            cookies.set(UserInfo.cookiesUserTokenKey, value, { secure: false, path: "/", expires: expires });
        } else {
            cookies.set(UserInfo.cookiesUserTokenKey, value, { secure: false, path: "/", expires: null });
        }

        this.refreshUserDetails();
    }

    public setAcceptCookiesMark() {
        const cookies = new Cookies();
        cookies.set(UserInfo.cookiesAcceptKey, "true", { secure: false, path: "/", expires: new Date(new Date().getFullYear() + 10, 1, 1) });
    }

    public getAcceptCookiesMark() : boolean {
        const cookies = new Cookies();
        const accptedText = cookies.get(UserInfo.cookiesAcceptKey);
        return accptedText != null && accptedText === "true";
    }

    public isLoggedIn(): boolean {
        return this.getUserToken() != null;
    }
    
    public clear() {
        const cookies = new Cookies();
        cookies.remove(UserInfo.cookiesUserTokenKey, { secure: false, path: "/" });

        this.userDetails = null;

        this.refreshUserDetails();
    }

    public refreshUserDetails() {
        this.sessionDataProvider.removeItem(null, UserInfo.userDetailsKey);
    }

    public getUserIdAsync(accountInfoProvider: AccountInfoProvider): Promise<string|null> {

        if (this.userDetails != null) {
            return Promise.resolve(this.userDetails.id);
        }

        this.userDetails = this.sessionDataProvider.getValue<IUserDetails>(null, UserInfo.userDetailsKey);
        if (this.userDetails != null) {
            return Promise.resolve(this.userDetails.id);
        }

        const userToken = this.getUserToken();
        if (userToken == null) {
            return Promise.resolve(null);
        }

        return this.updateCacheAsync(accountInfoProvider).then(() => this.userDetails?.id);
    }

    public getUserNameAsync(accountInfoProvider: AccountInfoProvider): Promise<string|null> {

        if (this.userDetails != null) {
            return Promise.resolve(this.userDetails.name);
        }

        this.userDetails = this.sessionDataProvider.getValue<IUserDetails>(null, UserInfo.userDetailsKey);
        if (this.userDetails != null) {
            return Promise.resolve(this.userDetails.name);
        }

        const userToken = this.getUserToken();
        if (userToken == null) {
            return Promise.resolve(null);
        }

        return this.updateCacheAsync(accountInfoProvider).then(() => this.userDetails?.name);
    }

    public getUserNameOrDefault(): string|null {

        if (this.userDetails != null) {
            return this.userDetails.name;
        }

        this.userDetails = this.sessionDataProvider.getValue<IUserDetails>(null, UserInfo.userDetailsKey);
        if (this.userDetails != null) {
            return this.userDetails.name;
        }

        return null;
    }

    public getUserImageAsync(accountInfoProvider: AccountInfoProvider): Promise<string|null> {

        if (this.userDetails != null) {
            return Promise.resolve(this.userDetails.imageId);
        }

        this.userDetails = this.sessionDataProvider.getValue<IUserDetails>(null, UserInfo.userDetailsKey);
        if (this.userDetails != null) {
            return Promise.resolve(this.userDetails.imageId);
        }

        const userToken = this.getUserToken();
        if (userToken == null) {
            return Promise.resolve(null);
        }

        return this.updateCacheAsync(accountInfoProvider).then(() => this.userDetails?.imageId);
    }

    public getUserImageOrDefault(): string|null {

        if (this.userDetails != null) {
            return this.userDetails.imageId;
        }

        this.userDetails = this.sessionDataProvider.getValue<IUserDetails>(null, UserInfo.userDetailsKey);
        if (this.userDetails != null) {
            return this.userDetails.imageId;
        }

        return null;
    }

    private updateCacheAsync(accountInfoProvider: AccountInfoProvider): Promise<void> {

        const userToken = this.getUserToken();
        if (userToken == null) {
            return Promise.resolve();
        }

        return this.loadDataFromServerAsync(accountInfoProvider)
            .catch(error => {
                Logger.Core.error("Cannot receive user details", error);
                this.clear();
            });
    }

    private loadDataFromServerAsync(accountInfoProvider: AccountInfoProvider): Promise<void> {

        return accountInfoProvider.getAccount()
            .then(x => {

                this.userDetails = {
                    id: x.id,
                    name: x.firstName,
                    imageId: x.image,
                };

                this.sessionDataProvider.setValue(null, UserInfo.userDetailsKey, this.userDetails);
            });
    }
}

interface IUserDetails {
    id: string;
    name: string;
    imageId: string;
}