import React from 'react';

import { ExternalFrameContentModel, ExternalFrameDesignModel } from "../../models/blocks/externalFrameModel";
import InnerHTML from 'dangerously-set-html-content'
import DoodleWrapper from "../common/doodleWrapper";
import BlockComponent from './blockComponent';
import TransactionContainer from '../effects/transactionContainer';
import SeparatorContainer from '../separators/separatorContainer';

export default class ExternalFrame extends BlockComponent {

    render() {

        // content
        const block = new ExternalFrameContentModel();
        block.initialize(this.props.context.targetBlock.blocksProps);

        const html = block.getHtml();

        // design
        const designProperties = this.props.context.commonProperties.concat(this.props.context.targetBlock.blocksProps);
        const designModel = new ExternalFrameDesignModel();
        designModel.setDefaultValues(this.props.context);
        designModel.setCustomValues(designProperties);

        const backgroundStyle = designModel.generateBackgroundStyle(this.props.context);
        const contentStyle = designModel.generateContentStyle(this.props.context);
        const textStyle = designModel.generateTextStyle();
        const fontClassModificator = designModel.getFontClassModificator();
        const doodle = designModel.generateDoodle();
        const hashNavigation = designModel.generateHashNavigationName();
        const effectAppearName = designModel.generateEffectAppear();
        const separatorType = designModel.getSeparator();

        return (
            <div id={hashNavigation} style={backgroundStyle}>
                {
                    doodle != null &&
                    <DoodleWrapper rule={doodle} />
                }
                <TransactionContainer style={contentStyle} effect={effectAppearName}>
                    {
                        html != null && html.length > 0 &&
                        <InnerHTML className={`lp-text ${fontClassModificator}`} style={textStyle} html={html} />
                    }
                </TransactionContainer>

                {
                    separatorType != null &&
                    <SeparatorContainer context={this.props.context} separatorType={separatorType} />
                }

            </div>
        );
    }
}