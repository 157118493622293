import React from 'react';

import "reflect-metadata";
import { resolve } from 'inversify-react';

import { Form, Button, Row, Col, NavLink, Container, Stack } from "react-bootstrap";

import UserInfo from "../models/userInfo"
import * as Logger from "../utils/logger";

import EmptyLayout from "../layouts/emptyLayout";
import { ErrorPanel, ErrorDetails } from "../controls/errorPanel";
import ComponentBase from "./common/componentBase";
import { AccountInfoProvider, IIdToken } from "../models/accountInfoProvider";
import UrlParams from "../utils/urlParams";
import FacebookLogin, * as ReactFacebookLogin from 'react-facebook-login';

class LoginPageState {
    isLoaded: boolean = true;
    isValidated: boolean = false;
    isInvalidPassword: boolean = false;
    error: ErrorDetails | null = null;
}

export default class LoginPage extends ComponentBase<any, LoginPageState> {

    @resolve(UserInfo)
    private readonly user: UserInfo;

    @resolve(AccountInfoProvider)
    private readonly accountProvider: AccountInfoProvider;

    constructor(prop: any) {
        super(prop);

        document.title = "Login";

        this.state = {
            isLoaded: true,
            isValidated: false,
            isInvalidPassword: false,
            error: null,
        };

        this.onSubmit = this.onSubmit.bind(this);
        this.onResponseFacebook = this.onResponseFacebook.bind(this);
    }

    async onSubmit(event:any): Promise<boolean> {

        event.preventDefault();
        event.stopPropagation();

        const currentTarget = event.currentTarget;
        currentTarget["passwordEditor"].setCustomValidity("");

        const isValid = currentTarget.checkValidity() === true;
        if (!isValid) {

            this.setState({ isInvalidPassword: false, isValidated: true });
            return false;
        }

        const email = event.target["emailEditor"].value;
        const password = event.target["passwordEditor"].value;

        let loginResult: IIdToken|null = null;
        try {
            loginResult = await this.accountProvider.login(email, password);
        }
        catch (errorLogin) {

            const errorDetails = new ErrorDetails("invalid password", errorLogin as Error);
            Logger.logError(errorDetails);

            currentTarget["passwordEditor"].setCustomValidity("invalid password");
            this.setState({ isInvalidPassword: true, isValidated: true });
            return false;
        }

        try {
            this.user.setUserToken(loginResult.token, true, new Date(loginResult.expires));

            const urlParams = new UrlParams(window.location.search);
            const backLink = urlParams.get("backLink");
            window.location.href = backLink == null ? '/app/dashboard' : backLink;
        }
        catch (error) {
            this.processError("Login failed", error as Error);
        }

        return false;
    }

    async onResponseFacebook(response: ReactFacebookLogin.ReactFacebookLoginInfo) {

        if (response == null || response.accessToken == null || response.accessToken.length === 0)
            return;

        try {

            const loginResult = await this.accountProvider.loginWithFacebook(response.accessToken);
            this.user.setUserToken(loginResult.token, true, new Date(loginResult.expires));

            const urlParams = new UrlParams(window.location.search);
            const backLink = urlParams.get("backLink");
            window.location.href = backLink == null ? '/app/dashboard' : backLink;

        }
        catch (errorLogin) {

            this.processError("Cannot login with facebook", errorLogin as Error);
            return;
        }
    }

    render() {
        return (
            <EmptyLayout>

                <Container fluid className="d-flex flex-column">
                    <Row xs={1} md={2} className="flex-fill">
                        <Col className="align-items-center d-none d-md-flex" style={{ "backgroundImage": "radial-gradient(rgb(100, 228, 255), rgb(0, 128, 255))"}}>
                            <div className="mx-auto">
                                <h1>Witamy w lifeDots</h1>
                            </div>
                        </Col>
                        <Col>
                            <div className="h-100 d-flex flex-column-reverse">
                                <div className="ms-auto d-flex flex-row gap-2">
                                    <a className="my-2 me-0" href='/app/restore'>Zapomniałem hasła</a>
                                </div>
                                <div className="my-auto mx-auto">
                                    <div className="px-4 d-grid">
                                        <h2 className="text-center">Zaloguj się</h2>

                                        <Form noValidate validated={this.state.isValidated} onSubmit={this.onSubmit} id="loginForm">
                                            <Form.Group controlId="emailEditor" className="mt-2">
                                                <Form.Control required type="email" placeholder="e-mail" />
                                                <Form.Control.Feedback type="invalid">E-mail jest wymagany</Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group controlId="passwordEditor" className="mt-2">
                                                <Form.Control required type="password" placeholder="hasło" />
                                                <Form.Control.Feedback type="invalid">
                                                    {
                                                        this.state.isInvalidPassword ? "Wprowadzono nieprawidłowe hasło lub e-mail" : "Hasło jest wymagane"
                                                    }
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Form>
                                        
                                        <Button className="mt-2 mx-0 rounded-0" type="submit" form="loginForm" size="lg" variant="success">Zaloguj się</Button>

                                        <div className="mx-auto mt-2">lub</div>

                                        <div className="mt-2">
                                            <FacebookLogin
                                                appId="433521375796886"
                                                autoLoad={false}
                                                scope="public_profile,email"
                                                fields="id"
                                                callback={this.onResponseFacebook}
                                                cssClass="rounded-0 btn btn-primary btn-lg w-100"
                                                language="pl"
                                                icon="bi bi-facebook"
                                                textButton=" Kontynuuj z Facebook"/>
                                        </div>

                                        <div className="mt-2 mx-auto d-flex flex-row gap-2">
                                            <div>Nie masz konta?</div>
                                            <a href='/app/register'>Załóż je</a>
                                        </div>
                                        
                                    </div>
                                </div>
                                
                            </div>
                        </Col>
                    </Row>
                </Container>
                
                <ErrorPanel error={this.state.error} />
            </EmptyLayout>
        );
    }
}