
import "reflect-metadata";
import { resolve } from 'inversify-react';
import { inject, injectable } from "inversify";

import WebRequest from "../utils/webRequest";
import UserInfo from "./userInfo";
import { IId } from "./entities/iidname";
import { IIdName } from "./entities/iid";
import { IName } from "./entities/iname";
import { ILatestStats } from "./statisticsProvider";

export interface IUserNames {
    usersNames: string[];
}

export interface ICount {
    count: number;
}

@injectable()
export class UsersInfoProvider {

    public user: UserInfo;
    
    public verifyUser(email: string): Promise<string> {
        return WebRequest.requestText("/api/identity/v1/account/verify",
            "POST",
            null,
            {
                "email": email,
            });
    }

    public createUser(email: string, password: string, code: string): Promise<IId> {
        return WebRequest.requestItem<IId>("/api/identity/v1/account/create",
            "POST",
            null,
            {
                "email": email,
                "password": password,
                "code": code,
            });
    }

    public searchUsers(text: string): Promise<IIdName[]> {

        let query = "";

        if (text != null && text.length > 0) {
            query += "&text=" + text;
        }

        if (query.length === 0) {
            return WebRequest.requestItem<IIdName[]>("/api/identity/v1/users", "GET", this.user.getUserToken(), null);
        }

        let url = "/api/identity/v1/users?" + query.substr(1);
        return WebRequest.requestItem<IIdName[]>(url, "GET", this.user.getUserToken(), null);
    }

    public getUsersCount(): Promise<ICount> {
        return WebRequest.requestItem<ICount>("/api/identity/v1/server/users_count", "GET", this.user.getUserToken(), null);
    }

    public getRegistrationsCount(): Promise<ICount> {
        return WebRequest.requestItem<ICount>("/api/identity/v1/server/registrations_tries_count", "GET", this.user.getUserToken(), null);
    }

    public getUser(userId: string): Promise<IName> {
        return WebRequest.requestItem<IName>("/api/identity/v1/users/" + userId, "GET", this.user.getUserToken(), null);
    }

    public getUserNames(ids: string[]): Promise<IUserNames> {
        const userInfo = this.user;
        return WebRequest.requestItem<IUserNames>("/api/identity/v1/users/names",
            "POST",
            userInfo.getUserToken(),
            {
                "usersIds": ids,
            });
    }

    public getLatestUsersCount(): Promise<ILatestStats> {
        return WebRequest.requestItem<ILatestStats>("/api/identity/v1/server/latest_users_count", "GET", this.user.getUserToken(), null);
    }

    public getLatestRegistrationsCount(): Promise<ILatestStats> {
        return WebRequest.requestItem<ILatestStats>("/api/identity/v1/server/latest_registrations_tries_count", "GET", this.user.getUserToken(), null);
    }
}