import * as Logger from "./logger";

import settings from "../configuration/config.json";
type RequestCredentials = "include" | "omit" | "same-origin";

export default class WebRequest {

    public static async request(endPoint: string, method: "GET" | "POST" | "DELETE", token: string|null, body: any, contentType: string): Promise<Response> {

        try {

            var myHeaders: any;
            if (contentType != null) {
                myHeaders = {
                    "Content-Type": contentType,
                    "Accept": "*/*",
                    "Authorization": token != null ? "Bearer " + token : null
                };
            } else {
                myHeaders = {
                    "Accept": "*/*",
                    "Authorization": token != null ? "Bearer " + token : null
                };
            }

            let credentials: RequestCredentials = token != null ? "include" : undefined;
            
            var requestOptions = {
                method: method,
                headers: myHeaders,
                body: body,
                credentials: credentials,
            };

            const response: Response = await fetch(settings.api_url + endPoint, requestOptions);
            if (!response.ok) {
                const responseText = await response.text();
                throw new Error(responseText);
            }

            return response;
        } catch (error) {

            Logger.Core.error("API request is failed", error as Error);
            throw error;
        }
    }

    public static requestText(endPoint: string, method: "GET" | "POST" | "DELETE", token: string|null, body: any): Promise<string> {

        let rawBody: string | null = null;
        if (body != null) {
            rawBody = JSON.stringify(body);
        }

        return WebRequest.request(endPoint, method, token, rawBody, "application/json")
            .then(response => response.text());
    }

    public static generateUploadLink<T>(endPoint: string, token: string): Promise<T> {

        return WebRequest.request(endPoint, "POST", token, null, "image/jpeg")
            .then(response => response.text())
            .then(result => JSON.parse(result));
    }

    public static requestItem<T>(endPoint: string, method: "GET" | "POST" | "DELETE", token: string|null, body: any): Promise<T> {

        return WebRequest.requestText(endPoint, method, token, body)
            .then(result => JSON.parse(result));
    }
}