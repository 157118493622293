import React from 'react';
import { NavDropdown } from 'react-bootstrap';

import { NavbarItemContentModel, NavbarItemDesignModel } from "../../models/blocks/navbarItemModel";
import BlockComponent from './blockComponent';

export default class EditorNavbarItem extends BlockComponent {

    render() {

        // content
        const contentModel = new NavbarItemContentModel();
        contentModel.initialize(this.props.context.targetBlock.blocksProps);

        const content = contentModel.getContent();
        const link = this.props.context.environment.getIsPreview() ? "#" :
            contentModel.getLink(this.props.context.environment.currentLocation);

        const hasContent = content != null && content.length > 0;
        if (!hasContent) {
            return null;
        }

        // design
        const designProperties = this.props.context.commonProperties.concat(this.props.context.targetBlock.blocksProps);
        const designModel = new NavbarItemDesignModel();
        designModel.setDefaultValues(this.props.context);
        designModel.setCustomValues(designProperties);

        const backgroundStyle = designModel.generateBackgroundStyle(this.props.context);
        const contentStyle = designModel.generateContentStyle(this.props.context);
        const textStyle = designModel.generateTextStyle();
        const fontClassModificator = designModel.getFontClassModificator();
        const doodle = designModel.generateDoodle();
        const hashNavigation = designModel.generateHashNavigationName();
        const effectAppearName = designModel.generateEffectAppear();

        return <a className={`lp-toolbar-link text-end ${fontClassModificator}`} href={link} style={textStyle}>{content}</a>;
    }
}