import React from 'react';

import { Navbar, Alert } from "react-bootstrap";

export class ErrorDetails {
    title: string;
    details: Error;

    constructor(title: string, details: Error) {
        this.title = title;
        this.details = details;
    }
}

interface IErrorPanelProps {
    error: ErrorDetails|null|undefined,
}

interface IErrorPanelState {
    show: boolean,
}

export class ErrorPanel extends React.Component<IErrorPanelProps, IErrorPanelState> {

    constructor(prop: any) {
        super(prop);

        this.state = { show: false };

        this.clearError = this.clearError.bind(this);
    }

    componentDidMount(): void {
        this.setState({ show: this.props.error != null });
    }

    componentDidUpdate(prevProps: IErrorPanelProps, prevState: IErrorPanelState) {

        if (prevProps.error === this.props.error) {
            return;
        }

        this.setState({ show: this.props.error != null });
    }
    
    clearError(): void {
        this.setState({ show: false });
    }

    public render() {

        let title: string = String(null);
        let details: string = String(null);

        const error = this.props.error;
        if (error != null) {
            title = error.title;
            details = error.details != null ? error.details.message : "Unknown exception.";
        }

        return (
            <React.Fragment>

                <Navbar fixed="bottom">
                    <Alert className="w-100" show={this.state.show} variant="danger" onClose={this.clearError} dismissible>
                        <Alert.Heading>{title}</Alert.Heading>
                        <p>{details}</p>
                    </Alert>
                </Navbar>

            </React.Fragment>
        );
    }
}