export default class UrlParams {

    qs: string;
    params: Map<string, string>;

    constructor(search: string) {
        this.qs = search.substr(1);
        this.params = new Map<string, string>();
        this.parseQuerstring();
    }
    parseQuerstring() {
        this.qs.split('&').reduce((a, b) => {

            const separator = b.indexOf("=");
            const key = b.substr(0, separator);
            const val = b.substr(separator + 1);

            a.set(key, val);
            return a;
        }, this.params);
    }
    get(key: string) {
        return this.params.get(key);
    }
}