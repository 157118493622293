import React from 'react';

import { LayoutTwoColumnsAndHeaderContentModel, LayoutTwoColumnsAndHeaderDesignModel } from "../../models/blocks/layoutTwoColumnsAndHeaderModel";
import BlockRender from '../renders/blockRender';
import DoodleWrapper from "../common/doodleWrapper";
import BlockComponent from './blockComponent';
import * as BlocksHelper from '../../models/blocksHelper';
import SeparatorContainer from '../separators/separatorContainer';

export default class LayoutTwoColumnsAndHeader extends BlockComponent {

    render() {

        // content
        const contentModel = new LayoutTwoColumnsAndHeaderContentModel();
        contentModel.initialize(this.props.context.targetBlock.blocksProps);

        const navbar = contentModel.getNavbar();
        const headerRow = contentModel.getHeader();
        const columnLeft = contentModel.getColumnLeft();
        const columnRight = contentModel.getColumnRight();

        // design
        const designProperties = this.props.context.commonProperties.concat(this.props.context.targetBlock.blocksProps);
        const designModel = new LayoutTwoColumnsAndHeaderDesignModel();
        designModel.setDefaultValues(this.props.context);
        designModel.setCustomValues(designProperties);

        const backgroundStyle = designModel.generateBackgroundStyle(this.props.context);
        const contentStyle = designModel.generateContentStyle(this.props.context);
        const doodle = designModel.generateDoodle();
        const hashNavigation = designModel.generateHashNavigationName();
        const separatorType = designModel.getSeparator();
        const navbarStyle = designModel.generateNavbarStyle();

        if (navbar != null) {
            backgroundStyle.gridTemplateRows = "auto 1fr";
        }

        if (headerRow != null) {
            contentStyle.gridTemplateRows = "auto 1fr";
        }

        const childCommonProps = designModel.formatCommonProps(designProperties);
        const hasAdditionalGap = designModel.getResolvedContentWidth() !== "max";

        // effect appear
        const effectAppearName = designModel.generateEffectAppear();

        // if it has navbar than we must increase z-index
        if (navbar != null) {
            backgroundStyle.zIndex = "var(--z-index-navbar-container)";
        }

        const resolvedContentWidth = designModel.getResolvedContentWidth();
        const resolvedBackgroundColor = designModel.getActiveBackgroundColor();
        const needInvertBlocks = BlocksHelper.needInvertBlocks(columnLeft, columnRight);

        const navbarContext = navbar == null ? null :
        {
            environment: this.props.context.environment,

            prevBlock: this.props.context.prevBlock,
            nextBlock: this.props.context.nextBlock,
            parentBlock: this.props.context,

            parentBlockWidth: resolvedContentWidth,
            parentBlockColor: resolvedBackgroundColor,
            parentBlockAnimation: effectAppearName,

            rootSectionType: this.props.context.rootSectionType,

            targetBlock: navbar,
            targetBlockType: navbar.blocksType,
            targetColorSchema: this.props.context.targetColorSchema,
            targetHasBackgroundImage: false,

            commonProperties: childCommonProps,
        };

        const headerRowContext = headerRow == null ? null :
        {
            environment: this.props.context.environment,

            prevBlock: navbarContext ?? this.props.context.prevBlock,
            nextBlock: this.props.context.nextBlock,
            parentBlock: this.props.context,

            parentBlockWidth: resolvedContentWidth,
            parentBlockColor: resolvedBackgroundColor,
            parentBlockAnimation: effectAppearName,

            rootSectionType: this.props.context.rootSectionType,

            targetBlock: headerRow,
            targetBlockType: headerRow.blocksType,
            targetColorSchema: this.props.context.targetColorSchema,
            targetHasBackgroundImage: false,

            commonProperties: childCommonProps,
        };

        if (navbarContext != null && headerRowContext != null) {
            navbarContext.nextBlock = headerRowContext;
        }

        return (
            <div id={hashNavigation} style={backgroundStyle}>
                {
                    doodle != null &&
                    <DoodleWrapper rule={doodle} />
                }
                {
                    navbar != null &&
                    <div style={navbarStyle}>
                        <BlockRender context={navbarContext} />
                    </div>
                }
                <div style={contentStyle}>
                    {
                        headerRow != null &&
                        <BlockRender context={headerRowContext} />
                    }
                    <div className={(needInvertBlocks ? "two-columns-into-two-rows-reversed" : "two-columns-into-two-rows") + (hasAdditionalGap ? " gap-lg-3" : "")}>
                        {
                            columnLeft != null &&
                            <div className="d-grid">
                                <BlockRender context={{
                                        environment: this.props.context.environment,

                                        prevBlock: headerRowContext ?? navbarContext ?? this.props.context.prevBlock,
                                        nextBlock: this.props.context.nextBlock,
                                        parentBlock: this.props.context,

                                        parentBlockWidth: resolvedContentWidth,
                                        parentBlockColor: resolvedBackgroundColor,
                                        parentBlockAnimation: effectAppearName,

                                        rootSectionType: this.props.context.rootSectionType,

                                        targetBlock: columnLeft,
                                        targetBlockType: columnLeft.blocksType,
                                        targetColorSchema: this.props.context.targetColorSchema,
                                        targetHasBackgroundImage: false,

                                        commonProperties: childCommonProps,
                                    }} />
                            </div>
                        }
                        {
                            columnRight != null &&
                            <div className="d-grid">
                                <BlockRender context={{
                                        environment: this.props.context.environment,

                                        prevBlock: headerRowContext ?? navbarContext ?? this.props.context.prevBlock,
                                        nextBlock: this.props.context.nextBlock,
                                        parentBlock: this.props.context,

                                        parentBlockWidth: resolvedContentWidth,
                                        parentBlockColor: resolvedBackgroundColor,
                                        parentBlockAnimation: effectAppearName,

                                        rootSectionType: this.props.context.rootSectionType,

                                        targetBlock: columnRight,
                                        targetBlockType: columnRight.blocksType,
                                        targetColorSchema: this.props.context.targetColorSchema,
                                        targetHasBackgroundImage: false,

                                        commonProperties: childCommonProps,
                                    }} />
                            </div>
                        }
                    </div>
                </div>
                {
                    separatorType != null &&
                    <SeparatorContainer context={this.props.context} separatorType={separatorType} />
                }
            </div>
        );
    }
}