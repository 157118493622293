import "reflect-metadata";

import "reflect-metadata";
import { resolve } from 'inversify-react';
import { injectable } from "inversify";

import WebRequest from "../utils/webRequest";
import UserInfo from "./userInfo";

export interface IEntriesUniqueCount {
    computerCount: number;
    mobileCount: number;
    facebookCount: number;
    instagramCount: number;
    twitterCount: number;
    searchCount: number;
    directCount: number;
    otherCount: number;
}

export interface IEntriesCount {
    count: number;
}

export interface IEntriesUniqueRangeCount {
    date: Date,
    computerCount: number;
    mobileCount: number;
    facebookCount: number;
    instagramCount: number;
    twitterCount: number;
    searchCount: number;
    directCount: number;
    otherCount: number;
}

export interface IEntriesRangeCount {
    date: Date,
    count: number;
}

export interface ILatestStats {
    currMonth: number,
    prevMonth: number;
    prevPrevMonth: number;
}

@injectable()
export class StatisticsProvider {

    public user: UserInfo;
    
    public getPointUniqueEntries(pointId: string, date: Date): Promise<IEntriesUniqueCount> {
        return WebRequest.requestItem<IEntriesUniqueCount>("/api/statistics/v1/entries/uniqueUsers?pointId=" + pointId + "&startDate=" + this.convert(date) + "&endDate=" + this.convert(date), "GET", this.user.getUserToken(), null);
    }

    public getPointEntries(pointId: string, date: Date): Promise<IEntriesCount> {
        return WebRequest.requestItem<IEntriesCount>("/api/statistics/v1/entries/pages?pointId=" + pointId + "&startDate=" + this.convert(date) + "&endDate=" + this.convert(date), "GET", this.user.getUserToken(), null);
    }

    public getPointUniqueEntriesRange(pointId: string, startDate: Date, endDate: Date): Promise<IEntriesUniqueRangeCount[]> {
        return WebRequest.requestItem<IEntriesUniqueRangeCount[]>("/api/statistics/v1/entries/uniqueUsersRange?pointId=" + pointId + "&startDate=" + this.convert(startDate) + "&endDate=" + this.convert(endDate), "GET", this.user.getUserToken(), null);
    }

    public getPointEntriesRange(pointId: string, startDate: Date, endDate: Date): Promise<IEntriesRangeCount[]> {
        return WebRequest.requestItem<IEntriesRangeCount[]>("/api/statistics/v1/entries/pagesRange?pointId=" + pointId + "&startDate=" + this.convert(startDate) + "&endDate=" + this.convert(endDate), "GET", this.user.getUserToken(), null);
    }

    public getUniqueEntriesRange(startDate: Date, endDate: Date): Promise<IEntriesUniqueRangeCount[]> {
        return WebRequest.requestItem<IEntriesUniqueRangeCount[]>("/api/statistics/v1/entries/uniqueUsersRange?startDate=" + this.convert(startDate) + "&endDate=" + this.convert(endDate), "GET", this.user.getUserToken(), null);
    }

    public getEntriesRange(startDate: Date, endDate: Date): Promise<IEntriesRangeCount[]> {
        return WebRequest.requestItem<IEntriesRangeCount[]>("/api/statistics/v1/entries/pagesRange?startDate=" + this.convert(startDate) + "&endDate=" + this.convert(endDate), "GET", this.user.getUserToken(), null);
    }

    public getLatestMainPageVisitors(): Promise<ILatestStats> {
        return WebRequest.requestItem<ILatestStats>("/api/statistics/v1/server/latest_main_page_visitors", "GET", this.user.getUserToken(), null);
    }

    private convert(date: Date): string {
        return date.toISOString().split('T')[0];
    }
}