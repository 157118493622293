import React from 'react';
import { SeparatorLineWithTextContentModel, SeparatorLineWithTextDesignModel } from '../../models/blocks/separatorLineWithTextModel';
import DoodleWrapper from '../common/doodleWrapper';
import TransactionContainer from '../effects/transactionContainer';

import BlockComponent from './blockComponent';

export default class SeparatorLineWithText extends BlockComponent {

    render() {

        // content
        const block = new SeparatorLineWithTextContentModel();
        block.initialize(this.props.context.targetBlock.blocksProps);

        const color = block.getColor();
        const content = block.getContent();

        // design
        const designProperties = this.props.context.commonProperties.concat(this.props.context.targetBlock.blocksProps);
        const designModel = new SeparatorLineWithTextDesignModel();
        designModel.setDefaultValues(this.props.context);
        designModel.setCustomValues(designProperties);

        const backgroundStyle = designModel.generateBackgroundStyle(this.props.context);
        const contentStyle = designModel.generateContentStyle(this.props.context);
        const textStyle = designModel.generateTextStyle();
        const fontClassModificator = designModel.getFontClassModificator();
        const doodle = designModel.generateDoodle();
        const hashNavigation = designModel.generateHashNavigationName();
        const effectAppearName = designModel.generateEffectAppear();
        const childCommonProps = designModel.formatCommonProps(designProperties);

        textStyle.backgroundColor = color;
        textStyle.zIndex = "var(--z-index-content)";

        return (
            <React.Fragment>
                <div id={hashNavigation} style={backgroundStyle}>
                    {
                        doodle != null &&
                        <DoodleWrapper rule={doodle} />
                    }
                    <TransactionContainer style={contentStyle} effect={effectAppearName}>
                        <div className="w-100 position-relative">
                            <div className="position-absolute w-100 d-grid">
                                <div className={`lp-text last-without-margin mx-auto w-auto px-2 ${fontClassModificator}`} style={textStyle} dangerouslySetInnerHTML={{ __html: content }} />
                            </div>
                            <hr className="w-100" style={{ height: "1px", color: color }} />
                        </div>
                    </TransactionContainer>
                </div>
            </React.Fragment>
        );
    }
}