import React from 'react';
import { SeparatorLineSimpleContentModel, SeparatorLineSimpleDesignModel } from '../../models/blocks/separatorLineSimpleModel';
import DoodleWrapper from '../common/doodleWrapper';
import TransactionContainer from '../effects/transactionContainer';

import BlockComponent from './blockComponent';

export default class SeparatorLineSimple extends BlockComponent {

    render() {

        // content
        const block = new SeparatorLineSimpleContentModel();
        block.initialize(this.props.context.targetBlock.blocksProps);

        const color = block.getColor();

        // design
        const designProperties = this.props.context.commonProperties.concat(this.props.context.targetBlock.blocksProps);
        const designModel = new SeparatorLineSimpleDesignModel();
        designModel.setDefaultValues(this.props.context);
        designModel.setCustomValues(designProperties);

        const backgroundStyle = designModel.generateBackgroundStyle(this.props.context);
        const contentStyle = designModel.generateContentStyle(this.props.context);
        const doodle = designModel.generateDoodle();
        const hashNavigation = designModel.generateHashNavigationName();
        const effectAppearName = designModel.generateEffectAppear();
        const childCommonProps = designModel.formatCommonProps(designProperties);
        
        return (
            <React.Fragment>
                <div id={hashNavigation} style={backgroundStyle}>
                    {
                        doodle != null &&
                            <DoodleWrapper rule={doodle} />
                    }
                    <TransactionContainer style={contentStyle} effect={effectAppearName}>
                        <hr className="w-100" style={{ height: "1px", color: color }}/>
                    </TransactionContainer>
                </div>
            </React.Fragment>
        );
    }
}