import React from 'react';

import { GalleryPreviewItemContentModel, GalleryPreviewItemDesignModel } from "../../models/blocks/galleryPreviewItemModel";
import BlockComponent from './blockComponent';
import 'photoswipe/dist/photoswipe.css'
import { Item } from 'react-photoswipe-gallery'

interface IEditorState {
    previewWidth: number,
    previewHeight: number,
    fullWidth: number,
    fullHeight: number,
}

export default class EditorGalleryPreviewItem extends BlockComponent<IEditorState> {

    constructor(props: any) {
        super(props);

        this.state = {
            previewWidth: null,
            previewHeight: null,
            fullWidth: null,
            fullHeight: null,
        }

        this.onLoad = this.onLoad.bind(this);
    }

    onLoad(event: any) {

        const previewWidth = event.target.naturalWidth;
        const previewHeight = event.target.naturalHeight;

        if (previewWidth === this.state.previewWidth && previewHeight === this.state.previewHeight)
            return;

        let fullWidth: number;
        let fullHeight: number;

        const kX = window.innerWidth / previewWidth;
        const kY = window.innerHeight / previewHeight;

        if (kX < kY) {
            fullWidth = previewWidth * kX;
            fullHeight = previewHeight * kX;
        } else {
            fullWidth = previewWidth * kY;
            fullHeight = previewHeight * kY;
        }

        this.setState({
            previewWidth: previewWidth,
            previewHeight: previewHeight,
            fullWidth: fullWidth,
            fullHeight: fullHeight,
        });
    }

    render() {

        // content
        const contentModel = new GalleryPreviewItemContentModel();
        contentModel.initialize(this.props.context.targetBlock.blocksProps);

        const imageId = contentModel.getImageId();
        const imageAltText = contentModel.getImageAltText();

        const hasImage = imageId != null && imageId.length > 0 || imageAltText != null && imageAltText.length > 0;
        if (!hasImage) {
            return null;
        }

        const imageUrl = this.props.context.environment.currentLocation.getImageUrl(imageId);
        const previewImageUrl = this.props.context.environment.currentLocation.getPreviewImageUrl(imageId);

        // design
        const designProperties = this.props.context.commonProperties.concat(this.props.context.targetBlock.blocksProps);
        const designModel = new GalleryPreviewItemDesignModel();
        designModel.setDefaultValues(this.props.context);
        designModel.setCustomValues(designProperties);

        const backgroundStyle = designModel.generateBackgroundStyle(this.props.context);
        const contentStyle = designModel.generateContentStyle(this.props.context);
        const doodle = designModel.generateDoodle();
        const hashNavigation = designModel.generateHashNavigationName();
        const effectAppearName = designModel.generateEffectAppear();

        return (
            <Item
                original={imageUrl}
                thumbnail={previewImageUrl}
                width={this.state.fullWidth}
                height={this.state.fullHeight}
                alt={imageAltText}
            >
                {({ ref, open }) => (
                    <img
                        className="m-1"
                        style={{ cursor: 'pointer' }}
                        src={previewImageUrl}
                        alt={imageAltText}
                        ref={ref as React.MutableRefObject<HTMLImageElement>}
                        onClick={open}
                        onLoad={this.onLoad}
                    />
                )}
            </Item>
        );
    }
}