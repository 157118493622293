import "reflect-metadata";
import { injectable } from "inversify";

import WebRequest from "../utils/webRequest";
import UserInfo from "./userInfo";

export interface IResponsesContainer {
    responses: string[],
}

@injectable()
export class GeneratorProvider {

    public user: UserInfo;
    
    public generate(pointName: string, pointType: string, requests: string[]): Promise<IResponsesContainer> {
        return WebRequest.requestItem<IResponsesContainer>("/api/generator/v1/content", "GET", this.user.getUserToken(),
            {
                "pointName": pointName,
                "pointType": pointType,
                "requests": requests,
            });
    }
}