import { IPageBlockProperty, IPageRenderedBlockProperty, IPageRenderedBlock } from "../../models/pageLayoutModel";
import { BlockPropType } from "../../models/blockType";
import CommonDesignModel from "./commonDesignModel";
import { IBlockModel } from "../modelsHelper";

export class PostFeedContentModel implements IBlockModel {

    private posts: IPageRenderedBlock[];
    private postsCount: number;

    getPosts(): IPageRenderedBlock[] { return this.posts; }
    getPostsAllCount(): number { return this.postsCount; }

    initialize(properties: IPageRenderedBlockProperty[]) {

        let blockCollection: IPageRenderedBlockProperty = null;
        let blockCount: IPageRenderedBlockProperty = null;

        for (let index = 0; index < properties.length; index++) {

            const property = properties[index];

            switch (property.propType) {
            case BlockPropType.Collection:
                blockCollection = property;
                break;
            case BlockPropType.Count:
                blockCount = property;
                break;
            default:
                break;
            }
        }

        this.posts = blockCollection?.propChildren ?? new Array<IPageRenderedBlock>();
        this.postsCount = blockCount?.propNumber ?? 0;
    }

    formatBlock(properties: IPageBlockProperty[]): IPageBlockProperty[] {
        return [];
    }
}

export class PostFeedDesignModel extends CommonDesignModel {

    constructor() {
        super();

        this.allowBackground = true;
        this.allowContentWidth = true;
        this.allowContentMargin = true;
        this.allowBlockHieghtAndMargin = true;
        this.allowHashNavigation = true;
        this.allowHorizontalAlignment = true;
        this.allowVerticalAlignment = true;
        this.allowTextStyle = true;
        this.allowAppearEffect = false;
        this.allowSeparator = true;
    }    
}