import "reflect-metadata";

import { injectable } from "inversify";

import SessionDataProvider from "./utils/sessionDataProvider";
import { PointsInfoProvider } from "./pointsInfoProvider";

@injectable()
export default class PointInfo {

    static pointDetailsKey = "Point";

    public sessionDataProvider: SessionDataProvider;
    public pointsProvider: PointsInfoProvider;

    private pointDetails: IPointDetails;

    public clear() {

        this.pointDetails = null;

        this.refreshUserDetails();
    }

    public refreshUserDetails() {
        this.sessionDataProvider.removeItem(null, PointInfo.pointDetailsKey);
    }

    public getPointNameAsync(pointId: string): Promise<string|null> {

        if (this.pointDetails != null && this.pointDetails.id === pointId) {
            return Promise.resolve(this.pointDetails.name);
        }

        this.pointDetails = this.sessionDataProvider.getValue<IPointDetails>(null, PointInfo.pointDetailsKey);
        if (this.pointDetails != null && this.pointDetails.id === pointId) {
            return Promise.resolve(this.pointDetails.name);
        }

        return this.updateCacheAsync(pointId).then(() => this.pointDetails?.name);
    }

    public getPointNameOrDefault(pointId: string): string|null {

        if (this.pointDetails != null && this.pointDetails.id === pointId) {
            return this.pointDetails.name;
        }

        this.pointDetails = this.sessionDataProvider.getValue<IPointDetails>(null, PointInfo.pointDetailsKey);
        if (this.pointDetails != null && this.pointDetails.id === pointId) {
            return this.pointDetails.name;
        }

        return null;
    }

    public getPointImageIdAsync(pointId: string): Promise<string|null> {

        if (this.pointDetails != null && this.pointDetails.id === pointId) {
            return Promise.resolve(this.pointDetails.imageId);
        }

        this.pointDetails = this.sessionDataProvider.getValue<IPointDetails>(null, PointInfo.pointDetailsKey);
        if (this.pointDetails != null && this.pointDetails.id === pointId) {
            return Promise.resolve(this.pointDetails.imageId);
        }

        return this.updateCacheAsync(pointId).then(() => this.pointDetails?.imageId);
    }

    public getPointImageIdOrDefault(pointId: string): string|null {

        if (this.pointDetails != null && this.pointDetails.id === pointId) {
            return this.pointDetails.imageId;
        }

        this.pointDetails = this.sessionDataProvider.getValue<IPointDetails>(null, PointInfo.pointDetailsKey);
        if (this.pointDetails != null && this.pointDetails.id === pointId) {
            return this.pointDetails.imageId;
        }

        return null;
    }
    
    private updateCacheAsync(pointId: string): Promise<void> {
        
        return this.pointsProvider.getPoint(pointId)
            .then(x => {

                this.pointDetails = {
                    id: x.id,
                    name: x.name,
                    imageId: x.favicon,
                };

                this.sessionDataProvider.setValue(null, PointInfo.pointDetailsKey, this.pointDetails);
            });
    }
}

interface IPointDetails {
    id: string;
    name: string;
    imageId: string;
}