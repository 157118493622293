import { IPageBlockProperty } from "../../models/pageLayoutModel";
import { IDoodle } from "./IDoodle";
import { BlockPropType } from "../blockType";

export default class Bubbles implements IDoodle {

    public color1: string;
    public color2: string;
    public color3: string;

    getDoodle(): string {
        return `
:doodle {
	@grid: 20x1 / 100%;
	overflow:hidden;
}
    
@keyframes move {
	100% {
		transform: translate3d(0, 0, 1px) rotate(360deg);
	}
}
			
width: 20vmin;
height: 20vmin;
border-radius: 20vmin;
backface-visibility: hidden;
position: absolute;
animation: move;
animation-duration: 35;
animation-timing-function: linear;
animation-iteration-count: infinite;
			
@nth(20) {
    color: ${this.color1};
    top: 56%;
    left: 51%;
    animation-duration: 15s;
    animation-delay: -82s;
    transform-origin: -11vw 25vh;
    box-shadow: 40vmin 0 5.035623884974701vmin currentColor;
}

@nth(1) {
    color: ${this.color2};
    top: 62%;
    left: 80%;
    animation-duration: 93s;
    animation-delay: -107s;
    transform-origin: -3vw -23vh;
    box-shadow: -40vmin 0 5.317210994300182vmin currentColor;
}

@nth(2) {
    color: ${this.color3};
    top: 93%;
    left: 29%;
    animation-duration: 80s;
    animation-delay: -130s;
    transform-origin: -1vw 14vh;
    box-shadow: 40vmin 0 5.871997851808431vmin currentColor;
}

@nth(3) {
    color: ${this.color1};
    top: 22%;
    left: 11%;
    animation-duration: 65s;
    animation-delay: -2s;
    transform-origin: -6vw -8vh;
    box-shadow: -40vmin 0 5.07294457193319vmin currentColor;
}

@nth(4) {
    color: ${this.color2};
    top: 29%;
    left: 2%;
    animation-duration: 33s;
    animation-delay: -50s;
    transform-origin: 7vw 22vh;
    box-shadow: -40vmin 0 5.047335864631095vmin currentColor;
}

@nth(5) {
    color: ${this.color3};
    top: 28%;
    left: 47%;
    animation-duration: 83s;
    animation-delay: -17s;
    transform-origin: 23vw 24vh;
    box-shadow: -40vmin 0 5.570021688278866vmin currentColor;
}

@nth(6) {
    color: ${this.color1};
    top: 38%;
    left: 44%;
    animation-duration: 12s;
    animation-delay: -107s;
    transform-origin: 6vw 12vh;
    box-shadow: 40vmin 0 5.56136833238413vmin currentColor;
}

@nth(7) {
    color: ${this.color2};
    top: 52%;
    left: 60%;
    animation-duration: 112s;
    animation-delay: -118s;
    transform-origin: 8vw -22vh;
    box-shadow: -40vmin 0 5.790045268156117vmin currentColor;
}

@nth(8) {
    color: ${this.color3};
    top: 75%;
    left: 83%;
    animation-duration: 40s;
    animation-delay: -149s;
    transform-origin: 2vw 7vh;
    box-shadow: -40vmin 0 5.1680045737448115vmin currentColor;
}

@nth(9) {
    color: ${this.color1};
    top: 77%;
    left: 22%;
    animation-duration: 117s;
    animation-delay: -132s;
    transform-origin: 13vw -14vh;
    box-shadow: 40vmin 0 5.257692793430709vmin currentColor;
}

@nth(10) {
    color: ${this.color2};
    top: 80%;
    left: 65%;
    animation-duration: 151s;
    animation-delay: -25s;
    transform-origin: 23vw 21vh;
    box-shadow: -40vmin 0 5.11506529788935vmin currentColor;
}

@nth(11) {
    color: ${this.color3};
    top: 37%;
    left: 38%;
    animation-duration: 127s;
    animation-delay: -2s;
    transform-origin: -21vw 9vh;
    box-shadow: 40vmin 0 5.543805514770584vmin currentColor;
}

@nth(12) {
    color: ${this.color1};
    top: 58%;
    left: 18%;
    animation-duration: 117s;
    animation-delay: -103s;
    transform-origin: 7vw -8vh;
    box-shadow: 40vmin 0 5.910149886106556vmin currentColor;
}

@nth(13) {
    color: ${this.color2};
    top: 46%;
    left: 85%;
    animation-duration: 150s;
    animation-delay: -59s;
    transform-origin: 7vw 25vh;
    box-shadow: 40vmin 0 5.575961335562941vmin currentColor;
}

@nth(14) {
    color: ${this.color3};
    top: 47%;
    left: 12%;
    animation-duration: 60s;
    animation-delay: -80s;
    transform-origin: 19vw -6vh;
    box-shadow: 40vmin 0 5.275604554184289vmin currentColor;
}

@nth(15) {
    color: ${this.color1};
    top: 71%;
    left: 57%;
    animation-duration: 39s;
    animation-delay: -1s;
    transform-origin: 1vw -9vh;
    box-shadow: 40vmin 0 5.886584699661466vmin currentColor;
}

@nth(16) {
    color: ${this.color2};
    top: 100%;
    left: 37%;
    animation-duration: 41s;
    animation-delay: -134s;
    transform-origin: 5vw 21vh;
    box-shadow: -40vmin 0 5.167611892371444vmin currentColor;
}

@nth(17) {
    color: ${this.color3};
    top: 82%;
    left: 89%;
    animation-duration: 79s;
    animation-delay: -16s;
    transform-origin: 17vw -19vh;
    box-shadow: -40vmin 0 5.980870908028282vmin currentColor;
}

@nth(18) {
    color: ${this.color1};
    top: 60%;
    left: 33%;
    animation-duration: 67s;
    animation-delay: -143s;
    transform-origin: 2vw 18vh;
    box-shadow: 40vmin 0 5.3528040185395565vmin currentColor;
}

@nth(19) {
    color: ${this.color2};
    top: 34%;
    left: 14%;
    animation-duration: 148s;
    animation-delay: -97s;
    transform-origin: -9vw -20vh;
    box-shadow: 40vmin 0 5.508217765158586vmin currentColor;
}
            `;
    }

    formatProperties(): IPageBlockProperty[] {

        const result = new Array<IPageBlockProperty>();
        result.push({
            propType: BlockPropType.DoodleBubblesColor1,
            propString: this.color1,
        });
        result.push({
            propType: BlockPropType.DoodleBubblesColor2,
            propString: this.color2,
        });
        result.push({
            propType: BlockPropType.DoodleBubblesColor3,
            propString: this.color3,
        });

        return result;
    }
}