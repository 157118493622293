export enum PageType {
    Unknown = 0,
    Landing = 1,
    Blog = 2,
    Help = 3,
    Contact = 4,
    Bio = 5,
    Team = 6,
    Offer = 7,
    Main = 8,
    Portfolio = 9,
}