import React from 'react';

import { FeatureItemContentModel, FeatureItemDesignModel } from "../../models/blocks/featureItemModel";
import BlockComponent from './blockComponent';
import TransactionContainer from '../effects/transactionContainer';

export default class EditorFeatureItem extends BlockComponent {

    render() {

        // content
        const contentModel = new FeatureItemContentModel();
        contentModel.initialize(this.props.context.targetBlock.blocksProps);

        const content = contentModel.getContent();

        const hasContent = content != null && content.length > 0;

        // design
        const designProperties = this.props.context.commonProperties.concat(this.props.context.targetBlock.blocksProps);
        const designModel = new FeatureItemDesignModel();
        designModel.setDefaultValues(this.props.context);
        designModel.setCustomValues(designProperties);

        const backgroundStyle = designModel.generateBackgroundStyle(this.props.context);
        const contentStyle = designModel.generateContentStyle(this.props.context);
        const textStyle = designModel.generateTextStyle();
        const fontClassModificator = designModel.getFontClassModificator();
        const doodle = designModel.generateDoodle();
        const hashNavigation = designModel.generateHashNavigationName();
        const effectAppearName = designModel.generateEffectAppear();

        return (
            <TransactionContainer className="d-flex flex-row flex-fill gap-2" effect={effectAppearName}>
                <div style={{ marginTop: "calc(0.3rem + .2vw)" }}>
                    <div className="bi bi-check-circle text-success d-flex align-self-start lp-text-after-subtitle" style={textStyle} />
                </div>
                <div className={`lp-text-after-subtitle last-without-margin ${fontClassModificator}`} style={textStyle} dangerouslySetInnerHTML={{ __html: content }}/>
            </TransactionContainer>
        );
    }
}