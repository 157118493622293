import React from 'react';
import ReactDOM from "react-dom/client";

import App from './app';

import "./styles/themes/default.css";
import "./styles/default.css";
import "./styles/pageEditorStyle.css";

import 'core-js/actual';
import 'whatwg-fetch';
import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap-icons/font/bootstrap-icons.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<React.StrictMode><App /></React.StrictMode>);