import React from 'react';

import { BlockType } from "../../models/blockType";
import { SimpleCardsContentModel, SimpleCardsDesignModel } from "../../models/blocks/simpleCardsModel";
import BlockRender from '../renders/blockRender';
import genUniqueId from "../../models/utils/genUniqueId";
import DoodleWrapper from "../common/doodleWrapper";
import BlockComponent from './blockComponent';
import RenderBlockContext from "../renders/renderBlockContext";
import SeparatorContainer from '../separators/separatorContainer';

export default class EditorSimpleCards extends BlockComponent {

    private id: string = genUniqueId();

    render() {

        // content
        const contentModel = new SimpleCardsContentModel();
        contentModel.initialize(this.props.context.targetBlock.blocksProps);

        const cards = contentModel.getCards();

        // design
        const designProperties = this.props.context.commonProperties.concat(this.props.context.targetBlock.blocksProps);
        const designModel = new SimpleCardsDesignModel();
        designModel.setDefaultValues(this.props.context);
        designModel.setCustomValues(designProperties);

        const backgroundStyle = designModel.generateBackgroundStyle(this.props.context);
        const contentStyle = designModel.generateContentStyle(this.props.context);
        const doodle = designModel.generateDoodle();
        const hashNavigation = designModel.generateHashNavigationName();
        const separatorType = designModel.getSeparator();

        const childCommonProps = designModel.formatCommonProps(designProperties);

        // layout
        const isSmallWidth = designModel.isSmallOrParentSmall();

        let containerClassName: string;
        if (cards.length <= 1) {
            containerClassName = "cards-container-1";
        }
        else if (cards.length === 2) {
            containerClassName = "cards-container-2";
        }
        else if (cards.length % 5 === 0) {
            containerClassName = isSmallWidth ? "cards-container-3" : "cards-container-5";
        }
        else if (cards.length % 4 === 0) {
            containerClassName = isSmallWidth ? "cards-container-2" : "cards-container-4";
        }
        else if (cards.length % 3 === 0) {
            containerClassName = "cards-container-3";
        }
        else {
            containerClassName = isSmallWidth ? "cards-container-3" : "cards-container-5";
        }

        // effect appear
        const effectAppearName = designModel.generateEffectAppear();
        const resolvedContentWidth = designModel.getResolvedContentWidth();
        const resolvedBackgroundColor = designModel.getActiveBackgroundColor();

        return (
            <div id={hashNavigation} style={backgroundStyle}>
                {
                    doodle != null &&
                    <DoodleWrapper rule={doodle} />
                }
                <div style={contentStyle}>
                    <div className={containerClassName}>
                        {
                            cards.map((card, cardIndex) => {

                                const context: RenderBlockContext =
                                {
                                    environment: this.props.context.environment,

                                    prevBlock: null,
                                    nextBlock: null,
                                    parentBlock: this.props.context,

                                    parentBlockWidth: resolvedContentWidth,
                                    parentBlockColor: resolvedBackgroundColor,
                                    parentBlockAnimation: effectAppearName,

                                    rootSectionType: this.props.context.rootSectionType,

                                    targetBlock: card,
                                    targetBlockType: BlockType.CardItem,
                                    targetColorSchema: this.props.context.targetColorSchema,
                                    targetHasBackgroundImage: false,

                                    commonProperties: childCommonProps,
                                };

                                return <div key={`card-${this.id}-${cardIndex}`}>
                                    <BlockRender context={context} />
                                </div>;
                            }
                            )
                        }
                    </div>
                </div>
                {
                    separatorType != null &&
                    <SeparatorContainer context={this.props.context} separatorType={separatorType} />
                }
            </div>
        );
    }
}