import React from 'react';

import "reflect-metadata";
import NavigationPanelPoint from "./navigationPanelPoint";

interface IRootProps {
    title: string;
    children?: React.ReactNode;
}

export default class NavigationPanelGroup extends React.Component<IRootProps> {

    render() {

        return (
            <React.Fragment>
                <div className="mt-2 mb-0">{this.props.title}</div>
                <hr className="mt-2 mb-0" />
                {this.props.children}
            </React.Fragment>
        );
    }
}