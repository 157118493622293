import "reflect-metadata";

import "reflect-metadata";
import { resolve } from 'inversify-react';
import { injectable } from "inversify";

import WebRequest from "../utils/webRequest";
import UserInfo from "./userInfo";
import { IId } from "./entities/iidname";
import { IIdToken } from "./accountInfoProvider";
import { WebpageStyleType } from "../controls/renders/pointPageEnvironment";
import { ILatestStats } from "./statisticsProvider";

export interface IPointDetails {
    id: string,
    domain: string;
    externalDomain: string;
    name: string;
    description: string;
    favicon: string;
}

export interface IPointContacts {
    facebook: string;
    telephone: string;
    address: string;
    mail: string;
    youtube: string;
    instagramId: string;
    telegramId: string;
    whatsAppId: string;
    twitterId: string;
    pinterestId: string;
    linkedinId: string;
}

export interface IPointShortDetails {
    id: string;
    domain: string;
    name: string;
}

export interface IMyPointsDetails {
    id: string;
    domain: string;
    externalDomain: string;
    name: string;
    description: string;
    favicon: string;
}

export interface ILatestPointsDetails {
    id: string;
    name: string;
    creation: string;
}

export interface IUserRole {
    userId: string;
    role: number;
}

export interface IIsAdmin {
    isAdmin: boolean;
}

export interface IVisualStyle {
    visualStyle: number;
}

export interface IIsVisible {
    isVisible: boolean;
}

export interface IPointLanguage {
    defaultLanguage: string;
    languages: string[];
}

export interface IPointActivity {
    executorId: string;
    date: string;
    text: string;
}

export interface IPointCertificate {
    isEmpty: boolean;
    details: string;
    expirationDate: string;
}

export interface IServerState {
    activityServiceValid: string;
    statisticsServiceValid: string;
    imagesServiceValid: string;
    pointsServiceValid: string;
    mqServiceValid: string;
    schedulerServiceValid: string;
    mailerServiceValid: string;
    identityServiceValid: string;
    generatorServiceValid: string;
}

export interface IFailedValidation {
    validationType: number;
    text: string;
    changedDate: string;
    lastValidationDate: string;
}

export interface IValidationCount {
    failed: number;
    success: number;
}

export interface IExternalStatistics {
    googleAnalyticsTag: string;
}

export interface ICount {
    count: number;
}

@injectable()
export class PointsInfoProvider {

    public user: UserInfo;
    
    public getMyPoints(): Promise<IMyPointsDetails[]> {
        return WebRequest.requestItem<IMyPointsDetails[]>("/api/points/v1/points/my", "GET", this.user.getUserToken(), null);
    }

    public getLatestPoints(): Promise<ILatestPointsDetails[]> {
        return WebRequest.requestItem<ILatestPointsDetails[]>("/api/points/v1/server/latest", "GET", this.user.getUserToken(), null);
    }

    public getLatestActivePoints(): Promise<ILatestPointsDetails[]> {
        return WebRequest.requestItem<ILatestPointsDetails[]>("/api/points/v1/server/latest_active", "GET", this.user.getUserToken(), null);
    }

    public getPoint(id: string): Promise<IPointDetails> {
        return WebRequest.requestItem<IPointDetails>("/api/points/v1/points/" + id + "/information", "GET", null, null).then(x => {
            x.id = id;
            return x;
        });
    }

    public getPointContacts(id: string): Promise<IPointContacts> {
        return WebRequest.requestItem<IPointContacts>("/api/points/v1/points/" + id + "/contacts", "GET", null, null);
    }

    public getPointExternalStatistics(id: string): Promise<IExternalStatistics> {
        return WebRequest.requestItem<IExternalStatistics>("/api/points/v1/points/" + id + "/external_statistics", "GET", this.user.getUserToken(), null);
    }

    public updatePointExternalStatistics(id: string, gaTag: string): Promise<string> {
        return WebRequest.requestText("/api/points/v1/points/" + id + "/external_statistics", "POST", this.user.getUserToken(),
            {
                googleAnalyticsTag: gaTag
            });
    }

    public getPointId(domain: string): Promise<IId> {
        return WebRequest.requestItem<IId>("/api/points/v1/points/" + domain, "GET", null, null);
    }

    public updatePointInformation(id: string, name: string, description: string, favicon: string): Promise<string> {
        return WebRequest.requestText("/api/points/v1/points/" + id + "/information", "POST", this.user.getUserToken(),
            {
                "name": name,
                "description": description,
                "favicon": favicon,
            });
    }

    public updatePointDomain(id: string, domain: string, externalDomain: string): Promise<string> {
        return WebRequest.requestText("/api/points/v1/points/" + id + "/domain", "POST", this.user.getUserToken(),
            {
                "domain": domain,
                "externalDomain": externalDomain,
            });
    }

    public updatePointLanguage(id: string, defaultLanguage: string, languages: string[]): Promise<string> {
        return WebRequest.requestText("/api/points/v1/points/" + id + "/language", "POST", this.user.getUserToken(),
            {
                "defaultLanguage": defaultLanguage,
                "languages": languages,
            });
    }

    public getPointVisibility(id: string): Promise<IIsVisible> {
        return WebRequest.requestItem<IIsVisible>("/api/points/v1/points/" + id + "/visibility", "GET", this.user.getUserToken(), null);
    }

    public getPointLanguage(id: string): Promise<IPointLanguage> {
        return WebRequest.requestItem<IPointLanguage>("/api/points/v1/points/" + id + "/language", "GET", this.user.getUserToken(), null);
    }

    public updatePointVisibility(id: string, isVisible: boolean): Promise<string> {
        return WebRequest.requestText("/api/points/v1/points/" + id + "/visibility", "POST", this.user.getUserToken(),
            {
                "isVisible": isVisible
            });
    }

    public updatePointVisualStyle(id: string, visualStyleType: WebpageStyleType): Promise<string> {
        return WebRequest.requestText("/api/points/v1/points/" + id + "/visual_style", "POST", this.user.getUserToken(),
            {
                "visualStyle": visualStyleType as number
            });
    }

    public getPointVisualStyle(id: string): Promise<IVisualStyle> {
        return WebRequest.requestItem<IVisualStyle>("/api/points/v1/points/" + id + "/visual_style", "GET", this.user.getUserToken(), null);
    }

    public updatePointContacts(id: string, facebook: string, youtube: string, address: string, telephone: string, mail: string, instagramId: string, telegramId: string, whatsAppId: string, twitterId: string, pinterestId: string, linkedinId: string): Promise<string> {
        return WebRequest.requestText("/api/points/v1/points/" + id + "/contacts", "POST", this.user.getUserToken(),
            {
                "facebook": facebook,
                "telephone": telephone,
                "address": address,
                "email": mail,
                "youtube": youtube,
                "instagramId": instagramId,
                "telegramId": telegramId,
                "whatsAppId": whatsAppId,
                "twitterId": twitterId,
                "pinterestId": pinterestId,
                "linkedinId": linkedinId,
            });
    }

    public getIsAdmin(id: string): Promise<IIsAdmin> {
        return WebRequest.requestItem<IIsAdmin>("/api/points/v1/points/" + id + "/isAdmin", "GET", this.user.getUserToken(), null);
    }

    public getActivities(pointId: string, daysCount: number): Promise<IPointActivity[]> {
        return WebRequest.requestItem<IPointActivity[]>("/api/points/v1/points/" + pointId + "/activities?daysCount=" + daysCount, "GET", this.user.getUserToken(), null);
    }

    public getWarnings(pointId: string): Promise<IFailedValidation[]> {
        return WebRequest.requestItem<IFailedValidation[]>("/api/scheduler/v1/validations/failed?pointId=" + pointId, "GET", this.user.getUserToken(), null);
    }

    public getWarningsCount(pointId: string): Promise<IValidationCount> {
        return WebRequest.requestItem<IValidationCount>("/api/scheduler/v1/validations/count?pointId=" + pointId, "GET", this.user.getUserToken(), null);
    }

    public createPoint(domain: string, name: string): Promise<IIdToken> {
        return WebRequest.requestItem<IIdToken>("/api/points/v1/points/create", "POST", this.user.getUserToken(),
            {
                "domain": domain,
                "name": name,
                "businessType": 0,
            });
    }

    public createPointWithAi(domain: string, name: string, description: string): Promise<IIdToken> {
        return WebRequest.requestItem<IIdToken>("/api/points/v1/points/create_with_ai", "POST", this.user.getUserToken(),
            {
                "domain": domain,
                "name": name,
                "description": description
            });
    }

    public getServerState(): Promise<IServerState> {
        return WebRequest.requestItem<IServerState>("/api/endpoint/v1/server/state", "GET", null, null);
    }

    public getServerVersion(): Promise<string> {
        return WebRequest.requestText("/api/endpoint/v1/server/version", "GET", null, null);
    }

    public getPointsCount(): Promise<ICount> {
        return WebRequest.requestItem<ICount>("/api/points/v1/server/points_count", "GET", this.user.getUserToken(), null);
    }

    public getPointsWithPagesCount(): Promise<ICount> {
        return WebRequest.requestItem<ICount>("/api/points/v1/server/points_with_pages_count", "GET", this.user.getUserToken(), null);
    }

    public getActivePointsCount(): Promise<ICount> {
        return WebRequest.requestItem<ICount>("/api/points/v1/server/active_points_count", "GET", this.user.getUserToken(), null);
    }

    public uploadCertificate(pointId: string, cert: Blob, key: Blob): Promise<Response> {

        if (cert == null) {
            return WebRequest.request("/api/points/v1/points/" + pointId + "/certificate", "POST", this.user.getUserToken(), null, "application/json");
        }

        var formdata = new FormData();
        formdata.set("cert", cert);
        formdata.set("key", key);
        
        return WebRequest.request("/api/points/v1/points/" + pointId + "/certificate", "POST", this.user.getUserToken(), formdata, null);
    }

    public getCertificateDetails(pointId: string): Promise<IPointCertificate> {
        return WebRequest.requestItem<IPointCertificate>("/api/points/v1/points/" + pointId + "/certificate", "GET", this.user.getUserToken(), null);
    }

    public getLatestPointsCount(): Promise<ILatestStats> {
        return WebRequest.requestItem<ILatestStats>("/api/points/v1/server/latest_points_count", "GET", this.user.getUserToken(), null);
    }

    public getLatestActivePointsCount(): Promise<ILatestStats> {
        return WebRequest.requestItem<ILatestStats>("/api/points/v1/server/latest_active_points_count", "GET", this.user.getUserToken(), null);
    }

    public getLatestPointsWithPagesCount(): Promise<ILatestStats> {
        return WebRequest.requestItem<ILatestStats>("/api/points/v1/server/latest_points_with_pages_count", "GET", this.user.getUserToken(), null);
    }
}