import React from 'react';

import { NumbersItemContentModel, NumbersItemDesignModel } from "../../models/blocks/numbersItemModel";
import BlockComponent from './blockComponent';
import TransactionContainer from '../effects/transactionContainer';

export default class NumbersItem extends BlockComponent {
    
    render() {

        // content
        const contentModel = new NumbersItemContentModel();
        contentModel.initialize(this.props.context.targetBlock.blocksProps);

        const title = contentModel.getTitle();
        const content = contentModel.getContent();

        const hasTitle = title != null && title.length > 0;
        const hasContent = content != null && content.length > 0;

        // design
        const designProperties = this.props.context.commonProperties.concat(this.props.context.targetBlock.blocksProps);
        const designModel = new NumbersItemDesignModel();
        designModel.setDefaultValues(this.props.context);
        designModel.setCustomValues(designProperties);

        const backgroundStyle = designModel.generateBackgroundStyle(this.props.context);
        const contentStyle = designModel.generateContentStyle(this.props.context);
        const textStyle = designModel.generateTextStyle();
        const fontClassModificator = designModel.getFontClassModificator();
        const doodle = designModel.generateDoodle();
        const hashNavigation = designModel.generateHashNavigationName();
        const effectAppearName = designModel.generateEffectAppear();
        
        return (
            <TransactionContainer className="overflow-hidden h-100" style={backgroundStyle} effect={effectAppearName}>
                <div style={contentStyle} className="mt-0">
                    {
                        hasTitle &&
                        <div className={`lp-text-title fw-bolder last-without-margin ${fontClassModificator}`} style={textStyle} dangerouslySetInnerHTML={{ __html: title }} />
                    }
                    {
                        hasContent &&
                        <div className={`lp-text last-without-margin ${fontClassModificator}`} style={textStyle} dangerouslySetInnerHTML={{ __html: content }} />
                    }
                </div>
            </TransactionContainer>
        );
    }
}