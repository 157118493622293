import React from 'react';

import { TextPlainContentModel, TextPlainDesignModel } from "../../models/blocks/textPlainModel";
import { BlockType } from '../../models/blockType';
import DoodleWrapper from "../common/doodleWrapper";
import TransactionContainer from '../effects/transactionContainer';
import BlockComponent from './blockComponent';
import SeparatorContainer from '../separators/separatorContainer';
import { WebpageStyleType } from '../renders/pointPageEnvironment';

export default class TextPlain extends BlockComponent {

    render() {

        // content
        const block = new TextPlainContentModel();
        block.initialize(this.props.context.targetBlock.blocksProps);

        const uppertitle = block.getUppertitle();
        const title = block.getTitle();
        const subtitle = block.getSubtitle();
        const content = block.getContent();

        const hasUppertitle = uppertitle != null && uppertitle.length > 0;
        const hasTitle = title != null && title.length > 0;
        const hasDescription = subtitle != null && subtitle.length > 0;
        const hasContent = content != null && content.length > 0;
        const hasHeader = hasUppertitle || hasTitle || hasDescription;
        
        // design
        const designModel = new TextPlainDesignModel();
        designModel.setDefaultValues(this.props.context);

        if (!hasContent &&
            this.props.context.parentBlock != null &&
            this.props.context.parentBlock.targetBlockType === BlockType.LayoutStack &&
            this.props.context.nextBlock != null) {

            switch (this.props.context.nextBlock.targetBlockType) {
                case BlockType.Map:
                case BlockType.ContactsInIcons:
                case BlockType.ContactsSimple:
                case BlockType.GalleryPolaroid:
                case BlockType.GalleryPreview:
                case BlockType.GallerySimple:
                case BlockType.ImageSimple:
                case BlockType.YoutubeFrame:
                case BlockType.ButtonsVertical:
                case BlockType.ButtonsVerticalWithContacts:
                case BlockType.CardsSmall:
                case BlockType.SimpleCards:
                case BlockType.Faq:
                case BlockType.PostFeed:
                case BlockType.PostFeedInColumns:
                case BlockType.Numbers:
                    designModel.defaultTextAndButtonsAlignment = "middle";
                    break;
                case BlockType.TimelinePoints:
                    designModel.defaultTextAndButtonsAlignment = this.props.context.environment.getWebpageStyle() === WebpageStyleType.Modern ? "middle" : "left";
                    break;
            }
        }
        else if (!hasContent &&
            this.props.context.parentBlock != null &&
            this.props.context.parentBlock.targetBlockType === BlockType.LayoutTwoColumnsAndHeader &&
            (this.props.context.prevBlock == null || this.props.context.prevBlock.parentBlock !== this.props.context.parentBlock)) {

            designModel.defaultTextAndButtonsAlignment = "middle";
        }

        const designProperties = this.props.context.commonProperties.concat(this.props.context.targetBlock.blocksProps);
        designModel.setCustomValues(designProperties);

        const backgroundStyle = designModel.generateBackgroundStyle(this.props.context);
        const contentStyle = designModel.generateContentStyle(this.props.context);
        const textStyle = designModel.generateTextStyle();
        const fontClassModificator = designModel.getFontClassModificator();
        const doodle = designModel.generateDoodle();
        const hashNavigation = designModel.generateHashNavigationName();
        const effectAppearName = designModel.generateEffectAppear();
        const separatorType = designModel.getSeparator();
        
        return (
            <div id={hashNavigation} style={backgroundStyle}>
                {
                    doodle != null &&
                    <DoodleWrapper rule={doodle} />
                }
                <TransactionContainer style={contentStyle} effect={effectAppearName}>
                    <div className="d-flex flex-column gap-2 w-100">
                        {
                            hasUppertitle &&
                            <div className={`lp-text-uppertitle last-without-margin ${fontClassModificator}`} style={textStyle} dangerouslySetInnerHTML={{ __html: uppertitle }} />
                        }

                        {
                            hasTitle &&
                            <h2 className={`lp-text-title last-without-margin mb-0 ${fontClassModificator}`} style={textStyle} dangerouslySetInnerHTML={{ __html: title }} />
                        }

                        {
                            hasDescription &&
                            <div className={`lp-text-description last-without-margin opacity-60 ${fontClassModificator}`} style={textStyle} dangerouslySetInnerHTML={{ __html: subtitle }} />
                        }

                        {
                            hasContent &&
                            <div className={`lp-text last-without-margin ${hasHeader ? "mt-3" : ""} ${fontClassModificator}`} style={textStyle} dangerouslySetInnerHTML={{ __html: content }} />
                        }
                    </div>
                </TransactionContainer>
                {
                    separatorType != null &&
                    <SeparatorContainer context={this.props.context} separatorType={separatorType} />
                }
            </div>
        );
    }
}