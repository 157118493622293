import React from 'react';

import "reflect-metadata";
import { resolve } from 'inversify-react';

import { Container, Row, Col, Button, Form } from "react-bootstrap";

import DefaultLayout from '../../layouts/defaultLayout';
import NoAccessPanel from "../../controls/noAccessPanel";
import WaitPanel from "../../controls/waitPanel";

import { ErrorDetails, ErrorPanel } from "../../controls/errorPanel";
import ComponentBase from "../common/componentBase";
import UserInfo from "../../models/userInfo";
import { CurrentLocationProvider } from "../../models/currentLocationProvider";
import AccountDetailsPage from "./accountDetailsPage";

interface IAccountPageState {
    isLoaded: boolean,
    selectedTab: string,
    error: ErrorDetails,
}

export default class AccountPage extends ComponentBase<any, IAccountPageState> {

    @resolve(UserInfo)
    private readonly user: UserInfo;

    @resolve(CurrentLocationProvider)
    private readonly currentLocation: CurrentLocationProvider;

    constructor(prop: any) {
        super(prop);

        document.title = "Konto";

        this.state = {
            isLoaded: false,
            selectedTab: null,
            error: null,
        };

        this.selectTab = this.selectTab.bind(this);
    }

    componentDidMount(): void {

        if (!this.user.isLoggedIn()) {
            window.location.href = this.currentLocation.getLoginLink();
            return;
        }

        this.generateStartState()
            .then(x => this.setState(x))
            .catch((error: Error) => this.processError("Cannot receive some details", error));
    }

    async generateStartState(): Promise<IAccountPageState> {

        const selectedTab = this.currentLocation.getHash(window.location, "#general");

        return {
            isLoaded: true,
            selectedTab: selectedTab,
            error: null,
        };
    }

    selectTab(tab: string): void {
        this.setState({ selectedTab: tab });
    }

    render() {
        
        if (!this.state.isLoaded) {
            return (
                <DefaultLayout>
                    <WaitPanel />
                    <ErrorPanel error={this.state.error} />
                </DefaultLayout>
            );
        }

        return (
            <DefaultLayout>

                <Container fluid className="general-content-container d-flex flex-column">
                    <Row className="flex-fill">

                        <Col xs={4} md={3} lg={2} className="left-sidebar">
                            <div className="mt-2 mb-0">Ogólne</div>
                            <hr className="mt-2 mb-0" />
                            <Button className="border-0 bi-collection mt-2 mb-0 w-100 text-start"
                                variant={this.state.selectedTab === "#general" ? "secondary" : "outline-secondary"} href="#selectedTab"
                                onClick={() => this.selectTab("#general")}> Szczegóły</Button>
                        </Col>

                        <Col xs={8} md={9} lg={10} className="p-2">
                            {this.generateChildControl(this.state.selectedTab)}
                        </Col>
                    </Row>
                </Container>

                <ErrorPanel error={this.state.error} />

            </DefaultLayout>
        );
    }

    generateChildControl(tabName: string): React.ReactNode {

        switch (tabName) {
            case "#general":
                return (<AccountDetailsPage />);
            default:
                return null;
        }
    }
}