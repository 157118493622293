import React from 'react';

import { Accordion } from "react-bootstrap";

import { FaqItemContentModel, FaqItemDesignModel } from "../../models/blocks/faqItemModel";
import genUniqueId from "../../models/utils/genUniqueId";
import BlockComponent from './blockComponent';

export default class FaqItem extends BlockComponent {

    private id: string = genUniqueId();

    render() {

        // content
        const contentModel = new FaqItemContentModel();
        contentModel.initialize(this.props.context.targetBlock.blocksProps);

        const title = contentModel.getTitle();
        const content = contentModel.getContent();

        const hasTitle = title != null && title.length > 0;
        const hasContent = content != null && content.length > 0;

        // design
        const designProperties = this.props.context.commonProperties.concat(this.props.context.targetBlock.blocksProps);
        const designModel = new FaqItemDesignModel();
        designModel.setDefaultValues(this.props.context);
        designModel.setCustomValues(designProperties);

        const backgroundStyle = designModel.generateBackgroundStyle(this.props.context);
        const contentStyle = designModel.generateContentStyle(this.props.context);
        const textStyle = designModel.generateTextStyle();
        const fontClassModificator = designModel.getFontClassModificator();
        const doodle = designModel.generateDoodle();
        const hashNavigation = designModel.generateHashNavigationName();
        const effectAppearName = designModel.generateEffectAppear();
        
        return (
            <Accordion.Item eventKey={this.id}>
                <Accordion.Header className="custom-accordion-header">
                    <h3 className={`lp-text-subtitle last-without-margin my-0 ${fontClassModificator}`} style={textStyle} dangerouslySetInnerHTML={{ __html: title }} />
                </Accordion.Header>
                <Accordion.Body>
                    <div className={`lp-text-after-subtitle last-without-margin my-0 ${fontClassModificator}`} style={textStyle} dangerouslySetInnerHTML={{ __html: content }} />
                </Accordion.Body>
            </Accordion.Item>
        );
    }
}