import React from 'react';
import { PagesInfoProvider, IPostShortDetails, INavigationPage, IRender, INavigationHelpPage } from "../../models/pagesInfoProvider";
import { IPointContacts, PointsInfoProvider } from "../../models/pointsInfoProvider";
import { CurrentLocationProvider } from "../../models/currentLocationProvider";
import UserInfo from "../../models/userInfo";
import { IPageRenderedBlock } from "../../models/pageLayoutModel";
import { ColorWithDescription } from "../../utils/colorUtils";
import { PageType } from '../../models/pageType';

export default class PointPageEnvironment {
    
    public currentLocation: CurrentLocationProvider;
    private pageProvider: PagesInfoProvider;
    private user: UserInfo;
    private isPreview: boolean;
    private colorStyle: WebpageColorStyle;
    private webpageStyleType: WebpageStyleType = WebpageStyleType.None;
    private pageType: PageType = PageType.Unknown;

    private renderInfo: IRender;
    private posts: Promise<IPostShortDetails[]>;
    private parserBlocks: IPageRenderedBlock[];
    
    constructor(currentLocation: CurrentLocationProvider, pageProvider: PagesInfoProvider, user: UserInfo, renderInfo: IRender, isPreview: boolean) {
        this.renderInfo = renderInfo;
        this.currentLocation = currentLocation;
        this.pageProvider = pageProvider;
        this.user = user;
        this.isPreview = isPreview;

        this.setWebpageStyle(renderInfo.visualStyle as WebpageStyleType);
        this.colorStyle = WebpageColorStyle.create(renderInfo.colors);
        this.pageType = (renderInfo.pageType as PageType) ?? PageType.Unknown;
    }

    public getLast3Posts(): Promise<IPostShortDetails[]> {

        if (this.posts == null) {
            this.posts = this.pageProvider.getPublicPosts(this.getPointId(), this.getLanguage(), 0, 3);
        }

        return this.posts;
    }

    public getContacts(): IPointContacts {

        return {
            address: this.renderInfo.address,
            mail: this.renderInfo.mail,
            telephone: this.renderInfo.telephone,
            facebook: this.renderInfo.facebook,
            youtube: this.renderInfo.youtube,
            instagramId: this.renderInfo.instagramId,
            telegramId: this.renderInfo.telegramId,
            whatsAppId: this.renderInfo.whatsAppId,
            twitterId: this.renderInfo.twitterId,
            pinterestId: this.renderInfo.pinterestId,
            linkedinId: this.renderInfo.linkedinId,
        };
    }

    public getInnerContent(): IPageRenderedBlock[] {

        if (this.renderInfo.innerContent != null && this.renderInfo.innerContent.length > 0) {
            const parsedBlocks = JSON.parse(this.renderInfo.innerContent);
            return parsedBlocks;
        }

        return new Array<IPageRenderedBlock>();
    }

    public getPointId(): string {
        return this.renderInfo.pointId;
    }

    public isLoggedIn(): boolean {
        return this.user.isLoggedIn();
    }

    public getAcceptCookiesMark(): boolean {
        return this.user.getAcceptCookiesMark();
    }

    public setAcceptCookiesMark() {
        this.user.setAcceptCookiesMark();
    }

    public isPost() : boolean {
        return this.renderInfo.isPost;
    }
    
    public isHelp() : boolean {
        return this.pageType === PageType.Help;
    }
    
    public isPostOrHelp() : boolean {
        return this.isPost() || this.isHelp();
    }
    
    public getPublishDate(): Date {

        if (this.renderInfo.publishDate == null || this.renderInfo.publishDate.length === 0)
            return null;

        return new Date(this.renderInfo.publishDate);
    }

    public getPointTitle() : string {
        return this.renderInfo.pointName;
    }

    public getId() : string {
        return this.renderInfo.id;
    }

    public getSeoTitle() : string {
        return this.renderInfo.seoTitle;
    }

    public getSeoDescription() : string {
        return this.renderInfo.seoDescription;
    }

    public getSeoImageId() : string {
        return this.renderInfo.seoImageId;
    }

    public getPagePath(): string {
        return this.renderInfo.path;
    }

    public getFavicon(): string {

        if (this.renderInfo.pointFavicon !== null && this.renderInfo.pointFavicon.length > 0) {
            return this.currentLocation.getImageUrl(this.renderInfo.pointFavicon);
        }
        
        return null;
    }

    public getBlocks(): IPageRenderedBlock[] {

        if (this.parserBlocks == null) {

            if (this.renderInfo.content != null && this.renderInfo.content.length > 0) {
                this.parserBlocks = JSON.parse(this.renderInfo.content);
            } else {
                this.parserBlocks = new Array<IPageRenderedBlock>();
            }
        }

        return this.parserBlocks;
    }

    public setBlocks(blocks: IPageRenderedBlock[])  {

        this.parserBlocks = blocks;
    }

    public getNavigationPages(): INavigationPage[] {
        return this.renderInfo.navigationPages ?? new Array<INavigationPage>();
    }

    public getNavigationHelpPages(): INavigationHelpPage[] {
        return this.renderInfo.navigationHelpPages ?? new Array<INavigationHelpPage>();
    }

    public getLanguages(): string[] {

        if (this.renderInfo.pointLanguages == null || this.renderInfo.pointLanguages.length === 0)
            return [this.renderInfo.language];

        return this.renderInfo.pointLanguages;
    }

    public getLanguage(): string {
        return this.renderInfo.language;
    }

    public getIsPreview(): boolean {
        return this.isPreview;
    }

    public getPageType(): PageType {
        return this.pageType;
    }
    public setPageType(pageType: PageType) {
        this.pageType = pageType;
    }

    public setColorSchema(schema: WebpageColorStyle) {
        return this.colorStyle = schema;
    }
    public getTitleColorSchema(): ColorSchema {
        return this.webpageStyleType === WebpageStyleType.None ? null : this.colorStyle.title;
    }
    public getSubtitleBackgroundColorSchema(): ColorSchema {
        return this.webpageStyleType === WebpageStyleType.None ? null : this.colorStyle.subtitle;
    }
    public getBackgroundColorSchema(): ColorSchema {
        return this.webpageStyleType === WebpageStyleType.None ? null : this.colorStyle.background;
    }
    public getAlternativeBackgroundColorSchema(): ColorSchema {
        return this.webpageStyleType === WebpageStyleType.None ? null : this.colorStyle.alternative;
    }

    public getWebpageStyle(): WebpageStyleType {
        return this.webpageStyleType;
    }
    public setWebpageStyle(style: WebpageStyleType)  {
        this.webpageStyleType = style;
    }
}

export class WebpageColorStyle {

    public alternative: ColorSchema;
    public background: ColorSchema;
    public subtitle: ColorSchema;
    public title: ColorSchema;

    constructor(title: ColorSchema, subtitle: ColorSchema, background: ColorSchema, alternative: ColorSchema) {
        this.alternative = alternative;
        this.background = background;
        this.subtitle = subtitle;
        this.title = title;
    }

    public static create(value: string): WebpageColorStyle {
        
        let title: ColorSchema = null;
        let subtitle: ColorSchema = null;
        let background: ColorSchema = null;
        let alternative: ColorSchema = null;

        if (value != null) {

            if (value.length >= 12) {
                title = new ColorSchema('#' + value.substring(0, 6), '#' + value.substring(6, 12));
            }
            if (value.length >= 24) {
                subtitle = new ColorSchema('#' + value.substring(12, 18), '#' + value.substring(18, 24));
            }
            if (value.length >= 36) {
                background = new ColorSchema('#' + value.substring(24, 30), '#' + value.substring(30, 36));
            }
            if (value.length >= 48) {
                alternative = new ColorSchema('#' + value.substring(36, 42), '#' + value.substring(42, 48));
            }
        }

        return new WebpageColorStyle(title, subtitle, background, alternative);
    }
}

export class ColorSchema {
    public background: ColorWithDescription; 
    public button: ColorWithDescription;

    constructor(background: string, button: string) {
        this.background = new ColorWithDescription(background);
        this.button = new ColorWithDescription(button);
    }
}

export enum WebpageStyleType {
    None = 0,
    Classic = 1,
    Modern = 2,
}