import React from 'react';

import { CardSmallItemContentModel, CardSmallItemDesignModel } from "../../models/blocks/cardSmallItemModel";
import BlockComponent from './blockComponent';
import TransactionContainer from '../effects/transactionContainer';

export default class CardSmallItem extends BlockComponent {
    
    render() {

        // content
        const contentModel = new CardSmallItemContentModel();
        contentModel.initialize(this.props.context.targetBlock.blocksProps);

        const title = contentModel.getTitle();
        const content = contentModel.getContent();
        const imageId = contentModel.getImageId();
        const imageAltText = contentModel.getImageAltText();
        const linkText = contentModel.getLinkText();
        const link = contentModel.getLink(this.props.context.environment.currentLocation);

        const hasTitle = title != null && title.length > 0;
        const hasContent = content != null && content.length > 0;
        const hasImage = imageId != null && imageId.length > 0;
        const hasLink = link != null && link.length > 0;
        const hasLinkText = linkText != null && linkText.length > 0;

        // design
        const designProperties = this.props.context.commonProperties.concat(this.props.context.targetBlock.blocksProps);
        const designModel = new CardSmallItemDesignModel();
        designModel.setDefaultValues(this.props.context);
        designModel.setCustomValues(designProperties);

        const backgroundStyle = designModel.generateBackgroundStyle(this.props.context);
        const contentStyle = designModel.generateContentStyle(this.props.context);
        const textStyle = designModel.generateTextStyle();
        const fontClassModificator = designModel.getFontClassModificator();
        const doodle = designModel.generateDoodle();
        const hashNavigation = designModel.generateHashNavigationName();
        const effectAppearName = designModel.generateEffectAppear();
        
        return (
            <TransactionContainer className="overflow-hidden h-100" style={backgroundStyle} effect={effectAppearName}>
                <div style={contentStyle}>
                    {
                        hasImage &&
                        <img style={{ objectFit: "contain", maxWidth: "100%", maxHeight: "100%" }}
                            className="me-auto" src={this.props.context.environment.currentLocation.getImageUrl(imageId)}
                            alt={imageAltText}/>
                    }
                    {
                        hasTitle &&
                        <div className={`lp-text-subtitle last-without-margin ${fontClassModificator}`} style={textStyle} dangerouslySetInnerHTML={{ __html: title }} />
                    }
                    {
                        hasContent &&
                        <div className={`lp-text last-without-margin ${fontClassModificator} small-card-content`} style={textStyle} dangerouslySetInnerHTML={{ __html: content }} />
                    }
                    {
                        hasLinkText &&
                        <a className={`lp-text-link last-without-margin ${fontClassModificator}`} style={textStyle} href={link}>{linkText} →</a>
                    }
                </div>
            </TransactionContainer>
        );
    }
}