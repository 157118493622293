import { IPageBlockProperty, IPageRenderedBlockProperty } from "../../models/pageLayoutModel";
import { BlockPropType } from "../../models/blockType";
import CommonDesignModel from "./commonDesignModel";
import { IBlockModel } from "../modelsHelper";
import RenderBlockContext from "../../controls/renders/renderBlockContext";

export class ExternalFrameContentModel implements IBlockModel {

    private html: string;

    getHtml(): string { return this.html; }

    initialize(properties: IPageRenderedBlockProperty[]) {

        let html: IPageRenderedBlockProperty = null;
        for (let index = 0; index < properties.length; index++) {

            const property = properties[index];

            switch (property.propType) {
                case BlockPropType.Html:
                    html = property;
                    break;
                default:
                    break;
            }
        }

        this.html = html?.propString;
    }

    formatBlock(properties: IPageBlockProperty[]): IPageBlockProperty[] {

        let html: IPageBlockProperty = null;

        for (let index = 0; index < properties.length; index++) {

            const property = properties[index];

            switch (property.propType) {
            case BlockPropType.Html:
                html = property;
                break;
            default:
                break;
            }
        }

        if (html == null) {
            html = { propType: BlockPropType.Html };
        }

        return [html];
    }
}

export class ExternalFrameDesignModel extends CommonDesignModel {

    constructor() {
        super();

        this.allowBackground = true;
        this.allowContentWidth = true;
        this.allowContentMargin = true;
        this.allowBlockHieghtAndMargin = true;
        this.allowTextStyle = false;
        this.allowHashNavigation = true;
        this.allowHorizontalAlignment = true;
        this.allowVerticalAlignment = true;
        this.allowAppearEffect = true;
        this.allowSeparator = true;
    }

    public setDefaultValues(renderContext: RenderBlockContext) {
        super.setDefaultValues(renderContext);

        this.defaultWidth = "max";
    }
}