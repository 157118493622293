import React from 'react';

import { Figure } from "react-bootstrap";

import { ErrorDetails } from "../errorPanel";
import * as Logger from "../../utils/logger";
import { IPostShortDetails } from "../../models/pagesInfoProvider";
import { PostFeedContentModel, PostFeedDesignModel } from "../../models/blocks/postFeedModel";
import genUniqueId from "../../models/utils/genUniqueId";
import DoodleWrapper from "../common/doodleWrapper";
import BlockComponent from './blockComponent';
import SeparatorContainer from '../separators/separatorContainer';

interface IState {
    posts: IPostShortDetails[],
}

export default class PostFeedInColumns extends BlockComponent<IState> {

    private id: string = genUniqueId();

    constructor(props: any) {
        super(props);

        this.state = {
            posts: null,
        }
    }

    componentDidMount(): void {

        this.generateStartState()
            .then(x => this.setState(x))
            .catch((error: Error) => Logger.logError(new ErrorDetails("Cannot render the block", error)));
    }

    async generateStartState(): Promise<IState> {

        let posts: IPostShortDetails[] = await this.props.context.environment.getLast3Posts();
        return {
            posts: posts,
        };
    }

    render() {

        // content
        const block = new PostFeedContentModel();
        block.initialize(this.props.context.targetBlock.blocksProps);

        // design
        const designProperties = this.props.context.commonProperties.concat(this.props.context.targetBlock.blocksProps);
        const designModel = new PostFeedDesignModel();
        designModel.setDefaultValues(this.props.context);
        designModel.setCustomValues(designProperties);

        const backgroundStyle = designModel.generateBackgroundStyle(this.props.context);
        const contentStyle = designModel.generateContentStyle(this.props.context);
        const textStyle = designModel.generateTextStyle();
        const fontClassModificator = designModel.getFontClassModificator();
        const doodle = designModel.generateDoodle();
        const hashNavigation = designModel.generateHashNavigationName();
        const effectAppearName = designModel.generateEffectAppear();
        const separatorType = designModel.getSeparator();

        contentStyle.display = "grid";

        return (
            <div id={hashNavigation} style={backgroundStyle}>
                {
                    doodle != null &&
                    <DoodleWrapper rule={doodle} />
                }
                <div style={contentStyle} className="cards-container-3 gap-3">
                    {
                        this.props.context.environment.getIsPreview() && this.state.posts != null && this.state.posts.length === 0 &&
                        <div className="text-secondary">Blog nie ma żadnych artykułów</div>
                    }
                    {
                        this.state.posts?.map((x, index) => {

                            const imageUrl = this.props.context.environment.currentLocation.getImageUrl(x.imageId) ??
                                this.props.context.environment.currentLocation.getLink("/defaultImage.png", null);

                            return (
                                <div key={`post_feed_columns-${this.id}-${x.id}`} className="d-flex flex-column gap-1">

                                    <div className="height-to-weight">
                                        <Figure.Image className="h-100 w-100" style={{ objectFit: "contain" }} src={imageUrl} />
                                    </div>

                                    <div className="d-flex flex-column gap-2 w-100">
                                        <h3 className={`lp-text-subtitle ${fontClassModificator}`} style={textStyle}>{x.title}</h3>

                                        {
                                            x.publishDate != null &&
                                            <div className={`lp-text-date ${fontClassModificator}`} style={textStyle}>{new Date(
                                                x.publishDate).toLocaleString()}</div>
                                        }

                                        {
                                            x.description != null &&
                                            <div className={`lp-text ${fontClassModificator}`} style={textStyle}>{x.description}</div>
                                        }

                                        <a href={"/blog/" + x.id}>Szczegóły</a>
                                    </div>

                                </div>);
                        })
                    }

                </div>
                {
                    separatorType != null &&
                    <SeparatorContainer context={this.props.context} separatorType={separatorType} />
                }
            </div>
        );
    }
}