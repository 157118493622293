import React from 'react';

import { ChildTimelinePointsContentModel as TimelineItemWithTitleContentModel, ChildTimelinePointsDesignModel as TimelineItemWithTitleDesignModel } from "../../models/blocks/childTimelinePointsModel";
import BlockComponent from './blockComponent';
import TransactionContainer from '../effects/transactionContainer';

export default class EditorTimelineItemWithTitle extends BlockComponent {

    render() {

        // content
        const contentModel = new TimelineItemWithTitleContentModel();
        contentModel.initialize(this.props.context.targetBlock.blocksProps);

        const title = contentModel.getTitle();
        const content = contentModel.getContent();

        const hasTitle = title != null && title.length > 0;
        const hasContent = content != null && content.length > 0;

        // design
        const designProperties = this.props.context.commonProperties.concat(this.props.context.targetBlock.blocksProps);
        const designModel = new TimelineItemWithTitleDesignModel();
        designModel.setDefaultValues(this.props.context);
        designModel.setCustomValues(designProperties);

        const backgroundStyle = designModel.generateBackgroundStyle(this.props.context);
        const contentStyle = designModel.generateContentStyle(this.props.context);
        const textStyle = designModel.generateTextStyle();
        const fontClassModificator = designModel.getFontClassModificator();
        const doodle = designModel.generateDoodle();
        const hashNavigation = designModel.generateHashNavigationName();
        const effectAppearName = designModel.generateEffectAppear();

        return (
            <TransactionContainer effect={effectAppearName}>
                {
                    hasTitle &&
                    <h3 className={`lp-text-subtitle last-without-margin mb-0 ${fontClassModificator}`} style={textStyle} dangerouslySetInnerHTML={{ __html: title }} />
                }
                {
                    hasContent &&
                    <div className={`lp-text-after-subtitle last-without-margin ${hasTitle ? "mt-2" : ""} ${fontClassModificator}`} style={textStyle} dangerouslySetInnerHTML={{ __html: content }} />
                }
            </TransactionContainer>
        );
    }
}