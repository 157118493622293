import "reflect-metadata";

import { injectable } from "inversify";

@injectable()
export default class SessionDataProvider {

    private getKey(parentId: string, key: string) : string {
        const fullKey = "lp." + (parentId ?? "settings") + "." + key;
        return fullKey;
    }

    public setString(parentId: string, key: string, value: string) {
        const fullKey = this.getKey(parentId, key);
        sessionStorage.setItem(fullKey, value);
    }

    public getString(parentId: string, key: string) {
        const fullKey = this.getKey(parentId, key);
        return sessionStorage.getItem(fullKey);
    }

    public setValue<T>(parentId: string, key: string, value: T) {

        let rawBody: string | null = null;
        if (value != null) {
            rawBody = JSON.stringify(value);
        }

        this.setString(parentId, key, rawBody);
    }

    public getValue<T>(parentId: string, key: string) : T {

        const rawBody = this.getString(parentId, key);
        if (rawBody == null) {
            return null;
        }

        return JSON.parse(rawBody);
    }

    public removeItem(parentId: string, key: string) {

        const fullKey = this.getKey(parentId, key);
        sessionStorage.removeItem(fullKey);
    }
}