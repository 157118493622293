import React from 'react';

import { LinksItemContentModel, LinksItemDesignModel } from "../../models/blocks/linksItemModel";
import BlockComponent from './blockComponent';


export default class LinksItem extends BlockComponent {
    
    render() {

        // content
        const contentModel = new LinksItemContentModel();
        contentModel.initialize(this.props.context.targetBlock.blocksProps);

        const content = contentModel.getContent();
        const link = contentModel.getLink(this.props.context.environment.currentLocation);
        
        // design
        const designProperties = this.props.context.commonProperties.concat(this.props.context.targetBlock.blocksProps);
        const designModel = new LinksItemDesignModel();
        designModel.setDefaultValues(this.props.context);
        designModel.setCustomValues(designProperties);

        const backgroundStyle = designModel.generateBackgroundStyle(this.props.context);
        const contentStyle = designModel.generateContentStyle(this.props.context);

        const textStyle = designModel.generateTextStyle();
        const fontClassModificator = designModel.getFontClassModificator();

        const doodle = designModel.generateDoodle();
        const hashNavigation = designModel.generateHashNavigationName();
        const effectAppearName = designModel.generateEffectAppear();
        
        return (
            <div style={backgroundStyle}>
                <div style={contentStyle}>
                    <a className={`mx-auto lp-text-link ${fontClassModificator}`} href={link} style={textStyle}>{content}</a>
                </div>
            </div>);
    }
}