import React from 'react';

import { Carousel } from "react-bootstrap";

import { LayoutCarouselContentModel, LayoutCarouselDesignModel } from "../../models/blocks/layoutCarouselModel";
import BlockRender from '../renders/blockRender';
import DoodleWrapper from "../common/doodleWrapper";
import BlockComponent from './blockComponent';
import TransactionContainer from '../effects/transactionContainer';
import RenderBlockContext from "../renders/renderBlockContext";
import genUniqueId from "../../models/utils/genUniqueId";
import SeparatorContainer from '../separators/separatorContainer';

export default class LayoutCarouselEditor extends BlockComponent {

    private id: string = genUniqueId();

    render() {

        // content
        const contentModel = new LayoutCarouselContentModel();
        contentModel.initialize(this.props.context.targetBlock.blocksProps);

        const navbar = contentModel.getNavbar();
        const blocks = contentModel.getBlocks();

        // design
        const designProperties = this.props.context.commonProperties.concat(this.props.context.targetBlock.blocksProps);
        const designModel = new LayoutCarouselDesignModel();
        designModel.setDefaultValues(this.props.context);
        designModel.setCustomValues(designProperties);

        const backgroundStyle = designModel.generateBackgroundStyle(this.props.context);
        const contentStyle = designModel.generateContentStyle(this.props.context);
        const doodle = designModel.generateDoodle();
        const hashNavigation = designModel.generateHashNavigationName();

        const childCommonProps = designModel.formatCommonProps(designProperties);

        if (navbar != null) {
            backgroundStyle.gridTemplateRows = "auto 1fr";
        }

        // effect appear
        const effectAppearName = designModel.generateEffectAppear();

        // if it has navbar than we must increase z-index
        if (navbar != null) {
            backgroundStyle.zIndex = "var(--z-index-navbar-container)";
        }

        const resolvedContentWidth = designModel.getResolvedContentWidth();
        const resolvedBackgroundColor = designModel.getActiveBackgroundColor();

        const navbarContext = navbar == null ? null :
            {
                environment: this.props.context.environment,

                prevBlock: this.props.context.prevBlock,
                nextBlock: this.props.context.nextBlock,
                parentBlock: this.props.context,

                parentBlockWidth: resolvedContentWidth,
                parentBlockColor: resolvedBackgroundColor,
                parentBlockAnimation: effectAppearName,

                rootSectionType: this.props.context.rootSectionType,

                targetBlock: navbar,
                targetBlockType: navbar.blocksType,
                targetColorSchema: this.props.context.targetColorSchema,
                targetHasBackgroundImage: false,

                commonProperties: childCommonProps,
            };

        return (
            <div id={hashNavigation} style={backgroundStyle}>
                {
                    doodle != null &&
                    <DoodleWrapper rule={doodle} />
                }
                {
                    navbar != null &&
                    <BlockRender context={navbarContext} />
                }
                <TransactionContainer style={contentStyle} effect={effectAppearName}>
                    <Carousel indicators={false}
                        prevIcon={
                            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 16 16">
                                <circle cx="8" cy="8" r="7" fill="gray" stroke-width="0.5" />
                                <path fill="white" d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z" />
                            </svg>}
                        nextIcon={<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 16 16">
                            <circle cx="8" cy="8" r="7" fill="gray" stroke-width="0.5" />
                            <path fill="white" d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
                        </svg>} >
                        {
                            blocks.map((x, index) => {

                                const context: RenderBlockContext =
                                {
                                    environment: this.props.context.environment,

                                    prevBlock: navbarContext ?? this.props.context.prevBlock,
                                    nextBlock: this.props.context.nextBlock,
                                    parentBlock: null,

                                    parentBlockWidth: null,
                                    parentBlockColor: resolvedBackgroundColor,
                                    parentBlockAnimation: effectAppearName,

                                    rootSectionType: this.props.context.rootSectionType,

                                    targetBlock: x,
                                    targetBlockType: x.blocksType,
                                    targetColorSchema: this.props.context.targetColorSchema,
                                    targetHasBackgroundImage: false,

                                    commonProperties: childCommonProps,
                                };

                                return <Carousel.Item key={`carousel_item-${this.id}-${index}`}>
                                    <BlockRender context={context} />
                                </Carousel.Item>;
                            })
                        }

                    </Carousel>
                </TransactionContainer>
            </div>
        );
    }
}