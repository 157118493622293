import React from 'react';

import { BlockType } from "../../models/blockType";
import EditorTextPlain from "../blocks/textPlain";
import EditorCoverTitleSubtitle from "../blocks/coverTitleSubtitle";
import EditorQuoteAuthor from "../blocks/quoteAuthor";
import EditorFooterPlainWithContacts from '../blocks/footerPlainWithContacts';
import EditorFooterWithoutContacts from "../blocks/footerWithoutContacts";
import EditorFooterWithNavigationAndContacts from "../blocks/footerWithNavigationAndContacts";
import EditorPostFeed from "../blocks/postFeed";
import EditorPostFeedPage from "../blocks/postFeedPage";
import FeaturesWithHeaderInColumn from "../blocks/featuresPlain";
import FeaturesSimpleInColumn from "../blocks/featuresSimpleInColumn";
import FeaturesSimpleInTwoColumns from "../blocks/featuresSimpleInTwoColumns";
import EditorSimpleCards from "../blocks/simpleCards";
import CardsSmall from "../blocks/cardsSmall";
import Numbers from "../blocks/numbers";
import EditorTimelinePoints from '../blocks/timelinePoints';
import EditorContactsSimple from '../blocks/contactsSimple';
import ContactsInIcons from '../blocks/contactsInIcons';
import EditorMapSimple from "../blocks/mapSimple";
import EditorGallerySimple from "../blocks/gallerySimple";
import EditorGalleryPreview from "../blocks/galleryPreview";
import GalleryPoladoid from "../blocks/galleryPolaroid";
import LayoutTwoColumnsGrid from "../blocks/layoutTwoColumns";
import LayoutTwoColumnsAndHeader from "../blocks/layoutTwoColumnsAndHeader";
import LayoutWithSingleChild from "../blocks/layoutWithSingleChild";
import LayoutStack from "../blocks/layoutStack";
import LayoutCarousel from "../blocks/layoutCarousel";
import EditorImageSimple from "../blocks/imageSimple";
import EditorExternalFrame from "../blocks/externalFrame";
import EditorYoutube from "../blocks/youtubeSimple";
import EditorDefaultNavbar from "../blocks/defaultNavbar";
import Buttons from "../blocks/buttons";
import ButtonsVertical from "../blocks/buttonsVertical";
import ButtonsVerticalWithContacts from "../blocks/buttonsVerticalWithContacts";
import ChildButtons from "../blocks/childButtons";
import Links from "../blocks/links";
import LinksItem from "../blocks/linksItem";
import FaqPlain from "../blocks/faqPlain";
import FaqItem from "../blocks/faqItem";
import EditorCardItem from "../blocks/cardItem";
import CardSmallItem from "../blocks/cardSmallItem";
import NumbersItem from "../blocks/numbersItem";
import EditorFeatureItem from "../blocks/featureItem";
import EditorFeatureItemWithTitle from "../blocks/featureItemWithTitle";
import EditorTimelineItemWithTitle from "../blocks/childTimelinePoints";
import EditorGalleryItem from "../blocks/galleryItem";
import EditorGalleryPreviewItem from "../blocks/galleryPreviewItem";
import EditorNavbarItem from "../blocks/navbarItem";
import NavbarLightItem from "../blocks/navbarLightItem";
import SeparatorEmpty from "../blocks/separatorEmpty";
import SeparatorLineSimple from "../blocks/separatorLineSimple";
import MenuAnchorsList from '../blocks/menuAnchorsList';
import HelpPage from '../blocks/helpPage';
import SeparatorLineWithText from '../blocks/separatorLineWithText';
import PostFeedInColumns from '../blocks/postFeedInColumns';
import RenderBlockContext from "./renderBlockContext";

interface IBlockRenderProps {
    context: RenderBlockContext,
}

interface IBlockRenderState {
}

export default class BlockRender extends React.Component<IBlockRenderProps, IBlockRenderState> {

    constructor(props: IBlockRenderProps) {
        super(props);
    }
    
    render() {

        switch (this.props.context.targetBlockType) {
            case BlockType.TextPlain:
                return (<EditorTextPlain context={this.props.context}/>);
            case BlockType.CoverTitleSubtitle:
                return (<EditorCoverTitleSubtitle context={this.props.context} />);
            case BlockType.QuoteAuthor:
                return (<EditorQuoteAuthor context={this.props.context} />);
            case BlockType.ImageSimple:
                return (<EditorImageSimple context={this.props.context}/>);
            case BlockType.FooterPlainWithContacts:
                return (<EditorFooterPlainWithContacts context={this.props.context}/>);
            case BlockType.FooterWithNavigationAndContacts:
                return (<EditorFooterWithNavigationAndContacts context={this.props.context}/>);
            case BlockType.FooterWithoutContacts:
                return (<EditorFooterWithoutContacts context={this.props.context}/>);
            case BlockType.PostFeed:
                return (<EditorPostFeed context={this.props.context}/>);
            case BlockType.PostFeedInColumns:
                return (<PostFeedInColumns context={this.props.context}/>);
            case BlockType.PostFeedPage:
                return (<EditorPostFeedPage context={this.props.context}/>);
            case BlockType.HelpPage:
                return (<HelpPage context={this.props.context}/>);
            case BlockType.FeaturesWithHeaderInColumn:
                return (<FeaturesWithHeaderInColumn context={this.props.context}/>);
            case BlockType.FeaturesSimpleInColumn:
                return (<FeaturesSimpleInColumn context={this.props.context}/>);
            case BlockType.FeaturesSimpleInTwoColumns:
                return (<FeaturesSimpleInTwoColumns context={this.props.context}/>);
            case BlockType.SimpleCards:
                return (<EditorSimpleCards context={this.props.context}/>);
            case BlockType.CardsSmall:
                return (<CardsSmall context={this.props.context}/>);
            case BlockType.Numbers:
                return (<Numbers context={this.props.context}/>);
            case BlockType.TimelinePoints:
                return (<EditorTimelinePoints context={this.props.context}/>);
            case BlockType.ContactsSimple:
                return (<EditorContactsSimple context={this.props.context}/>);
            case BlockType.ContactsInIcons:
                return (<ContactsInIcons context={this.props.context}/>);
            case BlockType.Map:
                return (<EditorMapSimple context={this.props.context}/>);
            case BlockType.GallerySimple:
                return (<EditorGallerySimple context={this.props.context}/>);
            case BlockType.GalleryPreview:
                return (<EditorGalleryPreview context={this.props.context}/>);
            case BlockType.GalleryPolaroid:
                return (<GalleryPoladoid context={this.props.context}/>);
            case BlockType.LayoutTwoColumns:
                return (<LayoutTwoColumnsGrid context={this.props.context}/>);
            case BlockType.LayoutStack:
                return (<LayoutStack context={this.props.context}/>);
            case BlockType.LayoutWithSingleChild:
                return (<LayoutWithSingleChild context={this.props.context}/>);
            case BlockType.LayoutTwoColumnsAndHeader:
                return (<LayoutTwoColumnsAndHeader context={this.props.context}/>);
            case BlockType.LayoutCarousel:
                return (<LayoutCarousel context={this.props.context}/>);
            case BlockType.ExternalFrame:
                return (<EditorExternalFrame context={this.props.context}/>);
            case BlockType.YoutubeFrame:
                return (<EditorYoutube context={this.props.context}/>);
            case BlockType.DefaultNavbar:
                return (<EditorDefaultNavbar context={this.props.context}/>);
            case BlockType.Buttons:
                return (<Buttons context={this.props.context}/>);
            case BlockType.ButtonsVertical:
                return (<ButtonsVertical context={this.props.context}/>);
            case BlockType.ButtonsVerticalWithContacts:
                return (<ButtonsVerticalWithContacts context={this.props.context}/>);
            case BlockType.Links:
                return (<Links context={this.props.context}/>);
            case BlockType.MenuAnchorsList:
                return (<MenuAnchorsList context={this.props.context}/>);
            case BlockType.Faq:
                return (<FaqPlain context={this.props.context}/>);
            case BlockType.SeparatorEmpty:
                return (<SeparatorEmpty context={this.props.context}/>);
            case BlockType.SeparatorLineSimple:
                return (<SeparatorLineSimple context={this.props.context}/>);
            case BlockType.SeparatorLineWithText:
                return (<SeparatorLineWithText context={this.props.context}/>);
            case BlockType.CardItem:
                return (<EditorCardItem context={this.props.context}/>);
            case BlockType.CardSmallItem:
                return (<CardSmallItem context={this.props.context}/>);
            case BlockType.NumbersItem:
                return (<NumbersItem context={this.props.context}/>);
            case BlockType.FeatureItem:
                return (<EditorFeatureItem context={this.props.context}/>);
            case BlockType.FeatureWithTitleItem:
                return (<EditorFeatureItemWithTitle context={this.props.context}/>);
            case BlockType.TimelineWithTitleItem:
                return (<EditorTimelineItemWithTitle context={this.props.context}/>);
            case BlockType.GalleryItem:
                return (<EditorGalleryItem context={this.props.context}/>);
            case BlockType.GalleryPreviewItem:
                return (<EditorGalleryPreviewItem context={this.props.context}/>);
            case BlockType.NavbarItem:
                return (<EditorNavbarItem context={this.props.context}/>);
            case BlockType.NavbarLightItem:
                return (<NavbarLightItem context={this.props.context}/>);
            case BlockType.ButtonsItem:
                return (<ChildButtons context={this.props.context}/>);
            case BlockType.LinksItem:
                return (<LinksItem context={this.props.context}/>);
            case BlockType.FaqItem:
                return (<FaqItem context={this.props.context}/>);
            default:
                return this.props.context.environment.getIsPreview() ? <div>Unknown block</div> : null;
        }
    }
}