import React from 'react';
import { Figure } from "react-bootstrap";

import { CardItemContentModel, CardItemDesignModel } from "../../models/blocks/cardItemModel";
import { BlockType } from "../../models/blockType";
import genUniqueId from "../../models/utils/genUniqueId";
import BlockRender from '../renders/blockRender';
import BlockComponent from './blockComponent';
import TransactionContainer from '../effects/transactionContainer';
import RenderBlockContext from "../renders/renderBlockContext";

export default class EditorCardItem extends BlockComponent {

    private id: string = genUniqueId();

    render() {

        // content
        const contentModel = new CardItemContentModel();
        contentModel.initialize(this.props.context.targetBlock.blocksProps);

        const title = contentModel.getTitle();
        const content = contentModel.getContent();
        const imageId = contentModel.getImageId();
        const imageAltText = contentModel.getImageAltText();
        const blockCollection = contentModel.getBlocks();

        const hasTitle = title != null && title.length > 0;
        const hasContent = content != null && content.length > 0;
        const hasImage = imageId != null && imageId.length > 0;
        const hasBlocks = blockCollection != null && blockCollection.length > 0;

        // design
        const designProperties = this.props.context.commonProperties.concat(this.props.context.targetBlock.blocksProps);
        const designModel = new CardItemDesignModel();
        designModel.setDefaultValues(this.props.context);
        designModel.setCustomValues(designProperties);

        const backgroundStyle = designModel.generateBackgroundStyle(this.props.context);
        const contentStyle = designModel.generateContentStyle(this.props.context);
        const textStyle = designModel.generateTextStyle();
        const fontClassModificator = designModel.getFontClassModificator();
        const doodle = designModel.generateDoodle();
        const hashNavigation = designModel.generateHashNavigationName();
        const childCommonProps = designModel.formatCommonProps(designProperties);
        const effectAppearName = designModel.generateEffectAppear();

        const resolvedContentWidth = designModel.getResolvedContentWidth();
        const resolvedBackgroundColor = designModel.getActiveBackgroundColor();

        return (
            <TransactionContainer className="overflow-hidden h-100" style={backgroundStyle} effect={effectAppearName}>
                {
                    hasImage &&
                    <Figure.Image style={{ objectFit: "contain", maxWidth: "100%", maxHeight: "100%" }}
                        className="w-100"
                        src={this.props.context.environment.currentLocation.getImageUrl(imageId)}
                        alt={imageAltText} />
                }
                <div style={contentStyle}>
                    {
                        hasTitle &&
                        <div className={`lp-text-subtitle last-without-margin ${fontClassModificator}`} style={textStyle} dangerouslySetInnerHTML={{ __html: title }} />
                    }
                    {
                        hasContent &&
                        <div className={`lp-text last-without-margin ${hasTitle ? "mt-2" : ""} ${fontClassModificator}`} style={textStyle} dangerouslySetInnerHTML={{ __html: content }} />
                    }
                    {
                        !hasBlocks ? null : blockCollection.map((x, childIndex) => {

                            const context: RenderBlockContext =
                            {
                                environment: this.props.context.environment,

                                prevBlock: null,
                                nextBlock: null,
                                parentBlock: this.props.context,

                                parentBlockWidth: resolvedContentWidth,
                                parentBlockColor: resolvedBackgroundColor,
                                parentBlockAnimation: effectAppearName,

                                rootSectionType: this.props.context.rootSectionType,

                                targetBlock: x,
                                targetBlockType: BlockType.FeatureItem,
                                targetColorSchema: this.props.context.targetColorSchema,
                                targetHasBackgroundImage: false,

                                commonProperties: childCommonProps,
                            };

                            return <div key={`feature-${this.id}-${childIndex}`}>
                                <BlockRender context={context} />
                            </div>;
                        })
                    }
                </div>
            </TransactionContainer>
        );
    }
}