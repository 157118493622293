import React from 'react';

import { BlockPropType } from "../../models/blockType";
import { GallerySimpleContentModel, GallerySimpleDesignModel } from "../../models/blocks/gallerySimpleModel";
import ReactImageGallery from "react-image-gallery";
import { ReactImageGalleryItem } from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import DoodleWrapper from "../common/doodleWrapper";
import BlockComponent from './blockComponent';
import TransactionContainer from '../effects/transactionContainer';
import SeparatorContainer from '../separators/separatorContainer';

export default class GallerySimple extends BlockComponent {

    render() {

        // content
        const contentModel = new GallerySimpleContentModel();
        contentModel.initialize(this.props.context.targetBlock.blocksProps);

        const images = contentModel.getImages();

        // design
        const designProperties = this.props.context.commonProperties.concat(this.props.context.targetBlock.blocksProps);
        const designModel = new GallerySimpleDesignModel();
        designModel.setDefaultValues(this.props.context);
        designModel.setCustomValues(designProperties);

        const backgroundStyle = designModel.generateBackgroundStyle(this.props.context);
        const contentStyle = designModel.generateContentStyle(this.props.context);
        const doodle = designModel.generateDoodle();
        const hashNavigation = designModel.generateHashNavigationName();
        const separatorType = designModel.getSeparator();

        const imagesArray = new Array<ReactImageGalleryItem>();
        for (const imageItem of images) {

            let imageId: string = null;
            let imageAltText: string = null;

            for (const propItem of imageItem.blocksProps) {
                if (propItem == null)
                    continue;

                switch (propItem.propType) {
                    case BlockPropType.Image:
                        imageId = propItem.propString;
                        break;
                    case BlockPropType.ImageAltText:
                        imageAltText = propItem.propString;
                        break;
                }
            }

            const hasImage = imageId != null && imageId.length > 0 || imageAltText != null && imageAltText.length > 0;
            if (!hasImage) {
                continue;
            }

            const imageUrl = this.props.context.environment.currentLocation.getImageUrl(imageId);
            const previewImageUrl = this.props.context.environment.currentLocation.getPreviewImageUrl(imageId);

            const imageRender = {
                original: imageUrl,
                originalAlt: imageAltText,
                thumbnail: previewImageUrl,
                thumbnailAlt: imageAltText,
                originalHeight: Math.min(window.innerHeight * 0.7, 600),
            }

            imagesArray.push(imageRender);
        }

        // effect appear
        const effectAppearName = designModel.generateEffectAppear();

        return (
            <div id={hashNavigation} style={backgroundStyle}>
                {
                    doodle != null &&
                    <DoodleWrapper rule={doodle} />
                }
                <TransactionContainer style={contentStyle} effect={effectAppearName}>
                    <ReactImageGallery items={imagesArray} showFullscreenButton={false} showPlayButton={false} showThumbnails={false} showBullets={true} />
                </TransactionContainer>

                {
                    separatorType != null &&
                    <SeparatorContainer context={this.props.context} separatorType={separatorType} />
                }
            </div>
        );
    }
}