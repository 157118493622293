import React from 'react';

import { FooterPlainWithContactsContentModel, FooterPlainWithContactsDesignModel } from "../../models/blocks/footerPlainWithContactsModel";
import PolicyLinks from '../common/policyLinks';
import BlockComponent from './blockComponent';

export default class EditorFooterPlainWithContacts extends BlockComponent {

    render() {

        // content
        const block = new FooterPlainWithContactsContentModel();
        block.initialize(this.props.context.targetBlock.blocksProps);

        const content = block.getContent();
        const contacts = this.props.context.environment.getContacts();

        // design
        const designProperties = this.props.context.commonProperties.concat(this.props.context.targetBlock.blocksProps);
        const designModel = new FooterPlainWithContactsDesignModel();
        designModel.setDefaultValues(this.props.context);
        designModel.setCustomValues(designProperties);

        const backgroundStyle = designModel.generateBackgroundStyle(this.props.context);
        const contentStyle = designModel.generateContentStyle(this.props.context);
        const textStyle = designModel.generateTextStyle();
        const fontClassModificator = designModel.getFontClassModificator();
        const doodle = designModel.generateDoodle();
        const hashNavigation = designModel.generateHashNavigationName();
        const effectAppearName = designModel.generateEffectAppear();

        return (
            <div style={backgroundStyle} className="mt-auto">
                <div style={contentStyle}>
                    <div className="footer-with-contacts-grid my-3">
                        <div className={`lp-text-small w-auto footer-with-contacts-grid-first-child ${fontClassModificator}`} style={textStyle}>
                            <div dangerouslySetInnerHTML={{ __html: content }} />
                        </div>
                        <div className={`lp-text-small d-flex flex-row gap-2 w-auto ${fontClassModificator}`}>
                            <PolicyLinks textStyle={textStyle} environment={this.props.context.environment}/>
                        </div>
                        <div className="d-flex flex-row me-auto gap-3 ms-xl-auto me-xl-0">
                            {
                                contacts.telephone != null && contacts.telephone.length > 0 &&
                                <a className="bi bi-telephone lp-icon-middle" style={textStyle} href={"tel:" + contacts.telephone} />
                            }
                            {
                                contacts.mail != null && contacts.mail.length > 0 &&
                                <a className="bi bi bi-envelope lp-icon-middle" style={textStyle} href={"mailto:" + contacts.mail} />
                            }
                            {
                                contacts.facebook != null && contacts.facebook.length > 0 &&
                                <a className="bi bi-facebook lp-icon-middle" style={textStyle} target="_blank" href={"https://www.facebook.com/" + contacts.facebook}/>
                            }
                            {
                                contacts.instagramId != null && contacts.instagramId.length > 0 &&
                                <a className="bi bi-instagram lp-icon-middle" style={textStyle} target="_blank" href={"https://www.instagram.com/" + contacts.instagramId} />
                            }
                            {
                                contacts.youtube != null && contacts.youtube.length > 0 &&
                                <a className="bi bi-youtube lp-icon-middle" style={textStyle} target="_blank" href={"https://www.youtube.com/" + contacts.youtube} />
                            }
                            {
                                contacts.telegramId != null && contacts.telegramId.length > 0 &&
                                <a className="bi bi-telegram lp-icon-middle" style={textStyle} target="_blank" href={"https://t.me/" + contacts.telegramId} />
                            }
                            {
                                contacts.whatsAppId != null && contacts.whatsAppId.length > 0 &&
                                <a className="bi bi-whatsapp lp-icon-middle" style={textStyle} target="_blank" href={"https://wa.me/" + contacts.whatsAppId} />
                            }
                            {
                                contacts.twitterId != null && contacts.twitterId.length > 0 &&
                                <a className="bi bi-twitter-x lp-icon-middle lp-text-after-subtitle" style={textStyle} target="_blank" href={"https://twitter.com/" + contacts.twitterId} />
                            }
                            {
                                contacts.pinterestId != null && contacts.pinterestId.length > 0 &&
                                <a className="bi bi-pinterest lp-icon-middle lp-text-after-subtitle" style={textStyle} target="_blank" href={"https://pinterest.com/" + contacts.pinterestId} />
                            }
                            {
                                contacts.linkedinId != null && contacts.linkedinId.length > 0 &&
                                <a className="bi bi-linkedin lp-icon-middle lp-text-after-subtitle" style={textStyle} target="_blank" href={"https://www.linkedin.com/company/" + contacts.linkedinId} />
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}