import React from 'react';

import "reflect-metadata";

import { Navbar, Dropdown, Offcanvas, NavDropdown } from "react-bootstrap";

import { DefaultNavbarContentModel, DefaultNavbarDesignModel } from "../../models/blocks/defaultNavbarModel";
import AccountMenu from "../accountMenu";
import genUniqueId from "../../models/utils/genUniqueId";
import BlockComponent from './blockComponent';
import { BlockType, BlockPropType } from '../../models/blockType';
import BlockRender from '../renders/blockRender';
import Style from "../../models/style";
import { getBlockString } from "../../models/blocksHelper";
import RenderBlockContext from "../renders/renderBlockContext";
import { IPageRenderedBlock, IPageRenderedBlockProperty } from "../../models/pageLayoutModel";
import { ColorWithDescription } from "../../utils/colorUtils";
import { CurrentLocationProvider } from "../../models/currentLocationProvider";
import PointPageEnvironment from "../renders/pointPageEnvironment";

interface IMenuItem {
    title: string,
    block: IPageRenderedBlock,
    blocks: IMenuItemChild[],
}

interface IMenuItemChild {
    title: string,
    link: string,
}

export default class DefaultNavbar extends BlockComponent {

    private id: string = genUniqueId();

    private isScrolled: boolean = false;
    private isExpanded: boolean = false;

    constructor(props: any) {
        super(props);

        this.state = { scrolled: false };

        this.changeLang = this.changeLang.bind(this);
        this.onToggle = this.onToggle.bind(this);
    }

    componentDidMount(): void {

        const win: Window = window;
        win.addEventListener("scroll", this.onScroll);
    }

    onScroll(event: Event) {

        const win: Window = window;
        const isScrolled = win.scrollY > 0;

        if (isScrolled === this.isScrolled) {
            return;
        }

        this.isScrolled = isScrolled;

        const navBar = win.document.getElementById("nav-bar");
        navBar.classList.toggle("scrolled", this.isScrolled || this.isExpanded);
    };

    componentWillUnmount(): void {

        const win: Window = window;
        win.removeEventListener("scroll", this.onScroll);
    }

    onToggle(isExpanded: boolean) {

        if (isExpanded === this.isExpanded) {
            return;
        }

        this.isExpanded = isExpanded;

        const win: Window = window;
        const navBar = win.document.getElementById("nav-bar");
        navBar.classList.toggle("scrolled", this.isScrolled || this.isExpanded);
    }

    changeLang(lang: string) {

        if (this.props.context.environment.getIsPreview())
            return;

        const link = this.props.context.environment.currentLocation.getLink(this.props.context.environment.getPagePath(), lang);
        window.location.href = link;
    }

    render() {

        const currentLocationProvider = this.props.context.environment.currentLocation;

        // content
        const contentModel = new DefaultNavbarContentModel();
        contentModel.initialize(this.props.context.targetBlock.blocksProps);

        const showTitle = contentModel.getShowTitle();
        const showContacts = contentModel.getShowContacts();
        const blocks = contentModel.getBlocks(this.props.context.environment);

        // design
        const designProperties = this.props.context.commonProperties
            .filter(x => x.propType !== BlockPropType.ContentWidth)
            .concat(this.props.context.targetBlock.blocksProps);

        const designModel = new DefaultNavbarDesignModel();
        designModel.setDefaultValues(this.props.context);
        designModel.setCustomValues(designProperties);

        const backgroundStyle = designModel.generateBackgroundStyle(this.props.context);
        const contentStyle = designModel.generateContentStyle(this.props.context);
        const textStyle = designModel.generateTextStyle();
        const fontClassModificator = designModel.getFontClassModificator();
        const doodle = designModel.generateDoodle();
        const hashNavigation = designModel.generateHashNavigationName();

        let buttonsContainerStyle: Style = null;
        if (designModel.allowTextAndButtonsAlignment) {

            buttonsContainerStyle = new Style();
            switch (designModel.getButtonsHorizontalAlignment()) {
                case "left":
                    buttonsContainerStyle.margin = "auto auto auto 0";
                    break;
                case "right":
                    buttonsContainerStyle.margin = "auto 0 auto auto";
                    break;
                case "middle":
                    buttonsContainerStyle.margin = "auto";
                    break;
            }
        }

        // special
        const language = currentLocationProvider.getLanguage();

        const nativeNavigationPages = this.props.context.environment.getNavigationPages();
        const mainPageLink = nativeNavigationPages.length === 0 ? "/" : currentLocationProvider.getLink(nativeNavigationPages[0].path, language);

        const languages = this.props.context.environment.getLanguages();
        const showLangBar = languages.length > 1;

        const hostname = currentLocationProvider.getHostname();
        const isMain = hostname === 'lifedots.pl' || hostname === 'localhost';

        const navBarClass = this.props.context.environment.getIsPreview() ? "lp-top-toolbar-preview" : "lp-top-toolbar";

        const contacts = this.props.context.environment.getContacts();
        const childCommonProps = designModel.formatCommonProps(designProperties);
        const effectAppearName = designModel.generateEffectAppear();

        // blocks
        const blockList = new Array<IMenuItem>();
        const blockGroups = new Map<string, IMenuItem>();
        for (const block of blocks) {

            const title: string = getBlockString(block, BlockPropType.LinkText);
            if (title == null) {
                continue;
            }

            const separatorIndex = title.indexOf("/");
            if (separatorIndex === -1 || separatorIndex === 0 || separatorIndex === (title.length - 1)) {
                blockList.push({
                    title: title,
                    block: block,
                    blocks: null,
                });
                continue;
            }

            const groupName = title.slice(0, separatorIndex);
            const menuName: string = title.slice(separatorIndex + 1, title.length);
            const menuLink: string = getBlockString(block, BlockPropType.Link);
            const menuChild = { title: menuName, link: menuLink };

            if (blockGroups.has(groupName)) {
                blockGroups.get(groupName).blocks.push(menuChild);
            } else {

                const newMenuItem: IMenuItem = {
                    title: groupName,
                    block: null,
                    blocks: new Array<IMenuItemChild>(menuChild),
                };

                blockGroups.set(groupName, newMenuItem);
                blockList.push(newMenuItem);
            }
        }

        const containsSeveralPages = blockList.length > 0;

        // render
        const renderedLangBar = !showLangBar ? null : (
            <div className="my-auto">
                <Dropdown className="d-flex" align="end">
                    <Dropdown.Toggle className={`lp-toolbar-link my-auto mx-0 ${fontClassModificator}`} style={textStyle} variant={null}>{this.props.context.environment.getLanguage().toUpperCase()}</Dropdown.Toggle>
                    <Dropdown.Menu>
                        {
                            languages.map((x, index) =>
                                <Dropdown.Item key={`lang-${this.id}-${index}`} onClick={() => this.changeLang(x)}>{x.toUpperCase()}</Dropdown.Item>)
                        }
                    </Dropdown.Menu>
                </Dropdown>
            </div>);

        const renderedAccountBar = !isMain ? null : (
            <div className="d-flex lp-toolbar-link my-auto">
                <AccountMenu />
            </div>);

        const faviconUrl = this.props.context.environment.getFavicon();
        const renderBrand = (styled: boolean) => (
            <Navbar.Brand href={mainPageLink} className="m-0" style={styled ? textStyle : null}>
                {
                    faviconUrl != null &&
                    <img
                        alt=""
                        src={faviconUrl}
                        width="30"
                        height="30"
                        className="d-inline-block align-top me-2"
                    />
                }
                {
                    showTitle &&
                    <span className={`lp-toolbar-link m-0 ${fontClassModificator}`}>{this.props.context.environment.getPointTitle()}</span>
                }
            </Navbar.Brand>);

        const resolvedContentWidth = designModel.getResolvedContentWidth();
        const resolvedBackgroundColor = designModel.getActiveBackgroundColor();

        return (
            <div style={{ "minHeight": "calc(2*calc(0.5rem) + 2*calc(0.3125rem) + 30px)" }}>
                <Navbar id="nav-bar" className={navBarClass} expand={false} style={backgroundStyle} onToggle={this.onToggle}>
                    <div className="d-flex flex-row w-100 flex-fill gap-3" style={contentStyle}>

                        {renderBrand(true)}

                        <div style={buttonsContainerStyle}>
                            <div className="d-none d-lg-flex flex-row">
                                {
                                    blockList.map((x, index) => this.renderMenuTop(x, resolvedBackgroundColor, childCommonProps, textStyle, fontClassModificator, index))
                                }
                            </div>
                        </div>

                        {
                            showContacts &&
                            <div className="w-auto my-auto d-none d-lg-flex flex-row gap-2">
                                {
                                    contacts.telephone != null && contacts.telephone.length > 0 &&
                                    <a className={`lp-text-after-subtitle text-decoration-none text-nowrap lp-toolbar-other-element ${fontClassModificator}`} style={textStyle} href={"tel:" + contacts.telephone}>{contacts.telephone}</a>
                                }
                                {
                                    contacts.mail != null && contacts.mail.length > 0 &&
                                    <a className={`bi bi bi-envelope lp-icon-middle lp-text-after-subtitle lp-toolbar-other-element ${fontClassModificator}`} style={textStyle} href={"mailto:" + contacts.mail} />
                                }
                                {
                                    contacts.facebook != null && contacts.facebook.length > 0 &&
                                    <a className="bi bi-facebook lp-icon-middle lp-text-after-subtitle lp-toolbar-other-element" style={textStyle} target="_blank" href={"https://www.facebook.com/" + contacts.facebook} />
                                }
                                {
                                    contacts.instagramId != null && contacts.instagramId.length > 0 &&
                                    <a className="bi bi-instagram lp-icon-middle lp-text-after-subtitle lp-toolbar-other-element" style={textStyle} target="_blank" href={"https://www.instagram.com/" + contacts.instagramId} />
                                }
                                {
                                    contacts.youtube != null && contacts.youtube.length > 0 &&
                                    <a className="bi bi-youtube lp-icon-middle lp-text-after-subtitle lp-toolbar-other-element" style={textStyle} target="_blank" href={"https://www.youtube.com/" + contacts.youtube} />
                                }
                                {
                                    contacts.telegramId != null && contacts.telegramId.length > 0 &&
                                    <a className="bi bi-telegram lp-icon-middle lp-text-after-subtitle lp-toolbar-other-element" style={textStyle} target="_blank" href={"https://t.me/" + contacts.telegramId} />
                                }
                                {
                                    contacts.whatsAppId != null && contacts.whatsAppId.length > 0 &&
                                    <a className="bi bi-whatsapp lp-icon-middle lp-text-after-subtitle lp-toolbar-other-element" style={textStyle} target="_blank" href={"https://wa.me/" + contacts.whatsAppId} />
                                }
                                {
                                    contacts.twitterId != null && contacts.twitterId.length > 0 &&
                                    <a className="bi bi-twitter-x lp-icon-middle lp-text-after-subtitle lp-toolbar-other-element" style={textStyle} target="_blank" href={"https://twitter.com/" + contacts.twitterId} />
                                }
                                {
                                    contacts.pinterestId != null && contacts.pinterestId.length > 0 &&
                                    <a className="bi bi-pinterest lp-icon-middle lp-text-after-subtitle lp-toolbar-other-element" style={textStyle} target="_blank" href={"https://pinterest.com/" + contacts.pinterestId} />
                                }
                                {
                                    contacts.linkedinId != null && contacts.linkedinId.length > 0 &&
                                    <a className="bi bi-linkedin lp-icon-middle lp-text-after-subtitle lp-toolbar-other-element" style={textStyle} target="_blank" href={"https://www.linkedin.com/company/" + contacts.linkedinId} />
                                }
                            </div>
                        }

                        <div className="d-flex flex-row">
                            {renderedLangBar}
                            {renderedAccountBar}
                        </div>

                        {
                            containsSeveralPages &&
                            <div className="d-flex d-lg-none">
                                <Navbar.Toggle aria-controls="offcanvasNavbar-expand" className="border-0 shadow-none px-0" style={textStyle}>
                                    <div className="bi bi-list" style={{ fontSize: "30px" }}></div>
                                </Navbar.Toggle>
                                <Navbar.Offcanvas id="offcanvasNavbar-expand" aria-labelledby="offcanvasNavbarLabel-expand" placement="end" style={{ width: "300px" }}>
                                    <Offcanvas.Header closeButton>
                                        <Offcanvas.Title id="offcanvasNavbarLabel-expand">
                                            {renderBrand(false)}
                                        </Offcanvas.Title>
                                    </Offcanvas.Header>
                                    <Offcanvas.Body className="d-flex flex-column">
                                        <div className="d-flex flex-column gap-3">
                                            {
                                                blockList.map((x, index) => this.renderMenuRight(x, index, fontClassModificator))
                                            }
                                        </div>
                                        {
                                            showContacts &&
                                            <React.Fragment>
                                                <div className="ms-auto mt-auto">
                                                    {
                                                        contacts.telephone != null && contacts.telephone.length > 0 &&
                                                        <a className={`lp-text-after-subtitle text-decoration-none ${fontClassModificator}`} href={"tel:" + contacts.telephone}>{contacts.telephone}</a>
                                                    }
                                                </div>
                                                <div className="ms-auto">
                                                    {
                                                        contacts.mail != null && contacts.mail.length > 0 &&
                                                        <a className={`lp-text-after-subtitle text-decoration-none ${fontClassModificator}`} href={"mailto:" + contacts.mail}>{contacts.mail}</a>
                                                    }
                                                </div>
                                                <div className="ms-auto d-flex flex-row gap-2">
                                                    {
                                                        contacts.facebook != null && contacts.facebook.length > 0 &&
                                                        <a className="bi bi-facebook lp-icon-middle lp-text-after-subtitle" target="_blank" href={"https://www.facebook.com/" + contacts.facebook} />
                                                    }
                                                    {
                                                        contacts.instagramId != null && contacts.instagramId.length > 0 &&
                                                        <a className="bi bi-instagram lp-icon-middle lp-text-after-subtitle" target="_blank" href={"https://www.instagram.com/" + contacts.instagramId} />
                                                    }
                                                    {
                                                        contacts.youtube != null && contacts.youtube.length > 0 &&
                                                        <a className="bi bi-youtube lp-icon-middle lp-text-after-subtitle" target="_blank" href={"https://www.youtube.com/" + contacts.youtube} />
                                                    }
                                                    {
                                                        contacts.telegramId != null && contacts.telegramId.length > 0 &&
                                                        <a className="bi bi-telegram lp-icon-middle lp-text-after-subtitle" target="_blank" href={"https://t.me/" + contacts.telegramId} />
                                                    }
                                                    {
                                                        contacts.whatsAppId != null && contacts.whatsAppId.length > 0 &&
                                                        <a className="bi bi-whatsapp lp-icon-middle lp-text-after-subtitle" target="_blank" href={"https://wa.me/" + contacts.whatsAppId} />
                                                    }
                                                    {
                                                        contacts.twitterId != null && contacts.twitterId.length > 0 &&
                                                        <a className="bi bi-twitter-x lp-icon-middle lp-text-after-subtitle" target="_blank" href={"https://twitter.com/" + contacts.twitterId} />
                                                    }
                                                    {
                                                        contacts.pinterestId != null && contacts.pinterestId.length > 0 &&
                                                        <a className="bi bi-pinterest lp-icon-middle lp-text-after-subtitle" target="_blank" href={"https://pinterest.com/" + contacts.pinterestId} />
                                                    }
                                                    {
                                                        contacts.linkedinId != null && contacts.linkedinId.length > 0 &&
                                                        <a className="bi bi-linkedin lp-icon-middle lp-text-after-subtitle" target="_blank" href={"https://www.linkedin.com/company/" + contacts.linkedinId} />
                                                    }
                                                </div>
                                            </React.Fragment>
                                        }
                                    </Offcanvas.Body>
                                </Navbar.Offcanvas>
                            </div>
                        }

                    </div>
                </Navbar>
            </div>
        );
    }

    renderMenuTop(menu: IMenuItem, resolvedBackgroundColor: ColorWithDescription, childCommonProps: IPageRenderedBlockProperty[], textStyle: Style, fontClassModificator: string, index: number): React.ReactElement {

        if (menu.block != null) {

            const context: RenderBlockContext =
            {
                environment: this.props.context.environment,

                prevBlock: null,
                nextBlock: null,
                parentBlock: this.props.context,

                parentBlockWidth: "max",
                parentBlockColor: resolvedBackgroundColor,
                parentBlockAnimation: "none",

                rootSectionType: this.props.context.rootSectionType,

                targetBlock: menu.block,
                targetBlockType: BlockType.NavbarItem,
                targetColorSchema: this.props.context.targetColorSchema,
                targetHasBackgroundImage: false,

                commonProperties: childCommonProps,
            };

            return <BlockRender key={`navbar-${this.id}-${index}-top`} context={context} />;
        }

        const currentLocationProvider = this.props.context.environment.currentLocation;
        const isPreview = this.props.context.environment.getIsPreview();
        return <NavDropdown key={`navbar-${this.id}-${index}-top-group`} title={menu.title} style={textStyle} align="end" className={`lp-toolbar-link text-end ${fontClassModificator}`}>
            {
                menu.blocks.map((x, childIndex) => <NavDropdown.Item key={`navbar-${this.id}-${childIndex}-${index}-top`} href={isPreview ? "#" : currentLocationProvider.getLink(x.link, currentLocationProvider.getLanguage())}>{x.title}</NavDropdown.Item>)
            }
        </NavDropdown>;
    }

    renderMenuRight(menu: IMenuItem, index: number, fontClassModificator: string): React.ReactElement {

        if (menu.block != null) {

            const context: RenderBlockContext =
            {
                environment: this.props.context.environment,

                prevBlock: null,
                nextBlock: null,
                parentBlock: this.props.context,

                parentBlockWidth: "max",
                parentBlockColor: null,
                parentBlockAnimation: "none",

                rootSectionType: this.props.context.rootSectionType,

                targetBlock: menu.block,
                targetBlockType: BlockType.NavbarLightItem,
                targetColorSchema: this.props.context.targetColorSchema,
                targetHasBackgroundImage: false,

                commonProperties: new Array<IPageRenderedBlockProperty>(),
            };

            if (fontClassModificator.length > 0) {
                context.commonProperties.push({
                    propType: BlockPropType.FontClassModificator,
                    propString: fontClassModificator,
                });
            }

            return <BlockRender key={`navbar-${this.id}-${index}-right`} context={context} />;
        }
        
        return <CollapsedMenu key={`navbar-${this.id}-${index}-right-group`} menu={menu} environment={this.props.context.environment} fontClassModificator={fontClassModificator}/>;
    }
}

interface ICollapsedMenuProp {
    menu: IMenuItem,
    environment: PointPageEnvironment,
    fontClassModificator: string,
}

interface ICollapsedMenuState {
    expanded: boolean,
}

class CollapsedMenu extends React.Component<ICollapsedMenuProp, ICollapsedMenuState> {

    constructor(props: any) {
        super(props);

        this.state = { expanded: false };
    }

    render() {

        const isPreview = this.props.environment.getIsPreview();

        return (
            <React.Fragment>
                <a className="d-flex flex-row gap-2 lp-toolbar-link cursor-pointer" onClick={() => this.setState({ expanded: !this.state.expanded })}>
                    <div className={`lp-toolbar-link text-end ms-auto ${this.props.fontClassModificator}`}>{this.props.menu.title}</div>
                    <span className={"bi bi-chevron-down " + (this.state.expanded ? "turn-animation-on" : "turn-animation-off")} />
                </a>

                {
                    this.state.expanded &&
                    this.props.menu.blocks.map((x, childIndex) =>
                        <a className="d-flex flex-row gap-2 lp-toolbar-link cursor-pointer" href={isPreview ? "#" : this.props.environment.currentLocation.getLink(x.link, this.props.environment.currentLocation.getLanguage())}>
                            <div className={`lp-toolbar-link text-end ms-auto ${this.props.fontClassModificator}`}>{x.title}</div>
                            <span className="bi bi-arrow-return-left" />
                        </a>)
                }

            </React.Fragment>);
    }
}