import React from 'react';

import { YoutubeSimpleContentModel, YoutubeSimpleDesignModel } from "../../models/blocks/youtubeSimpleModel";
import DoodleWrapper from "../common/doodleWrapper";
import BlockComponent from './blockComponent';
import TransactionContainer from '../effects/transactionContainer';
import SeparatorContainer from '../separators/separatorContainer';

export default class EditorYoutubeSimple extends BlockComponent {

    render() {

        // content
        const block = new YoutubeSimpleContentModel();
        block.initialize(this.props.context.targetBlock.blocksProps);

        const itemId = block.getItemId();

        // design
        const designProperties = this.props.context.commonProperties.concat(this.props.context.targetBlock.blocksProps);
        const designModel = new YoutubeSimpleDesignModel();
        designModel.setDefaultValues(this.props.context);
        designModel.setCustomValues(designProperties);

        const backgroundStyle = designModel.generateBackgroundStyle(this.props.context);
        const contentStyle = designModel.generateContentStyle(this.props.context);
        const doodle = designModel.generateDoodle();
        const hashNavigation = designModel.generateHashNavigationName();
        const link = "https://www.youtube.com/embed/" + itemId;
        const effectAppearName = designModel.generateEffectAppear();
        const separatorType = designModel.getSeparator();

        return (
            <div id={hashNavigation} style={backgroundStyle}>
                {
                    doodle != null &&
                    <DoodleWrapper rule={doodle} />
                }
                <TransactionContainer style={contentStyle} effect={effectAppearName}>
                    <div className="mx-auto d-flex">
                        <iframe className="mx-auto maxwidth-100"
                            width="853"
                            height="480"
                            src={link}
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            title="Embedded youtube"
                        />
                    </div>
                </TransactionContainer>
                {
                    separatorType != null &&
                    <SeparatorContainer context={this.props.context} separatorType={separatorType} />
                }
            </div>
        );
    }
}