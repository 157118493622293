import React from 'react';

import { FeaturesSimpleInColumnDesignModel, FeaturesSimpleInColumnContentModel } from "../../models/blocks/featuresSimpleInColumnModel";
import { ContentWidthType, EffectAppearType } from "../../models/blocks/commonDesignModel";
import { BlockType } from "../../models/blockType";
import BlockRender from "../renders/blockRender";
import DoodleWrapper from "../common/doodleWrapper";
import BlockComponent from './blockComponent';
import { IPageRenderedBlock, IPageRenderedBlockProperty } from '../../models/pageLayoutModel';
import RenderBlockContext from "../renders/renderBlockContext";
import { ColorWithDescription } from "../../utils/colorUtils";
import SeparatorContainer from '../separators/separatorContainer';

export default class FeaturesSimpleInTwoColumns extends BlockComponent {

    render() {

        // content
        const block = new FeaturesSimpleInColumnContentModel();
        block.initialize(this.props.context.targetBlock.blocksProps);

        const blockCollection = block.getBlocks();
        const columnItemsCount = blockCollection.length % 2 === 0 ? blockCollection.length / 2 : (blockCollection.length + 1) / 2;

        // design
        const designProperties = this.props.context.commonProperties.concat(this.props.context.targetBlock.blocksProps);
        const designModel = new FeaturesSimpleInColumnDesignModel();
        designModel.setDefaultValues(this.props.context);
        designModel.setCustomValues(designProperties);

        const backgroundStyle = designModel.generateBackgroundStyle(this.props.context);
        const contentStyle = designModel.generateContentStyle(this.props.context);
        const doodle = designModel.generateDoodle();
        const hashNavigation = designModel.generateHashNavigationName();
        const childCommonProps = designModel.formatCommonProps(designProperties);
        const effectAppearName = designModel.generateEffectAppear();
        const separatorType = designModel.getSeparator();

        const resolvedContentWidth = designModel.getResolvedContentWidth();
        const resolvedBackgroundColor = designModel.getActiveBackgroundColor();

        return (
            <div id={hashNavigation} style={backgroundStyle}>
                {
                    doodle != null &&
                    <DoodleWrapper rule={doodle} />
                }
                <div style={contentStyle}>

                    <div className="two-columns gap-lg-3">
                        <div className="d-flex flex-column gap-2">
                            {blockCollection.map((x, childIndex) => this.renderInnerBlock(x, childCommonProps, childIndex < columnItemsCount, resolvedContentWidth, resolvedBackgroundColor, effectAppearName))}
                        </div>
                        <div className="d-lg-flex flex-column d-none gap-2">
                            {blockCollection.map((x, childIndex) => childIndex < columnItemsCount ? null : this.renderInnerBlock(x, childCommonProps, true, resolvedContentWidth, resolvedBackgroundColor, effectAppearName))}
                        </div>
                    </div>

                </div>

                {
                    separatorType != null &&
                    <SeparatorContainer context={this.props.context} separatorType={separatorType} />
                }

            </div>
        );
    }

    renderInnerBlock(x: IPageRenderedBlock, childCommonProps: IPageRenderedBlockProperty[], alwaysVisible: boolean, parentWidth: ContentWidthType, parentBackgroundColor: ColorWithDescription, effectAppearName: EffectAppearType): React.ReactElement {

        const context: RenderBlockContext =
        {
            environment: this.props.context.environment,

            prevBlock: null,
            nextBlock: null,
            parentBlock: this.props.context,

            parentBlockWidth: parentWidth,
            parentBlockColor: parentBackgroundColor,
            parentBlockAnimation: effectAppearName,

            rootSectionType: this.props.context.rootSectionType,

            targetBlock: x,
            targetBlockType: BlockType.FeatureItem,
            targetColorSchema: this.props.context.targetColorSchema,
            targetHasBackgroundImage: false,

            commonProperties: childCommonProps,
        };

        return <div className={alwaysVisible ? "" : "d-lg-none"}>
            <BlockRender context={context} />
        </div>;
    }
}