import { RGBColor } from 'react-color'

export function isTransparent(hexVal: string): boolean {

    if (hexVal == null || hexVal.length === 0) {
        return true;
    }

    if (hexVal.length === 7) {
        return false;
    }

    if (hexVal.length === 8) {
        return hexVal[7] === "0";
    }

    if (hexVal.length === 9) {
        return hexVal[7] === "0" && hexVal[8] === "0";
    }

    return false;
}

export function convertHexToRgbA(hexVal: string): RGBColor {

    if (hexVal.length === 7) {
        hexVal += "FF";
    }
    else if (hexVal.length === 8) {
        hexVal = hexVal.substr(0, 7) + "0" + hexVal[7];
    }

    // If the hex value is valid.
    if (/^#([A-Fa-f0-9]{4}){1,2}$/.test(hexVal)) {

        // Getting the content after '#',
        // eg. 'ffffff' in case of '#ffffff'
        var ret = hexVal.slice(1);

        // Splitting each character
        var retArray = ret.split('');

        // Checking if the length is 4
        // then make that 8
        if (retArray.length === 4) {
            var ar = new Array<string>();
            ar.push(ret[0]);
            ar.push(ret[0]);
            ar.push(ret[1]);
            ar.push(ret[1]);
            ar.push(ret[2]);
            ar.push(ret[2]);
            retArray = ar;
        }

        // Starts with '0x'(in hexadecimal)
        const retNumber = Number('0x' + retArray.join(''));

        // Converting the first 2 characters
        // from hexadecimal to r value
        var r = (retNumber >> 24) & 255;

        // Converting the second 2 characters
        // to hexadecimal to g value
        var g = (retNumber >> 16) & 255;

        // Converting the second 2 characters
        // to hexadecimal to g value
        var b = (retNumber >> 8) & 255;

        // Converting the last 2 characters
        // to hexadecimal to b value
        var a = retNumber & 255;

        // Appending all of them to make
        // the RGBA value
        return { r, g, b, a: a / 255 };
    }

    return { r: 100, g: 100, b: 100, a: 1 }
}

export class ColorWithDescription {

    private color: string;
    private isDark: boolean;

    public getColor() : string {
         return this.color;
    }

    public isLight() : boolean {
         return !this.isDark;
    }

    constructor(value: string) {

        this.color = value;

        const hexToRgbA = convertHexToRgbA(value);
        const hsp = Math.sqrt(
            0.299 * (hexToRgbA.r * hexToRgbA.r) +
            0.587 * (hexToRgbA.g * hexToRgbA.g) +
            0.114 * (hexToRgbA.b * hexToRgbA.b));
        this.isDark = hsp < 127.5;
    }
}