import { IPageRenderedBlock, IPageBlockProperty, IPageBlock, IPageRenderedBlockProperty } from "../../models/pageLayoutModel";
import { BlockPropType } from "../../models/blockType";
import { isNavbar } from "../../models/blocksHelper";
import CommonDesignModel from "./commonDesignModel";
import { IBlockModel } from "../modelsHelper";
import RenderBlockContext from "../../controls/renders/renderBlockContext";

export class LayoutCarouselContentModel implements IBlockModel {

    private navbar: IPageRenderedBlock;
    private blocks: IPageRenderedBlock[];

    getNavbar(): IPageRenderedBlock { return this.navbar; }
    getBlocks(): IPageRenderedBlock[] { return this.blocks; }

    initialize(properties: IPageRenderedBlockProperty[]) {

        let blockCollection: IPageRenderedBlockProperty = null;

        for (let index = 0; index < properties.length; index++) {

            const property = properties[index];

            switch (property.propType) {
                case BlockPropType.Collection:
                    blockCollection = property;
                    break;
                default:
                    break;
            }
        }

        this.blocks = blockCollection?.propChildren ?? new Array<IPageRenderedBlock>();

        if (this.blocks.length > 0 && this.blocks[0] != null && isNavbar(this.blocks[0].blocksType)) {
            this.navbar = this.blocks[0];
            this.blocks = this.blocks.slice(1);
        } else {
            this.navbar = null;
        }
    }

    formatBlock(properties: IPageBlockProperty[]): IPageBlockProperty[] {

        let blocks: IPageBlockProperty = null;

        for (let index = 0; index < properties.length; index++) {

            const property = properties[index];

            switch (property.propType) {
            case BlockPropType.Collection:
                blocks = property;
                break;
            default:
                break;
            }
        }

        if (blocks == null) {
            blocks = { propType: BlockPropType.Collection };
        }

        if (blocks.propChildren == null) {
            blocks.propChildren = new Array<IPageBlock>();
        }

        return [blocks];
    }
}

export class LayoutCarouselDesignModel extends CommonDesignModel {

    constructor() {
        super();

        this.allowBackground = true;
        this.allowContentWidth = true;
        this.allowContentMargin = true;
        this.allowBlockHieghtAndMargin = true;
        this.allowHashNavigation = true;
        this.allowHorizontalAlignment = true;
        this.allowVerticalAlignment = true;
        this.allowTextAndButtonsAlignment = true;
        this.allowButtonsStyle = true;
        this.allowTextStyle = true;
        this.allowAppearEffect = true;
    }

    public setDefaultValues(renderContext: RenderBlockContext) {
        super.setDefaultValues(renderContext);

        this.defaultWidth = "max";
        this.defaultVerticalAlignment = "fill";
        this.defaultHorizontalAlignment = "fill";
    }
}