import React from 'react';

import { Figure } from "react-bootstrap";

import { ImageSimpleContentModel, ImageSimpleDesignModel } from "../../models/blocks/imageSimpleModel";
import Style from "../../models/style";
import DoodleWrapper from "../common/doodleWrapper";
import BlockComponent from './blockComponent';
import TransactionContainer from '../effects/transactionContainer';
import SeparatorContainer from '../separators/separatorContainer';

export default class ImageSimple extends BlockComponent {

    render() {

        // content
        const contentModel = new ImageSimpleContentModel();
        contentModel.initialize(this.props.context.targetBlock.blocksProps);

        const imageId = contentModel.getImage();
        const imageAltText = contentModel.getImageAltText();
        const descriptoin = contentModel.getImageDescription();

        // design
        const designProperties = this.props.context.commonProperties.concat(this.props.context.targetBlock.blocksProps);
        const designModel = new ImageSimpleDesignModel();
        designModel.setDefaultValues(this.props.context);
        designModel.setCustomValues(designProperties);

        const backgroundStyle = designModel.generateBackgroundStyle(this.props.context);
        const contentStyle = designModel.generateContentStyle(this.props.context);
        const textStyle = designModel.generateTextStyle();
        const fontClassModificator = designModel.getFontClassModificator();
        const doodle = designModel.generateDoodle();
        const hashNavigation = designModel.generateHashNavigationName();
        const separatorType = designModel.getSeparator();

        const effectImageAdditionalBackgroundColor = designModel.getEffectImageAdditionalBackgroundColor();
        const hasImageAdditionalBackgroundEffect = effectImageAdditionalBackgroundColor != null && effectImageAdditionalBackgroundColor.length > 0;

        const hasEffectImageBorderRadius = designModel.getEffectImageBorderRadius();

        const imageStyle = new Style();
        imageStyle.objectFit = "contain";
        imageStyle.maxWidth = "100%";
        imageStyle.maxHeight = "100%";

        if (hasImageAdditionalBackgroundEffect) {
            imageStyle.gridArea = "2 / 1 / 4 / 3";
        } else {
            imageStyle.gridArea = "1 / 1 / 4 / 4";
        }

        if (hasEffectImageBorderRadius) {
            imageStyle.borderRadius = "1rem";
        }

        const effectAppearName = designModel.generateEffectAppear();

        return (
            <div id={hashNavigation} style={backgroundStyle}>
                {
                    doodle != null &&
                    <DoodleWrapper rule={doodle} />
                }
                <TransactionContainer style={contentStyle} effect={effectAppearName}>

                    <div className="d-block">
                        <div className="d-grid m-0" style={{
                            gridTemplateColumns: "3rem auto 3rem",
                            gridTemplateRows: "3rem auto 3rem",
                        }}>

                            {
                                hasImageAdditionalBackgroundEffect &&
                                <div style={{
                                    background: effectImageAdditionalBackgroundColor,
                                    gridColumn: "2/4",
                                    gridRow: "1/3",
                                    borderRadius: imageStyle.borderRadius,
                                }} />
                            }


                            <Figure.Image style={imageStyle}
                                className="z-1 lp-image"
                                src={this.props.context.environment.currentLocation.getImageUrl(imageId) ?? this.props.context.environment.currentLocation.getLink("/defaultImage.png", null)}
                                alt={imageAltText} />

                        </div>
                    </div>
                    {
                        descriptoin != null && descriptoin.length > 0 &&
                        <div className={`lp-text-description last-without-margin ${fontClassModificator}`} style={textStyle} dangerouslySetInnerHTML={{ __html: descriptoin }} />
                    }

                </TransactionContainer>

                {
                    separatorType != null &&
                    <SeparatorContainer context={this.props.context} separatorType={separatorType} />
                }

            </div>
        );
    }
}