import React from 'react';

import { Stack, NavDropdown } from "react-bootstrap";

import { FooterWithNavigationAndContactsContentModel, FooterWithNavigationAndContactsDesignModel } from "../../models/blocks/footerWithNavigationAndContactsModel";
import genUniqueId from "../../models/utils/genUniqueId";
import PolicyLinks from '../common/policyLinks';
import BlockComponent from './blockComponent';

interface IMenuItem {
    title: string,
    link: string,
    blocks: IMenuItemChild[],
}

interface IMenuItemChild {
    title: string,
    link: string,
}

export default class EditorFooterWithNavigationAndContacts extends BlockComponent {

    private id: string = genUniqueId();
    
    render() {

        // content
        const block = new FooterWithNavigationAndContactsContentModel();
        block.initialize(this.props.context.targetBlock.blocksProps);

        const subtitle = block.getSubtitle();
        const content = block.getContent();
        const contacts = this.props.context.environment.getContacts();

        // design
        const designProperties = this.props.context.commonProperties.concat(this.props.context.targetBlock.blocksProps);
        const designModel = new FooterWithNavigationAndContactsDesignModel();
        designModel.setDefaultValues(this.props.context);
        designModel.setCustomValues(designProperties);

        const backgroundStyle = designModel.generateBackgroundStyle(this.props.context);
        const contentStyle = designModel.generateContentStyle(this.props.context);
        const textStyle = designModel.generateTextStyle();
        const fontClassModificator = designModel.getFontClassModificator();
        const doodle = designModel.generateDoodle();
        const hashNavigation = designModel.generateHashNavigationName();
        const effectAppearName = designModel.generateEffectAppear();

        // special
        const navigationPages = this.props.context.environment.getNavigationPages();
        const language = this.props.context.environment.currentLocation.getLanguage();

        const mainPageLink = navigationPages.length === 0 ? "/" : this.props.context.environment.currentLocation.getLink(navigationPages[0].path, language);

        //const navigationPagesColumn1 = navigationPages.length < 4 ? navigationPages : navigationPages.slice(0, 3);
        //const navigationPagesColumn2 = navigationPages.length < 4 ? null : navigationPages.slice(3);

        const hasTelephone = contacts.telephone != null && contacts.telephone.length > 0;
        const hasMail = contacts.mail != null && contacts.mail.length > 0;
        const hasAddress = contacts.address != null && contacts.address.length > 0;
        const hasFacebook = contacts.facebook != null && contacts.facebook.length > 0;
        const hasYoutube = contacts.youtube != null && contacts.youtube.length > 0;
        const hasInstagram = contacts.instagramId != null && contacts.instagramId.length > 0;
        const hasTelegram = contacts.telegramId != null && contacts.telegramId.length > 0;
        const hasWhatsApp = contacts.whatsAppId != null && contacts.whatsAppId.length > 0;
        const hasTwitter = contacts.twitterId != null && contacts.twitterId.length > 0;
        const hasPinterest = contacts.pinterestId != null && contacts.pinterestId.length > 0;
        const hasLinkedin = contacts.linkedinId != null && contacts.linkedinId.length > 0;

        const faviconUrl = this.props.context.environment.getFavicon();

        // blocks
        const blockList = new Array<IMenuItem>();
        const blockGroups = new Map<string, IMenuItem>();
        for (const block of navigationPages) {

            const title = block.title;
            const link = this.props.context.environment.currentLocation.getLink(block.path, language);

            const separatorIndex = title.indexOf("/");
            if (separatorIndex === -1 || separatorIndex === 0 || separatorIndex === (title.length - 1)) {
                blockList.push({
                    title: title,
                    link: link,
                    blocks: null,
                });
                continue;
            }

            const groupName = title.slice(0, separatorIndex);
            const menuName: string = title.slice(separatorIndex + 1, title.length);
            const menuChild = { title: menuName, link: link };

            if (blockGroups.has(groupName)) {
                blockGroups.get(groupName).blocks.push(menuChild);
            } else {

                const newMenuItem: IMenuItem = {
                    title: groupName,
                    link: null,
                    blocks: new Array<IMenuItemChild>(menuChild),
                };

                blockGroups.set(groupName, newMenuItem);
                blockList.push(newMenuItem);
            }
        }
        
        return (
            <React.Fragment>
                <div className="mt-auto d-flex flex-column" style={backgroundStyle}>
                    <div className="py-5 d-flex flex-row flex-wrap" style={{ "paddingLeft": "var(--medium-marge)", "paddingRight": "var(--medium-marge)"}}>
                        <div className="col-lg-6 col-12">
                            <Stack direction="vertical" gap={3} className="mb-3 mb-lg-0">
                                <a href={mainPageLink} className="text-white text-decoration-none">
                                    <Stack direction="horizontal" className="me-2 align-items-start mt-1" gap={3}>
                                        {
                                            faviconUrl != null &&
                                            <img className="mb-auto"
                                                alt=""
                                                src={faviconUrl}
                                                width="30" height="30" />
                                        }
                                        <span className={`lp-text-subtitle ${fontClassModificator} my-auto`} style={textStyle}>{this.props.context.environment.getPointTitle()}</span>
                                    </Stack>
                                </a>
                                {
                                    subtitle != null && subtitle.length > 0 &&
                                    <div className={`lp-text-small ${fontClassModificator}`} style={textStyle} dangerouslySetInnerHTML={{ __html: subtitle }} />
                                }
                            </Stack>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                            <Stack direction="vertical" gap={1} className="me-md-5 mb-3 mb-md-0">
                                <div className={`lp-text-description mb-2 ${fontClassModificator}`} style={textStyle}>MENU</div>
                                {
                                    blockList.map((menu, menuIndex) => {

                                        if (menu.blocks != null) {
                                            return (<NavDropdown key={`navPage-${this.id}-${menuIndex}`} title={menu.title} style={textStyle} className={`lp-text-light text-decoration-none ${fontClassModificator}`}>
                                                {
                                                    menu.blocks.map((child, childIndex) => <NavDropdown.Item key={`navPage-${this.id}-${menuIndex}-${childIndex}`} href={child.link}>{child.title}</NavDropdown.Item>)
                                                }
                                            </NavDropdown>);
                                        }

                                        return (
                                            <a key={`navPage-${this.id}-${menuIndex}`}
                                                className={`lp-text-light text-decoration-none ${fontClassModificator}`}
                                                style={textStyle}
                                                href={menu.link}>{menu.title}</a>);
                                    })
                                }
                            </Stack>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                            <Stack direction="vertical" gap={1}>
                                <div className={`lp-text-description mb-2 ${fontClassModificator}`} style={textStyle}>KONTAKT</div>

                                {
                                    this.props.context.environment.getIsPreview() && !hasTelephone && !hasMail && !hasAddress && !hasFacebook && !hasYoutube && !hasInstagram && !hasTelegram && !hasWhatsApp && !hasTwitter && !hasPinterest && !hasLinkedin &&
                                    <div className={`lp-text-light ${fontClassModificator}`} style={textStyle}><em>Projekt nie zawiera danych<br />kontaktowych. Trzeba dodać<br /> to w ustawieniach.</em></div>
                                }

                                {
                                    hasTelephone &&
                                    <div>
                                        <a className={`lp-text-light text-decoration-none ${fontClassModificator}`} style={textStyle} href={"tel:" + contacts.telephone}>{contacts.telephone}</a>
                                    </div>
                                }

                                {
                                    hasMail &&
                                    <div>
                                        <a className={`lp-text-light text-decoration-none ${fontClassModificator}`} style={textStyle} href={"mailto:" + contacts.mail}>{contacts.mail}</a>
                                    </div>
                                }

                                {
                                    hasAddress &&
                                    <div className={`lp-text-light ${fontClassModificator}`} style={textStyle}>{contacts.address}</div>
                                }

                                {
                                    (hasFacebook || hasYoutube || hasInstagram || hasTelegram || hasWhatsApp || hasTwitter || hasPinterest || hasLinkedin) &&
                                    <Stack direction="horizontal" gap={2}>
                                        {
                                            hasFacebook &&
                                            <a className="bi bi-facebook lp-icon-middle lp-text-light" style={textStyle} target="_blank" href={"https://www.facebook.com/" + contacts.facebook} />
                                        }
                                        {
                                            hasInstagram &&
                                            <a className="bi bi-instagram lp-icon-middle lp-text-light" style={textStyle} target="_blank" href={"https://www.instagram.com/" + contacts.instagramId} />
                                        }
                                        {
                                            hasYoutube &&
                                            <a className="bi bi-youtube lp-icon-middle lp-text-light" style={textStyle} target="_blank" href={"https://www.youtube.com/" + contacts.youtube} />
                                        }
                                        {
                                            hasTelegram &&
                                            <a className="bi bi-telegram lp-icon-middle lp-text-light" style={textStyle} target="_blank" href={"https://t.me/" + contacts.telegramId} />
                                        }
                                        {
                                            hasWhatsApp &&
                                            <a className="bi bi-whatsapp lp-icon-middle lp-text-light" style={textStyle} target="_blank" href={"https://wa.me/" + contacts.whatsAppId} />
                                        }
                                        {
                                            hasTwitter &&
                                            <a className="bi bi-twitter-x lp-icon-middle lp-text-after-subtitle" style={textStyle} target="_blank" href={"https://twitter.com/" + contacts.twitterId} />
                                        }
                                        {
                                            hasPinterest &&
                                            <a className="bi bi-pinterest lp-icon-middle lp-text-after-subtitle" style={textStyle} target="_blank" href={"https://pinterest.com/" + contacts.pinterestId} />
                                        }
                                        {
                                            hasLinkedin &&
                                            <a className="bi bi-linkedin lp-icon-middle lp-text-after-subtitle" style={textStyle} target="_blank" href={"https://www.linkedin.com/company/" + contacts.linkedinId} />
                                        }
                                    </Stack>
                                }
                            </Stack>
                        </div>
                    </div>
                    <div className="p-3" style={{ "borderTopColor": textStyle.color, "borderTopStyle": "solid", "borderTopWidth": "1px" }}>
                        <div className="footer-without-contacts-grid">
                            <div className={`lp-text-small w-auto ${fontClassModificator}`} style={textStyle}>
                                <div dangerouslySetInnerHTML={{ __html: content }}/>
                            </div>
                            <div className={`lp-text-small d-flex flex-row me-auto gap-2 w-auto ${fontClassModificator}`}>
                                <PolicyLinks textStyle={textStyle} environment={this.props.context.environment}/>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}