import "reflect-metadata";

import "reflect-metadata";
import { resolve } from 'inversify-react';
import { injectable } from "inversify";

import WebRequest from "../utils/webRequest";
import UserInfo from "./userInfo";
import { IId } from "./entities/iidname";

export interface IAccount {
    id: string;
    firstName: string;
    image: string;
}

export interface IAccountDetails {
    id: string;
    firstName: string;
    lastName: string;
    birthday?: number;
    gender?: number;
    location: string;
    image: string;
}

export interface IIdToken {
    id: string;
    token: string;
    expires: string;
}

@injectable()
export class AccountInfoProvider {

    public user: UserInfo;

    public getAccount(): Promise<IAccount> {
        return WebRequest.requestItem<IAccount>("/api/identity/v1/account", "GET", this.user.getUserToken(), null);
    }

    public getAccountDetails(): Promise<IAccountDetails> {
        return WebRequest.requestItem<IAccountDetails>("/api/identity/v1/account/details", "GET", this.user.getUserToken(), null);
    }

    public updateAccountName(firstName: string, lastName: string): Promise<string> {
        return WebRequest.requestText("/api/identity/v1/account/update/name", "POST", this.user.getUserToken(),
            {
                "firstName": firstName,
                "lastName": lastName,
            });
    }

    public updateAccountBirthday(birthday: number): Promise<string> {
        return WebRequest.requestText("/api/identity/v1/account/update/birthday", "POST", this.user.getUserToken(),
            {
                "birthday": birthday,
            });
    }

    public updateAccountGender(gender: number): Promise<string> {
        return WebRequest.requestText("/api/identity/v1/account/update/gender", "POST", this.user.getUserToken(),
            {
                "gender": gender,
            });
    }

    public updateAccountLocation(location: string): Promise<string> {
        return WebRequest.requestText("/api/identity/v1/account/update/location", "POST", this.user.getUserToken(),
            {
                "location": location,
            });
    }
    
    public updateAccountImage(image: string): Promise<string> {
        return WebRequest.requestText("/api/identity/v1/account/update/image", "POST", this.user.getUserToken(),
            {
                "image": image,
            });
    }

    public login(email: string, pass: string): Promise<IIdToken> {
        return WebRequest.requestItem<IIdToken>("/api/identity/v1/account/login", "POST", null,
            {
                "email": email,
                "password": pass,
            });
    }

    public loginWithFacebook(token: string): Promise<IIdToken> {
        return WebRequest.requestItem<IIdToken>("/api/identity/v1/account/loginWithFacebook", "POST", null,
            {
                "token": token,
            });
    }
}