import React from 'react';

import { Spinner } from "react-bootstrap";

export default class WaitPanel extends React.Component {

    public render() {

        const style = { top: "50%", left: "50%", transform: "translate(-50%, -50%)" };

        return (
            <React.Fragment>

                <div className="position-fixed d-flex" style={style}>
                    <div className="mx-auto">
                        <Spinner animation="border" />
                    </div>
                </div>

            </React.Fragment>
        );
    }
}