import PointPageEnvironment from "./pointPageEnvironment";
import { IPageRenderedBlock, IPageRenderedBlockProperty, IPageBlock } from "../../models/pageLayoutModel";
import { BlockType, BlockPropType } from "../../models/blockType";
import { ColorSchema } from "./pointPageEnvironment";
import RenderBlockContext from "./renderBlockContext";
import { isNavbar, isLayout, isFooter } from "../../models/blocksHelper";
import { SectionType } from "./renderBlockContext";
import { PageType } from "../../models/pageType";

export function generateContext(blocks: IPageRenderedBlock[], environment: PointPageEnvironment, parentBlock: RenderBlockContext, navbar: RenderBlockContext): RenderBlockContext[] {

    const result = new Array<RenderBlockContext>();
    for (let blockIndex = 0; blockIndex < blocks.length; blockIndex++) {

        const currentBlock = blocks[blockIndex];

        let hasBackgroundImage: boolean = false;
        if (parentBlock == null && currentBlock.blocksProps != null && currentBlock.blocksProps.length > 0) {
            for (var propIndex = 0; propIndex < currentBlock.blocksProps.length; propIndex++) {
                const property = currentBlock.blocksProps[propIndex];
                if (property != null && property.propType === BlockPropType.BackgroundImage && property.propString != null && property.propString.length > 0) {
                    hasBackgroundImage = true;
                    break;
                }   
            }
        }

        const context: RenderBlockContext =
        {
            environment: environment,

            prevBlock: null,
            nextBlock: null,
            parentBlock: parentBlock,

            parentBlockWidth: null,
            parentBlockColor: null,
            parentBlockAnimation: null,

            rootSectionType: null,

            targetBlock: currentBlock,
            targetBlockType: currentBlock.blocksType,
            targetColorSchema: null,
            targetHasBackgroundImage: hasBackgroundImage,

            commonProperties: [],
        };

        result.push(context);
    }

    for (let contextIndex = 0; contextIndex < result.length; contextIndex++) {

        const context = result[contextIndex];

        if (contextIndex > 0) {
            context.prevBlock = result[contextIndex - 1];
        }
        else if (navbar != null) {
            context.prevBlock = navbar;
        } else {
            context.prevBlock = parentBlock?.prevBlock;
        }

        if (contextIndex < result.length - 1) {
            context.nextBlock = result[contextIndex + 1];
        } else {
            context.nextBlock = parentBlock?.nextBlock;
        }
    }

    if (parentBlock != null) {
        for (const item of result) {
            item.targetColorSchema = parentBlock.targetColorSchema;
            item.rootSectionType = parentBlock.rootSectionType;
        }
    } else {

        // detect sections
        const schamas = new Array<SectionType>();
        for (let contextIndex = 0; contextIndex < result.length; contextIndex++) {

            const context = result[contextIndex];
            
            if (isNavbar(context.targetBlockType)) {
                schamas.push(SectionType.Navbar);
                continue;
            }

            if (isFooter(context.targetBlockType)) {
                schamas.push(SectionType.Footer);
                continue;
            }

            schamas.push(SectionType.Unknown);
        }

        for (let contextIndex = 0; contextIndex < result.length; contextIndex++) {

            const schema = schamas[contextIndex];
            if (schema === SectionType.Navbar)
                continue;

            if (schema === SectionType.Unknown) {

                const context = result[contextIndex];
                if (hasTitle(context.targetBlock)) {
                    schamas[contextIndex] = SectionType.Title;
                }
            }

            break;
        }

        const pageType = environment.getPageType();
        if (!environment.isPost() && (pageType === PageType.Landing || pageType === PageType.Main)) {

            for (let contextIndex = 0; contextIndex < result.length; contextIndex++) {

                const schema = schamas[contextIndex];
                if (schema === SectionType.Navbar || schema === SectionType.Title)
                    continue;

                if (schema === SectionType.Unknown) {

                    const context = result[contextIndex];
                    if (canBeIntro(context.targetBlock)) {
                        schamas[contextIndex] = SectionType.Intro;
                    }
                }

                break;
            }

            for (let contextIndex = result.length - 1; contextIndex >= 0; contextIndex--) {

                const schema = schamas[contextIndex];
                if (schema === SectionType.Footer)
                    continue;

                if (schema === SectionType.Unknown) {

                    const context = result[contextIndex];
                    if (canBeLastWord(context.targetBlock)) {
                        schamas[contextIndex] = SectionType.LastWord;
                    }
                }

                break;
            }
        }

        if (schamas.find(x => x === SectionType.Unknown) == null) {

            for (let contextIndex = 0; contextIndex < result.length; contextIndex++) {

                const schema = schamas[contextIndex];
                if (schema === SectionType.Intro || schema === SectionType.LastWord) {
                    schamas[contextIndex] = SectionType.Unknown;
                }
            }
        }

        let hasAnyRegular = false;
        for (let contextIndex = 0; contextIndex < result.length; contextIndex++) {

            const schema = schamas[contextIndex];
            if (schema === SectionType.Unknown) {
                schamas[contextIndex] = SectionType.Regular;
                hasAnyRegular = true;
            }
        }

        //
        const titleColorSchema = environment.getTitleColorSchema();
        const backgroundColorSchema = environment.getBackgroundColorSchema();
        const subtitleColorSchema = environment.getSubtitleBackgroundColorSchema();

        for (let contextIndex = 0; contextIndex < result.length; contextIndex++) {

            const context = result[contextIndex];
            const schema = schamas[contextIndex];

            context.rootSectionType = schema;

            switch (schema) {
                case SectionType.Navbar:
                case SectionType.Footer:
                    context.targetColorSchema = titleColorSchema;
                    break;
                case SectionType.Title:
                    context.targetColorSchema = hasAnyRegular && pageType !== PageType.Help ? titleColorSchema : backgroundColorSchema;
                    break;
                case SectionType.Intro:
                case SectionType.LastWord:
                    context.targetColorSchema = pageType !== PageType.Help ? subtitleColorSchema : backgroundColorSchema;
                    break;
                default:
                    context.targetColorSchema = backgroundColorSchema;
                    break;
            }
        }
    }

    return result;
}

function hasTitle(block: IPageRenderedBlock): boolean {

    if (block == null)
        return false;

    if (block.blocksType === BlockType.CoverTitleSubtitle) {
        return true;
    }
    
    const collectionProperty = block.blocksProps.find(x => x.propType === BlockPropType.Collection);
    if (collectionProperty == null) {
        return false;
    }

    for (const block of collectionProperty.propChildren) {
        if (hasTitle(block))
            return true;
    }

    return false;
}

function canBeLastWord(block: IPageRenderedBlock): boolean {

    if (block == null)
        return true;

    switch (block.blocksType) {
        case BlockType.TextPlain:
        case BlockType.ImageSimple:
        case BlockType.Buttons:
        case BlockType.Links:
        case BlockType.GalleryPolaroid:
        case BlockType.QuoteAuthor:
        case BlockType.PostFeed:
        case BlockType.PostFeedInColumns:
        case BlockType.ContactsSimple:
        case BlockType.ContactsInIcons:
        case BlockType.YoutubeFrame:
        case BlockType.ExternalFrame:
        case BlockType.Map:
        case BlockType.Faq:
            return true;   
    }

    const collectionProperty = block.blocksProps.find(x => x.propType === BlockPropType.Collection);
    if (collectionProperty == null) {
        return false;
    }

    for (const block of collectionProperty.propChildren) {
        if (canBeLastWord(block))
            return true;
    }

    return false;
}

function canBeIntro(block: IPageRenderedBlock): boolean {

    if (block == null)
        return true;

    switch (block.blocksType) {
        case BlockType.TextPlain:
        case BlockType.ImageSimple:
        case BlockType.Buttons:
        case BlockType.Links:
            return true;   
    }

    const collectionProperty = block.blocksProps.find(x => x.propType === BlockPropType.Collection);
    if (collectionProperty == null) {
        return false;
    }

    for (const block of collectionProperty.propChildren) {
        if (canBeIntro(block))
            return true;
    }

    return false;
}