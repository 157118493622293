import { IPageBlockProperty, IPageRenderedBlockProperty, IPageRenderedBlock, IPageBlock } from "../../models/pageLayoutModel";
import { BlockPropType } from "../../models/blockType";
import CommonDesignModel from "./commonDesignModel";
import { IBlockModel } from "../modelsHelper";
import RenderBlockContext from "../../controls/renders/renderBlockContext";
import { WebpageStyleType } from "../../controls/renders/pointPageEnvironment";

export class ButtonsContentModel implements IBlockModel {

    private blocks: IPageRenderedBlock[];

    getBlocks(): IPageRenderedBlock[] { return this.blocks; }

    initialize(properties: IPageRenderedBlockProperty[]) {

        let blockCollection: IPageRenderedBlockProperty = null;

        for (let index = 0; index < properties.length; index++) {

            const property = properties[index];

            switch (property.propType) {
            case BlockPropType.Collection:
                blockCollection = property;
                break;
            default:
                break;
            }
        }

        this.blocks = blockCollection?.propChildren ?? new Array<IPageRenderedBlock>();
    }

    formatBlock(properties: IPageBlockProperty[]): IPageBlockProperty[] {
        let blocks: IPageBlockProperty = null;

        for (let index = 0; index < properties.length; index++) {

            const property = properties[index];

            switch (property.propType) {
            case BlockPropType.Collection:
                blocks = property;
                break;
            default:
                break;
            }
        }

        if (blocks == null) {
            blocks = { propType: BlockPropType.Collection };
        }

        if (blocks.propChildren == null) {
            blocks.propChildren = new Array<IPageBlock>();
        }

        return [blocks];
    }
}

export class ButtonsDesignModel extends CommonDesignModel {

    constructor() {
        super();

        this.allowBackground = true;
        this.allowContentWidth = true;
        this.allowContentMargin = true;
        this.allowBlockHieghtAndMargin = true;
        this.allowHashNavigation = false;
        this.allowHorizontalAlignment = true;
        this.allowVerticalAlignment = true;
        this.allowTextAndButtonsAlignment = true;
        this.allowTextStyle = true;
        this.allowAppearEffect = true;
        this.allowButtonsStyle = true;
        this.allowSeparator = true;
    }
}