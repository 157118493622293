import { IPageBlockProperty, IPageRenderedBlockProperty } from "../../models/pageLayoutModel";
import { BlockPropType } from "../../models/blockType";
import { IBlockModel } from "../modelsHelper";
import Style from "../style";
import CommonDesignModel from "./commonDesignModel";
import { CurrentLocationProvider } from "../currentLocationProvider";
import RenderBlockContext from "../../controls/renders/renderBlockContext";

export class LinksItemContentModel implements IBlockModel {

    private content: string;
    private link: string;

    getContent(): string { return this.content; }

    getLink(currentLocationProvider: CurrentLocationProvider): string {

        const link = currentLocationProvider.getLink(this.link, currentLocationProvider.getLanguage());
        return link;
    }

    initialize(properties: IPageRenderedBlockProperty[]) {

        let content: IPageRenderedBlockProperty = null;
        let link: IPageRenderedBlockProperty = null;

        for (let index = 0; index < properties.length; index++) {

            const property = properties[index];

            switch (property.propType) {
            case BlockPropType.LinkText:
                content = property;
                break;
            case BlockPropType.Link:
                link = property;
                break;
            default:
                break;
            }
        }

        this.content = content?.propString;
        this.link = link?.propString;
    }

    formatBlock(properties: IPageBlockProperty[]): IPageBlockProperty[] {

        let content: IPageBlockProperty = null;
        let link: IPageBlockProperty = null;

        for (let index = 0; index < properties.length; index++) {

            const property = properties[index];

            switch (property.propType) {
            case BlockPropType.LinkText:
                content = property;
                break;
            case BlockPropType.Link:
                link = property;
                break;
            default:
                break;
            }
        }

        if (content == null) {
            content = { propType: BlockPropType.LinkText };
        }

        if (link == null) {
            link = { propType: BlockPropType.Link };
        }

        return [content, link];
    }
}

export class LinksItemDesignModel extends CommonDesignModel {

    constructor() {
        super();

        this.allowBackground = false;
        this.allowContentWidth = false;
        this.allowContentMargin = false;
        this.allowBlockHieghtAndMargin = false;
        this.allowHashNavigation = false;
        this.allowHorizontalAlignment = false;
        this.allowVerticalAlignment = false;
        this.allowDoodle = false;
        this.allowTextStyle = true;
        this.allowTextAndButtonsAlignment = true;
        this.allowAppearEffect = false;
    }
    
    generateBackgroundStyle(context: RenderBlockContext): Style {

        const result: Style = super.generateBackgroundStyle(context);
        result.display = "block";

        return result;
    }

    generateTextStyle(): Style {

        const result: Style = super.generateTextStyle();
        result.color = null;

        return result;
    }
}