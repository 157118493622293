import { IPageBlockProperty } from "../../models/pageLayoutModel";
import { IDoodle } from "./IDoodle";
import { BlockPropType } from "../blockType";

export default class Triangles implements IDoodle {

    public color0: string;
    public color1: string;
    public color2: string;

    getDoodle(): string {
        return `
 :doodle {
    @grid: 10 / 100vmax;
  }
  background: @pick(${this.color0}, ${this.color1}, ${this.color2});
  opacity: @r(.9);
  clip-path: polygon(
    @rand(50%) 0, 50% @rand(70%), 0 @rand(100%)
  );
  
  animation: test infinite @r(100s, 150s) linear @rand(-100, 0)s;
  
  @keyframes test {
    0% {
      transform: translate3d(0, 0, 0);
    }
    50% {
      transform: translate3d(@r(-500%, 1000%), @r(-500%, 1000%), 0);
    }
    100% {
      transform: translate3d(0, 0, 0);
    }
  }
            `;
    }

    formatProperties(): IPageBlockProperty[] {

        const result = new Array<IPageBlockProperty>();
        result.push({
            propType: BlockPropType.DoodleTrianglesColor0,
            propString: this.color0,
        });
        result.push({
            propType: BlockPropType.DoodleTrianglesColor1,
            propString: this.color1,
        });
        result.push({
            propType: BlockPropType.DoodleTrianglesColor2,
            propString: this.color2,
        });

        return result;
    }
}