import { IPageBlockProperty, IPageRenderedBlockProperty } from "../../models/pageLayoutModel";
import { BlockPropType } from "../../models/blockType";
import CommonDesignModel from "./commonDesignModel";
import { IBlockModel } from "../modelsHelper";
import RenderBlockContext from "../../controls/renders/renderBlockContext";

export class MapSimpleContentModel implements IBlockModel {

    private address: string;

    getAddress(): string { return this.address; }

    initialize(properties: IPageRenderedBlockProperty[]) {

        let address: IPageRenderedBlockProperty = null;
        for (let index = 0; index < properties.length; index++) {

            const property = properties[index];

            switch (property.propType) {
                case BlockPropType.Address:
                    address = property;
                    break;
                default:
                    break;
            }
        }

        this.address = address?.propString;
    }

    formatBlock(properties: IPageBlockProperty[]): IPageBlockProperty[] {

        let address: IPageBlockProperty = null;

        for (let index = 0; index < properties.length; index++) {

            const property = properties[index];

            switch (property.propType) {
            case BlockPropType.Address:
                address = property;
                break;
            default:
                break;
            }
        }

        if (address == null) {
            address = { propType: BlockPropType.Address };
        }

        return [address];
    }
}

export class MapSimpleDesignModel extends CommonDesignModel {

    constructor() {
        super();

        this.allowBackground = true;
        this.allowContentWidth = true;
        this.allowContentMargin = true;
        this.allowBlockHieghtAndMargin = true;
        this.allowHashNavigation = true;
        this.allowHorizontalAlignment = true;
        this.allowVerticalAlignment = true;
        this.allowTextStyle = false;
        this.allowAppearEffect = true;
        this.allowSeparator = true;
    }
}