import React from 'react';
import "reflect-metadata";
import { resolve } from 'inversify-react';

import { Button, Figure, Pagination } from "react-bootstrap";

import { IPostShortDetails, PagesInfoProvider } from "../../models/pagesInfoProvider";
import { PostFeedContentModel } from "../../models/blocks/postFeedModel";
import { PostFeedPageDesignModel } from "../../models/blocks/postFeedPageModel";
import Style from "../../models/style";
import genUniqueId from "../../models/utils/genUniqueId";
import DoodleWrapper from "../common/doodleWrapper";
import BlockComponent from './blockComponent';
import SeparatorContainer from '../separators/separatorContainer';
import { BlockPropType } from "../../models/blockType";
import { getPageArg } from "../../models/currentLocationProvider";

interface IEditorState {
    isLoaded: boolean,
    isLoading: boolean,
    pageIndex: number,
    pageCount: number,
    posts: IPostShortDetails[],
}

export default class PostFeedPage extends BlockComponent<Partial<IEditorState>> {

    @resolve(PagesInfoProvider)
    private readonly pagesInfoProvider: PagesInfoProvider;

    private id: string = genUniqueId();

    constructor(props: any) {
        super(props);

        const statePosts = new Array<IPostShortDetails>();

        const block = new PostFeedContentModel();
        block.initialize(this.props.context.targetBlock.blocksProps);

        const allPostsCount = block.getPostsAllCount();
        const allPagesCount = Math.ceil(allPostsCount / 5);
        const pageIndex = getPageArg();

        const preloadedPosts = block.getPosts();
        for (const post of preloadedPosts) {

            if (post == null || post.blocksProps == null || post.blocksProps.length === 0)
                break;

            let title: string = null;
            let description: string = null;
            let imageId: string = null;
            let postId: string = null;
            let date: string = null;
            for (const propItem of post.blocksProps) {
                switch (propItem.propType) {
                    case BlockPropType.Title:
                        title = propItem.propString;
                        break;
                    case BlockPropType.Content:
                        description = propItem.propString;
                        break;
                    case BlockPropType.Image:
                        imageId = propItem.propString;
                        break;
                    case BlockPropType.ItemId:
                        postId = propItem.propString;
                        break;
                    case BlockPropType.Date:
                        date = propItem.propString;
                        break;
                }
            }

            statePosts.push({
                id: postId,
                title: title,
                description: description,
                imageId: imageId,
                publishDate: date,
            });
        }
        
        this.state = {
            isLoaded: statePosts.length > 0,
            isLoading: statePosts.length === 0,
            pageIndex: pageIndex,
            pageCount: allPagesCount,
            posts: statePosts,
        }
    }

    async componentDidMount() {

        if (this.state.isLoaded)
            return;

        const pointId = this.props.context.environment.getPointId();
        const newPosts: IPostShortDetails[] = await this.pagesInfoProvider.getPublicPosts(
            pointId,
            this.props.context.environment.getLanguage(),
            (this.state.pageIndex - 1) * 5, 5);

        const allPostsCount = await this.pagesInfoProvider.getPublicPostsCount(pointId);
        const allPagesCount = Math.ceil(allPostsCount.count / 5);

        this.setState({
            isLoaded: true,
            isLoading: false,
            pageIndex: this.state.pageIndex,
            pageCount: allPagesCount,
            posts: newPosts,
        });
    }

    generateStyle(imageId: string): Style {

        const imageUrl = this.props.context.environment.currentLocation.getImageUrl(imageId);

        const style = new Style();
        style.width = "200px";
        style.height = "200px";
        style.backgroundImage = "url(" + imageUrl + ")";
        style.backgroundRepeat = "no-repeat";
        style.backgroundSize = "contain";
        style.backgroundPosition = "center";

        return style;
    }

    render() {

        if (!this.state.isLoaded) {
            return null;
        }
        
        // design
        const designProperties = this.props.context.commonProperties.concat(this.props.context.targetBlock.blocksProps);
        const designModel = new PostFeedPageDesignModel();
        designModel.setDefaultValues(this.props.context);
        designModel.setCustomValues(designProperties);

        const backgroundStyle = designModel.generateBackgroundStyle(this.props.context);
        const contentStyle = designModel.generateContentStyle(this.props.context);
        const textStyle = designModel.generateTextStyle();
        const fontClassModificator = designModel.getFontClassModificator();
        const doodle = designModel.generateDoodle();
        const hashNavigation = designModel.generateHashNavigationName();
        const effectAppearName = designModel.generateEffectAppear();
        const separatorType = designModel.getSeparator();

        const pageLocation = this.props.context.environment.currentLocation.getPageLocation();
        
        return (
            <div id={hashNavigation} style={backgroundStyle}>
                {
                    doodle != null &&
                    <DoodleWrapper rule={doodle} />
                }
                <div style={contentStyle}>
                    {
                        this.props.context.environment.getIsPreview() && this.state.posts.length === 0 &&
                        <div className="text-secondary">Blog nie ma żadnych artykułów</div>
                    }
                    {
                        this.state.posts.map((x, index) => {

                            const imageUrl = this.props.context.environment.currentLocation.getImageUrl(x.imageId) ??
                                this.props.context.environment.currentLocation.getLink("/defaultImage.png", null);

                            const reversePosition = index % 2 === 1;
                            const fisrtColumnClass = reversePosition ? "two-columns-first-column-reversed" : "two-columns-first-column";
                            const secondColumnClass = reversePosition ? "two-columns-second-column-reversed" : "two-columns-second-column";

                            return (
                                <div key={`post_preview-${this.id}-${x.id}`} className="two-columns gap-3 mb-3 grid-dense">

                                    <div className={"height-to-weight " + fisrtColumnClass}>
                                        <Figure.Image className="h-100 w-100" style={{ objectFit: "contain" }} src={imageUrl} />
                                    </div>

                                    <div className={"d-flex flex-column gap-2 my-auto " + secondColumnClass}>
                                        <h3 className={`lp-text-subtitle ${fontClassModificator}`} style={textStyle}>{x.title}</h3>

                                        {
                                            x.publishDate != null &&
                                            <div className={`lp-text-date ${fontClassModificator}`} style={textStyle}>{new Date(
                                                x.publishDate).toLocaleString()}</div>
                                        }

                                        {
                                            x.description != null &&
                                            <div className={`lp-text ${fontClassModificator}`} style={textStyle}>{x.description}</div>
                                        }

                                        <a href={"/blog/" + x.id}>Szczegóły</a>
                                    </div>

                                </div>);
                        })
                    }
                    {
                        this.state.pageCount > 1 &&
                        <Pagination className="mx-auto">

                            {
                                this.state.pageIndex > 3 &&
                                <React.Fragment>
                                    <Pagination.First href={pageLocation + "?page=1"} style={textStyle} />
                                    <Pagination.Ellipsis style={textStyle} />
                                </React.Fragment>
                            }

                            {
                                this.state.pageIndex > 2 &&
                                <Pagination.Item style={textStyle} href={pageLocation + "?page=" + (this.state.pageIndex - 2)}>{this.state.pageIndex - 2}</Pagination.Item>
                            }

                            {
                                this.state.pageIndex > 1 &&
                                <Pagination.Item style={textStyle} href={pageLocation + "?page=" + (this.state.pageIndex - 1)}>{this.state.pageIndex - 1}</Pagination.Item>
                            }

                            <Pagination.Item active>{this.state.pageIndex}</Pagination.Item>

                            {
                                this.state.pageIndex < this.state.pageCount &&
                                <Pagination.Item style={textStyle} href={pageLocation + "?page=" + (this.state.pageIndex + 1)}>{this.state.pageIndex + 1}</Pagination.Item>
                            }

                            {
                                this.state.pageIndex < (this.state.pageCount - 1) &&
                                <Pagination.Item style={textStyle} href={pageLocation + "?page=" + (this.state.pageIndex + 2)}>{this.state.pageIndex + 2}</Pagination.Item>
                            }

                            {
                                this.state.pageIndex < (this.state.pageCount - 2) &&
                                <React.Fragment>
                                    <Pagination.Ellipsis style={textStyle} />
                                    <Pagination.Last style={textStyle} href={pageLocation + "?page=" + this.state.pageCount} />
                                </React.Fragment>
                            }

                        </Pagination>
                    }
                    
                </div>
                {
                    separatorType != null &&
                    <SeparatorContainer context={this.props.context} separatorType={separatorType} />
                }
            </div>
        );
    }
}