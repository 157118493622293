import { IPageBlock, IPageRenderedBlock, IPageBlockProperty, IPageRenderedBlockProperty } from "../../models/pageLayoutModel";
import { BlockPropType } from "../../models/blockType";
import CommonDesignModel from "./commonDesignModel";
import { IBlockModel } from "../modelsHelper";
import RenderBlockContext from "../../controls/renders/renderBlockContext";
import { WebpageStyleType } from "../../controls/renders/pointPageEnvironment";

export class TimelinePointsContentModel implements IBlockModel {

    private blocks: IPageRenderedBlock[];

    getBlocks(): IPageRenderedBlock[] { return this.blocks; }

    initialize(properties: IPageRenderedBlockProperty[]) {

        let blockCollection: IPageRenderedBlockProperty = null;

        for (let index = 0; index < properties.length; index++) {

            const property = properties[index];

            switch (property.propType) {
                case BlockPropType.Collection:
                    blockCollection = property;
                    break;
                default:
                    break;
            }
        }

        this.blocks = blockCollection?.propChildren ?? new Array<IPageRenderedBlock>();
    }

    formatBlock(properties: IPageBlockProperty[]): IPageBlockProperty[] {

        let blocks: IPageBlockProperty = null;

        for (let index = 0; index < properties.length; index++) {

            const property = properties[index];

            switch (property.propType) {
            case BlockPropType.Collection:
                blocks = property;
                break;
            default:
                break;
            }
        }

        if (blocks == null) {
            blocks = { propType: BlockPropType.Collection };
        }

        if (blocks.propChildren == null) {
            blocks.propChildren = new Array<IPageBlock>();
        }

        return [blocks];
    }
}

export type ThemeTimelinePoints = "smallNumberLeft" | "smallNumberCenter" | "largeNumbersLeft";

export class TimelinePointsDesignModel extends CommonDesignModel {

    private defaultTheme: ThemeTimelinePoints;
    private theme: ThemeTimelinePoints;

    constructor() {
        super();

        this.allowBackground = true;
        this.allowContentWidth = true;
        this.allowContentMargin = true;
        this.allowBlockHieghtAndMargin = true;
        this.allowHashNavigation = true;
        this.allowHorizontalAlignment = true;
        this.allowVerticalAlignment = true;
        this.allowTextAndButtonsAlignment = true;
        this.allowTextStyle = true;
        this.allowAppearEffect = true;
        this.allowSeparator = true;
    }

    public setDefaultValues(renderContext: RenderBlockContext) {
        super.setDefaultValues(renderContext);

        if (renderContext == null) {
            this.defaultTheme = "smallNumberLeft";
            return;
        }

        switch (renderContext.environment.getWebpageStyle()) {
            case WebpageStyleType.Classic:
                this.defaultTheme = "smallNumberLeft";
                break;
            case WebpageStyleType.Modern:
                this.defaultTheme = "smallNumberCenter";
                break;
            default:
                this.defaultTheme = "smallNumberLeft";
                break;
        }
    }

    public setCustomValues(properties: IPageRenderedBlockProperty[]) {
        super.setCustomValues(properties);

        this.theme = null;
        for (let index = 0; index < properties.length; index++) {

            const property = properties[index];
            if (property == null) {
                continue;
            }

            switch (property.propType) {
                case BlockPropType.ThemeTimelinePoints:
                    {
                        const currentTheme = property.propString as ThemeTimelinePoints;
                        if (currentTheme != null) {
                            this.theme = currentTheme;
                        }
                        break;
                    }
            }
        }
    }

    public getTheme(): ThemeTimelinePoints {
        return this.theme ?? this.defaultTheme;
    }

    public formatBlock(properties: IPageRenderedBlockProperty[]): IPageBlockProperty[] {

        const result = super.formatBlock(properties);

        let theme: IPageBlockProperty = null;
        for (const item of properties) {

            if (item != null && item.propType === BlockPropType.ThemeTimelinePoints) {
                theme = { propType: item.propType, propString: item.propString };
                break;
            }
        }

        if (theme == null) {
            theme = { propType: BlockPropType.ThemeTimelinePoints };
        }

        result.push(theme);

        return result;
    }
}