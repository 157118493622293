import { IPageBlockProperty, IPageRenderedBlockProperty } from "../../models/pageLayoutModel";
import { BlockPropType } from "../../models/blockType";
import CommonDesignModel from "./commonDesignModel";
import { IBlockModel } from "../modelsHelper";
import RenderBlockContext from "../../controls/renders/renderBlockContext";

export class FaqItemContentModel implements IBlockModel {

    private title: string;
    private content: string;

    getTitle(): string { return this.title; }
    getContent(): string { return this.content; }

    initialize(properties: IPageRenderedBlockProperty[]) {

        let title: IPageRenderedBlockProperty = null;
        let content: IPageRenderedBlockProperty = null;

        for (let index = 0; index < properties.length; index++) {

            const property = properties[index];

            switch (property.propType) {
                case BlockPropType.Title:
                    title = property;
                    break;
                case BlockPropType.Content:
                    content = property;
                    break;
                default:
                    break;
            }
        }

        this.title = title?.propString;
        this.content = content?.propString;
    }

    formatBlock(properties: IPageBlockProperty[]): IPageBlockProperty[] {

        let title: IPageBlockProperty = null;
        let content: IPageBlockProperty = null;

        for (let index = 0; index < properties.length; index++) {

            const property = properties[index];

            switch (property.propType) {
            case BlockPropType.Title:
                title = property;
                break;
            case BlockPropType.Content:
                content = property;
                break;
            default:
                break;
            }
        }

        if (title == null) {
            title = { propType: BlockPropType.Title };
        }

        if (content == null) {
            content = { propType: BlockPropType.Content };
        }

        return [title, content];
    }
}

export class FaqItemDesignModel extends CommonDesignModel {

    constructor() {
        super();

        this.allowBackground = false;
        this.allowContentWidth = false;
        this.allowContentMargin = false;
        this.allowBlockHieghtAndMargin = false;
        this.allowHashNavigation = false;
        this.allowHorizontalAlignment = false;
        this.allowVerticalAlignment = false;
        this.allowDoodle = false;
        this.allowTextStyle = true;
        this.allowAppearEffect = false;
    }
}