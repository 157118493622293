import { IPageBlockProperty, IPageRenderedBlockProperty } from "../../models/pageLayoutModel";
import { BlockPropType } from "../../models/blockType";
import CommonDesignModel from "./commonDesignModel";
import { IBlockModel } from "../modelsHelper";
import RenderBlockContext from "../../controls/renders/renderBlockContext";

export class TextPlainContentModel implements IBlockModel {

    private title: string;
    private subtitle: string;
    private uppertitle: string;
    private content: string;

    getTitle(): string { return this.title; }
    getSubtitle(): string { return this.subtitle; }
    getUppertitle(): string { return this.uppertitle; }
    getContent(): string { return this.content; }

    initialize(properties: IPageRenderedBlockProperty[]) {

        let title: IPageRenderedBlockProperty = null;
        let subtitle: IPageRenderedBlockProperty = null;
        let uppertitle: IPageRenderedBlockProperty = null;
        let content: IPageRenderedBlockProperty = null;
        for (let index = 0; index < properties.length; index++) {

            const property = properties[index];

            switch (property.propType) {
                case BlockPropType.Title:
                    title = property;
                    break;
                case BlockPropType.Subtitle:
                    subtitle = property;
                    break;
                case BlockPropType.Uppertitle:
                    uppertitle = property;
                    break;
                case BlockPropType.Content:
                    content = property;
                    break;
                default:
                    break;
            }
        }

        this.title = title?.propString;
        this.subtitle = subtitle?.propString;
        this.uppertitle = uppertitle?.propString;
        this.content = content?.propString;
    }

    formatBlock(properties: IPageBlockProperty[]): IPageBlockProperty[] {

        let title: IPageBlockProperty = null;
        let subtitle: IPageBlockProperty = null;
        let uppertitle: IPageBlockProperty = null;
        let content: IPageBlockProperty = null;

        for (let index = 0; index < properties.length; index++) {

            const property = properties[index];

            switch (property.propType) {
            case BlockPropType.Title:
                title = property;
                break;
            case BlockPropType.Subtitle:
                subtitle = property;
                break;
            case BlockPropType.Uppertitle:
                uppertitle = property;
                break;
            case BlockPropType.Content:
                content = property;
                break;
            default:
                break;
            }
        }

        if (title == null) {
            title = { propType: BlockPropType.Title };
        }

        if (subtitle == null) {
            subtitle = { propType: BlockPropType.Subtitle };
        }

        if (uppertitle == null) {
            uppertitle = { propType: BlockPropType.Uppertitle };
        }

        if (content == null) {
            content = { propType: BlockPropType.Content };
        }

        return [title, subtitle, uppertitle, content];
    }
}

export class TextPlainDesignModel extends CommonDesignModel {

    constructor() {
        super();

        this.allowBackground = true;
        this.allowContentWidth = true;
        this.allowContentMargin = true;
        this.allowBlockHieghtAndMargin = true;
        this.allowHashNavigation = true;
        this.allowHorizontalAlignment = true;
        this.allowVerticalAlignment = true;
        this.allowTextAndButtonsAlignment = true;
        this.allowTextStyle = true;
        this.allowAppearEffect = true;
        this.allowSeparator = true;
    }
}