import { BlockPropType, BlockType } from "./blockType";
import { IPageRenderedBlock } from "./pageLayoutModel";

export enum BlockVisibility {
    Visible = 0,
    Hidden = 1,
}

export function isLayout(blockType: BlockType): boolean {
    return blockType === BlockType.LayoutStack ||
        blockType === BlockType.LayoutTwoColumns ||
        blockType === BlockType.LayoutTwoColumnsAndHeader ||
        blockType === BlockType.LayoutWithSingleChild ||
        blockType === BlockType.LayoutCarousel;
}

export function isNavbar(blockType: BlockType): boolean {
    return blockType === BlockType.DefaultNavbar;
}

export function isFooter(blockType: BlockType): boolean {
    return blockType === BlockType.FooterPlainWithContacts ||
        blockType === BlockType.FooterWithNavigationAndContacts ||
        blockType === BlockType.FooterWithoutContacts;
}

export function needInvertBlocks(block1: IPageRenderedBlock, block2: IPageRenderedBlock): boolean {
    if (block1 == null || block2 == null)
        return true;

    switch (block1.blocksType) {
        case BlockType.ImageSimple:
        case BlockType.GalleryPolaroid:
        case BlockType.GalleryPreview:
        case BlockType.GallerySimple:
        case BlockType.Map:
            return true;
    }

    return false;
}

export function getBlockString(block: IPageRenderedBlock, propType: BlockPropType): string {

    if (block == null || block.blocksProps == null || block.blocksProps.length === 0)
        return null;

    for (let index = 0; index < block.blocksProps.length; index++) {

        const item = block.blocksProps[index];
        if (item != null && item.propType === propType) {
            return item.propString;
        }
    }

    return null;
}

export function isContentProperty(propertyType: BlockPropType): boolean {

    switch (propertyType) {
        case BlockPropType.Uppertitle:
        case BlockPropType.Subtitle:
        case BlockPropType.Title:
        case BlockPropType.Content:
        case BlockPropType.Html:
        case BlockPropType.RegularText:
        case BlockPropType.Image:
        case BlockPropType.ImageDescription:
        case BlockPropType.Link:
        case BlockPropType.LinkText:
        case BlockPropType.Author:
        case BlockPropType.Address:
        case BlockPropType.ShowTitle:
        case BlockPropType.ItemId:
        case BlockPropType.Collection:
            return true;
        default:
            return false;
    }
}

export function getColor(index: number): string {

    switch (index % 5) {
        case 0: return "lightgreen";
        case 1: return "deepskyblue";
        case 2: return "darkorange";
        case 3: return "blueviolet";
        case 4: return "hotpink";
    }

    return "white";
}

export function getBlockTitle(parentBlockType: BlockType, blockType: BlockType, blockIndex: number): string {

    if (blockType != null) {
        switch (blockType) {
            case BlockType.TextPlain:
                return "Tekst";
            case BlockType.CoverTitleSubtitle:
                return "Tytuł strony";
            case BlockType.QuoteAuthor:
                return "Cytat";
            case BlockType.ImageSimple:
                return "Obraz";
            case BlockType.FooterWithoutContacts:
            case BlockType.FooterPlainWithContacts:
            case BlockType.FooterWithNavigationAndContacts:
                return "Stopka";
            case BlockType.ContactsSimple:
            case BlockType.ContactsInIcons:
                return "Kontakt";
            case BlockType.ExternalFrame:
                return "Html";
            case BlockType.YoutubeFrame:
                return "Youtube";
            case BlockType.DefaultNavbar:
            case BlockType.MenuAnchorsList:
                return "Menu";
            case BlockType.Map:
                return "Mapa";
            case BlockType.GalleryPolaroid:
            case BlockType.GalleryPreview:
            case BlockType.GallerySimple:
                return "Obrazy";
            case BlockType.Buttons:
            case BlockType.ButtonsVertical:
            case BlockType.ButtonsVerticalWithContacts:
                return "Przyciski";
            case BlockType.Links:
                return "Linki";
            case BlockType.SimpleCards:
            case BlockType.CardsSmall:
                return "Karty";
            case BlockType.Numbers:
                return "Liczby";
            case BlockType.LayoutStack:
            case BlockType.LayoutTwoColumns:
            case BlockType.LayoutTwoColumnsAndHeader:
            case BlockType.LayoutWithSingleChild:
            case BlockType.LayoutCarousel:
                return "Blok układu";
            case BlockType.SeparatorEmpty:
            case BlockType.SeparatorLineSimple:
            case BlockType.SeparatorLineWithText:
                return "Separator";
            case BlockType.FeaturesWithHeaderInColumn:
            case BlockType.FeaturesSimpleInColumn:
            case BlockType.FeaturesSimpleInTwoColumns:
            case BlockType.TimelinePoints:
                return "Lista";
            case BlockType.Faq:
                return "Pytania";
            case BlockType.PostFeed:
            case BlockType.PostFeedInColumns:
            case BlockType.PostFeedPage:
                return "Artykuły na blogu";
        }
    }

    let elementName: string = "Element";
    switch (parentBlockType) {
        case BlockType.FeaturesWithHeaderInColumn:
        case BlockType.FeaturesSimpleInColumn:
        case BlockType.FeaturesSimpleInTwoColumns:
        case BlockType.CardItem:
            elementName = "Element listy";
            break;
        case BlockType.SimpleCards:
        case BlockType.CardsSmall:
            elementName = "Karta";
            break;
        case BlockType.Numbers:
            elementName = "Liczba";
            break;
        case BlockType.TimelinePoints:
            elementName = "Krok";
            break;
        case BlockType.GallerySimple:
        case BlockType.GalleryPreview:
        case BlockType.GalleryPolaroid:
            elementName = "Obraz";
            break;
        case BlockType.LayoutTwoColumns:
            return blockIndex === 0 ? "Lewa strona" : "Prawa strona";
        case BlockType.LayoutStack:
            elementName = "Rząd";
            break;
        case BlockType.Buttons:
        case BlockType.ButtonsVertical:
        case BlockType.ButtonsVerticalWithContacts:
            elementName = "Przycisk";
            break;
        case BlockType.Links:
            elementName = "Link";
            break;
        case BlockType.LayoutTwoColumnsAndHeader:
            return blockIndex === 0 ? "Górny rząd" : blockIndex === 1 ? "Lewa strona" : "Prawa strona";
        case BlockType.Faq:
            elementName = "Pytanie";
            break;
        case BlockType.DefaultNavbar:
        case BlockType.MenuAnchorsList:
            elementName = "Element menu";
            break;
    }

    return blockIndex != null ? `${elementName} #${blockIndex + 1}` : elementName;
}

export function getBlockColor(parentBlockType: BlockType, blockType: BlockType): string {

    if (blockType != null)
        switch (blockType) {
            case BlockType.TextPlain:
                return "#51600e";
            case BlockType.CoverTitleSubtitle:
                return "#9013FE";
            case BlockType.QuoteAuthor:
                return "#23798e";
            case BlockType.ImageSimple:
            case BlockType.GalleryPolaroid:
            case BlockType.GalleryPreview:
            case BlockType.GallerySimple:
                return "#116f96";
            case BlockType.FooterWithoutContacts:
            case BlockType.FooterPlainWithContacts:
            case BlockType.FooterWithNavigationAndContacts:
                return "#522919";
            case BlockType.ContactsSimple:
            case BlockType.ContactsInIcons:
                return "#355255";
            case BlockType.YoutubeFrame:
                return "#7f0000";
            case BlockType.Map:
                return "#18948d";
            case BlockType.Buttons:
            case BlockType.ButtonsVertical:
            case BlockType.ButtonsVerticalWithContacts:
            case BlockType.DefaultNavbar:
            case BlockType.MenuAnchorsList:
            case BlockType.Links:
                return "#102a4e";
            case BlockType.SimpleCards:
            case BlockType.CardsSmall:
            case BlockType.FeaturesWithHeaderInColumn:
            case BlockType.FeaturesSimpleInColumn:
            case BlockType.FeaturesSimpleInTwoColumns:
            case BlockType.TimelinePoints:
            case BlockType.Faq:
            case BlockType.PostFeed:
            case BlockType.PostFeedInColumns:
            case BlockType.PostFeedPage:
            case BlockType.Numbers:
                return "#a34404";
            case BlockType.SeparatorEmpty:
            case BlockType.SeparatorLineSimple:
            case BlockType.SeparatorLineWithText:
                return "#a67700";
        }

        switch (parentBlockType) {
            case BlockType.FeaturesWithHeaderInColumn:
            case BlockType.FeaturesSimpleInColumn:
            case BlockType.FeaturesSimpleInTwoColumns:
            case BlockType.CardItem:
            case BlockType.SimpleCards:
            case BlockType.CardsSmall:
            case BlockType.TimelinePoints:
            case BlockType.Faq:
            case BlockType.Numbers:
            case BlockType.NumbersItem:
                return "#a34404";
            case BlockType.GallerySimple:
            case BlockType.GalleryPreview:
            case BlockType.GalleryPolaroid:
                return "#116f96";
            case BlockType.Buttons:
            case BlockType.ButtonsVertical:
            case BlockType.ButtonsVerticalWithContacts:
            case BlockType.DefaultNavbar:
            case BlockType.MenuAnchorsList:
            case BlockType.Links:
                return "#102a4e";
        }

    return "#6C757D";
}