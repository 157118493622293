import React from 'react';

import { QuoteAuthorContentModel, QuoteAuthorDesignModel } from "../../models/blocks/quoteAuthorModel";
import DoodleWrapper from "../common/doodleWrapper";
import BlockComponent from './blockComponent';
import TransactionContainer from '../effects/transactionContainer';
import SeparatorContainer from '../separators/separatorContainer';

export default class EditorQuoteAuthor extends BlockComponent {

    render() {

        // content
        const block = new QuoteAuthorContentModel();
        block.initialize(this.props.context.targetBlock.blocksProps);

        const content = block.getContent();
        const author = block.getAuthor();

        // design
        const designProperties = this.props.context.commonProperties.concat(this.props.context.targetBlock.blocksProps);
        const designModel = new QuoteAuthorDesignModel();
        designModel.setDefaultValues(this.props.context);
        designModel.setCustomValues(designProperties);

        const backgroundStyle = designModel.generateBackgroundStyle(this.props.context);
        const contentStyle = designModel.generateContentStyle(this.props.context);
        const textStyle = designModel.generateTextStyle();
        const fontClassModificator = designModel.getFontClassModificator();
        const doodle = designModel.generateDoodle();
        const hashNavigation = designModel.generateHashNavigationName();
        const effectAppearName = designModel.generateEffectAppear();
        const separatorType = designModel.getSeparator();

        return (
            <div id={hashNavigation} style={backgroundStyle}>
                {
                    doodle != null &&
                    <DoodleWrapper rule={doodle} />
                }
                <TransactionContainer style={contentStyle} effect={effectAppearName}>
                    <div className="d-flex flex-column gap-2 w-100">
                        <div className="text-center display-4 bi bi-quote last-without-margin" style={textStyle} />
                        <div className={`lp-text last-without-margin ${fontClassModificator}`} style={textStyle} dangerouslySetInnerHTML={{ __html: content }} />

                        {
                            author != null && author.length > 0 &&
                            <div className={`lp-text-subtitle last-without-margin ${fontClassModificator}`} style={textStyle} dangerouslySetInnerHTML={{ __html: author }} />
                        }
                    </div>
                </TransactionContainer>
                {
                    separatorType != null &&
                    <SeparatorContainer context={this.props.context} separatorType={separatorType} />
                }
            </div>
        );
    }
}