import { IPageBlockProperty, IPageRenderedBlockProperty } from "../../models/pageLayoutModel";
import { BlockPropType } from "../../models/blockType";
import CommonDesignModel from "./commonDesignModel";
import { IBlockModel } from "../modelsHelper";
import RenderBlockContext from "../../controls/renders/renderBlockContext";
import { WebpageStyleType } from "../../controls/renders/pointPageEnvironment";
import Style from "../style";

export class FooterWithNavigationAndContactsContentModel implements IBlockModel {

    private subtitle: string;
    private content: string;

    getContent(): string { return this.content; }
    getSubtitle(): string { return this.subtitle; }

    initialize(properties: IPageRenderedBlockProperty[]) {

        let subtitle: IPageRenderedBlockProperty = null;
        let content: IPageRenderedBlockProperty = null;
        for (let index = 0; index < properties.length; index++) {

            const property = properties[index];

            switch (property.propType) {
                case BlockPropType.Content:
                    content = property;
                    break;
                case BlockPropType.Subtitle:
                    subtitle = property;
                    break;
                default:
                    break;
            }
        }

        this.subtitle = subtitle?.propString;
        this.content = content?.propString;
    }

    formatBlock(properties: IPageBlockProperty[]): IPageBlockProperty[] {

        let content: IPageBlockProperty = null;
        let subtitle: IPageBlockProperty = null;

        for (let index = 0; index < properties.length; index++) {

            const property = properties[index];

            switch (property.propType) {
            case BlockPropType.Content:
                content = property;
                break;
            case BlockPropType.Subtitle:
                subtitle = property;
                break;
            default:
                break;
            }
        }

        if (subtitle == null) {
            subtitle = { propType: BlockPropType.Subtitle };
        }

        if (content == null) {
            content = { propType: BlockPropType.Content };
        }

        return [subtitle, content];
    }
}

export class FooterWithNavigationAndContactsDesignModel extends CommonDesignModel {

    constructor() {
        super();

        this.allowBackground = true;
        this.allowContentWidth = true;
        this.allowContentMargin = true;
        this.allowBlockHieghtAndMargin = false;
        this.allowHashNavigation = false;
        this.allowHorizontalAlignment = false;
        this.allowVerticalAlignment = false;
        this.allowDoodle = false;
        this.allowTextStyle = true;
        this.allowAppearEffect = false;
    }

    public setDefaultValues(renderContext: RenderBlockContext) {
        super.setDefaultValues(renderContext);

        if (renderContext != null && renderContext.environment.getWebpageStyle() === WebpageStyleType.Classic)
            this.defaultWidth = "small";
        else
            this.defaultWidth = "large";

        this.defaultContentMarginBottom = "none";
        this.defaultContentMarginTop = "none";
        this.defaultHorizontalAlignment = "fill";
    }
}