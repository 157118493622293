import React from 'react';

import { GalleryPreviewContentModel, GalleryPreviewDesignModel } from "../../models/blocks/galleryPreviewModel";
import { BlockType } from "../../models/blockType";
import BlockRender from "../renders/blockRender";
import genUniqueId from "../../models/utils/genUniqueId";
import 'photoswipe/dist/photoswipe.css'
import { Gallery } from 'react-photoswipe-gallery'
import DoodleWrapper from "../common/doodleWrapper";
import BlockComponent from './blockComponent';
import TransactionContainer from '../effects/transactionContainer';
import RenderBlockContext from "../renders/renderBlockContext";
import SeparatorContainer from '../separators/separatorContainer';

export default class GalleryPreview extends BlockComponent {

    private id: string = genUniqueId();

    render() {

        // content
        const contentModel = new GalleryPreviewContentModel();
        contentModel.initialize(this.props.context.targetBlock.blocksProps);

        const images = contentModel.getImages();

        // design
        const designProperties = this.props.context.commonProperties.concat(this.props.context.targetBlock.blocksProps);
        const designModel = new GalleryPreviewDesignModel();
        designModel.setDefaultValues(this.props.context);
        designModel.setCustomValues(designProperties);

        const backgroundStyle = designModel.generateBackgroundStyle(this.props.context);
        const contentStyle = designModel.generateContentStyle(this.props.context);
        const doodle = designModel.generateDoodle();
        const hashNavigation = designModel.generateHashNavigationName();
        const childCommonProps = designModel.formatCommonProps(designProperties);
        const effectAppearName = designModel.generateEffectAppear();
        const separatorType = designModel.getSeparator();

        const resolvedContentWidth = designModel.getResolvedContentWidth();
        const resolvedBackgroundColor = designModel.getActiveBackgroundColor();

        return (
            <div id={hashNavigation} style={backgroundStyle}>
                {
                    doodle != null &&
                    <DoodleWrapper rule={doodle} />
                }
                <TransactionContainer style={contentStyle} effect={effectAppearName}>
                    <Gallery>
                        {
                            images.map((image, index) => {

                                const context: RenderBlockContext =
                                {
                                    environment: this.props.context.environment,

                                    prevBlock: null,
                                    nextBlock: null,
                                    parentBlock: this.props.context,

                                    parentBlockWidth: resolvedContentWidth,
                                    parentBlockColor: resolvedBackgroundColor,
                                    parentBlockAnimation: effectAppearName,

                                    rootSectionType: this.props.context.rootSectionType,

                                    targetBlock: image,
                                    targetBlockType: BlockType.GalleryPreviewItem,
                                    targetColorSchema: this.props.context.targetColorSchema,
                                    targetHasBackgroundImage: false,

                                    commonProperties: childCommonProps,
                                };

                                return <BlockRender key={`feature-${this.id}-${index}`} context={context} />;
                            })
                        }
                    </Gallery>
                </TransactionContainer>

                {
                    separatorType != null &&
                    <SeparatorContainer context={this.props.context} separatorType={separatorType} />
                }

            </div>
        );
    }
}