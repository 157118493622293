import PointPageEnvironment from "./pointPageEnvironment";
import { IPageRenderedBlock, IPageRenderedBlockProperty } from "../../models/pageLayoutModel";
import { BlockType } from "../../models/blockType";
import SmlType from "../../models/utils/sml";
import { ColorSchema } from "./pointPageEnvironment";
import { ColorWithDescription } from "../../utils/colorUtils";
import { EffectAppearType } from "../../models/blocks/commonDesignModel";

export default class RenderBlockContext {

    environment: PointPageEnvironment;

    prevBlock: RenderBlockContext;
    nextBlock: RenderBlockContext;
    parentBlock: RenderBlockContext;

    parentBlockWidth: SmlType;
    parentBlockColor: ColorWithDescription;
    parentBlockAnimation: EffectAppearType;

    rootSectionType: SectionType;

    targetBlock: IPageRenderedBlock;
    targetBlockType: BlockType;
    targetColorSchema: ColorSchema;
    targetHasBackgroundImage: boolean;

    commonProperties: IPageRenderedBlockProperty[];
}

export enum SectionType {
    Unknown,
    Navbar,
    Title,
    Intro,
    Regular,
    LastWord,
    Footer,
}