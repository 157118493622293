import { IPageBlockProperty, IPageRenderedBlockProperty } from "../../models/pageLayoutModel";
import { BlockPropType } from "../../models/blockType";
import CommonDesignModel from "./commonDesignModel";
import { IBlockModel } from "../modelsHelper";
import RenderBlockContext from "../../controls/renders/renderBlockContext";

export class YoutubeSimpleContentModel implements IBlockModel {

    private itemId: string;

    getItemId(): string { return this.itemId; }

    initialize(properties: IPageRenderedBlockProperty[]) {

        let itemId: IPageRenderedBlockProperty = null;
        for (let index = 0; index < properties.length; index++) {

            const property = properties[index];

            switch (property.propType) {
                case BlockPropType.ItemId:
                    itemId = property;
                    break;
                default:
                    break;
            }
        }

        this.itemId = itemId?.propString;
    }

    formatBlock(properties: IPageBlockProperty[]): IPageBlockProperty[] {

        let itemId: IPageBlockProperty = null;

        for (let index = 0; index < properties.length; index++) {

            const property = properties[index];

            switch (property.propType) {
            case BlockPropType.ItemId:
                itemId = property;
                break;
            default:
                break;
            }
        }

        if (itemId == null) {
            itemId = { propType: BlockPropType.ItemId };
        }

        return [itemId];
    }
}

export class YoutubeSimpleDesignModel extends CommonDesignModel {

    constructor() {
        super();

        this.allowBackground = true;
        this.allowContentWidth = true;
        this.allowContentMargin = true;
        this.allowBlockHieghtAndMargin = true;
        this.allowHashNavigation = true;
        this.allowHorizontalAlignment = true;
        this.allowVerticalAlignment = true;
        this.allowTextStyle = false;
        this.allowAppearEffect = true;
        this.allowSeparator = true;
    }
}