import React from 'react';
import ReactDOM from 'react-dom';
import "reflect-metadata";

import RenderBlockContext from "../renders/renderBlockContext";

interface IEditorProps {
    context: RenderBlockContext,
}

export default class BlockComponent<TState = {}> extends React.Component<IEditorProps, TState> {
}