import React from 'react';
import { Container, Row } from "react-bootstrap";

import NavMenu from '../controls/navMenu';
import ComponentBase from '../pages/common/componentBase';

class DefaultLayoutProps {
    children?: React.ReactNode
}

export default class DefaultLayout extends ComponentBase<DefaultLayoutProps, any> {

    constructor(prop: any) {
        super(prop);
    }

    render() {
        return (
            <Container fluid className="min-vh-100 d-flex flex-column">
                <Row>
                    <NavMenu />
                </Row>
                <Row fluid className="flex-fill">
                    {this.props.children}
                </Row>
            </Container>
        );
    }
}