import React from 'react';

import "reflect-metadata";
import { resolve } from 'inversify-react';
import settings from "../configuration/config.json";

import { Button, Nav, NavDropdown, Figure, Stack } from "react-bootstrap";

import UserInfo from "../models/userInfo"
import * as Logger from "../utils/logger";
import { AccountInfoProvider } from "../models/accountInfoProvider";
import { CurrentLocationProvider } from "../models/currentLocationProvider";

interface IAccountMenuState {
    isLoginIn: boolean,
    name: string|null,
    image: string|null,
}

export default class AccountMenu extends React.Component<any, IAccountMenuState> {

    @resolve(UserInfo)
    private readonly user: UserInfo; 

    @resolve(AccountInfoProvider)
    private readonly accountProvider: AccountInfoProvider;

    @resolve(CurrentLocationProvider)
    private readonly currentLocationProvider: CurrentLocationProvider;

    constructor(props: any) {
        super(props);

        this.state = {
            isLoginIn: false,
            name: null,
            image: null,
        }

        this.onLogin = this.onLogin.bind(this);
        this.onLogout = this.onLogout.bind(this);
    }
    
    componentDidMount(): void {

        this.generateStartState()
            .then(x => this.setState(x))
            .catch((error: Error) => Logger.Core.error("Cannot receive some details", error));
    }

    async generateStartState(): Promise<IAccountMenuState> {

        const isLoggedIn = this.user.getUserToken() != null;
        if (!isLoggedIn) {

            return {
                isLoginIn: false,
                name: null,
                image: null,
            };
        }

        const userName = await this.user.getUserNameAsync(this.accountProvider);
        const userImage = await this.user.getUserImageAsync(this.accountProvider);
        
        return {
            isLoginIn: true,
            name: userName,
            image: userImage,
        };
    }

    private onLogout() {
        this.user.clear();
        window.location.href = '/';
    }

    private onLogin() {

        let loginUrl = this.currentLocationProvider.getLink("/app/login", null);
        if (window.location.pathname != null && window.location.pathname !== '/') {
            loginUrl += '?backLink=' + window.location.href;
        }

        window.location.href = loginUrl;
    }

    public render() {

        if (!this.state.isLoginIn) {
            
            return (
                <React.Fragment>
                    <Button onClick={this.onLogin} className="me-2" variant="success">Zaloguj</Button>
                </React.Fragment>
            );
        }
        
        return (
            <React.Fragment>
                <NavDropdown id="nav-dropdown" align="end" className="dropdown-arrow-hide" title={
                    <Figure.Image className="my-0 mx-auto d-block" style={{width: "30px", height: "30px" }}
                        roundedCircle={true}
                        src={this.currentLocationProvider.getImageUrl(this.state.image) ?? "/emptyProfile.png"}
                    />
                } >

                    <NavDropdown.Item href={this.currentLocationProvider.getLink("/app/dashboard", null)}>Pulpit</NavDropdown.Item>
                    <NavDropdown.Item href={this.currentLocationProvider.getLink("/app/account", null)}>Konto</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item onClick={this.onLogout}>Wyloguj się</NavDropdown.Item>
                </NavDropdown>
            </React.Fragment>
        );
    }
}