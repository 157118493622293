import { IPageBlockProperty, IPageRenderedBlockProperty } from "../../models/pageLayoutModel";
import { BlockPropType } from "../../models/blockType";
import CommonDesignModel from "./commonDesignModel";
import { IBlockModel } from "../modelsHelper";
import Style from "../style";
import RenderBlockContext from "../../controls/renders/renderBlockContext";

export class GalleryItemContentModel implements IBlockModel {

    private imageId: string;
    private imageAltText: string;

    getImageId(): string { return this.imageId; }
    getImageAltText(): string { return this.imageAltText; }

    initialize(properties: IPageRenderedBlockProperty[]) {

        let image: IPageRenderedBlockProperty = null;
        let imageAltText: IPageRenderedBlockProperty = null;

        for (let index = 0; index < properties.length; index++) {

            const property = properties[index];

            switch (property.propType) {
            case BlockPropType.Image:
                image = property;
                break;
            case BlockPropType.ImageAltText:
                imageAltText = property;
                break;
            default:
                break;
            }
        }

        this.imageId = image?.propString;
        this.imageAltText = imageAltText?.propString;
    }

    formatBlock(properties: IPageBlockProperty[]): IPageBlockProperty[] {

        let image: IPageBlockProperty = null;
        let imageAltText: IPageBlockProperty = null;

        for (let index = 0; index < properties.length; index++) {

            const property = properties[index];

            switch (property.propType) {
            case BlockPropType.Image:
                image = property;
                break;
            case BlockPropType.ImageAltText:
                imageAltText = property;
                break;
            default:
                break;
            }
        }

        if (image == null) {
            image = { propType: BlockPropType.Image };
        }

        if (imageAltText == null) {
            imageAltText = { propType: BlockPropType.ImageAltText };
        }

        return [image, imageAltText];
    }
}

export class GalleryItemDesignModel extends CommonDesignModel {

    constructor() {
        super();

        this.allowBackground = false;
        this.allowContentWidth = false;
        this.allowContentMargin = false;
        this.allowBlockHieghtAndMargin = false;
        this.allowHashNavigation = false;
        this.allowHorizontalAlignment = false;
        this.allowVerticalAlignment = false;
        this.allowDoodle = false;
        this.allowTextStyle = true;
        this.allowAppearEffect = false;
    }
    
    generateBackgroundStyle(context: RenderBlockContext): Style {

        const result = super.generateBackgroundStyle(context);
        result.padding = "0";

        return result;
    }
}