import { IPageRenderedBlock, IPageBlockProperty, IPageBlock, IPageRenderedBlockProperty } from "../../models/pageLayoutModel";
import { isNavbar } from "../../models/blocksHelper";
import { BlockPropType } from "../../models/blockType";
import CommonDesignModel from "./commonDesignModel";
import { IBlockModel } from "../modelsHelper";
import Style from "../style";
import RenderBlockContext, { SectionType } from "../../controls/renders/renderBlockContext";
import { WebpageStyleType } from "../../controls/renders/pointPageEnvironment";

export class LayoutWithSingleChildContentModel implements IBlockModel {

    private navbar: IPageRenderedBlock;
    private block: IPageRenderedBlock;

    getNavbar(): IPageRenderedBlock { return this.navbar; }
    getInnerBlock(): IPageRenderedBlock { return this.block; }

    initialize(properties: IPageRenderedBlockProperty[]) {
        
        let blocks: IPageRenderedBlockProperty = null;

        for (let index = 0; index < properties.length; index++) {

            const property = properties[index];

            switch (property.propType) {
                case BlockPropType.Collection:
                    blocks = property;
                    break;
                default:
                    break;
            }
        }

        if (blocks != null && blocks.propChildren != null && blocks.propChildren.length > 0 && blocks.propChildren[0] != null && isNavbar(blocks.propChildren[0].blocksType)) {
            this.navbar = blocks.propChildren[0];
        } else {
            this.navbar = null;
        }

        const blockIndex = this.navbar == null ? 0 : 1;
        if (blocks != null && blocks.propChildren != null && blocks.propChildren.length > blockIndex) {
            this.block = blocks.propChildren[blockIndex];
        } else {
            this.block = null;
        }
    }

    formatBlock(properties: IPageBlockProperty[]): IPageBlockProperty[] {

        let blocks: IPageBlockProperty = null;

        for (let index = 0; index < properties.length; index++) {

            const property = properties[index];

            switch (property.propType) {
            case BlockPropType.Collection:
                blocks = property;
                break;
            default:
                break;
            }
        }

        if (blocks == null) {
            blocks = { propType: BlockPropType.Collection };
        }

        if (blocks.propChildren == null) {
            blocks.propChildren = new Array<IPageBlock>();
        }

        if (blocks.propChildren.length < 1) {
            blocks.propChildren.push(null);
        }

        const containsNavbar = blocks.propChildren.length > 0 && blocks.propChildren[0] != null && isNavbar(blocks.propChildren[0].blocksType);
        const requiredBlocksCount = containsNavbar ? 2 : 1;
        while (blocks.propChildren.length < requiredBlocksCount) {
            blocks.propChildren.push(null);
        }

        return [blocks];
    }
}

export class LayoutWithSingleChildDesignModel extends CommonDesignModel {

    constructor() {
        super();

        this.allowBackground = true;
        this.allowContentWidth = true;
        this.allowContentMargin = true;
        this.allowBlockHieghtAndMargin = true;
        this.allowHashNavigation = true;
        this.allowHorizontalAlignment = true;
        this.allowVerticalAlignment = true;
        this.allowTextAndButtonsAlignment = true;
        this.allowButtonsStyle = true;
        this.allowTextStyle = true;
        this.allowAppearEffect = true;
        this.allowSeparator = true;
    }

    public setDefaultValues(renderContext: RenderBlockContext) {
        super.setDefaultValues(renderContext);

        if (renderContext != null && renderContext.environment.getWebpageStyle() === WebpageStyleType.Classic)
            this.defaultWidth = "small";
        else
            this.defaultWidth = "max";
        
        this.defaultHorizontalAlignment = "middle";

        if (renderContext != null && renderContext.rootSectionType === SectionType.Title) {
            this.defaultTextAndButtonsAlignment = "middle";
        }
    }

    generateContentStyle(context: RenderBlockContext): Style {

        const result: Style = super.generateContentStyle(context);
        result.display = "grid";
        result.gridTemplateColumns = "1fr";
        result.gridTemplateRows = "1fr";

        return result;
    }
}