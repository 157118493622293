import { IPageBlockProperty, IPageRenderedBlockProperty } from "../../models/pageLayoutModel";
import CommonDesignModel from "./commonDesignModel";
import { IBlockModel } from "../modelsHelper";
import RenderBlockContext from "../../controls/renders/renderBlockContext";

export class ContactsSimpleContentModel implements IBlockModel {

    initialize(properties: IPageRenderedBlockProperty[]) {
         }

    formatBlock(properties: IPageBlockProperty[]): IPageBlockProperty[] {

        return [];
    }
}

export class ContactsSimpleDesignModel extends CommonDesignModel {

    constructor() {
        super();

        this.allowBackground = true;
        this.allowContentWidth = true;
        this.allowContentMargin = true;
        this.allowBlockHieghtAndMargin = true;
        this.allowHashNavigation = true;
        this.allowHorizontalAlignment = true;
        this.allowVerticalAlignment = true;
        this.allowAppearEffect = true;
        this.allowTextStyle = true;
        this.allowTextAndButtonsAlignment = true;
        this.allowSeparator = true;
    }
}