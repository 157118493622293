import React from 'react';

import { Figure } from "react-bootstrap";

import { GalleryItemContentModel, GalleryItemDesignModel } from "../../models/blocks/galleryItemModel";
import BlockComponent from './blockComponent';

export default class EditorGalleryItem extends BlockComponent {

    render() {

        // content
        const contentModel = new GalleryItemContentModel();
        contentModel.initialize(this.props.context.targetBlock.blocksProps);

        const imageId = contentModel.getImageId();
        const imageAltText = contentModel.getImageAltText();

        const hasImage = imageId != null && imageId.length > 0;

        // design
        const designProperties = this.props.context.commonProperties.concat(this.props.context.targetBlock.blocksProps);
        const designModel = new GalleryItemDesignModel();
        designModel.setDefaultValues(this.props.context);
        designModel.setCustomValues(designProperties);

        const backgroundStyle = designModel.generateBackgroundStyle(this.props.context);
        const contentStyle = designModel.generateContentStyle(this.props.context);
        const doodle = designModel.generateDoodle();
        const hashNavigation = designModel.generateHashNavigationName();
        const effectAppearName = designModel.generateEffectAppear();

        return (
            <React.Fragment>
                {
                    hasImage &&
                    <div className="gallery-image d-flex mx-auto">
                        <Figure.Image style={{ objectFit: "contain", maxWidth: "100%", maxHeight: "100%" }}
                            className="m-auto"
                            src={this.props.context.environment.currentLocation.getImageUrl(imageId)}
                            alt={imageAltText} />
                    </div>
                }
            </React.Fragment>
        );
    }
}