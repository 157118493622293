import React from 'react';

import DoodleWrapper from "../common/doodleWrapper";
import { ButtonsContentModel, ButtonsDesignModel } from "../../models/blocks/buttonsModel";
import BlockRender from '../renders/blockRender';
import { BlockPropType, BlockType } from "../../models/blockType";
import genUniqueId from "../../models/utils/genUniqueId";
import Style from "../../models/style";
import BlockComponent from './blockComponent';
import TransactionContainer from '../effects/transactionContainer';
import { IPageRenderedBlock } from "../../models/pageLayoutModel";
import RenderBlockContext from "../renders/renderBlockContext";
import SeparatorContainer from '../separators/separatorContainer';

export default class ButtonsVerticalWithContacts extends BlockComponent {

    private id: string = genUniqueId();

    render() {

        // content
        const contentModel = new ButtonsContentModel();
        contentModel.initialize(this.props.context.targetBlock.blocksProps);

        const blocks = contentModel.getBlocks();

        // design
        const designProperties = this.props.context.commonProperties
            .filter(x => x.propType !== BlockPropType.FontShadow && x.propType !== BlockPropType.FontBorderColor)
            .concat(this.props.context.targetBlock.blocksProps);

        const designModel = new ButtonsDesignModel();
        designModel.setDefaultValues(this.props.context);
        designModel.defaultTextAndButtonsAlignment = "middle";
        designModel.setCustomValues(designProperties);

        const backgroundStyle = designModel.generateBackgroundStyle(this.props.context);
        const contentStyle = designModel.generateContentStyle(this.props.context);
        const doodle = designModel.generateDoodle();
        const hashNavigation = designModel.generateHashNavigationName();
        const childCommonProps = designModel.formatCommonProps(designProperties);
        const effectAppearName = designModel.generateEffectAppear();
        const separatorType = designModel.getSeparator();

        let buttonsContainerStyle: Style = null;
        if (designModel.allowTextAndButtonsAlignment) {

            buttonsContainerStyle = new Style();
            switch (designModel.getButtonsHorizontalAlignment()) {
                case "left":
                    buttonsContainerStyle.margin = "auto auto auto 0";
                    break;
                case "right":
                    buttonsContainerStyle.margin = "auto 0 auto auto";
                    break;
                case "middle":
                    buttonsContainerStyle.margin = "auto";
                    break;
            }
        }

        const contacts = this.props.context.environment.getContacts();

        let phoneText: string;
        switch (this.props.context.environment.getLanguage().toUpperCase()) {
            case "PL":
            case "CZ":
                phoneText = "Telefon";
                break;
            case "RU":
                phoneText = "Телефон";
                break;
            default:
                phoneText = "Telephone";
                break;
        }

        const resolvedContentWidth = designModel.getResolvedContentWidth();
        const resolvedBackgroundColor = designModel.getActiveBackgroundColor();

        const generateButtonContext = (targetBlock: IPageRenderedBlock): RenderBlockContext => {

            const context: RenderBlockContext =
            {
                environment: this.props.context.environment,

                prevBlock: null,
                nextBlock: null,
                parentBlock: this.props.context,

                parentBlockWidth: resolvedContentWidth,
                parentBlockColor: resolvedBackgroundColor,
                parentBlockAnimation: effectAppearName,

                rootSectionType: this.props.context.rootSectionType,

                targetBlock: targetBlock,
                targetBlockType: BlockType.ButtonsItem,
                targetColorSchema: this.props.context.targetColorSchema,
                targetHasBackgroundImage: false,

                commonProperties: childCommonProps,
            };

            return context;
        }

        return (
            <div id={hashNavigation} style={backgroundStyle}>
                {
                    doodle != null &&
                    <DoodleWrapper rule={doodle} />
                }
                <TransactionContainer style={contentStyle} effect={effectAppearName}>
                    <div className="d-flex flex-column gap-3" style={buttonsContainerStyle}>
                        {
                            blocks.map((x, index) => <BlockRender key={`button-${this.id}-${index}`} context={generateButtonContext(x)} />)
                        }

                        {
                            contacts.telephone != null && contacts.telephone.length > 0 &&
                            <BlockRender key={`button-${this.id}-telephone`}
                                context={generateButtonContext({
                                    blocksType: BlockType.ButtonsItem,
                                    blocksProps: [
                                        {
                                            propType: BlockPropType.LinkText,
                                            propString: phoneText,
                                        },
                                        {
                                            propType: BlockPropType.Link,
                                            propString: "tel:" + contacts.telephone,
                                        }],
                                })} />
                        }
                        {
                            contacts.mail != null && contacts.mail.length > 0 &&
                            <BlockRender key={`button-${this.id}-mail`}
                                context={generateButtonContext({
                                    blocksType: BlockType.ButtonsItem,
                                    blocksProps: [
                                        {
                                            propType: BlockPropType.LinkText,
                                            propString: "E-Mail",
                                        },
                                        {
                                            propType: BlockPropType.Link,
                                            propString: "mailto:" + contacts.mail,
                                        }],
                                })} />
                        }
                        {
                            contacts.facebook != null && contacts.facebook.length > 0 &&
                            <BlockRender key={`button-${this.id}-facebook`}
                                context={generateButtonContext({
                                    blocksType: BlockType.ButtonsItem,
                                    blocksProps: [
                                        {
                                            propType: BlockPropType.LinkText,
                                            propString: "Facebook",
                                        },
                                        {
                                            propType: BlockPropType.Link,
                                            propString: "https://www.facebook.com/" + contacts.facebook,
                                        }],
                                })} />
                        }
                        {
                            contacts.instagramId != null && contacts.instagramId.length > 0 &&
                            <BlockRender key={`button-${this.id}-instagram`}
                                context={generateButtonContext({
                                    blocksType: BlockType.ButtonsItem,
                                    blocksProps: [
                                        {
                                            propType: BlockPropType.LinkText,
                                            propString: "Instagram",
                                        },
                                        {
                                            propType: BlockPropType.Link,
                                            propString: "https://www.instagram.com/" + contacts.instagramId,
                                        }],
                                })} />
                        }
                        {
                            contacts.youtube != null && contacts.youtube.length > 0 &&
                            <BlockRender key={`button-${this.id}-youtube`}
                                context={generateButtonContext({
                                    blocksType: BlockType.ButtonsItem,
                                    blocksProps: [
                                        {
                                            propType: BlockPropType.LinkText,
                                            propString: "Youtube",
                                        },
                                        {
                                            propType: BlockPropType.Link,
                                            propString: "https://www.youtube.com/" + contacts.youtube,
                                        }],
                                })} />
                        }
                        {
                            contacts.telegramId != null && contacts.telegramId.length > 0 &&
                            <BlockRender key={`button-${this.id}-telegramId`}
                                context={generateButtonContext({
                                    blocksType: BlockType.ButtonsItem,
                                    blocksProps: [
                                        {
                                            propType: BlockPropType.LinkText,
                                            propString: "Telegram",
                                        },
                                        {
                                            propType: BlockPropType.Link,
                                            propString: "https://t.me/" + contacts.telegramId,
                                        }],
                                })} />
                        }
                        {
                            contacts.whatsAppId != null && contacts.whatsAppId.length > 0 &&
                            <BlockRender key={`button-${this.id}-whatsAppId`}
                                context={generateButtonContext({
                                    blocksType: BlockType.ButtonsItem,
                                    blocksProps: [
                                        {
                                            propType: BlockPropType.LinkText,
                                            propString: "WhatsApp",
                                        },
                                        {
                                            propType: BlockPropType.Link,
                                            propString: "https://wa.me/" + contacts.whatsAppId,
                                        }],
                                })} />
                        }
                        {
                            contacts.twitterId != null && contacts.twitterId.length > 0 &&
                            <BlockRender key={`button-${this.id}-twitterId`}
                                context={generateButtonContext({
                                    blocksType: BlockType.ButtonsItem,
                                    blocksProps: [
                                        {
                                            propType: BlockPropType.LinkText,
                                            propString: "X",
                                        },
                                        {
                                            propType: BlockPropType.Link,
                                            propString: "https://twitter.com/" + contacts.twitterId,
                                        }],
                                })} />
                        }
                        {
                            contacts.pinterestId != null && contacts.pinterestId.length > 0 &&
                            <BlockRender key={`button-${this.id}-pinterestId`}
                                context={generateButtonContext({
                                    blocksType: BlockType.ButtonsItem,
                                    blocksProps: [
                                        {
                                            propType: BlockPropType.LinkText,
                                            propString: "Pinterest",
                                        },
                                        {
                                            propType: BlockPropType.Link,
                                            propString: "https://pinterest.com/" + contacts.pinterestId,
                                        }],
                                })} />
                        }
                        {
                            contacts.linkedinId != null && contacts.linkedinId.length > 0 &&
                            <BlockRender key={`button-${this.id}-linkedinId`}
                                context={generateButtonContext({
                                    blocksType: BlockType.ButtonsItem,
                                    blocksProps: [
                                        {
                                            propType: BlockPropType.LinkText,
                                            propString: "LinkedIn",
                                        },
                                        {
                                            propType: BlockPropType.Link,
                                            propString: "https://www.linkedin.com/company/" + contacts.linkedinId,
                                        }],
                                })} />
                        }
                    </div>
                </TransactionContainer>

                {
                    separatorType != null &&
                    <SeparatorContainer context={this.props.context} separatorType={separatorType} />
                }

            </div>
        );
    }
}