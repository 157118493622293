import { IPageRenderedBlockProperty, IPageBlockProperty, IPageRenderedBlock, IPageBlock } from "../../models/pageLayoutModel";
import { BlockPropType } from "../../models/blockType";
import CommonDesignModel from "./commonDesignModel";
import { CurrentLocationProvider } from "../currentLocationProvider";
import { BlockRenderType } from "./commonDesignModel";
import { IBlockModel } from "../modelsHelper";
import Style from "../style";
import RenderBlockContext from "../../controls/renders/renderBlockContext";

export class CardItemContentModel implements IBlockModel {

    private title: string;
    private content: string;
    private imageId: string;
    private imageAltText: string;
    private blocks: IPageRenderedBlock[];

    getTitle(): string { return this.title; }
    getContent(): string { return this.content; }
    getImageId(): string { return this.imageId; }
    getImageAltText(): string { return this.imageAltText; }
    getBlocks(): IPageRenderedBlock[] { return this.blocks; }

    initialize(properties: IPageRenderedBlockProperty[]) {

        let title: IPageRenderedBlockProperty = null;
        let content: IPageRenderedBlockProperty = null;
        let image: IPageRenderedBlockProperty = null;
        let imageAltText: IPageRenderedBlockProperty = null;
        let blockCollection: IPageRenderedBlockProperty = null; 
        
        for (let index = 0; index < properties.length; index++) {

            const property = properties[index];

            switch (property.propType) {
            case BlockPropType.Title:
                title = property;
                break;
            case BlockPropType.Content:
                content = property;
                break;
            case BlockPropType.Image:
                image = property;
                break;
            case BlockPropType.ImageAltText:
                imageAltText = property;
                break;
            case BlockPropType.Collection:
                blockCollection = property;
                break;
            default:
                break;
            }
        }

        this.title = title?.propString;
        this.content = content?.propString;
        this.imageId = image?.propString;
        this.imageAltText = imageAltText?.propString;
        this.blocks = blockCollection?.propChildren ?? new Array<IPageRenderedBlock>();
    }

    formatBlock(properties: IPageBlockProperty[]): IPageBlockProperty[] {

        let title: IPageBlockProperty = null;
        let content: IPageBlockProperty = null;
        let image: IPageBlockProperty = null;
        let imageAltText: IPageBlockProperty = null;
        let blocks: IPageBlockProperty = null;

        for (let index = 0; index < properties.length; index++) {

            const property = properties[index];

            switch (property.propType) {
            case BlockPropType.Title:
                title = property;
                break;
            case BlockPropType.Content:
                content = property;
                break;
            case BlockPropType.Image:
                image = property;
                break;
            case BlockPropType.ImageAltText:
                imageAltText = property;
                break;
            case BlockPropType.Collection:
                blocks = property;
                break;
            default:
                break;
            }
        }

        if (title == null) {
            title = { propType: BlockPropType.Title };
        }

        if (content == null) {
            content = { propType: BlockPropType.Content };
        }

        if (image == null) {
            image = { propType: BlockPropType.Image };
        }

        if (imageAltText == null) {
            imageAltText = { propType: BlockPropType.ImageAltText };
        }

        if (blocks == null) {
            blocks = { propType: BlockPropType.Collection };
        }

        if (blocks.propChildren == null) {
            blocks.propChildren = new Array<IPageBlock>();
        }

        return [title, content, image, imageAltText, blocks ];
    }
}

export class CardItemDesignModel extends CommonDesignModel {

    constructor() {
        super();

        this.allowBackground = true;
        this.allowContentWidth = false;
        this.allowContentMargin = false;
        this.allowBlockHieghtAndMargin = false;
        this.allowTextAndButtonsAlignment = true;
        this.allowHashNavigation = false;
        this.allowHorizontalAlignment = false;
        this.allowVerticalAlignment = false;
        this.allowTextStyle = true;
        this.allowDoodle = false;
        this.allowAppearEffect = true;
    }

    public setDefaultValues(renderContext: RenderBlockContext) {

        super.setDefaultValues(renderContext);

        this.defaultVerticalAlignment = "top";
        this.defaultTextAndButtonsAlignment = "middle";
    }

    generateBackgroundStyle(context: RenderBlockContext): Style {

        const result = super.generateBackgroundStyle(context);
        result.padding = "0";
        result.display = "flex";
        result.flexDirection = "column";
        
        return result;
    }

    generateContentStyle(context: RenderBlockContext): Style {

        const result = super.generateContentStyle(context);
        result.display = "flex";
        result.flexDirection = "column";
        result.gridGap = "0.5rem";
        result.width = "100%";

        if (this.allowBackground && (this.borderColor != null || this.backgroundShadow || this.backgroundColor != null || this.backgroundImage != null)) {
            result.padding = "var(--small-marge)";
        }

        return result;
    }
}