import "reflect-metadata";

import "reflect-metadata";
import { injectable } from "inversify";

import UserInfo from "./userInfo";
import { PagesInfoProvider } from "./pagesInfoProvider";
import { PointsInfoProvider } from "./pointsInfoProvider";
import { CurrentLocationProvider } from "./currentLocationProvider";
import PointPageEnvironment from "../controls/renders/pointPageEnvironment";
import RenderBlockContext from "../controls/renders/renderBlockContext";
import { SectionType } from "../controls/renders/renderBlockContext";
import { WebpageColorStyle, WebpageStyleType, ColorSchema } from "../controls/renders/pointPageEnvironment";

@injectable()
export class TestEnvironmentProvider {

    public pagesInfoProvider: PagesInfoProvider;
    public pointsProvider: PointsInfoProvider;
    public currentLocation: CurrentLocationProvider;
    public userInfo: UserInfo;
    
    public async getPagePreviewEnvironment(pointId: string, pageId: string): Promise<PointPageEnvironment>  {

        const environment = await this.getPreviewEnvironmentAsync(pointId, false, pageId);
        return environment;
    }

    public async getPostPreviewEnvironment(pointId: string, postId: string): Promise<PointPageEnvironment>  {

        const environment = await this.getPreviewEnvironmentAsync(pointId, true, postId);
        return environment;
    }

    private async getPreviewEnvironmentAsync(pointId: string, isPost: boolean, targetId: string): Promise<PointPageEnvironment> {

        const pointDetails = await this.pointsProvider.getPoint(pointId);
        const pointLanguage = await this.pointsProvider.getPointLanguage(pointId);
        const pointContacts = await this.pointsProvider.getPointContacts(pointId);
        const pages = await this.pagesInfoProvider.getAllPages(pointId);
        
        const activeDomain = pointDetails.externalDomain != null && pointDetails.externalDomain.length > 0 ?
            pointDetails.externalDomain : pointDetails.domain;

        let activePagePath: string = "";
        let activePageType: number = 0;
        if (targetId != null && targetId.length > 0) {
            if (isPost) {
                activePagePath = "blog/" + targetId;
            } else {
                const settings = await this.pagesInfoProvider.getPageNameSettings(targetId);
                activePagePath = settings.path;
                activePageType = settings.type;
            }
        }

        const locationProvider = new CurrentLocationProvider();
        locationProvider.initialize(activeDomain, activePagePath);

        const environment = new PointPageEnvironment(locationProvider, this.pagesInfoProvider, this.userInfo,
            {
                pointId: pointDetails.id,

                pointName: pointDetails.name,
                pointLanguages: pointLanguage.languages,
                pointFavicon: pointDetails.favicon,

                navigationPages: pages.filter(x => x.isVisible && x.showOnNavbar),
                navigationHelpPages: [],

                isPost: isPost,
                language: pointLanguage.defaultLanguage,

                content: "",
                innerContent: "",
                visualStyle: 0,
                businessType: 0,
                colors: "",
                pageType: activePageType,

                publishDate: "",
                path: activePagePath,
                id: targetId,

                address: pointContacts.address,
                telephone: pointContacts.telephone,
                mail: pointContacts.mail,
                facebook: pointContacts.facebook,
                youtube: pointContacts.youtube,
                instagramId: pointContacts.instagramId,
                telegramId: pointContacts.telegramId,
                whatsAppId: pointContacts.whatsAppId,
                twitterId: pointContacts.twitterId,
                pinterestId: pointContacts.pinterestId,
                linkedinId: pointContacts.linkedinId,

                seoTitle: "Title",
                seoDescription: "Description",
                seoImageId: "",
            },
            true);

        return environment;
    }

    public getPreviewEnvironment(): PointPageEnvironment {

        const environment = new PointPageEnvironment(null, null, null,
            {
                pointId: null,

                pointName: null,
                pointLanguages: [],
                pointFavicon: null,

                navigationPages: [],
                navigationHelpPages: [],

                isPost: false,
                language: null,

                content: null,
                innerContent: null,
                visualStyle: 0,
                businessType: 0,
                colors: "",
                pageType: 0,

                publishDate: null,
                path: null,
                id: null,

                address: null,
                telephone: null,
                mail: null,
                facebook: null,
                youtube: null,
                instagramId: null,
                telegramId: null,
                whatsAppId: null,
                twitterId: null,
                pinterestId: null,
                linkedinId: null,

                seoTitle: null,
                seoDescription: null,
                seoImageId: null,
            }, true);

        return environment;
    }

    public getPreviewContext(): RenderBlockContext {
        return this.getPreviewContextWithLocation(null);
    }

    public getPreviewContextWithLocation(currentLocation: CurrentLocationProvider): RenderBlockContext {

        const environment = new PointPageEnvironment(currentLocation, null, null,
            {
                pointId: null,

                pointName: null,
                pointLanguages: [],
                pointFavicon: null,

                navigationPages: [],
                navigationHelpPages: [],

                isPost: false,
                language: null,

                content: null,
                innerContent: null,
                visualStyle: 0,
                businessType: 0,
                colors: "",
                pageType: 0,

                publishDate: null,
                path: null,
                id: null,

                address: null,
                telephone: null,
                mail: null,
                facebook: null,
                youtube: null,
                instagramId: null,
                telegramId: null,
                whatsAppId: null,
                twitterId: null,
                pinterestId: null,
                linkedinId: null,

                seoTitle: null,
                seoDescription: null,
                seoImageId: null,
            }, true);

        return {
            environment: environment,

            prevBlock: null,
            nextBlock: null,
            parentBlock: null,

            parentBlockWidth: null,
            parentBlockColor: null,
            parentBlockAnimation: null,

            rootSectionType: SectionType.Regular,

            targetBlock: null,
            targetBlockType: null,
            targetColorSchema: environment.getBackgroundColorSchema(),
            targetHasBackgroundImage: false,

            commonProperties: [],
        };
    }
}