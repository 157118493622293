import { Property } from "csstype";

export default class Style {
    width: string;
    maxWidth: string;
    height: string;
    minHeight: string;
    borderRadius: string;
    borderColor: string;
    borderStyle: string;
    borderWidth: string;
    backgroundColor: string;
    backgroundImage: string;
    backgroundRepeat: string;
    backgroundSize: string;
    backgroundPosition: string;
    left: string;
    top: string;
    color: string;
    textShadow: string;
    display: string;
    flexDirection: Property.FlexDirection | undefined;
    flex: string;
    gridTemplateColumns: string;
    gridTemplateRows: string;
    gridArea: string;
    gridGap: string;
    alignItems: string;
    alignSelf: string;
    margin: string;
    padding: string;
    boxShadow: string;
    minWidth: string;
    maxHeight: string;
    objectFit: Property.ObjectFit | undefined;
    position: Property.Position | undefined;
    zIndex: Property.ZIndex | undefined;
    textAlign: Property.TextAlign | undefined;
    justifyContent: string;
}