import { IPageBlockProperty } from "../../models/pageLayoutModel";
import { IDoodle } from "./IDoodle";
import { BlockPropType } from "../blockType";

export default class Squares implements IDoodle {

    public colorFill: string;
    public colorBorder: string;

    getDoodle(): string {
        return `
:doodle {
			@grid: 6x1 / 100vw 100%;
			overflow:hidden;
		}
    
--size:@rand(100px, 200px);

position: absolute;
display: block;
list-style: none;
width: @var(--size);
height: @var(--size);
animation: doodle-squares-animate 19s linear @rand(-19, 0)s infinite;
@place-cell: @rand(0, 100)% calc(100% + @var(--size));
background: ${this.colorFill};
border: 2px solid ${this.colorBorder};

@keyframes doodle-squares-animate {
    0%{
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }
    100%{
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }
}
`;
    }

    formatProperties(): IPageBlockProperty[] {

        const result = new Array<IPageBlockProperty>();
        result.push({
            propType: BlockPropType.DoodleSquaresColorFill,
            propString: this.colorFill ?? "transparent",
        });
        result.push({
            propType: BlockPropType.DoodleSquaresColorBorder,
            propString: this.colorBorder ?? "transparent",
        });
        
        return result;
    }
}