import { IPageBlockProperty, IPageRenderedBlockProperty } from "../pageLayoutModel";
import CommonDesignModel from "./commonDesignModel";
import { IBlockModel } from "../modelsHelper";
import RenderBlockContext from "../../controls/renders/renderBlockContext";

export class HelpPageContentModel implements IBlockModel {

    initialize(properties: IPageRenderedBlockProperty[]) { }

    formatBlock(properties: IPageBlockProperty[]): IPageBlockProperty[] {
        return [];
    }
}

export class HelpPageDesignModel extends CommonDesignModel {

    constructor() {
        super();

        this.allowBackground = false;
        this.allowContentWidth = false;
        this.allowContentMargin = false;
        this.allowBlockHieghtAndMargin = false;
        this.allowHashNavigation = false;
        this.allowHorizontalAlignment = false;
        this.allowVerticalAlignment = false;
        this.allowTextStyle = false;
        this.allowAppearEffect = false;
    }

    public setDefaultValues(renderContext: RenderBlockContext) {
        super.setDefaultValues(renderContext);

        this.defaultWidth = "max";
        this.defaultHorizontalAlignment = "fill";
        this.defaultContentMarginBottom = "none";
        this.defaultContentMarginTop = "none";
    }
}