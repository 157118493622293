import React from 'react';
import { BlockPropType } from '../../models/blockType';
import { isTransparent } from '../../utils/colorUtils';
import DoodleWrapper from '../common/doodleWrapper';

import BlockComponent from "../blocks/blockComponent";

export default class SeparatorSpikes extends BlockComponent {

    render() {

        // bottom color
        let bottomColor: string = null;
        if (this.props.context.nextBlock != null) {
            bottomColor = this.props.context.nextBlock.targetBlock.blocksProps.find(x => x.propType === BlockPropType.BackgroundColor)?.propString ??
                this.props.context.nextBlock.targetColorSchema?.background.getColor();
        }

        const isBottomTransparent = isTransparent(bottomColor);
        const bottomColorResolved = isBottomTransparent ? "white" : bottomColor;

        const rule = `:doodle { background: transparent; }

                    @grid: 1 / 100vw 25px;
                    background: @svg(
                        viewBox: 0 0 50 25;
                        fill: ${bottomColorResolved};
                        path { d: M0 25 25 0 50 25; };);`;

        return (
            <div style={{ height: "25px" }} className="position-relative mb-negative">
                <DoodleWrapper rule={rule} />
            </div>
        );
    }
}