import React from 'react';
import { Container, Row, Col, Stack } from "react-bootstrap";

import { ThemeTimelinePoints, TimelinePointsContentModel, TimelinePointsDesignModel } from "../../models/blocks/timelinePointsModel";
import { BlockType, BlockPropType } from "../../models/blockType";
import BlockRender from "../renders/blockRender";
import genUniqueId from "../../models/utils/genUniqueId";
import DoodleWrapper from "../common/doodleWrapper";
import BlockComponent from './blockComponent';
import RenderBlockContext from "../renders/renderBlockContext";
import SeparatorContainer from '../separators/separatorContainer';
import { IPageRenderedBlockProperty } from "../../models/pageLayoutModel";
import Style from "../../models/style";

export default class EditorTimelinePoints extends BlockComponent {

    private id: string = genUniqueId();

    render() {

        // content
        const block = new TimelinePointsContentModel();
        block.initialize(this.props.context.targetBlock.blocksProps);

        const blockCollection = block.getBlocks();

        // design
        const designProperties = this.props.context.commonProperties
            .filter(x => x.propType !== BlockPropType.TextAndButtonsAlignment)
            .concat(this.props.context.targetBlock.blocksProps);

        const designModel = new TimelinePointsDesignModel();
        designModel.setDefaultValues(this.props.context);
        designModel.setCustomValues(designProperties);

        const backgroundStyle = designModel.generateBackgroundStyle(this.props.context);
        const contentStyle = designModel.generateContentStyle(this.props.context);
        const textStyle = designModel.generateTextStyle();
        const doodle = designModel.generateDoodle();
        const hashNavigation = designModel.generateHashNavigationName();
        const childCommonProps = designModel.formatCommonProps(designProperties);
        const effectAppearName = designModel.generateEffectAppear();
        const separatorType = designModel.getSeparator();
        const theme = designModel.getTheme();

        const resolvedContentWidth = designModel.getResolvedContentWidth();
        const resolvedBackgroundColor = designModel.getActiveBackgroundColor();

        const children = blockCollection.map((x, index) => {

            const context: RenderBlockContext =
            {
                environment: this.props.context.environment,

                prevBlock: null,
                nextBlock: null,
                parentBlock: this.props.context,

                parentBlockWidth: resolvedContentWidth,
                parentBlockColor: resolvedBackgroundColor,
                parentBlockAnimation: effectAppearName,

                rootSectionType: this.props.context.rootSectionType,

                targetBlock: x,
                targetBlockType: BlockType.TimelineWithTitleItem,
                targetColorSchema: this.props.context.targetColorSchema,
                targetHasBackgroundImage: false,

                commonProperties: childCommonProps,
            };

            return context;
        });

        return (
            <div id={hashNavigation} style={backgroundStyle}>
                {
                    doodle != null &&
                    <DoodleWrapper rule={doodle} />
                }

                <div style={contentStyle}>
                    {
                        theme === "smallNumberCenter" ? this.renderChildrenForSmallNumberCenter(children, childCommonProps, textStyle) :
                        theme === "largeNumbersLeft" ? this.renderChildrenForLargeNumbersLeft(children, textStyle) :
                            this.renderChildrenForSmallNumberLeft(children, textStyle)
                    }
                </div>

                {
                    separatorType != null &&
                    <SeparatorContainer context={this.props.context} separatorType={separatorType} />
                }
            </div>
        );
    }

    renderChildrenForSmallNumberCenter(children: RenderBlockContext[], childCommonProps: IPageRenderedBlockProperty[], textStyle: Style): React.ReactElement {

        const childCommonPropsLeftSide: IPageRenderedBlockProperty[] = [{
            propType: BlockPropType.TextAndButtonsAlignment,
            propString: "right",
            propNumber: null,
            propBool: null,
            propChildren: null,
        } as IPageRenderedBlockProperty].concat(childCommonProps);

        const childCommonPropsRightSide: IPageRenderedBlockProperty[] = [{
            propType: BlockPropType.TextAndButtonsAlignment,
            propString: "left",
            propNumber: null,
            propBool: null,
            propChildren: null,
        } as IPageRenderedBlockProperty].concat(childCommonProps);

        return <React.Fragment>
                   {
                       children.map((context, index) => {

                           const leftSide = index % 2 === 0;
                           const isNotLastItem = index < children.length - 1;

                           return (
                               <React.Fragment key={`timeLinePoint-${this.id}-${index}`}>
                                   <Row className="mx-0 d-none d-md-flex" >
                                       <Col className="px-0">
                                           {
                                               leftSide &&
                                               <div className={isNotLastItem ? "mb-3" : ""}>
                                                   <BlockRender context={{
                                                       environment: context.environment,

                                                       prevBlock: context.prevBlock,
                                                       nextBlock: context.nextBlock,
                                                       parentBlock: context.parentBlock,

                                                       parentBlockWidth: context.parentBlockWidth,
                                                       parentBlockColor: context.parentBlockColor,
                                                       parentBlockAnimation: context.parentBlockAnimation,

                                                       rootSectionType: context.rootSectionType,

                                                       targetBlock: context.targetBlock,
                                                       targetBlockType: context.targetBlockType,
                                                       targetColorSchema: context.targetColorSchema,
                                                       targetHasBackgroundImage: false,

                                                       commonProperties: childCommonPropsLeftSide,
                                                   }} />
                                               </div>
                                           }
                                       </Col>
                                       <Col xs="auto">
                                           <Stack style={{ "width": "36px", color: textStyle.color }} className="h-100">
                                               <div className="border-circle">{index + 1}</div>
                                               <div className="py-2 flex-fill">
                                                   {index < children.length - 1 && <div className="vertical-line" />}
                                               </div>
                                           </Stack>
                                       </Col>
                                       <Col className="px-0">
                                           {
                                               !leftSide &&
                                               <div className={isNotLastItem ? "mb-3" : ""}>
                                                   <BlockRender context={{
                                                       environment: context.environment,

                                                       prevBlock: context.prevBlock,
                                                       nextBlock: context.nextBlock,
                                                       parentBlock: context.parentBlock,

                                                       parentBlockWidth: context.parentBlockWidth,
                                                       parentBlockColor: context.parentBlockColor,
                                                       parentBlockAnimation: context.parentBlockAnimation,

                                                       rootSectionType: context.rootSectionType,

                                                       targetBlock: context.targetBlock,
                                                       targetBlockType: context.targetBlockType,
                                                       targetColorSchema: context.targetColorSchema,
                                                       targetHasBackgroundImage: false,

                                                       commonProperties: childCommonPropsRightSide,
                                                   }} />
                                               </div>
                                           }
                                       </Col>
                                   </Row>
                                   <Row className="mx-0 d-flex d-md-none" key={`timeLinePoint-${this.id}-${index}`}>
                                       <Col xs="auto" className="ps-0">
                                           <Stack style={{ "width": "36px", color: textStyle.color }} className="h-100">
                                               <div className="border-circle">{index + 1}</div>
                                               <div className="py-2 flex-fill">
                                                   {index < children.length - 1 && <div className="vertical-line" />}
                                               </div>
                                           </Stack>
                                       </Col>
                                       <Col className="px-0">
                                           <div className={isNotLastItem ? "mb-3" : ""}>
                                               <BlockRender context={context} />
                                           </div>
                                       </Col>
                                   </Row>
                               </React.Fragment>);
                       })
                   }
               </React.Fragment>;
    }

    private renderChildrenForLargeNumbersLeft(children: RenderBlockContext[], textStyle: Style): React.ReactElement {

        return <React.Fragment>
            {
                children.map((context, index) => {

                    return (
                        <div className="d-flex flex-row gap-3" key={`timeLinePoint-${this.id}-${index}`}>
                            <span style={{ "fontSize": "7rem", "lineHeight": "6rem", "fontFamily": 'Helvetica, sans-serif', "color": textStyle.color }} className="d-inline-block">{index + 1}</span>
                            <BlockRender context={context} />
                        </div>);
                })
            }
        </React.Fragment>;
    }

    private renderChildrenForSmallNumberLeft(children: RenderBlockContext[], textStyle: Style): React.ReactElement {

        return <React.Fragment>
            {
                children.map((context, index) => {

                    const isNotLastItem = index < children.length - 1;

                    return (
                        <div className="d-flex flex-row flex-fill gap-3 mx-0" key={`timeLinePoint-${this.id}-${index}`}>
                            <div className="d-flex flex-column" style={{ "width": "36px", color: textStyle.color }}>
                                <div className="border-circle">{index + 1}</div>
                                <div className="py-2 flex-fill">
                                    {isNotLastItem && <div className="vertical-line" />}
                                </div>
                            </div>
                            <div className={isNotLastItem ? "mb-3 w-100" : "w-100"}>
                                <BlockRender context={context} />
                            </div>
                        </div>);
                })
            }
        </React.Fragment>;
    }
}