import React from 'react';

import "reflect-metadata";
import { resolve } from 'inversify-react';

import { Navbar, Nav, Container } from "react-bootstrap";

import AccountMenu from "./accountMenu";
import UserInfo from "../models/userInfo";

export default class NavMenu extends React.Component {

    @resolve(UserInfo)
    private readonly user: UserInfo; 

    constructor(props: any) {
        super(props);
        
        this.state = {
        }
    }

    public render() {
        return (
            <Navbar bg="light">
                <Container fluid>

                    <Navbar.Brand href="/">lifeDots</Navbar.Brand>

                    <Nav className="me-auto">
                        {this.user.isLoggedIn() && <Nav.Link href="/app/dashboard">Pulpit</Nav.Link>}
                    </Nav>

                    <div className="lp-toolbar-link-right">
                        <AccountMenu />
                    </div>
                    
                </Container>
            </Navbar>
        );
    }
}